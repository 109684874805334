import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as actionsType from './constants';
import { walletActions } from './actions';
import { walletService } from './services';
import { SnackbarError, SnackbarSuccess } from '../../../../helpers/snackbar';
import i18next from 'i18next';
import { history } from '../../../../helpers/store';

export const bankSagaKey = 'bank';

const userSelector = (state) => state.authentication.user;

function* getWallet() {
  try {
    let wallet = yield call(walletService.getWallet);
    yield put(walletActions.getWalletSuccess(wallet));
  } catch (e) {
    yield put(walletActions.getWalletError());
  }
}

function* getWalletHistories(action) {
  try {
    let data = yield call(
      walletService.getWalletHistories,
      action.year,
      action.month,
    );
    yield put(walletActions.getWalletHistoriesSuccess(data));
  } catch (e) {
    yield put(walletActions.getWalletHistoriesError());
  }
}

function* getWalletCommissionsCreditNotes() {
  try {
    let data = yield call(walletService.getWalletCommissionsCreditNotes);
    yield put(walletActions.getWalletCommissionsCreditNotesSuccess(data));
  } catch (e) {
    yield put(walletActions.getWalletCommissionsCreditNotesError());
  }
}

function* transfer() {
  try {
    const user = yield select(userSelector);
    if (!user.bankAccountHolder || !user.iban) {
      yield put(walletActions.closeTransferModal());
      yield put(walletActions.transferError());
      SnackbarError(i18next.t('errorMessageCompleteBankDetails'));
      history.push(`/${i18next.language}/pro/settings/account`);
      return;
    }
    let wallet = yield call(walletService.transfer);
    yield put(walletActions.transferSuccess(wallet));
    yield put(walletActions.closeTransferModal());
    SnackbarSuccess(i18next.t('transferRegistered'));
    history.push(`/${i18next.language}/pro/commissions/transfers`);
  } catch (e) {
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
    yield put(walletActions.transferError());
  }
}

export default function* watchWallet() {
  yield takeEvery(actionsType.GET_WALLET_REQUEST, getWallet);
  yield takeEvery(actionsType.GET_WALLET_HISTORIES_REQUEST, getWalletHistories);
  yield takeEvery(
    actionsType.GET_WALLET_COMMISSIONS_CREDIT_NOTES_REQUEST,
    getWalletCommissionsCreditNotes,
  );
  yield takeEvery(actionsType.TRANSFER_REQUEST, transfer);
}
