import React from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import DialogContent from '@mui/material/DialogContent';
import SignInForm from './SignInForm';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      paddingBottom: 16,
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD',
        },
        '& .MuiDivider-root': {
          margin: -1,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiCard-root': {
          overflow: 'auto',
        },
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden',
      },
      '& h4': {
        marginBottom: 20,
      },
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
    },
    '& .submitBtn': {
      borderRadius: 0,
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const SignInModal = ({ setSignInModalOpen, signInModalOpen }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setSignInModalOpen(false);
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={signInModalOpen}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        <Trans i18nKey="signIn">Sign in</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item sm={12} xs={12}>
            <SignInForm />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default SignInModal;
