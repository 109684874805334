import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ProfilingSection from './components/ProfilingSection';
import { grey } from '@mui/material/colors';
import Divider from '@mui/material/Divider';
import Header from '../../../components/Layout/Customer/Header/Header';
import { useSEO } from '../../../helpers/useSEO';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 140,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0
    },
    '& h2': {
      fontSize: '2.1em',
      fontWeight: '500',
      marginBottom: 32,
    },
    '& h3': {
      fontSize: '1.7em',
      fontWeight: '500',
      color: grey[600],
      marginTop: 32,
      marginBottom: 16,
    },
    '& .MuiTypography-body1': {
      marginBottom: 8,
    },
    '& .MuiList-root': {
      paddingLeft: 32,
      '& .MuiListItem-root': {
        paddingBottom: 0,
      },
    },
    '& .MuiLink-root': {
      fontSize: 18,
    },
    '& .MuiDivider-root': {
      background: '#000',
      marginBottom: 24,
    },
  },
}));

const MicroFranchisee = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags(
    '/career-opportunity/micro-franchisee',
    {
      fr: '/career-opportunity/micro-franchisee',
      nl: '/career-opportunity/micro-franchisee',
      en: '/career-opportunity/micro-franchisee',
    },
  );

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t('becomePartner')}</title>
        <meta content={t('becomePartner')} property="og:title" />
        <meta
          content={t('networkOpportunityMetaDescription')}
          name="description"
        />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
        <link
          as="image"
          href={`${process.env.REACT_APP_API_RESOURCES_URL}/images/nutristionist.svg`}
          rel="preload"
        />
        <link
          as="image"
          href={`${process.env.REACT_APP_API_RESOURCES_URL}/images/delegate-nutra.svg`}
          rel="preload"
        />
        <link
          as="image"
          href={`${process.env.REACT_APP_API_RESOURCES_URL}/images/business-dev.svg`}
          rel="preload"
        />
      </Helmet>
      <Header />
      <Container maxWidth={'xl'}>
        <Grid alignItems={'flex-start'} container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/nutristionist.svg`}
              width={'100%'}
            />
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <Typography component={'h2'} variant={'h2'}>
              {t('microFranchisee1')}
            </Typography>
            <Typography color={'secondary'} component={'h2'} variant={'h2'}>
              {t('microFranchisee2')}
            </Typography>

            <Typography>{t('microFranchisee3')}</Typography>
            <Typography>{t('microFranchisee4')}</Typography>
            <Typography>{t('microFranchisee5')}</Typography>
            <Typography>{t('microFranchisee6')}</Typography>
            <ProfilingSection />
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee7')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('microFranchisee8')}</Typography>
              <Typography component={'li'}>{t('microFranchisee9')}</Typography>
              <Typography component={'li'}>{t('microFranchisee10')}</Typography>
              <Typography component={'li'}>{t('microFranchisee11')}</Typography>
              <Typography component={'li'}>{t('microFranchisee12')}</Typography>
              <Typography component={'li'}>{t('microFranchisee13')}</Typography>
              <Typography component={'li'}>{t('microFranchisee14')}</Typography>
              <Typography component={'li'}>{t('microFranchisee15')}</Typography>
              <Typography component={'li'}>{t('microFranchisee16')}</Typography>
              <Typography component={'li'}>{t('microFranchisee17')}</Typography>
              <Typography component={'li'}>{t('microFranchisee18')}</Typography>
              <Typography component={'li'}>{t('microFranchisee19')}</Typography>
              <Typography component={'li'}>{t('microFranchisee20')}</Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee21')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('microFranchisee22')}</Typography>
              <Typography component={'li'}>{t('microFranchisee23')}</Typography>
              <Typography component={'li'}>{t('microFranchisee24')}</Typography>
              <Typography component={'ul'}>
                <Typography component={'li'}>
                  {t('microFranchisee25')}
                </Typography>
                <Typography component={'li'}>
                  {t('microFranchisee26')}
                </Typography>
                <Typography component={'li'}>
                  {t('microFranchisee27')}
                </Typography>
                <Typography component={'li'}>
                  {t('microFranchisee28')}
                </Typography>
              </Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee29')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('microFranchisee30')}</Typography>
              <Typography component={'li'}>{t('microFranchisee31')}</Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee32')}
            </Typography>

            <Typography>{t('microFranchisee33')}</Typography>
            <Typography>{t('microFranchisee34')}</Typography>
            <Typography>{t('microFranchisee35')}</Typography>
            <Typography>{t('microFranchisee36')}</Typography>
            <Typography>{t('microFranchisee37')}</Typography>
            <Typography>{t('microFranchisee38')}</Typography>
            <Typography>{t('microFranchisee39')}</Typography>
            {/*<Typography>Le budget total de la formation (495 SCR), particulièrement attractif par la qualité des intervenants, nous permet d'offrir une certification reconnue en Europe.</Typography>*/}

            <ProfilingSection />
            <Divider />
          </Grid>
        </Grid>

        <Grid alignItems={'flex-start'} container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/delegate-nutra.svg`}
              width={'100%'}
            />
          </Grid>
          <Grid item md={8} sm={6} xs={12}>
            <Typography color={'secondary'} component={'h2'} variant={'h2'}>
              {t('microFranchisee40')}
            </Typography>
            <Typography>{t('microFranchisee41')}</Typography>
            <Typography>{t('microFranchisee42')}</Typography>
            <Typography>{t('microFranchisee43')}</Typography>

            <Typography>{t('microFranchisee44')}</Typography>

            <Typography>{t('microFranchisee45')}</Typography>
            <Typography>{t('microFranchisee46')}</Typography>

            <Typography>{t('microFranchisee47')}</Typography>

            <ProfilingSection />

            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee48')}
            </Typography>

            <Typography component={'ul'}>
              <Typography component={'li'}>{t('microFranchisee49')}</Typography>
              <Typography component={'li'}>{t('microFranchisee50')}</Typography>
              <Typography component={'li'}>{t('microFranchisee51')}</Typography>
              <Typography component={'li'}>{t('microFranchisee52')}</Typography>
              <Typography component={'li'}>{t('microFranchisee53')}</Typography>
              <Typography component={'li'}>{t('microFranchisee54')}</Typography>
              <Typography component={'li'}>{t('microFranchisee55')}</Typography>
              <Typography component={'li'}>{t('microFranchisee56')}</Typography>
              <Typography component={'li'}>{t('microFranchisee57')}</Typography>
              <Typography component={'li'}>{t('microFranchisee58')}</Typography>
              <Typography component={'li'}>{t('microFranchisee59')}</Typography>
              <Typography component={'li'}>{t('microFranchisee60')}</Typography>
              <Typography component={'li'}>{t('microFranchisee61')}</Typography>
              <Typography component={'li'}>{t('microFranchisee62')}</Typography>
              <Typography component={'li'}>{t('microFranchisee63')}</Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee64')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('microFranchisee65')}</Typography>
              <Typography component={'li'}>{t('microFranchisee66')}</Typography>
              <Typography component={'li'}>{t('microFranchisee67')}</Typography>
              <Typography component={'li'}>{t('microFranchisee68')}</Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee69')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('microFranchisee70')}</Typography>
              <Typography component={'li'}>{t('microFranchisee71')}</Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee72')}
            </Typography>

            <Typography>{t('microFranchisee73')}</Typography>

            <Typography>{t('microFranchisee74')}</Typography>

            <Typography>{t('microFranchisee75')}</Typography>

            <Typography>{t('microFranchisee76')}</Typography>

            <Typography>{t('microFranchisee77')}</Typography>

            <Typography>{t('microFranchisee78')}</Typography>

            <Typography>{t('microFranchisee79')}</Typography>

            <Typography>{t('microFranchisee80')}</Typography>

            {/*<Typography>Le budget total de la formation (495 SCR), particulièrement attractif par la qualité des intervenants, nous permet d'offrir une certification reconnue en Europe.</Typography>*/}

            <ProfilingSection />
            <Divider />
          </Grid>
        </Grid>

        <Grid alignItems={'flex-start'} container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/business-dev.svg`}
              width={'100%'}
            />
          </Grid>
          <Grid item md={8} sm={6} xs={12}>
            <Typography color={'secondary'} component={'h2'} variant={'h2'}>
              {t('microFranchisee81')}
            </Typography>

            <Typography>{t('microFranchisee82')}</Typography>
            <Typography>{t('microFranchisee83')}</Typography>
            <Typography>{t('microFranchisee84')}</Typography>

            <ProfilingSection />

            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee85')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('microFranchisee86')}</Typography>
              <Typography component={'li'}>{t('microFranchisee87')}</Typography>
              <Typography component={'li'}>{t('microFranchisee88')}</Typography>
              <Typography component={'li'}>{t('microFranchisee89')}</Typography>
              <Typography component={'li'}>{t('microFranchisee90')}</Typography>
              <Typography component={'li'}>{t('microFranchisee91')}</Typography>
              <Typography component={'li'}>{t('microFranchisee92')}</Typography>
              <Typography component={'li'}>{t('microFranchisee93')}</Typography>
              <Typography component={'li'}>{t('microFranchisee94')}</Typography>
              <Typography component={'li'}>{t('microFranchisee95')}</Typography>
              <Typography component={'li'}>{t('microFranchisee96')}</Typography>
              <Typography component={'li'}>{t('microFranchisee97')}</Typography>
              <Typography component={'li'}>{t('microFranchisee98')}</Typography>
              <Typography component={'li'}>{t('microFranchisee99')}</Typography>
              <Typography component={'li'}>
                {t('microFranchisee100')}
              </Typography>
              <Typography component={'li'}>
                {t('microFranchisee101')}
              </Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee102')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>
                {t('microFranchisee103')}
              </Typography>
              <Typography component={'li'}>
                {t('microFranchisee104')}
              </Typography>
              <Typography component={'li'}>
                {t('microFranchisee105')}
              </Typography>
              <Typography component={'li'}>
                {t('microFranchisee106')}
              </Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee107')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>
                {t('microFranchisee108')}
              </Typography>
              <Typography component={'li'}>
                {t('microFranchisee109')}
              </Typography>
            </Typography>

            <Typography component={'h3'} variant={'h3'}>
              {t('microFranchisee110')}
            </Typography>
            <Typography>{t('microFranchisee111')}</Typography>
            <Typography>{t('microFranchisee112')}</Typography>
            <Typography>{t('microFranchisee113')}</Typography>
            <Typography>{t('microFranchisee114')}</Typography>
            <Typography>{t('microFranchisee115')}</Typography>
            <Typography>{t('microFranchisee116')}</Typography>
            <Typography>{t('microFranchisee117')}</Typography>
            <Typography>{t('microFranchisee118')}</Typography>
            {/*<Typography>Le budget total de la formation (495 SCR), particulièrement attractif par la qualité des intervenants, nous permet d'offrir une certification reconnue en Europe.</Typography>*/}

            <ProfilingSection />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default MicroFranchisee;
