import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { userSelector } from '../../../../../Authentication/store/selectors';
import { shopActions } from '../../../../Shop/store/actions';
import { shippingAddressSelectedSelector } from '../../../../Shop/store/selectors';
import ShareCartButton from '../../../../Shop/Checkout/components/ShareCartButton';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiCard-root': {
        overflow: 'auto',
      },
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden',
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
    },
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      overflow: 'auto',
    },
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD',
      },
    },
  },
  detailsRowPrice: {
    paddingTop: 20,
  },
  note: {
    marginTop: 16,
    backgroundColor: '#e5f5f5',
    padding: 16,
    borderRadius: 16,
    '& .title': {
      color: '#007676',
      fontWeight: 'bold',
    },
    '& .ql-size-small': {
      fontSize: '0.75em',
    },
    '& .ql-size-large': {
      fontSize: '1.5em',
    },
  },
  divider: {
    //borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
}));

const OrderRecommendationDetails = ({ order }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { t } = useTranslation();
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);

  const setCartByOrder = (orderId) => {
    dispatch(
      shopActions.setCartFromOrderRequest(
        orderId,
        shippingAddressSelected.country,
      ),
    );
  };

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Grid alignItems={'center'} container spacing={2}>
          <Grid item>
            <ShareCartButton
              color={'primary'}
              order={order}
              variant={'outlined'}
            >
              <Trans i18nKey={'shareThisOrder'}>Share this order</Trans>
            </ShareCartButton>
          </Grid>
          {user.id === order.userId && (
            <Grid item>
              <Button>
                <Typography
                  color={'secondary'}
                  onClick={() => setCartByOrder(order.id)}
                  variant={'button'}
                >
                  <Trans i18nKey={'orderThisRecommendation'}>
                    Order this recommendation
                  </Trans>
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item sm={5} xs={12}>
        <List className={classes.listItems}>
          {order.products.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem className={classes.item}>
                <Grid alignItems={'center'} container justifyContent={'center'}>
                  <Grid item xs={4}>
                    <Grid
                      alignItems={'center'}
                      container
                      justifyContent={'center'}
                    >
                      <img
                        alt={item.originalProduct.detail.name}
                        height={80}
                        src={item.originalProduct.image[200]}
                        width={80}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid
                      alignItems={'center'}
                      container
                      justifyContent={'center'}
                    >
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          <b>{`${item.quantity} x `}</b>
                          {item.originalProduct.detail.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {order.products.length > 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      {order.displayedNote && (
        <Grid className={classes.note} sm={7} xs={12}>
          <Typography className={'title'} gutterBottom>
            {order.status === 'admin' ? t('sharksNote') : t('advisorNote')} :
          </Typography>
          <Typography
            component={'div'}
            dangerouslySetInnerHTML={{ __html: order.displayedNote }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default OrderRecommendationDetails;
