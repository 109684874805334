import { createTheme } from '@mui/material';
import { green, grey, purple, red } from '@mui/material/colors';

const logisticTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1025,
      lg: 1280,
      xl: 1455,
    },
  },
  palette: {
    background: {
      default: '#fafafa',
    },
    primary: {
      light: '#69696a',
      main: '#28282a',
      dark: '#1e1e1f',
    },
    secondary: {
      light: purple[600],
      main: purple[800],
      dark: purple[900],
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      light: red[50],
      main: red[500],
      dark: red[700],
    },
    success: {
      light: green[50],
      main: green[500],
      dark: green[700],
    },
  },
  typography: {
    fontFamily: [
      'Roboto',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 700,
  },
  shadows: [
    'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
    'rgba(145, 158, 171, 0.22) 0px 1px 3px 0px, rgba(145, 158, 171, 0.14) 0px 13px 26px -3px',
    'rgba(145, 158, 171, 0.24) 0px 2px 4px 0px, rgba(145, 158, 171, 0.16) 0px 14px 28px -2px',
    'rgba(145, 158, 171, 0.26) 0px 3px 5px 0px, rgba(145, 158, 171, 0.18) 0px 15px 30px -1px',
    'rgba(145, 158, 171, 0.28) 0px 4px 6px 0px, rgba(145, 158, 171, 0.20) 0px 16px 32px 0px',
    'rgba(145, 158, 171, 0.3) 0px 5px 7px 0px, rgba(145, 158, 171, 0.22) 0px 17px 34px 1px',
    'rgba(145, 158, 171, 0.32) 0px 6px 8px 0px, rgba(145, 158, 171, 0.24) 0px 18px 36px 2px',
    'rgba(145, 158, 171, 0.34) 0px 7px 9px 0px, rgba(145, 158, 171, 0.26) 0px 19px 38px 3px',
    'rgba(145, 158, 171, 0.36) 0px 8px 10px 0px, rgba(145, 158, 171, 0.28) 0px 20px 40px 4px',
  ],
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          color: grey[900],
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow:
            'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
          borderRadius: 16,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          boxShadow:
            'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px',
          borderRadius: 16,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          textTransform: 'inherit',
        },
        containedSecondary: {
          //background: 'linear-gradient(45deg, #077675 30%, #075454 90%)'
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          width: '90%',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormLabel-root': {
            //left: 10
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '8px 24px',
        },
      },
    },
  },
});

export default logisticTheme;
