import { all } from 'redux-saga/effects';
import watchAuthentication from '../containers/Authentication/store/saga';
import watchConsultants from '../containers/Customer/Consultants/store/saga';
import watchAcademy from '../containers/Customer/Academy/store/saga';
import watchWallet from '../containers/Customer/Wallet/store/saga';
import watchHome from '../containers/Customer/Home/store/saga';
import watchShop from '../containers/Customer/Shop/store/saga';
import watchMag from '../containers/Customer/Mag/store/saga';

export default function* rootSaga() {
  yield all([
    watchAuthentication(),
    watchConsultants(),
    watchAcademy(),
    watchWallet(),
    watchHome(),
    watchShop(),
    watchMag(),
  ]);
}
