export const GET_ACADEMY_PROGRAM_REQUEST =
  'ACADEMY/GET_ACADEMY_PROGRAM_REQUEST';
export const GET_ACADEMY_PROGRAM_SUCCESS =
  'ACADEMY/GET_ACADEMY_PROGRAM_SUCCESS';
export const GET_ACADEMY_PROGRAM_FAILED = 'ACADEMY/GET_ACADEMY_PROGRAM_FAILED';

export const GET_ACADEMY_PROGRAMS_REQUEST =
  'ACADEMY/GET_ACADEMY_PROGRAMS_REQUEST';
export const GET_ACADEMY_PROGRAMS_SUCCESS =
  'ACADEMY/GET_ACADEMY_PROGRAMS_SUCCESS';
export const GET_ACADEMY_PROGRAMS_FAILED =
  'ACADEMY/GET_ACADEMY_PROGRAMS_FAILED';
