import { useSelector } from 'react-redux';
import { sessionSelector } from '../containers/Customer/Shop/store/selectors';
import { useTranslation } from 'react-i18next';

export const useCustomerPath = () => {
  const { i18n } = useTranslation();
  const session = useSelector(sessionSelector);
  return `/${i18n.language}-${session?.country || 'BE'}`;
};

export const useProPath = () => {
  const { i18n } = useTranslation();
  return `/${i18n.language}`;
};
