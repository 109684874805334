import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const AwardIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        d="M17.54,23.86h0a.39.39,0,0,1-.33-.22L14,17.39a.38.38,0,0,1,.2-.51.42.42,0,0,1,.54.19l2.85,5.65,1.69-2.36a.44.44,0,0,1,.37-.16l3,.21-3.18-5.73a.39.39,0,0,1,.17-.52.43.43,0,0,1,.55.17l3.52,6.34a.37.37,0,0,1,0,.39.4.4,0,0,1-.37.16L19.84,21l-2,2.72A.41.41,0,0,1,17.54,23.86Z"
        fill="#fff"
      />
      <path
        d="M10.51,23.86a.39.39,0,0,1-.33-.17L8.21,21l-3.49.25a.4.4,0,0,1-.37-.16.37.37,0,0,1,0-.38L7.27,15a.41.41,0,0,1,.54-.17.36.36,0,0,1,.19.5L5.36,20.42l3-.22a.42.42,0,0,1,.37.16l1.7,2.36,2.62-5a.42.42,0,0,1,.54-.17.38.38,0,0,1,.19.51l-2.92,5.63a.43.43,0,0,1-.34.21Z"
        fill="#fff"
      />
      <path
        d="M13.43,18.23a.38.38,0,0,1-.27-.1l-1.22-1.05-1.61.33a.41.41,0,0,1-.45-.21l-.69-1.41-1.63-.28a.39.39,0,0,1-.33-.36l-.06-1.54-1.39-.84a.36.36,0,0,1-.16-.45l.57-1.45L5.25,9.6a.37.37,0,0,1,0-.48L6.41,8,6.06,6.47a.37.37,0,0,1,.22-.42l1.5-.65.3-1.52a.38.38,0,0,1,.38-.31l1.65-.06L11,2.21a.42.42,0,0,1,.49-.15L13,2.59l1.36-.88a.42.42,0,0,1,.51,0l1.21,1,1.61-.33a.42.42,0,0,1,.46.21l.69,1.41,1.62.27a.38.38,0,0,1,.33.36l.07,1.55,1.39.83a.39.39,0,0,1,.16.46L21.86,9l.94,1.27a.35.35,0,0,1,0,.47l-1.12,1.14L22,13.4a.38.38,0,0,1-.23.43l-1.5.64L20,16a.4.4,0,0,1-.38.31l-1.65.06-.89,1.3a.42.42,0,0,1-.48.15L15,17.28l-1.36.88A.39.39,0,0,1,13.43,18.23Zm-1.37-1.94a.4.4,0,0,1,.28.1l1.13,1,1.26-.82a.44.44,0,0,1,.37,0l1.44.5.83-1.21a.42.42,0,0,1,.33-.17l1.53-.06.28-1.42a.37.37,0,0,1,.23-.27l1.39-.6-.32-1.41a.36.36,0,0,1,.1-.34l1-1.06-.87-1.18a.34.34,0,0,1,0-.35l.53-1.35L20.27,6.8a.4.4,0,0,1-.19-.3l0-1.44L18.51,4.8a.37.37,0,0,1-.29-.22l-.64-1.3-1.5.3a.45.45,0,0,1-.37-.09l-1.12-1-1.27.82a.41.41,0,0,1-.37,0l-1.44-.5-.83,1.22a.4.4,0,0,1-.33.17l-1.53.06L8.54,5.73A.35.35,0,0,1,8.32,6l-1.4.6L7.25,8a.39.39,0,0,1-.1.34L6.1,9.41,7,10.6A.36.36,0,0,1,7,11L6.49,12.3l1.29.77a.42.42,0,0,1,.19.31L8,14.82l1.51.26a.41.41,0,0,1,.3.21l.64,1.31,1.5-.31Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
export default AwardIcon;
