import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { userService } from '../store/services';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { green } from '@mui/material/colors';
import {
  SnackbarConfirm,
  SnackbarFormattedError,
} from '../../../../helpers/snackbar';
import AddShippingAddressModal from './AddShippingAddressModal';
import UpdateShippingAddressModal from './UpdateShippingAddressModal';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  addressCard: {
    height: '100%',
    '& p.address-name': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& h2': {
      fontSize: 20,
      minHeight: 80,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiCardHeader-subheader': {
      minHeight: 48,
    },
    '& .MuiCardHeader-action': {
      '& .MuiButtonBase-root': {
        height: 40,
      },
    },
  },
  add: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
  },
  labelSelected: {
    background: green[900],
    color: '#fff',
    padding: '0 10px',
    lineHeight: '30px',
    borderRadius: 8,
  },
}));

const UserShippingAddresses = ({ onChange, user }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [shippingAddresses, setShippingAddresses] = useState([]);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateShippingAddressSelected, setUpdateShippingAddressSelected] =
    useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  useEffect(() => {
    userService
      .getUserShippingAddresses(user.id)
      .then((shippingAddresses) => setShippingAddresses(shippingAddresses));
  }, [user]);

  const handleReloadShippingAddresses = () => {
    onChange();
    userService
      .getUserShippingAddresses(user.id)
      .then((shippingAddresses) => setShippingAddresses(shippingAddresses));
  };

  const handleRemoveAddress = (address) => {
    return () => {
      SnackbarConfirm(
        <>
          Supprimer l'adresse :<br /> {address.address}
        </>,
        () => {
          userService
            .removeUserShippingAddress(user.id, address.id)
            .then(() => {
              handleReloadShippingAddresses();
            })
            .catch((e) => {
              SnackbarFormattedError(e.error);
            });
        },
      );
    };
  };

  const handleOpenUpdateDialog = (address) => {
    return () => {
      setUpdateShippingAddressSelected(address);
      setUpdateDialogOpen(true);
    };
  };

  return (
    <Grid container spacing={2}>
      {Array.isArray(shippingAddresses) &&
        shippingAddresses.map((address) => (
          <Grid item key={address.id} lg={4} md={6} xs={12}>
            <Card className={classes.addressCard}>
              <CardHeader
                action={
                  <>
                    <IconButton
                      aria-label="settings"
                      onClick={handleOpenUpdateDialog(address)}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                    {!address.byDefault && (
                      <IconButton
                        aria-label="settings"
                        onClick={handleRemoveAddress(address)}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    )}
                  </>
                }
                title={address.name ? address.name : t('address')}
              />
              <CardContent>
                <Typography className={'address-name'}>
                  {address.firstName} {address.lastName}
                </Typography>
                <Typography className={'address-text'}>
                  {`${address.address} ${
                    address.additionalInfo ? `(${address.additionalInfo})` : ``
                  }`}
                </Typography>
              </CardContent>
              <CardActions>
                {address.byDefault && (
                  <Typography className={classes.labelSelected}>
                    <Trans i18nKey={'defaultShippingAddress'}>
                      Default address
                    </Trans>
                  </Typography>
                )}
              </CardActions>
            </Card>
          </Grid>
        ))}
      <Grid item lg={4} md={6} xs={12}>
        <Card className={classnames(classes.addressCard, classes.add)}>
          <CardHeader
            action={
              <IconButton aria-label="settings">
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            }
            onClick={() => setAddDialogOpen(true)}
            subheader={
              <Trans i18nKey="addNewShippingAddress">
                Add new shipping address
              </Trans>
            }
            title={<Trans i18nKey="newAddress">New address</Trans>}
          />
        </Card>
      </Grid>
      {addDialogOpen && (
        <AddShippingAddressModal
          dialogOpen={addDialogOpen}
          onChange={handleReloadShippingAddresses}
          setDialogOpen={setAddDialogOpen}
          user={user}
        />
      )}
      {updateDialogOpen && updateShippingAddressSelected && (
        <UpdateShippingAddressModal
          dialogOpen={updateDialogOpen}
          onChange={handleReloadShippingAddresses}
          setDialogOpen={setUpdateDialogOpen}
          shippingAddress={updateShippingAddressSelected}
        />
      )}
    </Grid>
  );
};
export default UserShippingAddresses;
