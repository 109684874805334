import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SearchIcon from '@mui/icons-material/Search';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchDrawer from './SearchDrawer';
import Button from '@mui/material/Button';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { menuActions } from '../Menu/store/actions';
import SvgIcon from '@mui/material/SvgIcon';

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: '#fff',
  },
  logo: {
    textDecoration: 'none',
    '& img': {
      height: 52,
    },
  },
  navButton: {
    //marginLeft: 10,
    marginRight: 10,
    textDecoration: 'none',
    color: 'inherit',
  },
}));

const MenuIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path d="M30,7.5V10H0V7.5H30z M30,0v2.5H0V0H30z" />
      <g>
        <path d="M7.2,19.5C7.1,18.4,7.1,17,7.1,16h0c-0.3,0.9-0.6,2-1,3.1L4.6,23H3.8l-1.3-3.8C2.1,18,1.8,17,1.6,16h0c0,1-0.1,2.4-0.2,3.6l-0.2,3.5h-1L0.7,15h1.3l1.4,3.9c0.3,1,0.6,1.9,0.8,2.7h0c0.2-0.8,0.5-1.7,0.9-2.7L6.6,15h1.3l0.5,8.1h-1L7.2,19.5z" />
        <path d="M14.2,19.3h-3.1v2.9h3.5v0.9H10V15h4.4v0.9h-3.3v2.6h3.1V19.3z" />
        <path d="M15.9,23.1V15h1.1l2.6,4.1c0.6,0.9,1.1,1.8,1.5,2.6l0,0c-0.1-1.1-0.1-2.1-0.1-3.3V15h1v8.1H21L18.4,19c-0.6-0.9-1.1-1.8-1.5-2.7l0,0c0.1,1,0.1,2,0.1,3.3v3.5H15.9z" />
        <path d="M24.9,15v4.8c0,1.8,0.8,2.6,1.9,2.6c1.2,0,2-0.8,2-2.6V15h1.1v4.7c0,2.5-1.3,3.5-3.1,3.5c-1.7,0-2.9-0.9-2.9-3.5V15H24.9z" />
      </g>
    </SvgIcon>
  );
};

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [searchDrawerOpen, setSearchDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    dispatch(menuActions.toggleMenu());
  };

  return (
    <AppBar
      elevation={1}
      position="sticky"
      sx={(theme) => ({
        zIndex: theme.zIndex.drawer + 1,
        boxShadow: 'rgba(0, 0, 0, 0.18) 0px 1px 2px !important;',
      })}
    >
      <Toolbar className={classes.appBar}>
        <Grid alignItems="center" container>
          <Grid item sm={6} xs={2}>
            <Hidden implementation={'css'} lgUp>
              <IconButton
                aria-label="open drawer"
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={(theme) => ({
                  marginRight: theme.spacing(2),
                  color: '#000',
                })}
              >
                <MenuIcon sx={{ width: '1.6em' }} />
              </IconButton>
            </Hidden>
            <Hidden implementation={'css'} lgDown>
              <Link className={classes.logo} to="/admin">
                <img
                  alt=""
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.jpeg`}
                />
              </Link>
            </Hidden>
          </Grid>
          <Grid item sm={6} xs={10}>
            <Grid alignItems="center" container justifyContent={'flex-end'}>
              <Button
                className={classes.navButton}
                onClick={() => setSearchDrawerOpen(true)}
                variant="text"
              >
                <SearchIcon />
              </Button>
              <Button
                className={classes.navButton}
                component={Link}
                to={'/admin/users/add'}
                variant="text"
              >
                <PersonAddIcon />
              </Button>
              <Button
                className={classes.navButton}
                onClick={() => history.push('/')}
              >
                <ExitToAppIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <SearchDrawer
          searchDrawerOpen={searchDrawerOpen}
          setSearchDrawerOpen={setSearchDrawerOpen}
        />
      </Toolbar>
    </AppBar>
  );
}
