import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { giftCardSelector } from '../store/selectors';

const ItemPrice = ({
  className,
  component,
  item,
  session,
  variant,
  view,
  withDiscount,
  withMultiplier,
  withTaxes,
  withoutDiscountMessage,
  withoutLimitedMessage,
}) => {
  const discountCode = useSelector(giftCardSelector);
  const { i18n, t } = useTranslation();
  const [priceTaxIncluded, setPriceTaxIncluded] = useState(
    parseFloat(
      withMultiplier
        ? item.priceAndTaxDetails?.final.price +
            item.priceAndTaxDetails?.final.taxes
        : item.priceAndTaxDetails?.base.price +
            item.priceAndTaxDetails?.base.taxes,
    ),
  );
  const [discountMultiplier, setDiscountMultiplier] = useState(1);

  useEffect(() => {
    if ((!withTaxes || !session.hasTaxes) && !item.taxesMandatory) {
      setPriceTaxIncluded(
        parseFloat(
          withMultiplier
            ? item.priceAndTaxDetails?.final.price
            : item.priceAndTaxDetails?.base.price,
        ),
      );
    }

    if (withDiscount && !session.isPro && discountCode) {
      setDiscountMultiplier(parseFloat((100 - discountCode.amount) / 100));
    }
  }, [session, item]);

  if (!priceTaxIncluded) {
    return null;
  }

  return (
    <Typography className={className} component={component} variant={variant}>
      {`${(
        Math.round(Math.round(priceTaxIncluded * discountMultiplier * 100)) /
        100
      ).toFixed(2)} ${item.currency}`}
      {!withoutLimitedMessage &&
        withMultiplier &&
        item.priceAndTaxDetails.hasLimitedCommissions && (
          <>
            {view === 'large' && (
              <span className={'limitedCommissions'} key={'large'}>
                {`${t('limitedCommissions')}: ${
                  Math.round(item.priceAndTaxDetails.multiplier * 1000) / 10
                }%`}
              </span>
            )}
            {view === 'small' && (
              <span className={'limitedCommissions'} key={'small'}>
                {`${t('limited')}: ${
                  Math.round(item.priceAndTaxDetails.multiplier * 1000) / 10
                }%`}
              </span>
            )}
          </>
        )}
      {!withoutDiscountMessage &&
        withDiscount &&
        discountCode?.displayedName && (
          <span className={'discount'}>
            {discountCode.displayedName[i18n.language]
              ? discountCode.displayedName[i18n.language]
              : t('discount')}
            : - {Math.round((1 - discountMultiplier) * 1000) / 10}%
          </span>
        )}
    </Typography>
  );
};
export default ItemPrice;
