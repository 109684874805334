import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import FormValidator from '../../../../components/Validators/FormValidator';
import SearchUserAutocomplete from '../../Users/components/SearchUserAutocomplete';
import { SnackbarConfirm } from '../../../../helpers/snackbar';
import { userService } from '../store/services';
import DialogContent from '@mui/material/DialogContent';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD',
        },
        '& .MuiDivider-root': {
          margin: -1,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiCard-root': {
          overflow: 'auto',
        },
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden',
      },
      '& h4': {
        marginBottom: 20,
      },
      '& .MuiInputBase-input': {
        backgroundColor: '#fff',
      },
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
    },
  },
  form: {
    '& .enterBtn': {
      marginTop: 42,
    },
    '& .secondaryLink': {
      marginTop: 15,
      '& .MuiButton-label': {
        lineHeight: 1.5,
        fontSize: '1rem',
      },
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const UserTransferModal = ({ onSuccess, open, setOpen, user }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [pro, setPro] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleUserChange = (e, pro) => {
    setPro(pro);
  };

  const handleSubmit = () => {
    if (pro) {
      SnackbarConfirm(`Tansférer l'utilisateur ?`, () => {
        userService.transferUser(user, pro).then(() => {
          onSuccess();
          setOpen(false);
        });
      });
    }
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        Transfert d'utilisateur
      </DialogTitle>
      <FormValidator
        className={classes.form}
        noValidate
        onError={(errors) => console.log(errors)}
        onSubmit={handleSubmit}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {`Veuillez séléctionner le pro en charge de l'utilisateur : `}
                <b>
                  {user.firstName} {user.lastName}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SearchUserAutocomplete
                autoFocus
                onChange={handleUserChange}
                target={'pro'}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" fullWidth type="submit" variant="contained">
            Effectuer le tranfert
          </Button>
        </DialogActions>
      </FormValidator>
    </Dialog>
  );
};

export default UserTransferModal;
