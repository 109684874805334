import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WidgetsTwoToneIcon from '@mui/icons-material/WidgetsTwoTone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import AllOutIcon from '@mui/icons-material/AllOut';
import Hidden from '@mui/material/Hidden';
import { purple } from '@mui/material/colors';
import MenuItem from '../Menu/MenuItem';
import Menu from '../Menu/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { menuIsOpenSelector } from '../Menu/store/selectors';
import { menuActions } from '../Menu/store/actions';
import { grey } from '@mui/material/colors';
import { isSuperAdmin } from '../../../containers/Authentication/store/selectors';
import { red } from '@mui/material/colors';

const drawerWidth = 240;

const useStylesDrawerPro = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    '& .MuiToolbar-root': {
      '& img': {
        marginTop: 8,
        width: '100%',
      },
    },
  },
  drawerContainer: {
    overflow: 'auto',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& ul': {
      '& .active': {
        backgroundColor: grey[200],
      },
    },
    '& .MuiCollapse-wrapper': {
      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50,
      },
    },
    '& .MuiListItem-button': {
      height: '80px',
      '& .MuiSvgIcon-root': {
        fontSize: '1.7em',
        fill: purple[900],
        '&.redColor': {
          fill: red[900],
        },
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const MenuDrawer = () => {
  const classes = useStylesDrawerPro();
  const dispatch = useDispatch();
  const menuIsOpen = useSelector(menuIsOpenSelector);
  const userIsSuperAdmin = useSelector(isSuperAdmin);

  const handleDrawerToggle = () => {
    dispatch(menuActions.toggleMenu());
  };

  const drawerList = (
    <div className={classes.drawerContainer}>
      <Menu onItemSelected={handleDrawerToggle}>
        <MenuItem
          expandTo={'/logistic/orders/todo'}
          icon={<EventNoteOutlinedIcon />}
          title={'En attente'}
          to={'/logistic/orders/todo'}
        />
        <MenuItem
          expandTo={'/logistic/orders/processing'}
          icon={<ScheduleIcon />}
          title={'Préparées en attente de livraison'}
          to={'/logistic/orders/processing'}
        />
        <MenuItem
          expandTo={'/logistic/orders/backToSender'}
          icon={<ReplayTwoToneIcon />}
          title={"Retour à l'expéditeur"}
          to={'/logistic/orders/backToSender'}
        />
        <MenuItem
          expandTo={'/logistic/orders/emergencies'}
          icon={<ErrorOutlineOutlinedIcon className={'redColor'} />}
          title={'Urgences'}
          to={'/logistic/orders/emergencies'}
        />
        <MenuItem
          expandTo={'/logistic/orders/errored'}
          hidden={!userIsSuperAdmin}
          icon={<ErrorOutlineOutlinedIcon className={'redColor'} />}
          title={'Erreurs'}
          to={'/logistic/orders/errored'}
        />
        <MenuItem
          expandTo={'/logistic/orders/done'}
          icon={<AllOutIcon />}
          title={'Tous les envois'}
          to={'/logistic/orders/done'}
        />
      </Menu>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden implementation="js" lgUp>
        <Drawer
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          open={menuIsOpen}
          sx={(theme) => ({ zIndex: theme.zIndex.drawer + 2 })}
          variant="temporary"
        >
          <Toolbar>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.jpeg`}
            />
          </Toolbar>
          {drawerList}
        </Drawer>
      </Hidden>
      <Hidden implementation="js" lgDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          open
          variant="permanent"
        >
          <Toolbar />
          {drawerList}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MenuDrawer;
