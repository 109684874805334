import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { shopActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelector, shopCategoriesSelector } from '../store/selectors';
import { Link } from '../../../../helpers/multilingual';
import { getFormalizedName } from '../helpers/helper';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';

const useStyles = makeStyles(() => ({
  solutions: {
    paddingTop: 32,
    paddingBottom: 32,
    '& .solutionTitle': {
      fontSize: 20,
      marginRight: 40,
      color: '#999',
      fontWeight: 600,
    },
    '& .solution': {
      border: '1px dashed #999',
      borderRadius: '50%',
      padding: 4,
      position: 'relative',
      display: 'inline-block',
      '&:hover': {
        border: '1px solid #999',
        '& .overlay-highlight': {
          opacity: 0.1,
        },
      },
    },
    '& .title': {
      marginTop: 8,
      fontWeight: 500,
    },
    '& .MuiPaper-root': {
      boxShadow: 'none',
      height: 60,
      width: 60,
      //display: 'inline-block',
      borderRadius: '50%',
      color: '#fff',
      textAlign: 'center',
      fontSize: '2em',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

const CategoriesSolutions = ({ categoryId, fullWidth }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const session = useSelector(sessionSelector);
  const categories = useSelector(shopCategoriesSelector);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!categories[i18n.language] || categories[i18n.language].length === 0) {
      dispatch(shopActions.getCategoriesRequest(i18n.language));
    }
  }, [i18n.language, session]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAndScrollToTop = () => {
    setOpen(false);
    if (typeof window === 'object') {
      window.scrollTo(0, 0);
    }
  };

  if (!categories[i18n.language]) {
    return null;
  }

  return (
    <React.Fragment key={`sol-${categoryId}`}>
      <Button
        color={'secondary'}
        fullWidth={fullWidth}
        onClick={handleOpen}
        sx={{ fontSize: '1rem', boxShadow: 'none !important' }}
        variant={'contained'}
      >
        <Trans i18nKey={'seeSolutions'}>See Solutions</Trans>
      </Button>
      <Drawer anchor={'bottom'} onClose={handleClose} open={open}>
        <Grid
          className={classes.solutions}
          container
          justifyContent={'center'}
          spacing={2}
        >
          {categories[i18n.language]
            .filter((category) => category.reference?.includes('Sol'))
            .map((category) => (
              <Grid
                item
                key={`solution-${category.id}`}
                lg={1}
                md={1}
                sm={2}
                sx={{ textAlign: 'center' }}
                xs={4}
              >
                <Box
                  className={'solution'}
                  component={Link}
                  onClick={handleCloseAndScrollToTop}
                  to={`/shop/category/${getFormalizedName(category.name)}/${
                    category.id
                  }`}
                >
                  <span className="overlay-highlight"></span>
                  <Paper
                    className={`${
                      category.id === categoryId ? 'active' : 'inactive'
                    }`}
                    color={'secondary'}
                    key={`${category.id}Category`}
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_API_URL}/resources/images/categories/${category.id}-200.jpg)`,
                      backgroundSize: 'cover',
                    }}
                  ></Paper>
                </Box>
                <Typography align={'center'} className={'title'}>
                  {category.name}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </Drawer>
    </React.Fragment>
  );
};

export default CategoriesSolutions;
