import React from 'react';
import List from '@mui/material/List';
import { useLocation } from 'react-router-dom';

export const MenuContext = React.createContext({
  handleDrawerClose: () => {},
});

const Menu = ({ children, className, onItemSelected }) => {
  const { pathname } = useLocation();

  const contextValue = {
    pathname,
    onItemSelected,
  };
  return (
    <List className={className}>
      <MenuContext.Provider value={contextValue}>
        {children}
      </MenuContext.Provider>
    </List>
  );
};
export default Menu;
