import React, { useEffect, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { isGoogleMapApiError, isGoogleMapApiLoaded } from './store/selectors';
import { Trans } from 'react-i18next';
import { grey } from '@mui/material/colors';

const options = {
  zoomControlOptions: {
    //position: google.maps.ControlPosition.RIGHT_CENTER // ,
    // ...otherOptions
  },
  clickableIcons: false,
};
const useStyles = makeStyles(() => ({
  error: {
    background: grey[100],
    padding: 30,
    height: '100%',
  },
}));

export default function Map(props) {
  const classes = useStyles();
  let googleMapRef = useRef(undefined);

  const googleMapIsLoaded = useSelector(isGoogleMapApiLoaded);
  const googleMapError = useSelector(isGoogleMapApiError);

  const [zoom, setZoom] = useState(props.zoom);
  const [center, setCenter] = useState(props.coordinates);

  useEffect(() => {
    setZoom(props.zoom);
    setCenter(props.coordinates);
  }, [props.zoom, props.center]);

  const handleDragEnd = () => {
    if (!googleMapRef.current) return;
    const zoom = googleMapRef.current.zoom;
    const coordinates = googleMapRef.current.getCenter().toJSON();
    props.onDragEnd({ coordinates, zoom });
  };

  const handleZoomChange = () => {
    if (!googleMapRef.current) return;
    const zoom = googleMapRef.current.zoom;
    const coordinates = googleMapRef.current.getCenter().toJSON();
    props.onZoomChanged({ coordinates, zoom });
  };

  const handleLoad = (map) => {
    googleMapRef.current = map;
  };

  function renderMap() {
    return (
      <GoogleMap
        center={center}
        id="google-map"
        mapContainerStyle={{ height: '100%', width: '100%' }}
        onLoad={handleLoad}
        zoom={zoom}
        {...props}
        onDragEnd={handleDragEnd}
        onZoomChanged={handleZoomChange}
        options={options}
      />
    );
  }

  if (googleMapError) {
    return (
      <div className={classes.error}>
        <Trans i18nKey={'mapDisplayError'}>
          Oops, it looks like the card is not charging ... <br />
          Try to reload your browser.
        </Trans>
      </div>
    );
  }

  return googleMapIsLoaded ? renderMap() : <CircularProgress />;
}
