import axios from '../../../../helpers/axios';
import { getGeolocationCountryCode } from '../helpers/helper';

export const shopService = {
  getShippingAddresses,
  addShippingAddress,
  updateShippingAddress,
  removeShippingAddress,
  setAsDefaultShippingAddress,
  getProducts,
  getProProducts,
  getCatalogProducts,
  searchProducts,
  getCategories,
  getCategory,
  getProduct,
  signInSession,
  signInSessionByDirectLink,
  getCheckout,
  getPickupPoints,
  checkoutValidate,
  getProductsByIds,
  getProductsByOrderId,
  getShippingMethods,
  getOrders,
  getOrderStatus,
  saveOrder,
  getReviewsTodo,
  addReview,
  getProductReviews,
  getGiftCards,
  getGiftCardByCode,
  getActiveDiscountCode,
};

function getShippingAddresses() {
  return new Promise((resolve, reject) => {
    axios
      .get('/user/shippingAddresses')
      .then(({ shippingAddresses }) => {
        resolve({ shippingAddresses });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addShippingAddress(shippingAddress) {
  return new Promise((resolve, reject) => {
    axios
      .post('/user/shippingAddress', shippingAddress)
      .then(({ shippingAddress }) => {
        resolve({ shippingAddress });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateShippingAddress(id, shippingAddress) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/user/shippingAddress/${id}`, shippingAddress)
      .then(({ shippingAddress }) => {
        resolve({ shippingAddress });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeShippingAddress(shippingAddress) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/user/shippingAddress/${shippingAddress.id}`)
      .then(() => {
        resolve({ shippingAddress });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setAsDefaultShippingAddress(shippingAddress) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/user/shippingAddress/${shippingAddress.id}/default`)
      .then(() => {
        resolve({ shippingAddress });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProducts(lang, country, categoryId, offset) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/shop/products/${lang}/${country}${
          categoryId && categoryId !== 'all' ? `/category/${categoryId}` : ''
        }${offset ? `/offset/${offset}` : ''}`,
      )
      .then(({ next, products }) => {
        resolve({ products, next });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProProducts(lang, country) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/products/${lang}/${country}/pro`)
      .then(({ next, products }) => {
        resolve({ products, next });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCatalogProducts(lang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/products/${lang}/BE/all`)
      .then(({ products }) => {
        resolve({ products });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductsByIds(lang, country, ids) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop/products/${lang}/${country}`, { ids: ids })
      .then(({ products }) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductsByOrderId(lang, orderId, country) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/products/${lang}/${country}/order/${orderId}`)
      .then(({ products }) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function searchProducts(lang, countryCode, search, adminWithoutRestriction) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/shop${
          adminWithoutRestriction ? '/admin' : ''
        }/products/${lang}/${countryCode}/search/${search}`,
      )
      .then(({ products }) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategories(lang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/categories/${lang}`)
      .then(({ categories }) => {
        resolve(categories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategory(lang, categoryId) {
  return new Promise((resolve, reject) => {
    if (categoryId === 'all') {
      resolve(null);
      return;
    }
    axios
      .get(`/shop/category/${lang}/${categoryId}`)
      .then(({ category }) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProduct(lang, countryCode, productId, allowHidden = false) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/shop/product/${lang}/${countryCode}/${productId}${
          allowHidden ? `/allow/hidden` : ''
        }`,
      )
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function signInSession(consultantId, countryCode) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`/shop/session`, {
        consultantId,
        countryCode: countryCode || (await getGeolocationCountryCode('BE')),
      })
      .then(({ session }) => {
        resolve(session);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function signInSessionByDirectLink(directLink, countryCode) {
  return new Promise(async (resolve, reject) => {
    axios
      .post(`/shop/link/session`, {
        directLink,
        countryCode: await getGeolocationCountryCode(countryCode),
      })
      .then(({ session }) => {
        resolve(session);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCheckout(
  lang,
  shippingMethodId,
  shippingAddressId,
  shippingPickupPoint,
  items,
  useWallet,
  giftCard,
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop/checkout`, {
        lang,
        shippingMethodId,
        shippingAddressId,
        shippingPickupPoint,
        items: Object.keys(items).map((key) => {
          return { id: items[key].id, quantity: items[key].quantity };
        }),
        useWallet,
        giftCard,
      })
      .then(({ checkout }) => {
        resolve(checkout);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPickupPoints(shippingAddressId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/pickupPoints/${shippingAddressId}`)
      .then(({ pickupPoints }) => {
        resolve(pickupPoints);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function checkoutValidate(order) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop/order/validate`, {
        id: order.id,
      })
      .then(({ invoice }) => {
        resolve(invoice);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function saveOrder(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop/order/${orderId}/save`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getShippingMethods(country, weight) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/shipping/methods/${country}/weight/${weight}`)
      .then(({ shippingMethods }) => {
        resolve(shippingMethods);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrders(lang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/orders/${lang}`)
      .then(({ orders }) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrderStatus(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/order/${orderId}/status`)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getReviewsTodo(lang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/products/${lang}/reviews/todo`)
      .then(({ products }) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addReview(productId, lang, rating, review) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/shop/product/${lang}/${productId}/review`, { rating, review })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductReviews(productId, offset = 0) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/product/${productId}/reviews${offset ? `/${offset}` : ''}`)
      .then(({ reviews }) => {
        resolve(reviews);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getGiftCards() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/gifts`)
      .then(({ giftCards }) => {
        resolve(giftCards);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getGiftCardByCode(code) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/gift/${code}`)
      .then(({ giftCard }) => {
        resolve(giftCard);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getActiveDiscountCode() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/discount/active`)
      .then(({ discountCode }) => {
        resolve(discountCode);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
