import produce from 'immer';
import initialState from './initialState';
import * as actionsType from './constants';
import * as userShippingActionsType from '../../Customer/Shop/store/constants';
import { PARTNER_CAREER } from './constants';

export default (state = initialState.authentication, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionsType.SIGNIN_JWT_REQUEST:
      case actionsType.SIGNIN_CREDENTIALS_REQUEST:
      case actionsType.SIGNUP_CREDENTIALS_REQUEST:
        draft.authenticationRequested = true;
        break;

      case actionsType.SIGNIN_JWT_SUCCESS:
      case actionsType.SIGNIN_CREDENTIALS_SUCCESS:
      case actionsType.SIGNUP_CREDENTIALS_SUCCESS:
      case actionsType.SIGNUP_PSIOSTORE_CREDENTIALS_SUCCESS:
        draft.authenticated = true;
        draft.authenticationRequested = false;
        draft.user = { ...action.user, provider: 'jwt' };
        break;

      case actionsType.SIGNIN_JWT_FAILED:
      case actionsType.SIGNIN_CREDENTIALS_FAILED:
      case actionsType.SIGNUP_CREDENTIALS_FAILED:
      case actionsType.SIGNUP_PSIOSTORE_CREDENTIALS_FAILED:
        draft.authenticationRequested = false;
        draft.user = null;
        break;

      case actionsType.SIGNOUT:
      case actionsType.SIGNOUT_ALL_DEVICES:
      case actionsType.SIGNOUT_INVALID_ACCESS_TOKEN:
        draft.authenticationRequested = false;
        draft.authenticated = false;
        draft.user = null;
        break;

      case actionsType.UPDATE_USER_REQUEST:
      case actionsType.RELOAD_USER_REQUEST:
        draft.updateUserRequested = true;
        draft.reloadUserRequested = true;
        break;

      case actionsType.UPDATE_USER_SUCCESS:
      case actionsType.RELOAD_USER_SUCCESS:
        draft.updateUserRequested = false;
        draft.user = action.user;
        break;

      case actionsType.UPDATE_USER_FAILED:
      case actionsType.RELOAD_USER_FAILED:
        draft.updateUserRequested = false;
        break;

      case actionsType.GET_USER_CONTACT_REQUEST:
        break;

      case actionsType.GET_USER_CONTACT_SUCCESS:
        draft.contact = action.contact;
        break;

      case actionsType.GET_USER_CONTACT_FAILED:
        draft.contact = null;
        break;

      case userShippingActionsType.ADD_SHIPPING_ADDRESS_SUCCESS:
      case userShippingActionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS:
        draft.user.shippingAddress = action.shippingAddress;
        break;

      case actionsType.SET_MEMBERSHIP_MODAL_OPEN:
        switch (state.user.career) {
          case PARTNER_CAREER:
            draft.partnerMembershipModalOpen = action.open;
            break;
          default:
            draft.structureMembershipModalOpen = action.open;
        }
        draft.membershipAlerted = true;
        break;

      case actionsType.TAKE_CONTROL_REQUEST:
        break;

      case actionsType.TAKE_CONTROL_SUCCESS:
        draft.user = action.user;
        break;

      case actionsType.TAKE_CONTROL_FAILED:
        break;

      case actionsType.RESTORE_CONTROL_REQUEST:
        break;

      case actionsType.RESTORE_CONTROL_SUCCESS:
        draft.user = action.user;
        break;

      case actionsType.RESTORE_CONTROL_FAILED:
        break;

      case actionsType.SET_MAILING_SUBSCRIPTION:
        draft.user.allowMailing = action.subscribed;
        break;

      case actionsType.SET_MAILING_PRO_SUBSCRIPTION:
        draft.user.allowProMailing = action.subscribed;
        break;

      default:
    }
  });
