import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sessionSelector } from '../../store/selectors';
import { useTranslation } from 'react-i18next';
import { shopService } from '../../store/services';
import { SnackbarFormattedError } from '../../../../../helpers/snackbar';

const SearchBarContext = createContext({});

const SearchBarProvider = ({ children }) => {
  const session = useSelector(sessionSelector);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const { i18n, t } = useTranslation();
  const [isDialogOpen, setDialogOpen] = useState(false);

  const popularSearches = [
    'Zenspire',
    t('magnesium'),
    t('vitaminD'),
    t('antioxidants'),
  ];

  useEffect(() => {
    if (search.length >= 2) {
      const timer = setTimeout(() => {
        handleSearch(search);
      }, 300);

      return () => clearTimeout(timer);
    } else {
      setItems([]);
    }
  }, [search]);

  const handleSearch = (value) => {
    shopService
      .searchProducts(i18n.language, session?.country || 'BE', value, false)
      .then((items) => {
        setItems(items);
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
      });
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <SearchBarContext.Provider
      value={{
        items,
        search,
        setSearch,
        isDialogOpen,
        popularSearches,
        openDialog,
        closeDialog,
      }}
    >
      {children}
    </SearchBarContext.Provider>
  );
};

export const useSearchBar = () => useContext(SearchBarContext);

export default SearchBarProvider;
