import axios from '../../../../helpers/axios';

export const mailingService = {
  addMailing,
  getMailing,
  getMailingsDraft,
  getMailingsQueued,
  getMailingsCompleted,
  updateMailing,
  prepareMailing,
  getListMailing,
  sendTestMailing,
  putInQueueMailing,
  sendMailing,
  duplicateMailing,
  deleteMailing,
  uploadMailingImage,
  findDistinctCountries,
  findEvents,
};

function getMailingsDraft() {
  return new Promise((resolve, reject) => {
    axios
      .get('/admin/mailings/draft')
      .then(({ mailings }) => {
        resolve(mailings);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getMailingsQueued() {
  return new Promise((resolve, reject) => {
    axios
      .get('/admin/mailings/queued')
      .then(({ mailings }) => {
        resolve(mailings);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getMailingsCompleted() {
  return new Promise((resolve, reject) => {
    axios
      .get('/admin/mailings/completed')
      .then(({ mailings }) => {
        resolve(mailings);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getMailing(mailingId, checkErrors = false) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/mailing/${mailingId}${checkErrors ? '/errors/check' : ''}`)
      .then(({ mailing }) => {
        resolve(mailing);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addMailing(mailing) {
  return new Promise((resolve, reject) => {
    axios
      .post('/admin/mailing', mailing)
      .then(({ mailing }) => {
        resolve(mailing);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateMailing(mailingId, mailing) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/mailing/${mailingId}`, mailing)
      .then(({ mailing }) => {
        resolve(mailing);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function prepareMailing(mailingId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/mailing/${mailingId}/prepare`)
      .then(({ memberList }) => {
        resolve(memberList);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getListMailing(mailingId, offset) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/admin/mailing/${mailingId}/list${offset ? `/offset/${offset}` : ``}`,
      )
      .then(({ members }) => {
        resolve(members);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendTestMailing(mailingId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/mailing/${mailingId}/test/send`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function putInQueueMailing(mailingId) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/admin/mailing/${mailingId}/queue`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendMailing(mailingId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/mailing/${mailingId}/send`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function duplicateMailing(mailingId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/mailing/${mailingId}/duplicate`)
      .then(({ mailing }) => {
        resolve(mailing);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deleteMailing(mailingId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/admin/mailing/${mailingId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function uploadMailingImage(file, filename) {
  return new Promise((resolve, reject) => {
    const data = new FormData();
    data.append('file', file, filename);

    axios
      .post(`/admin/mailing/image`, data, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
      })
      .then((file) => {
        resolve(file);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findDistinctCountries() {
  return new Promise((resolve, reject) => {
    axios
      .get(`user/countries/distinct`)
      .then(({ countries }) => {
        resolve(countries);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findEvents() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/manager/events/available`)
      .then(({ events }) => {
        resolve(events);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
