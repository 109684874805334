import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import {
  SnackbarError,
  SnackbarFormattedError,
} from '../../../../helpers/snackbar';
import FormValidator from '../../../../components/Validators/FormValidator';
import TextFieldValidator from '../../../../components/Validators/TextFieldValidator';
import AutocompletePlaces from '../../../../components/GoogleAutocomplete/AutocompletePlaces';
import { userService } from '../store/services';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20,
    },
  },
  cancel: {
    borderRadius: 8,
    fontSize: 16,
    height: 46,
    padding: '8px 20px',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const AddShippingAddressModal = ({
  dialogOpen,
  onChange,
  setDialogOpen,
  user,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    name: '',
    shippingAddress: null,
  });

  const handleSubmit = () => {
    setFormSubmitted(true);
    if (data.shippingAddress === null) {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    setLoading(true);
    userService
      .addUserShippingAddress(user.id, data)
      .then(() => {
        setLoading(false);
        setDialogOpen(false);
        onChange();
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
        setLoading(false);
      });
  };

  const handleChange = (event) => {
    setData({
      ...data,
      [event.target.name]: event.target.value,
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleShippingAddressChanged = (place) => {
    setData({
      ...data,
      shippingAddress: place,
    });
  };

  return (
    <Dialog
      disableScrollLock
      fullScreen={fullScreen}
      onClose={handleClose}
      open={dialogOpen || false}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        <Trans i18nKey="addNewShippingAddress">Add new shipping address</Trans>
      </DialogTitle>
      <FormValidator
        noValidate
        onError={(errors) => console.log(errors)}
        onSubmit={handleSubmit}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldValidator
                    autoComplete="shippingAddressName"
                    fullWidth
                    label={"Nom de l'adresse"}
                    name="name"
                    onChange={handleChange}
                    placeholder={t('home')}
                    value={data.name}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextFieldValidator
                    autoComplete="firstName"
                    fullWidth
                    label={t('firstName')}
                    name="firstName"
                    onChange={handleChange}
                    required
                    value={data.firstName}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextFieldValidator
                    autoComplete="lastName"
                    fullWidth
                    label={t('lastName')}
                    name="lastName"
                    onChange={handleChange}
                    required
                    value={data.lastName}
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompletePlaces
                    customFieldsEnabled
                    error={formSubmitted && !data.address}
                    fullWidth
                    helperText={
                      formSubmitted &&
                      !data.address &&
                      t('validatorFieldRequired')
                    }
                    initialValue=""
                    label={t('shippingAddress')}
                    name="address"
                    onPlaceChanged={handleShippingAddressChanged}
                    placeholder=""
                    preview
                    required
                    requiredFields={['streetName', 'city', 'country']}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
            type="button"
            variant="outlined"
          >
            <Trans i18nKey={'cancelButton'}>Cancel</Trans>
          </Button>
          <Button
            color={'primary'}
            disabled={loading}
            type="submit"
            variant="contained"
          >
            Ajouter l'adresse
          </Button>
        </DialogActions>
      </FormValidator>
    </Dialog>
  );
};
export default AddShippingAddressModal;
