import produce from 'immer';
import * as actions from './constants';

export const initialState = {
  bank: {
    wallet: {
      amount: 0,
      currency: 'SCR',
      isSet: false,
    },
    modalOpen: false,
    transferRequested: false,
    histories: [],
    commissionsCreditNotes: [],
  },
};

export const bankReducerKey = 'bank';

export default (state = initialState.bank, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actions.GET_WALLET_REQUEST:
        draft.wallet = initialState.bank.wallet;
        break;
      case actions.GET_WALLET_SUCCESS:
        draft.wallet = { ...action.wallet, isSet: true };
        break;
      case actions.GET_WALLET_ERROR:
        draft.error = action.error;
        draft.wallet = initialState.bank.wallet;
        break;
      case actions.GET_WALLET_HISTORIES_REQUEST:
        draft.histories = initialState.bank.histories;
        break;
      case actions.GET_WALLET_HISTORIES_SUCCESS:
        draft.histories = action.data;
        break;
      case actions.GET_WALLET_HISTORIES_ERROR:
        draft.error = action.error;
        draft.histories = initialState.bank.histories;
        break;
      case actions.GET_WALLET_COMMISSIONS_CREDIT_NOTES_REQUEST:
        draft.commissionsCreditNotes = initialState.bank.commissionsCreditNotes;
        break;
      case actions.GET_WALLET_COMMISSIONS_CREDIT_NOTES_SUCCESS:
        draft.commissionsCreditNotes = action.data;
        break;
      case actions.GET_WALLET_COMMISSIONS_CREDIT_NOTES_ERROR:
        draft.error = action.error;
        draft.commissionsCreditNotes = initialState.bank.commissionsCreditNotes;
        break;
      case actions.OPEN_TRANSFER_MODAL:
        draft.modalOpen = true;
        break;
      case actions.CLOSE_TRANSFER_MODAL:
        draft.modalOpen = false;
        break;
      case actions.TRANSFER_REQUEST:
        draft.transferRequested = true;
        break;
      case actions.TRANSFER_SUCCESS:
        draft.transferRequested = false;
        draft.wallet.amount = 0;
        break;
      case actions.TRANSFER_ERROR:
        draft.transferRequested = false;
        draft.error = action.error;
        break;
      default:
    }
  });
