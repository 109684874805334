import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionsType from './constants';
import { magActions } from './actions';
import { SnackbarError } from '../../../../helpers/snackbar';
import { magService } from './services';
import i18next from 'i18next';

function* getArticle(action) {
  try {
    const article = yield call(
      magService.getArticleByUrl,
      action.url,
      action.lang,
    );
    yield put(magActions.getArticleSuccess(article));
  } catch (e) {
    yield put(magActions.getArticleFail());
    SnackbarError(i18next.t('errorMessageArticleDoesNotExistInLanguage'));
  }
}

function* getArticles(action) {
  try {
    const articles = yield call(magService.getArticles, action.lang);
    yield put(magActions.getArticlesSuccess(articles));
  } catch (e) {
    yield put(magActions.getArticlesFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

export default function* watchMag() {
  yield takeEvery(actionsType.GET_ARTICLE_REQUEST, getArticle);
  yield takeEvery(actionsType.GET_ARTICLES_REQUEST, getArticles);
}
