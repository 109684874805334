import { Link } from '../../../../../helpers/multilingual';
import Button from '@mui/material/Button';
import React from 'react';

const DesktopMenuItem = ({ title, ...props }) => {
  return (
    <Button
      component={Link}
      sx={{
        display: { md: 'inherit', xs: 'none' },
        fontSize: { lg: 18, md: 16 },
        height: 40,
        fontWeight: 500,
        ml: { lg: '16px !important', md: '8px !important' },
      }}
      title={title}
      variant={'text'}
      {...props}
    >
      {title}
    </Button>
  );
};
export default DesktopMenuItem;
