import produce from 'immer';
import * as actionsType from './constants';
import * as authenticationActionsType from '../../../Authentication/store/constants';
import { initialState } from './initialState';
import Cookie from '../../../../helpers/cookie';

export const shopReducerKey = 'shop';

export default (state = initialState.shop, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionsType.GET_SHIPPING_ADDRESSES_REQUEST:
        draft.userShipping.addressesRequested = true;
        break;

      case actionsType.GET_SHIPPING_ADDRESSES_SUCCESS:
        draft.userShipping.addressesRequested = false;
        draft.userShipping.addresses = action.shippingAddresses;
        break;

      case actionsType.GET_SHIPPING_ADDRESSES_FAILED:
        draft.userShipping.addressesRequested = false;
        draft.userShipping.addresses = [];
        break;

      case actionsType.ADD_SHIPPING_ADDRESS_REQUEST:
        draft.userShipping.addAddressRequested = true;
        break;

      case actionsType.ADD_SHIPPING_ADDRESS_SUCCESS:
        //draft.userShipping.addresses = draft.userShipping.addresses.map(address => ({...address, byDefault: false}));
        draft.userShipping.addresses.unshift(action.shippingAddress);
        draft.userShipping.addAddressRequested = false;
        break;

      case actionsType.ADD_SHIPPING_ADDRESS_FAILED:
        draft.userShipping.addAddressRequested = false;
        break;

      case actionsType.UPDATE_SHIPPING_ADDRESS_REQUEST:
        draft.userShipping.updateAddressRequested = true;
        break;

      case actionsType.UPDATE_SHIPPING_ADDRESS_SUCCESS:
        draft.userShipping.updateAddressRequested = false;
        break;

      case actionsType.UPDATE_SHIPPING_ADDRESS_FAILED:
        draft.userShipping.updateAddressRequested = false;
        break;

      case actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST:
        break;

      case actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS:
        draft.userShipping.addresses = draft.userShipping.addresses.map(
          (address) => ({
            ...address,
            byDefault: address.id === action.shippingAddress.id,
          }),
        );
        draft.cart.shipping.address = action.shippingAddress;
        Cookie.set('shipping-address', action.shippingAddress);
        draft.cart.shipping.method = null;
        Cookie.remove('shipping-method');
        draft.cart.shipping.pickupPoint = null;
        Cookie.remove('shipping-pickup-point');
        draft.checkout = null;
        break;

      case actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_FAILED:
        break;

      case actionsType.REMOVE_SHIPPING_ADDRESS_REQUEST:
        draft.userShipping.removeAddressRequested = true;
        break;

      case actionsType.REMOVE_SHIPPING_ADDRESS_SUCCESS:
        draft.userShipping.addresses = draft.userShipping.addresses.filter(
          (address) => address.id !== action.shippingAddress.id,
        );
        draft.userShipping.removeAddressRequested = false;
        break;

      case actionsType.REMOVE_SHIPPING_ADDRESS_FAILED:
        draft.userShipping.removeAddressRequested = false;
        break;

      case actionsType.GET_PRODUCTS_REQUEST:
        draft.productsRequested = true;
        break;

      case actionsType.GET_PRODUCTS_SUCCESS:
        draft.productsRequested = false;
        const productsKey = `${action.categoryId}-${action.country}-${action.lang}`;
        draft.products[productsKey] = draft.products[productsKey]
          ? draft.products[productsKey]
          : { data: [], endOfProducts: false };
        draft.products[productsKey].data = [
          ...draft.products[productsKey].data,
          ...action.products,
        ];
        if (action.products.length < 24) {
          draft.products[productsKey].endOfProducts = true;
        }
        break;

      case actionsType.GET_PRODUCTS_FAIL:
        draft.productsRequested = false;
        break;

      case actionsType.GET_CATALOG_PRODUCTS_REQUEST:
        break;

      case actionsType.GET_CATALOG_PRODUCTS_SUCCESS:
        draft.catalog = action.products;
        break;

      case actionsType.GET_CATALOG_PRODUCTS_FAIL:
        draft.catalog = [];
        break;

      case actionsType.SEARCH_PRODUCTS_REQUEST:
        draft.search = action.search;
        break;

      case actionsType.SEARCH_PRODUCTS_SUCCESS:
        draft.searchProducts = action.products;
        break;

      case actionsType.SEARCH_PRODUCTS_FAIL:
        draft.searchProducts = [];
        draft.search = '';
        break;

      case actionsType.SEARCH_DRAWER_OPEN:
        draft.isSearchDrawerOpen = action.isOpen;
        break;

      case actionsType.GET_CATEGORIES_REQUEST:
        break;

      case actionsType.GET_CATEGORIES_SUCCESS:
        draft.categories[action.lang] = action.categories;
        break;

      case actionsType.GET_CATEGORIES_FAIL:
        draft.categories[action.lang] = [];
        break;

      case actionsType.GET_CATEGORY_REQUEST:
        draft.categoryId = action.categoryId;
        break;

      case actionsType.GET_PRODUCT_REQUEST:
        draft.product = null;
        break;

      case actionsType.GET_PRODUCT_SUCCESS:
        draft.product = action.product;
        break;

      case actionsType.GET_PRODUCT_FAIL:
        draft.product = null;
        break;

      case actionsType.CART_OPEN_MODAL:
        draft.cart.isModalOpened = true;
        break;

      case actionsType.CART_CLOSE_MODAL:
        draft.cart.isModalOpened = false;
        break;

      case actionsType.CART_SET_FROM_ORDER_REQUEST:
        draft.cart.items = {};
        break;

      case actionsType.CART_SET_FROM_ORDER_SUCCESS:
        action.items.map((item) => {
          draft.cart.items[item.id] = {
            ...item,
          };
        });
        break;

      case actionsType.CART_SET_FROM_ORDER_FAIL:
        draft.cart.items = {};
        break;

      case actionsType.CART_ADD_ITEM:
        draft.checkout = null;
        const items = state.cart.items || {};
        draft.cart.items = {
          ...items,
          [action.item.id]: items[action.item.id]
            ? {
                ...action.item,
                quantity: items[action.item.id].quantity + action.quantity,
              }
            : {
                ...action.item,
                quantity: action.quantity,
              },
        };
        break;

      case actionsType.CART_REMOVE_ITEMS:
        draft.checkout = null;
        draft.cart.items = {};
        break;

      case actionsType.CART_SET_ITEM_QUANTITY:
        draft.checkout = null;
        draft.cart.items = {
          ...state.cart.items,
          [action.item.id]: {
            ...action.item,
            quantity: action.quantity,
          },
        };
        if (action.quantity === 0) {
          delete draft.cart.items[action.item.id];
        }
        break;

      case actionsType.SIGNIN_SESSION_REQUEST:
      case actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST:
        draft.sessionError = false;
        break;

      case actionsType.SIGNIN_SESSION_SUCCESS:
      case actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_SUCCESS:
        draft.session = { ...action.session, updating: false };
        draft.sessionError = false;
        break;

      case actionsType.SIGNIN_SESSION_FAIL:
      case actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_FAIL:
        //draft.session = null;
        //Cookie.remove('session');
        draft.sessionError = true;
        break;

      case actionsType.UPDATE_SESSION_REQUEST:
        draft.session.updating = action.updating;
        break;

      case actionsType.SET_SHIPPING_ADDRESS_SELECTED:
        draft.cart.shipping.address = action.shippingAddress;
        draft.cart.shipping.methods = [];
        draft.cart.shipping.method = null;
        draft.cart.shipping.pickupPoint = null;
        //draft.checkout = null;
        Cookie.set('shipping-address', action.shippingAddress);
        Cookie.remove('shipping-method');
        Cookie.remove('shipping-pickup-point');
        break;

      case actionsType.SET_SHIPPING_METHOD_SELECTED:
        draft.cart.shipping.method = action.shippingMethod;
        draft.cart.shipping.pickupPoint = null;
        //draft.checkout = null;
        Cookie.set('shipping-method', action.shippingMethod);
        Cookie.remove('shipping-pickup-point');
        break;

      case actionsType.SET_SHIPPING_PICKUP_POINT_SELECTED:
        draft.cart.shipping.method = action.shippingMethod;
        draft.cart.shipping.pickupPoint = action.pickupPoint;
        //draft.checkout = null;
        Cookie.set('shipping-method', action.shippingMethod);
        Cookie.set('shipping-pickup-point', {
          id: action.pickupPoint.id,
          name: action.pickupPoint.name,
          type: action.pickupPoint.type,
          street: action.pickupPoint.street,
          number: action.pickupPoint.number,
          zip: action.pickupPoint.zip,
          city: action.pickupPoint.city,
          country: action.pickupPoint.country,
        });
        break;

      case actionsType.SET_USE_WALLET_SELECTED:
        draft.cart.useWallet = action.useWallet;
        break;

      case actionsType.GET_SHIPPING_METHODS_REQUEST:
        draft.cart.shipping.methodsRequested = true;
        break;

      case actionsType.GET_SHIPPING_METHODS_SUCCESS:
        draft.cart.shipping.methodsRequested = false;
        draft.cart.shipping.methods = action.shippingMethods;
        break;

      case actionsType.GET_SHIPPING_METHODS_FAIL:
        draft.cart.shipping.methodsRequested = false;
        break;

      case actionsType.GET_CHECKOUT_REQUEST:
        draft.checkout = initialState.shop.checkout;
        break;

      case actionsType.GET_CHECKOUT_SUCCESS:
        draft.checkout = action.checkout;
        break;

      case actionsType.GET_CHECKOUT_FAIL:
        draft.session = null;
        Cookie.remove('session');
        draft.cart.shipping.address = null;
        Cookie.remove('shipping-address');
        draft.cart.shipping.method = null;
        Cookie.remove('shipping-method');
        draft.cart.shipping.pickupPoint = null;
        Cookie.remove('shipping-pickup-point');
        draft.checkout = null;
        break;

      case actionsType.CLEAN_CHECKOUT:
      case authenticationActionsType.SIGNOUT:
      case authenticationActionsType.SIGNOUT_ALL_DEVICES:
      case authenticationActionsType.SIGNOUT_INVALID_ACCESS_TOKEN:
        draft.cart.items = {};
        try {
          localStorage.removeItem('cart');
        } catch (e) {
          /* empty */
        }
        draft.session = null;
        Cookie.remove('session');
        draft.cart.shipping.address = null;
        Cookie.remove('shipping-address');
        draft.cart.shipping.method = null;
        Cookie.remove('shipping-method');
        draft.cart.shipping.pickupPoint = null;
        Cookie.remove('shipping-pickup-point');
        draft.checkout = null;
        break;

      case authenticationActionsType.SIGNIN_CREDENTIALS_SUCCESS:
      case authenticationActionsType.SIGNIN_JWT_SUCCESS:
      case authenticationActionsType.RESTORE_CONTROL_SUCCESS:
      case authenticationActionsType.TAKE_CONTROL_SUCCESS:
      case authenticationActionsType.RELOAD_USER_SUCCESS:
      case authenticationActionsType.UPDATE_USER_SUCCESS:
        draft.products = initialState.shop.products;
        break;

      case actionsType.GET_ORDERS_REQUEST:
        break;

      case actionsType.GET_ORDERS_SUCCESS:
        draft.orders = action.orders;
        break;

      case actionsType.GET_ORDERS_FAIL:
        draft.orders = [];
        break;

      case actionsType.GET_GIFT_CARDS_REQUEST:
        break;

      case actionsType.GET_GIFT_CARDS_SUCCESS:
        draft.giftCards = action.giftCards;
        break;

      case actionsType.GET_GIFT_CARDS_FAIL:
        draft.giftCards = [];
        break;

      case actionsType.SELECT_GIFT_CARD:
        draft.giftCard = action.giftCard;
        break;

      case actionsType.GET_ACTIVE_DISCOUNT_CODE_REQUEST:
        break;

      case actionsType.GET_ACTIVE_DISCOUNT_CODE_SUCCESS:
        draft.activeDiscountCode = action.activeDiscountCode;
        break;

      case actionsType.GET_ACTIVE_DISCOUNT_CODE_FAIL:
        draft.activeDiscountCode = null;
        break;

      default:
    }
  });
