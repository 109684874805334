import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { shopActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { shopCategoriesSelector } from '../store/selectors';
import { Link } from '../../../../helpers/multilingual';
import TuneIcon from '@mui/icons-material/Tune';
import { grey } from '@mui/material/colors';
import { getFormalizedName } from '../helpers/helper';
import { isPro } from '../../../Authentication/store/selectors';
import Hidden from '@mui/material/Hidden';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemButton } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import CategoriesSolutions from './CategoriesSolutions';
import Grid from '@mui/material/Grid';

const useStylesFilters = makeStyles((theme) => ({
  root: {
    '& .MuiButton-root': {
      marginBottom: 16,
      fontSize: '1rem',
      padding: '6px 24px',
      marginLeft: 5,
      marginRight: 5,
      boxShadow: 'none !important',
      backgroundImage: 'none',
      '&.MuiButton-containedPrimary': {
        color: '#000',
        backgroundColor: grey[100],
      },
      '&.active': {
        backgroundColor: grey[300],
        color: '#000',
      },
      '&.inactive': {
        '&:hover': {
          color: '#000',
          backgroundColor: grey[200],
        },
      },
    },
    [theme.breakpoints.down('lg')]: {
      // justifyContent: 'center',
    },
    [theme.breakpoints.down('md')]: {
      // justifyContent: 'center',
      '& .MuiButton-root': {
        //width: '33.33%',
        marginBottom: 20,
        minWidth: 0,
        fontSize: '0.9rem',
        padding: '6px 15px',
      },
    },
  },
}));

const Filters = ({ categoryId }) => {
  const classes = useStylesFilters();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const userIsPro = useSelector(isPro);
  const categories = useSelector(shopCategoriesSelector);
  const [drawerCategoriesOpen, setDrawerCategoriesOpen] = useState(false);

  useEffect(() => {
    if (!categories[i18n.language] || categories[i18n.language].length === 0) {
      dispatch(shopActions.getCategoriesRequest(i18n.language));
    }
  }, [i18n.language]);

  const handleOpenDrawerCategories = () => {
    setDrawerCategoriesOpen(true);
  };

  const handleCloseAndScrollToTop = () => {
    setDrawerCategoriesOpen(false);
    if (typeof window === 'object') {
      window.scrollTo(0, 0);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerCategoriesOpen(false);
  };

  if (!categories[i18n.language]) {
    return null;
  }

  return (
    <Box>
      <Hidden implementation={'css'} mdUp>
        <Grid container justifyContent={'flex-end'} spacing={2}>
          <Grid item xs={6}>
            <CategoriesSolutions categoryId={categoryId} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <Button
              color={'primary'}
              fullWidth
              onClick={handleOpenDrawerCategories}
              startIcon={<TuneIcon />}
              sx={{ fontSize: '1rem' }}
              variant={'outlined'}
            >
              {t('filters')}
            </Button>
          </Grid>
        </Grid>
        <Drawer
          anchor={'bottom'}
          onClose={handleCloseDrawer}
          open={drawerCategoriesOpen}
        >
          <List>
            <ListItem
              component={Link}
              disablePadding
              onClick={handleCloseAndScrollToTop}
              to={`/shop`}
            >
              <ListItemButton>
                <ListItemText primary={t('allProducts')} />
              </ListItemButton>
            </ListItem>
            {categories[i18n.language]
              .filter((category) => category.promoted)
              .map((category) => (
                <ListItem
                  component={Link}
                  disablePadding
                  key={`category-mobile-${category.id}`}
                  onClick={handleCloseAndScrollToTop}
                  to={`/shop/category/${getFormalizedName(category.name)}/${
                    category.id
                  }`}
                >
                  <ListItemButton>
                    <ListItemText primary={category.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            {userIsPro && (
              <ListItem
                component={Link}
                disablePadding
                onClick={handleCloseAndScrollToTop}
                to={`/shop/pro`}
              >
                <ListItemButton>
                  <ListItemText primary={t('shopProTitle')} />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </Drawer>
      </Hidden>
      <Hidden implementation={'css'} mdDown>
        <Box
          className={classes.root}
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            mt: 2,
            justifyContent: 'center',
          }}
        >
          <Button
            className={`${categoryId === 'all' ? 'active' : 'inactive'} first`}
            color={'primary'}
            component={Link}
            key={'allCategory'}
            to={`/shop`}
            variant={'contained'}
          >
            <Trans i18nKey={'allProducts'}>All products</Trans>
          </Button>

          {categories[i18n.language]
            .filter((category) => category.promoted)
            .map((category) => (
              <Button
                className={`${
                  category.id === categoryId ? 'active' : 'inactive'
                } not-first`}
                color={'primary'}
                component={Link}
                key={`category-${category.id}`}
                to={`/shop/category/${getFormalizedName(category.name)}/${
                  category.id
                }`}
                variant={'contained'}
              >
                {category.name}
              </Button>
            ))}
          {userIsPro && (
            <Button
              className={`${categoryId === 'pro' ? 'active' : 'inactive'}`}
              color={'primary'}
              component={Link}
              startIcon={<LockOpenOutlinedIcon />}
              to={`/shop/pro`}
              variant={'contained'}
            >
              <Trans i18nKey={'shopProTitle'}>Pro Shop Sharks</Trans>
            </Button>
          )}
          <CategoriesSolutions categoryId={categoryId} />
        </Box>
      </Hidden>
    </Box>
  );
};

export default Filters;
