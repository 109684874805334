import Grid from '@mui/material/Grid';
import TextFieldValidator from '../../../components/Validators/TextFieldValidator';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '../../../helpers/multilingual';
import FormValidator from '../../../components/Validators/FormValidator';
import React, { useState } from 'react';
import { authenticationActions } from '../store/actions';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const SignInForm = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmitSignIn = () => {
    dispatch(
      authenticationActions.signInCredentialsRequest(
        email,
        password,
        rememberMe,
      ),
    );
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  return (
    <Box
      sx={{
        width: '100%', // Fix IE 11 issue.
        mt: 2,
        '& .link': {
          color: '#000',
          textDecoration: 'none',
        },
      }}
    >
      <FormValidator
        noValidate
        onError={(errors) => console.log(errors)}
        onSubmit={handleSubmitSignIn}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldValidator
              autoComplete="email"
              fullWidth
              label={t('email')}
              name="email"
              onChange={(event) => setEmail(event.target.value)}
              required
              type="email"
              value={email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldValidator
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              label={t('password')}
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              required
              type={showPassword ? 'text' : 'password'}
              value={password}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rememberMe}
                  color="primary"
                  onChange={handleRememberMeChange}
                  value="true"
                />
              }
              label={t('rememberMe')}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              color={'secondary'}
              fullWidth
              size={'large'}
              type="submit"
              variant="contained"
            >
              <Trans i18nKey={'signIn'}>Sign In</Trans>
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography align={'right'}>
              <Link className={'link'} to="/auth/forgot-password">
                <Trans i18nKey={'forgotPassword'}>Forgot password</Trans> ?
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </FormValidator>
    </Box>
  );
};
export default SignInForm;
