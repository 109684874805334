import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const CalendarIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="cls-1"
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        className="cls-2"
        d="M23.66,21.81H4.83a.34.34,0,0,1-.33-.35V4.68a.34.34,0,0,1,.33-.35H23.66a.34.34,0,0,1,.34.35V21.46A.35.35,0,0,1,23.66,21.81Zm-18.49-.7H23.32V5H5.17Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M23.66,7.6H4.83a.36.36,0,0,1,0-.71H23.66a.36.36,0,0,1,0,.71Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M7.2,6a.35.35,0,0,1-.34-.36V3.45a.34.34,0,1,1,.67,0V5.66A.35.35,0,0,1,7.2,6Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M11.9,6a.35.35,0,0,1-.34-.36V3.45a.34.34,0,1,1,.67,0V5.66A.35.35,0,0,1,11.9,6Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M16.6,6a.35.35,0,0,1-.34-.36V3.45a.34.34,0,1,1,.67,0V5.66A.35.35,0,0,1,16.6,6Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M21.32,6A.34.34,0,0,1,21,5.64V3.42a.34.34,0,1,1,.67,0V5.64A.35.35,0,0,1,21.32,6Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M8.67,12.53H7.17a.35.35,0,0,1-.34-.36V10.61a.35.35,0,0,1,.34-.35h1.5a.35.35,0,0,1,.34.35v1.56A.35.35,0,0,1,8.67,12.53Zm-1.16-.71h.82V11H7.51Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M12.89,12.53h-1.5a.35.35,0,0,1-.34-.36V10.61a.35.35,0,0,1,.34-.35h1.5a.34.34,0,0,1,.33.35v1.56A.35.35,0,0,1,12.89,12.53Zm-1.16-.71h.82V11h-.82Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M17.1,12.53H15.6a.35.35,0,0,1-.33-.36V10.61a.34.34,0,0,1,.33-.35h1.5a.35.35,0,0,1,.34.35v1.56A.35.35,0,0,1,17.1,12.53Zm-1.16-.71h.83V11h-.83Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M21.32,12.53h-1.5a.35.35,0,0,1-.34-.36V10.61a.35.35,0,0,1,.34-.35h1.5a.35.35,0,0,1,.34.35v1.56A.35.35,0,0,1,21.32,12.53Zm-1.16-.71H21V11h-.82Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M8.67,15.77H7.17a.35.35,0,0,1-.34-.36V13.85a.34.34,0,0,1,.34-.35h1.5a.34.34,0,0,1,.34.35v1.56A.35.35,0,0,1,8.67,15.77Zm-1.16-.71h.82V14.2H7.51Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M12.89,15.77h-1.5a.35.35,0,0,1-.34-.36V13.85a.34.34,0,0,1,.34-.35h1.5a.34.34,0,0,1,.33.35v1.56A.35.35,0,0,1,12.89,15.77Zm-1.16-.71h.82V14.2h-.82Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M16.35,15.62a.3.3,0,0,1-.23-.1l-.61-.63a.37.37,0,0,1,0-.5.33.33,0,0,1,.48,0l.36.38.83-.86a.33.33,0,0,1,.47,0,.37.37,0,0,1,0,.5l-1.06,1.11A.31.31,0,0,1,16.35,15.62Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M21.32,15.77h-1.5a.35.35,0,0,1-.34-.36V13.85a.35.35,0,0,1,.34-.35h1.5a.34.34,0,0,1,.34.35v1.56A.35.35,0,0,1,21.32,15.77Zm-1.16-.71H21V14.2h-.82Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M8.67,19H7.17a.34.34,0,0,1-.34-.35V17.09a.34.34,0,0,1,.34-.35h1.5a.34.34,0,0,1,.34.35v1.56A.34.34,0,0,1,8.67,19Zm-1.16-.7h.82v-.86H7.51Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M12.89,19h-1.5a.34.34,0,0,1-.34-.35V17.09a.34.34,0,0,1,.34-.35h1.5a.34.34,0,0,1,.33.35v1.56A.34.34,0,0,1,12.89,19Zm-1.16-.7h.82v-.86h-.82Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M17.1,19H15.6a.34.34,0,0,1-.33-.35V17.09a.34.34,0,0,1,.33-.35h1.5a.34.34,0,0,1,.34.35v1.56A.34.34,0,0,1,17.1,19Zm-1.16-.7h.83v-.86h-.83Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
export default CalendarIcon;
