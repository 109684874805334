import React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDivider-root': {
      margin: -1,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiCard-root': {
        overflow: 'auto',
      },
    },
    '& .MuiTypography-subtitle1': {
      maxHeight: 42,
      lineHeight: '20px',
      overflow: 'hidden',
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  listItems: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '30vh',
      overflow: 'auto',
    },
  },
  item: {
    [theme.breakpoints.up('sm')]: {
      '& .MuiListItem-root': {
        borderRight: '1px solid #DDD',
      },
    },
  },
  detailsRowPrice: {
    paddingTop: 20,
  },
}));

const OrderHiddenDetails = ({ order }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid className={classes.root} container>
      <Grid item xs={12}>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <Grid alignItems={'center'} container spacing={2}>
              {order.shippingTrackingLink && (
                <Grid item>
                  <Button>
                    <Typography
                      color={'secondary'}
                      component={'a'}
                      href={order.shippingTrackingLink}
                      target={'_blank'}
                      variant={'button'}
                    >
                      <Trans i18nKey={'orderTracking'}>Order tracking</Trans>
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={5} xs={12}>
        <List className={classes.listItems}>
          {order.products.map((item) => (
            <React.Fragment key={item.id}>
              <ListItem className={classes.item}>
                <Grid alignItems={'center'} container justifyContent={'center'}>
                  <Grid item xs={4}>
                    <Grid
                      alignItems={'center'}
                      container
                      justifyContent={'center'}
                    >
                      <img
                        alt={item.originalProduct.detail.name}
                        height={80}
                        src={item.originalProduct.image[200]}
                        width={80}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid
                      alignItems={'center'}
                      container
                      justifyContent={'center'}
                    >
                      <Grid item xs={8}>
                        <Typography variant="subtitle1">
                          <b>{`${item.quantity} x `}</b>
                          {item.originalProduct.detail.name}
                        </Typography>
                        {item.deferredQuantity > 0 && (
                          <i>
                            {t('deferredShipping')} ({item.deferredQuantity}/
                            {item.quantity})
                          </i>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
              {order.products.length > 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Grid>
      <Divider flexItem orientation="vertical" />
    </Grid>
  );
};

export default OrderHiddenDetails;
