import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import LinearProgress from '@mui/material/LinearProgress';
import Grid from '@mui/material/Grid';

const PageLoader = ({ height = '50vh', text }) => {
  const { t } = useTranslation();

  return (
    <Grid
      alignItems={'center'}
      container
      justifyContent={'center'}
      style={{ minHeight: height }}
    >
      <Grid item sm={4} xs={6}>
        <Typography align={'center'}>{text || t('loading')} ...</Typography>
        <LinearProgress color="secondary" />
      </Grid>
    </Grid>
  );
};
export default PageLoader;
