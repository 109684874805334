import React, { useEffect } from 'react';
import CustomerRoutes from '../routes/CustomerRoutes';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { initLanguage } from '../helpers/multilingual';
import CookieConsent from '../components/Layout/Customer/CookieConsent/CookieConsent';
import SocialNetworksRedirection from '../containers/Authentication/components/SocialNetworksRedirection';
import customerTheme from '../assets/styles/themes/customerTheme';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { shopActions } from '../containers/Customer/Shop/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeDiscountCodeSelector,
  sessionSelector,
} from '../containers/Customer/Shop/store/selectors';
import ActiveDiscountDrawer from '../components/Layout/Customer/ActiveDiscountDrawer';
import Box from '@mui/material/Box';
import ReactGA from 'react-ga4';
import SearchBarDialog from '../containers/Customer/Shop/components/Search/SearchBarDialog';
import SearchBarProvider from '../containers/Customer/Shop/components/Search/SearchBarProvider';
import { deviceTypeSelector } from '../reducers/globalSelectors';
import { useParams } from 'react-router-dom';

function CustomerLayout(props) {
  const { i18n } = useTranslation();
  const { country, lang } = useParams();
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const activeGiftCard = useSelector(activeDiscountCodeSelector);
  const deviceType = useSelector(deviceTypeSelector);

  initLanguage(lang);

  useEffect(() => {
    ReactGA.initialize('G-78KT1JYNGQ');
    import('react-facebook-pixel')
      .then((module) => module.default)
      .then((ReactPixel) => {
        ReactPixel.init('1604904353375610');
      });
  }, []);

  useEffect(() => {
    if (
      !props.match.params.directLink &&
      !session?.parentId &&
      !session?.registered
    ) {
      dispatch(shopActions.signInSessionRequest(null, country));
    }
  }, [session?.parentId]);

  useEffect(() => {
    if (session && session.country !== country) {
      dispatch(shopActions.signInSessionRequest(session.parentId, country));
    }
  }, [country]);

  useEffect(() => {
    dispatch(shopActions.getActiveDiscountCodeRequest());
  }, [session?.isPro]);

  useEffect(() => {
    if (activeGiftCard) {
      dispatch(shopActions.selectGiftCard(activeGiftCard));
    }
  }, [activeGiftCard]);

  return (
    <ThemeProvider theme={customerTheme(deviceType)}>
      <CssBaseline />
      <Helmet>
        <html lang={i18n.language} />
      </Helmet>
      <Box sx={{ minHeight: '70vh' }}>
        <SearchBarProvider>
          <CustomerRoutes {...props} />
          <SearchBarDialog />
        </SearchBarProvider>
      </Box>
      <CookieConsent />
      <SocialNetworksRedirection />
      {activeGiftCard && <ActiveDiscountDrawer />}
    </ThemeProvider>
  );
}

export default CustomerLayout;
