import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const HeartIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill={'#00207f'}
      />
      <path
        d="M26.53,12.84a5.8,5.8,0,0,0-.45-8.6,6,6,0,0,0-8,.64L16.83,6.15,15.55,4.88a6,6,0,0,0-8-.64,5.8,5.8,0,0,0-.44,8.6l9.2,9.2a.69.69,0,0,0,1,0Z"
        fill={'#00207f'}
        stroke="#fff"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
export default HeartIcon;
