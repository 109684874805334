import React, { useEffect, useState } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import OrderDetails from './components/OrderDetails';
import { shopActions } from '../../../Shop/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import { ordersSelector } from '../../../Shop/store/selectors';
import OrderStatus from './components/OrderStatus';
import OrderRecommendationDetails from './components/OrderRecommendationDetails';
import OrderFreeDetails from './components/OrderFreeDetails';
import OrderHiddenDetails from './components/OrderHiddenDetails';
import useDateFns from '../../../../../helpers/useDateFns';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Orders = () => {
  const dispatch = useDispatch();
  const dateFns = useDateFns();
  const orders = useSelector(ordersSelector);
  const [expanded, setExpanded] = useState('');

  useEffect(() => {
    dispatch(shopActions.getOrdersRequest(i18next.language));
  }, []);

  useEffect(() => {
    setExpanded(Array.isArray(orders) && orders.length > 0 ? orders[0].id : '');
  }, [orders]);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {orders.map((order) => (
        <Accordion
          expanded={expanded === order.id}
          key={order.id}
          onChange={handleChange(order.id)}
          square
        >
          <AccordionSummary>
            <Typography>
              <b>
                {dateFns.format(order.createdAt, 'LL')}
                {order.status === 'published' &&
                  ` - ${
                    Math.round(
                      (parseFloat(order.totalPrice) + parseFloat(order.taxes)) *
                        100,
                    ) / 100
                  } ${order.currency}`}
              </b>
              {` - `}
              <OrderStatus order={order} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {(order.status === 'published' || order.status === 'gotBack') && (
              <OrderDetails order={order} />
            )}
            {(order.status === 'recommendation' ||
              order.status === 'admin') && (
              <OrderRecommendationDetails order={order} />
            )}
            {order.status === 'free' && <OrderFreeDetails order={order} />}
            {(order.status === 'deferred' || order.status === 'resent') && (
              <OrderHiddenDetails order={order} />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
export default Orders;
