import * as actionsType from './constants';

export const homeActions = {
  getTreesCountRequest,
  getTreesCountSuccess,
};

export function getTreesCountRequest() {
  return {
    type: actionsType.GET_TREES_COUNT_REQUEST,
  };
}

export function getTreesCountSuccess(treesCount) {
  return {
    type: actionsType.GET_TREES_COUNT_SUCCESS,
    treesCount,
  };
}
