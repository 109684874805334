import { createSelector } from 'reselect';

const mapSelector = (globalState) => globalState.map;
export const isGoogleMapApiLoaded = createSelector(
  [mapSelector],
  (mapState) => mapState.isLoaded,
);
export const isGoogleMapApiError = createSelector(
  [mapSelector],
  (mapState) => mapState.error,
);
