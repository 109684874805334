import React, { useEffect } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import LineChartByYearAndMonth from '../../../../components/Charts/LineChartByYearAndMonth';
import Card from '@mui/material/Card';
import { statsActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  networkAverageTurnoverByActiveProsAmountLinesGraphDataSelector,
  networkAverageTurnoverByActiveProsAmountSelector,
} from '../store/selectors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PageLoader from '../../../../components/PageLoader';

const NetworkAverageTurnoversByActiveProsByMonthChart = ({ user }) => {
  const dispatch = useDispatch();
  const averageOrderAmountLinesGraphData = useSelector(
    networkAverageTurnoverByActiveProsAmountLinesGraphDataSelector(user.id),
  );
  const averageOrderAmountByYear = useSelector(
    networkAverageTurnoverByActiveProsAmountSelector(user.id),
  );

  useEffect(() => {
    if (!averageOrderAmountLinesGraphData) {
      dispatch(statsActions.getNetworkSalesRequest(user.id));
    }
    if (!averageOrderAmountByYear) {
      dispatch(statsActions.getNetworkActiveProsCountByMonthRequest(user.id));
    }
  }, []);

  return (
    <Card>
      <CardHeader
        sx={{ height: 95 }}
        title={"Chiffre d'affaire moyen par pros actifs"}
      />
      <Divider />
      <CardContent>
        <LineChartByYearAndMonth
          data={averageOrderAmountLinesGraphData}
          heightLg={350}
          heightMd={300}
          heightSm={300}
          labels={'months'}
          primaryColor={{
            border: 'rgb(89,80,236)',
            background: 'rgba(89,80,236, 0.04)',
          }}
        />
      </CardContent>
      <Grid container>
        {!averageOrderAmountByYear && <PageLoader height={200} />}
        {averageOrderAmountByYear && (
          <>
            {Object.keys(averageOrderAmountByYear).map((year) => (
              <Grid item key={year} xs={4}>
                <Typography align={'center'} component={'div'} gutterBottom>
                  <Typography>{year}</Typography>
                  <Typography variant={'h6'}>
                    {averageOrderAmountByYear[year].toFixed(2)} SCR
                  </Typography>
                </Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
    </Card>
  );
};
export default NetworkAverageTurnoversByActiveProsByMonthChart;
