import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CustomerLayout from '../layouts/CustomerLayout';
import AdminLayout from '../layouts/AdminLayout';
import LogisticLayout from '../layouts/LogisticLayout';
import AccountingLayout from '../layouts/AccountingLayout';
import {
  RouteAccounting,
  RouteAdmin,
  RouteLogistic,
  RouteNewUrl,
} from '../containers/Authentication/store/helper';
import ScrollToTop from '../helpers/ScrollToTop';

function Router() {
  ScrollToTop();

  return (
    <Switch>
      <RouteAdmin component={AdminLayout} path="/admin" />
      <RouteLogistic component={LogisticLayout} path="/logistic" />
      <RouteAccounting component={AccountingLayout} path="/accounting" />
      <Route
        component={CustomerLayout}
        exact
        path="/:lang(en|fr|nl)-:country/*"
      />
      <Route
        component={CustomerLayout}
        exact
        path="/:lang(en|fr|nl)-:country"
      />
      <RouteNewUrl exact path="/:lang(en|fr|nl)/:path+" />
      <RouteNewUrl exact path="/:lang(en|fr|nl)" />
      <RouteNewUrl path="/:path+" />
      <RouteNewUrl path="/" />
    </Switch>
  );
}

export default Router;
