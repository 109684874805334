import axios from '../../../../helpers/axios';

export const statsService = {
  getTurnoverByMonth,
  getRecurringTurnoverByMonth,
  getRecruitmentByMonth,
  getTurnover,
  getRecruitment,
  getPacksAmount,
  getProductsAmount,
  getPacksAmountByMonth,
  getBestSellersAmount,
  getBestRecruitersAmount,
  getOrdersCountByMonth,
  getProsCountByMonth,
  getActiveProsCountByMonth,
  getTurnoverByCareer,
  getAccountingNumbers,
  getProsPerformances,
  getRecruitsProsPerformances,
  getActiveProsByCareerRequest,
  getStockConsumedByDates,
  getStockConsumedOrdersByDates,
  getOrganicInvoices,

  getDirectSales,
  getNetworkSales,
  getDirectRecurringSales,
  getNetworkRecurringSales,
  getDirectRecruitment,
  getNetworkRecruitment,
  getDirectBestSellersAmount,
  getDirectBestRecruitersAmount,
  getNetworkBestSellersAmount,
  getNetworkBestRecruitersAmount,
  getNetworkPacksAmount,
  getNetworkProductsAmount,
  getDirectPacksAmount,
  getDirectProductsAmount,
  getNetworkTurnoverByCareer,
  getNetworkActiveProsCountByMonth,
  getNetworkPerformances,
};

function getTurnoverByMonth() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/turnover/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecurringTurnoverByMonth() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/turnover/recurring/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getTurnover(date) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/turnover/${date}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecruitmentByMonth() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/recruitment/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecruitment() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/recruitment`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPacksAmount(year, month = null) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/products/packs/amount/${year}${month ? `/${month}` : ''}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductsAmount(year, month = null) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/products/amount/${year}${month ? `/${month}` : ''}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPacksAmountByMonth(year) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/products/packs/amount/${year}/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBestSellersAmount(year, month, productType) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/admin/bestsellers/turnover${
          productType !== 'all' ? `/type/${productType}` : ''
        }/amount/${year}${month ? `/${month}` : ''}`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBestRecruitersAmount(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/bestRecruiters/amount/${year}${month ? `/${month}` : ''}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrdersCountByMonth() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/orders/count/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProsCountByMonth() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/pros/all/count/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getActiveProsCountByMonth() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/pros/active/count/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getTurnoverByCareer(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/turnover/career/${year}${month ? `/${month}` : ''}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getAccountingNumbers(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/accounting/${year}${month ? `/${month}` : ''}/numbers`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProsPerformances(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/pros/performances/${year}${month ? `/${month}` : ''}`)
      .then(({ performances }) => {
        resolve(performances);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getRecruitsProsPerformances(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/pros/recruits/${year}/${month}/performances/`)
      .then(({ users }) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getActiveProsByCareerRequest(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/pros/activity/${year}/${month}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockConsumedByDates(start, end) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/stocks/consumed/${start}/${end}`)
      .then(({ products }) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockConsumedOrdersByDates(id, start, end) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/stocks/consumed/${start}/${end}/product/${id}`)
      .then(({ orders }) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrganicInvoices(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/invoices/organics/${year}${month ? `/${month}` : ''}`)
      .then(({ invoices }) => {
        resolve(invoices);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectSales(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/user/${userId}/direct/sales`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkSales(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/user/${userId}/network/sales`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectRecurringSales(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/user/${userId}/direct/sales/recurring`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkRecurringSales(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/user/${userId}/network/sales/recurring`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectRecruitment(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/user/${userId}/direct/recruitment`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkRecruitment(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/user/${userId}/network/recruitment`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectPacksAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/direct/packs/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectProductsAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/direct/products/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkPacksAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/network/packs/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkProductsAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/network/products/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectBestSellersAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/direct/bestsellers/turnover/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDirectBestRecruitersAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/direct/bestRecruiters/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkBestSellersAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/network/bestsellers/turnover/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkBestRecruitersAmount(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/network/bestRecruiters/amount/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkTurnoverByCareer(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/network/turnover/career/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkActiveProsCountByMonth(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/user/${userId}/network/active/count/months`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getNetworkPerformances(userId, year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/user/${userId}/network/performances/${year}${
          month ? `/${month}` : ''
        }`,
      )
      .then(({ users }) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
