import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import FormValidator from '../../../../components/Validators/FormValidator';
import { SnackbarConfirm } from '../../../../helpers/snackbar';
import { userService } from '../store/services';
import MenuItem from '@mui/material/MenuItem';
import SelectValidator from '../../../../components/Validators/SelectValidator';
import TextFieldValidator from '../../../../components/Validators/TextFieldValidator';
import { getCurrency } from '../../../../helpers/utils';
import DialogContent from '@mui/material/DialogContent';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD',
        },
        '& .MuiDivider-root': {
          margin: -1,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiCard-root': {
          overflow: 'auto',
        },
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden',
      },
      '& h4': {
        marginBottom: 20,
      },
      '& .MuiInputBase-input': {
        backgroundColor: '#fff',
      },
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
    },
  },
  form: {
    '& .enterBtn': {
      marginTop: 42,
    },
    '& .secondaryLink': {
      marginTop: 15,
      '& .MuiButton-label': {
        lineHeight: 1.5,
        fontSize: '1rem',
      },
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const UserCreditModal = ({ onSuccess, open, setOpen, user }) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [formValues, setFormValues] = useState({
    object: '',
    amount: '',
  });

  const handleChangeFormValue = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (formValues.amount) {
      SnackbarConfirm(
        `"${formValues.object}" de ${formValues.amount} ${getCurrency(
          user.country,
        )} ?`,
        () => {
          userService.creditUser(user, formValues).then(() => {
            onSuccess();
            setOpen(false);
          });
        },
      );
    }
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">Créditer le compte</DialogTitle>
      <FormValidator
        className={classes.form}
        noValidate
        onError={(errors) => console.log(errors)}
        onSubmit={handleSubmit}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                Vous êtes sur le point de créditer le compte utilisateur,
                n'oubliez pas que le montant est dans la devise de
                l'utilisateur. Vous pouvez enlever du crédit en utilisant un
                montant négatif.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SelectValidator
                fullWidth
                label={"Séléctionner l'objet du crédit"}
                name="object"
                onChange={handleChangeFormValue}
                required
                value={formValues.object || ''}
              >
                <MenuItem value={'bonus'}>Bonus</MenuItem>
                <MenuItem value={'purchaseRefund'}>Remboursement</MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                label={`Montant du crédit en ${getCurrency(user.country)}`}
                name="amount"
                onChange={handleChangeFormValue}
                required
                type={'number'}
                value={formValues.amount}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Annuler
          </Button>
          <Button color="primary" type="submit" variant="contained">
            Créditer le compte
          </Button>
        </DialogActions>
      </FormValidator>
    </Dialog>
  );
};

export default UserCreditModal;
