import React, { useState } from 'react';
import CardContent from '@mui/material/CardContent';
import FormValidator from '../../../../components/Validators/FormValidator';
import Grid from '@mui/material/Grid';
import TextFieldValidator from '../../../../components/Validators/TextFieldValidator';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import AutocompletePlaces from '../../../../components/GoogleAutocomplete/AutocompletePlaces';
import Button from '@mui/material/Button';
import {
  SnackbarError,
  SnackbarFormattedError,
} from '../../../../helpers/snackbar';
import SelectValidator from '../../../../components/Validators/SelectValidator';
import MenuItem from '@mui/material/MenuItem';
import {
  ADMIN_ROLE,
  CUSTOMER_ROLE,
  DELEGATE_ROLE,
  PROSPECT_ROLE,
} from '../../../Authentication/store/constants';
import { userService } from '../store/services';
import { history } from '../../../../helpers/store';

const UserUpdateCard = ({ onChange, user }) => {
  const { t } = useTranslation();
  const [addressChanged, setAddressChanged] = useState(false);
  const [userValues, setUserValues] = useState({
    ...user,
    billingAddress: user.address,
  });

  const handleChange = (e) => {
    setUserValues({
      ...userValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleBillingAddressChanged = (place) => {
    setAddressChanged(true);
    setUserValues({
      ...userValues,
      ['billingAddress']: place,
    });
  };

  const handleSubmit = () => {
    if (addressChanged && typeof userValues.billingAddress !== 'object') {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    userService
      .updateUser(userValues.id, userValues)
      .then((user) => {
        onChange();
        history.push(`/admin/users/${user.id}`);
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Card>
      <CardContent>
        <FormValidator
          noValidate
          onError={(errors) => {
            console.log('ERRORS', errors);
          }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SelectValidator
                fullWidth
                label={'Role'}
                name="role"
                onChange={handleChange}
                required
                value={userValues.role}
              >
                <MenuItem value={PROSPECT_ROLE}>{t(PROSPECT_ROLE)}</MenuItem>
                <MenuItem value={CUSTOMER_ROLE}>{t(CUSTOMER_ROLE)}</MenuItem>
                <MenuItem value={DELEGATE_ROLE}>Professionel</MenuItem>
                <MenuItem value={ADMIN_ROLE}>{t(ADMIN_ROLE)}</MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('firstName')}
                name="firstName"
                onChange={handleChange}
                required
                value={userValues.firstName ? userValues.firstName : ''}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('lastName')}
                name="lastName"
                onChange={handleChange}
                required
                value={userValues.lastName ? userValues.lastName : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                onChange={handleChange}
                required
                type="email"
                value={userValues.email ? userValues.email : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                autoComplete="phone"
                fullWidth
                label={t('phone')}
                name="phone"
                onChange={handleChange}
                required
                value={userValues.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectValidator
                fullWidth
                label={t('mailingAndDataLanguage')}
                name="lang"
                onChange={handleChange}
                required
                value={userValues.lang}
              >
                <MenuItem value="fr">{t('french')}</MenuItem>
                <MenuItem value="en">{t('english')}</MenuItem>
              </SelectValidator>
            </Grid>
            <Grid item xs={12}>
              <AutocompletePlaces
                customFieldsEnabled
                error={
                  addressChanged &&
                  typeof userValues.billingAddress !== 'object'
                }
                fullWidth
                helperText={
                  addressChanged &&
                  typeof userValues.billingAddress !== 'object' &&
                  t('validatorFieldRequired')
                }
                initialCustomFieldAdditionalInfo={userValues.additionalInfo}
                initialCustomFieldCity={userValues.city}
                initialCustomFieldCountry={userValues.country}
                initialCustomFieldStreetName={userValues.streetName}
                initialCustomFieldStreetNumber={userValues.streetNumber}
                initialCustomFieldZip={userValues.zip}
                initialValue={`${userValues.billingAddress} ${
                  userValues.additionalInfo
                    ? `(${userValues.additionalInfo})`
                    : ''
                }`}
                label={t('billingAddress')}
                name="address"
                onPlaceChanged={handleBillingAddressChanged}
                placeholder=""
                preview
                required={addressChanged}
                requiredFields={['streetName', 'city', 'country']}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('company')}
                name="company"
                onChange={handleChange}
                value={userValues.company || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('taxNumber')}
                name="taxNumber"
                onChange={handleChange}
                value={userValues.taxNumber || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Button color="primary" type="submit" variant="contained">
                Modifier l'utilisateur
              </Button>
            </Grid>
          </Grid>
        </FormValidator>
      </CardContent>
    </Card>
  );
};
export default UserUpdateCard;
