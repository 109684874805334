import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const ContactIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M28.7,26.1h-9.8C8.6,26.1,0.2,17.8,0.2,7.5V0.8h9.8c10.3,0,18.6,8.4,18.6,18.6V26.1z"
        fill="#00207f"
      />
      <path
        d="M19.5,10.2c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5c0-2.1-1.7-3.7-3.7-3.7s-3.7,1.7-3.7,3.7c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5c0-2.7,2.2-4.8,4.8-4.8S19.5,7.6,19.5,10.2z M21.6,10.3L21.6,10.3c0-3.9-3.1-7-6.9-7s-6.9,3.1-6.9,6.9v0.1c-1.5,0.3-2.7,1.6-2.7,3.2c0,1.6,1.2,2.9,2.7,3.2v0.1c0,3.3,2.3,6.1,5.4,6.8c0.2,0.7,0.8,1.3,1.6,1.3c0.9,0,1.6-0.7,1.6-1.6c0-0.9-0.7-1.6-1.6-1.6c-0.6,0-1.2,0.4-1.4,0.9c-2.6-0.6-4.5-2.9-4.5-5.7v-6.4c0-3.2,2.6-5.9,5.9-5.9s5.9,2.6,5.9,5.9v6.4h1.1v-0.1c1.5-0.3,2.7-1.6,2.7-3.2C24.3,11.8,23.2,10.5,21.6,10.3z M13.4,12.4c0-0.4-0.4-0.8-0.8-0.8c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8C13,13.2,13.4,12.8,13.4,12.4z M16.8,11.6c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8C17.6,11.9,17.3,11.6,16.8,11.6z M14.7,18.8c1.2,0,2.1-1,2.1-2.1h-4.3C12.6,17.8,13.5,18.8,14.7,18.8z"
        fill="#FFFFFF"
      />
    </SvgIcon>
  );
};
export default ContactIcon;
