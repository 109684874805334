import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import SelectValidator from '../../../../../components/Validators/SelectValidator';
import MenuItem from '@mui/material/MenuItem';
import React, { useEffect, useState } from 'react';
import { shopActions } from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  shippingAddressSelectedSelector,
  userShippingAddressesSelector,
} from '../../store/selectors';
import AddShippingAddressDialog from '../../../Account/components/ShippingAddresses/components/AddShippingAddressDialog';
import makeStyles from '@mui/styles/makeStyles';
import { userSelector } from '../../../../Authentication/store/selectors';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const ShippingAddressSelector = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const user = useSelector(userSelector);
  const shippingAddresses = useSelector(userShippingAddressesSelector);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);

  const [addShippingModalOpen, setAddShippingModalOpen] = useState(false);

  useEffect(() => {
    dispatch(shopActions.getShippingAddressesRequest());
  }, [user]);

  useEffect(() => {
    if (!shippingAddressSelected && shippingAddresses?.length > 0) {
      handleChangeShippingAddress(shippingAddresses[0]);
    }
  }, [shippingAddresses]);

  const handleChangeShippingAddress = (shippingAddress) => {
    dispatch(shopActions.setShippingAddressSelected(shippingAddress));
  };

  return (
    <Grid alignItems={'center'} container justifyContent={'space-between'}>
      <Grid item>
        <Typography component="h4" variant={'h6'}>
          <Trans i18nKey="shippedTo">Shipped to</Trans> :
        </Typography>
      </Grid>
      <Grid item>
        <Grid alignItems={'center'} container>
          <Grid item>
            <Typography
              className={classes.link}
              color={'inherit'}
              onClick={() => setAddShippingModalOpen(true)}
              variant="body1"
            >
              <Trans i18nKey="addNewShipping">Add new address</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {shippingAddresses && (
        <Grid item sm={12} xs={12}>
          <SelectValidator
            fullWidth
            label={t('selectedShippingAddress')}
            name="shippingSelected"
            onChange={(e) => {
              handleChangeShippingAddress(
                shippingAddresses.find((addr) => addr.id === e.target.value),
              );
            }}
            required
            value={shippingAddressSelected && shippingAddressSelected.id}
          >
            {shippingAddresses.map((shippingAddress) => (
              <MenuItem key={shippingAddress.id} value={shippingAddress.id}>
                {shippingAddress.name ? shippingAddress.name : t('address')}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
      )}
      {!!shippingAddressSelected && (
        <Grid item xs={12}>
          <Typography component={'p'}>
            {`${shippingAddressSelected.firstName} ${shippingAddressSelected.lastName}`}
          </Typography>
          <Typography component={'p'}>
            {`${shippingAddressSelected.address} ${
              shippingAddressSelected.additionalInfo
                ? `(${shippingAddressSelected.additionalInfo})`
                : ``
            }`}
          </Typography>
        </Grid>
      )}
      <AddShippingAddressDialog
        dialogOpen={addShippingModalOpen}
        setDialogOpen={setAddShippingModalOpen}
      />
    </Grid>
  );
};
export default ShippingAddressSelector;
