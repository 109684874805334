import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import i18next from 'i18next';
import Reviews from './Reviews';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import ItemPriceDisplay from '../../components/ItemPriceDisplay';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';
import { getFormalizedName } from '../../helpers/helper';

function TabPanel(props) {
  const { children, index, value, ...other } = props;

  return (
    <div
      aria-labelledby={`vertical-tab-${index}`}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      role="tabpanel"
      style={{ width: '100%' }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
    value: index,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    '& .MuiTabs-root': {
      overflow: 'inherit',
      '& .MuiTab-wrapper': {
        alignItems: 'flex-end',
      },
    },
    '& .MuiBox-root': {
      paddingTop: 0,
    },
    '& .tabs': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '& .priceInfo': {
      position: 'relative',
      fontSize: '1.8rem !important',
    },
    '& .oldPriceInfo': {
      fontSize: '1.8rem !important',
      textDecoration: 'line-through',
    },
    '& .proPriceInfo': {
      position: 'relative',
      fontWeight: 'bold',
      fontSize: '2.125rem !important',
      color: red[500],
      marginLeft: 10,
      '& .limitedCommissions': {
        display: 'block',
        marginLeft: 0,
        fontSize: 16,
        color: red[500],
      },
      '& .discount': {
        display: 'block',
        marginLeft: 0,
        fontSize: 20,
        color: red[500],
      },
    },
    '& .video': {
      width: '100%',
      position: 'relative',
      paddingBottom: '56.25%',
      overflow: 'hidden',
      '& iframe': {
        borderRadius: 5,
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
      },
    },
  },
}));

const TabPanelContent = ({ product, session }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        aria-label="Product menu"
        className={'tabs'}
        onChange={handleChange}
        orientation="vertical"
        value={value}
        variant="scrollable"
      >
        <Tab label={t('card')} {...a11yProps(0)} />
        {!!session && session.isPro && product.detail.proInfo && (
          <Tab
            className={'infoPro'}
            id={`vertical-tab-${9}`}
            label={t('proInfo')}
            value={9}
          />
        )}
        {product.detail.definition && (
          <Tab label={t('definition')} {...a11yProps(1)} />
        )}
        {product.detail.indications && (
          <Tab label={t('indications')} {...a11yProps(2)} />
        )}
        {product.detail.recommendedDosage && (
          <Tab label={t('usage')} {...a11yProps(3)} />
        )}
        {(product.detail.contraindications ||
          product.detail.guaranteedWithout ||
          product.detail.precautionsForUse) && (
          <Tab label={t('recommendations')} {...a11yProps(4)} />
        )}
        {product.detail.conservation && (
          <Tab label={t('conservation')} {...a11yProps(5)} />
        )}
        {(product.detail.composition ||
          product.detail.excipients ||
          product.detail.ingredients) && (
          <Tab label={t('composition')} {...a11yProps(6)} />
        )}
        {!!product.detail.video && <Tab label={t('video')} {...a11yProps(7)} />}
        {product.reviewsRating && (
          <Tab label={t('reviews')} {...a11yProps(8)} />
        )}
      </Tabs>
      <TabPanel index={0} value={value}>
        <Typography component="h2" variant="h2">
          {product.detail.name}
        </Typography>
        {product.allowInCart && (
          <ItemPriceDisplay product={product} session={session} />
        )}
        <Divider />
        <Typography component="p" variant="body1">
          {product.detail.description}
        </Typography>
        <br />
        {product.unit !== 'other' && (
          <Typography component="p" variant="body1">
            {`${t('boxContains')} ${product.quantity} ${product.unit}`}
          </Typography>
        )}
        {product.packageItems && (
          <List>
            {product.packageItems.map((item) => (
              <ListItemButton
                href={`/shop/product/${getFormalizedName(item.detail.name)}/${
                  item.id
                }`}
                key={`${product.id}-${item.id}`}
                target={'_blank'}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      alt={item.detail.name}
                      src={item.image[600]}
                      sx={{ borderRadius: 0, mr: 4, width: 100, height: 100 }}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.detail.name}
                    secondary={item.detail.description}
                    sx={{ height: 100, overflow: 'hidden' }}
                  />
                </ListItem>
              </ListItemButton>
            ))}
          </List>
        )}
      </TabPanel>
      <TabPanel index={1} value={value}>
        <Typography component="h4" variant="h5">
          <Trans i18nKey="definition">Definition :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.definition}
        </Typography>
      </TabPanel>
      <TabPanel index={2} value={value}>
        <Typography component="h4" variant="h5">
          <Trans i18nKey="indications">Indications :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.indications}
        </Typography>
      </TabPanel>
      <TabPanel index={3} value={value}>
        <Typography component="h4" variant="h5">
          <Trans i18nKey="recommendedDosage">Recommended dosage :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.recommendedDosage}
        </Typography>
      </TabPanel>
      <TabPanel index={4} value={value}>
        <Typography component="h4" variant="h5">
          <Trans i18nKey="contraindications">Contraindications :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.contraindications}
        </Typography>
        <Divider />
        <Typography component="h4" variant="h5">
          <Trans i18nKey="guaranteedWithout">Guaranteed without :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.guaranteedWithout}
        </Typography>
        <Divider />
        <Typography component="h4" variant="h5">
          <Trans i18nKey="precautionsForUse">Precautions for use :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.precautionsForUse}
        </Typography>
      </TabPanel>
      <TabPanel index={5} value={value}>
        <Typography component="h4" variant="h5">
          <Trans i18nKey="conservation">Conservation :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.conservation}
        </Typography>
      </TabPanel>
      <TabPanel index={6} value={value}>
        <Typography component="h4" variant="h5">
          <Trans i18nKey="composition">Composition :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.composition}
        </Typography>
        <Divider />
        <Typography component="h4" variant="h5">
          <Trans i18nKey="excipients">Excipients :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.excipients}
        </Typography>
        <Divider />
        <Typography component="h4" variant="h5">
          <Trans i18nKey="ingredients">Ingredients :</Trans>
        </Typography>
        <Typography component="p" variant="body1">
          {product.detail.ingredients}
        </Typography>
      </TabPanel>
      {!!product.detail.video && (
        <TabPanel index={7} value={value}>
          <Typography className={'video'} component={'div'} gutterBottom>
            <iframe
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              height="360"
              src={`${product.detail.video}?autoplay=0&color=ffffff&title=0&byline=0&portrait=0&modestbranding=1&rel=0&showinfo=0&texttrack=${i18next.language}`}
              width="640"
            />
          </Typography>
        </TabPanel>
      )}
      {product.reviewsRating && (
        <TabPanel index={8} value={value}>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="reviews">Reviews</Trans>
          </Typography>
          <div>
            <Typography component="span" variant="h2">
              {parseFloat(product.reviewsRating).toFixed(1)}
            </Typography>
            <Typography component="span" sx={{ ml: 1 }} variant="h4">
              /5
            </Typography>
          </div>
          <Typography component="i">
            <Trans
              i18nKey={'calculatedFromReview'}
              values={{ count: product.reviewsCount }}
            >
              Calculated from {product.reviewsCount} customer review(s)
            </Trans>
          </Typography>
          <Divider />
          {value === 8 && <Reviews product={product} />}
        </TabPanel>
      )}
      {!!session && session.isPro && product.detail.proInfo && (
        <TabPanel className={'infoPro'} index={9} value={value}>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="proInfo">Pro info :</Trans>
          </Typography>
          <Typography component="p" variant="body1">
            {product.detail.proInfo}
          </Typography>
        </TabPanel>
      )}
    </div>
  );
};

export default TabPanelContent;
