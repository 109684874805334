import React, { useRef } from 'react';
import { getElementAtEvent, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { Chart, registerables } from 'chart.js';
import useDateFns from '../../helpers/useDateFns';
import Box from '@mui/material/Box';
if (Array.isArray(registerables)) {
  Chart.register(...registerables);
}

const LineChartByYearAndMonth = ({
  data,
  heightLg,
  heightMd,
  heightSm,
  onEventClick = () => {},
  primaryColor,
  secondaryColor = {
    border: 'rgb(160,160,160)',
    background: 'rgba(160,160,160, 0.04)',
  },
  tertiaryColor = {
    border: 'rgb(230,230,230)',
    background: 'rgba(230,230,230, 0.04)',
  },
}) => {
  const chartRef = useRef();
  const { t } = useTranslation();
  const dateFns = useDateFns();

  const labels = [
    t('Jan'),
    t('Feb'),
    t('Mar'),
    t('Apr'),
    t('May'),
    t('Jun'),
    t('Jul'),
    t('Aug'),
    t('Sep'),
    t('Oct'),
    t('Nov'),
    t('Dec'),
  ];

  let datasetsDefaultValues = {
    fill: true,
    borderWidth: 2,
    hoverBorderWidth: 4,
    lineTension: 0.3,
    pointBorderColor: '#FFF',
    pointBorderWidth: 1,
  };

  let directSalesData = {
    labels: labels,
    datasets: [],
  };

  let options = {
    maintainAspectRatio: false,
    plugins: {
      responsive: true,
    },
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  };

  const handleEventClick = (event) => {
    const elems = getElementAtEvent(chartRef.current, event);
    if (!elems[0]) {
      return;
    }

    onEventClick(
      elems.map((elem) => {
        const month = `${elem.index + 1}`.padStart(2, '0');
        const year = dateFns.getYear(
          dateFns.addYears(dateFns.subYears(new Date(), 2), elem.datasetIndex),
        );
        return { year, month };
      }),
    );
  };

  const getColor = (year) => {
    switch (year) {
      case dateFns.getYear(new Date()).toString():
        return primaryColor.border;
      case dateFns.getYear(dateFns.subYears(new Date(), 1)).toString():
        return secondaryColor.border;
      default:
        return tertiaryColor.border;
    }
  };
  const getBackgroundColor = (year) => {
    switch (year) {
      case dateFns.getYear(new Date()).toString():
        return primaryColor.background;
      case dateFns.getYear(dateFns.subYears(new Date(), 1)).toString():
        return secondaryColor.background;
      default:
        return tertiaryColor.background;
    }
  };

  if (!data) {
    return null;
  }

  const last = (ctx, value) =>
    ctx.datasetIndex === data.length - 1 &&
    ctx.p0DataIndex > dateFns.getMonth(new Date()) - 2
      ? value
      : [6, 0];

  data.map((rawData) => {
    directSalesData.datasets.push({
      ...datasetsDefaultValues,
      label: rawData.label,
      data: rawData.data,
      backgroundColor: getBackgroundColor(rawData.label),
      pointBackgroundColor: getColor(rawData.label),
      borderColor: getColor(rawData.label),
      segment: {
        //borderColor: ctx => skipped(ctx, 'rgb(0,0,0,0.2)') || down(ctx, 'rgb(192,75,75)'),
        borderDash: (ctx) => last(ctx, [6, 6]),
      },
      hoverBackgroundColor: getColor(rawData.label),
      hoverBorderColor: getColor(rawData.label),
    });
  });

  return (
    <Box
      sx={(theme) => ({
        '& canvas': {
          width: '100% !important',
          height: `${heightLg}px !important`,
          [theme.breakpoints.down('lg')]: {
            height: `${heightMd}px !important`,
          },
          [theme.breakpoints.down('md')]: {
            height: `${heightSm}px !important`,
          },
        },
      })}
    >
      <Line
        data={directSalesData}
        onClick={handleEventClick}
        options={options}
        ref={chartRef}
      />
    </Box>
  );
};
export default LineChartByYearAndMonth;
