import React from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import makeStyles from '@mui/styles/makeStyles';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import { PARTNER_CAREER } from '../../../Authentication/store/constants';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import ComputerIcon from '@mui/icons-material/Computer';
import useDateFns from '../../../../helpers/useDateFns';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 300,
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
    '& .greenColor': {
      color: green[700],
    },
    '& .redColor': {
      color: red[700],
    },
  },
}));

const UserCard = ({ user }) => {
  const classes = useStyles();
  const dateFns = useDateFns();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              alignItems={'flex-start'}
              container
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item sm={6} xs={12}>
                <Typography component="h3" gutterBottom variant={'h6'}>
                  <Trans i18nKey={'generalInformation'}>
                    General information
                  </Trans>
                  {` :`}
                </Typography>
                <Typography component={'p'} variant="body1">
                  {user.firstName} {user.lastName}
                </Typography>
                {user.company && (
                  <Typography component={'p'} variant="body1">
                    {user.company}
                  </Typography>
                )}
                {user.taxNumber && (
                  <Typography component={'p'} variant="body1">
                    TVA : {user.taxNumber}
                  </Typography>
                )}
                <Typography component={'p'} variant="body1">
                  <Typography
                    color={'inherit'}
                    component={'a'}
                    href={`tel:${user.phone}`}
                  >
                    {user.phone}
                  </Typography>
                </Typography>
                <Typography component={'p'} variant="body1">
                  <Typography
                    color={'inherit'}
                    component={'a'}
                    href={`mailto:${user.email}`}
                  >
                    {user.email}
                  </Typography>
                </Typography>
                <Typography component={'p'} variant="body1">
                  {user.address}
                </Typography>
                {user.provider && (
                  <Typography color={'error'} component={'p'} variant="body1">
                    Provenance : {user.provider}
                  </Typography>
                )}
              </Grid>
              {user.shippingAddress && (
                <Grid item sm={6} xs={12}>
                  <Typography component="h4" gutterBottom variant={'h6'}>
                    <Trans i18nKey="shippingAddress">Shipping address</Trans> :
                  </Typography>
                  <Typography component={'p'}>
                    {`${user.shippingAddress.firstName} ${user.shippingAddress.lastName}`}
                  </Typography>
                  <Typography component={'p'}>
                    <Typography
                      color={'inherit'}
                      component={'a'}
                      href={`https://www.google.fr/maps/place/${user.shippingAddress.address}`}
                      target={'_blank'}
                    >
                      {`${user.shippingAddress.address} ${
                        user.shippingAddress.additionalInfo
                          ? `(${user.shippingAddress.additionalInfo})`
                          : ''
                      }`}
                    </Typography>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>

          {user.isPro && (
            <Grid item sm={6} xs={12}>
              <Typography component="h3" gutterBottom variant={'h6'}>
                Identifiant :
              </Typography>
              <Typography component={'p'} variant="h5">
                {user.directLink}
              </Typography>
            </Grid>
          )}
          {user.wallet && (
            <Grid item sm={6} xs={12}>
              <Typography component="h3" gutterBottom variant={'h6'}>
                Solde actuel :
              </Typography>
              <Typography
                className={user.wallet.amount > 0 ? 'greenColor' : 'redColor'}
                component={'p'}
                variant="h5"
              >
                {user.wallet.amount} {user.wallet.currency}
              </Typography>
            </Grid>
          )}
          {user.isPro && user.career !== PARTNER_CAREER && (
            <Grid item sm={6} xs={12}>
              <Typography component="h3" gutterBottom variant={'h6'}>
                Certification :
              </Typography>
              {user.certified && (
                <Typography
                  className={'greenColor'}
                  component={'p'}
                  variant="h5"
                >
                  Certifié(e)
                </Typography>
              )}
              {!user.certified && (
                <Typography className={'redColor'} component={'p'} variant="h5">
                  Non certifié(e)
                </Typography>
              )}
            </Grid>
          )}
          {user.isPro && (
            <Grid item sm={6} xs={12}>
              {user.membershipDaysRemaining > 0 && (
                <Typography component="h3" gutterBottom variant={'h6'}>
                  Adhésion active :
                </Typography>
              )}
              {user.membershipDaysRemaining <= 0 && (
                <Typography component="h3" gutterBottom variant={'h6'}>
                  Adhésion inactive :
                </Typography>
              )}
              <Typography
                className={
                  user.membershipDaysRemaining > 0 ? 'greenColor' : 'redColor'
                }
                component={'p'}
                variant="h5"
              >
                {dateFns.format(user.membershipEndAt, 'LL')} (
                {user.membershipDaysRemaining} jours)
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography component="h3" gutterBottom variant={'h6'}>
              Dernières connexions :
            </Typography>
            <List>
              {user.refreshTokens.map((refreshToken) => (
                <ListItem key={refreshToken.id}>
                  <ListItemIcon>
                    {refreshToken.deviceType === 'mobile' && (
                      <PhoneIphoneIcon />
                    )}
                    {refreshToken.deviceType === 'tablet' && <TabletMacIcon />}
                    {refreshToken.deviceType === 'desktop' && <ComputerIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <>
                        {`${dateFns.format(
                          refreshToken.updatedAt,
                          'LL',
                        )} (${dateFns.formatDistanceToNow(
                          new Date(refreshToken.updatedAt),
                        )}) - `}
                        <Typography
                          className={
                            refreshToken.status === 'active'
                              ? 'greenColor'
                              : 'redColor'
                          }
                          component={'span'}
                        >
                          {refreshToken.status}
                        </Typography>
                      </>
                    }
                    secondary={`Créé le ${dateFns.format(
                      refreshToken.createdAt,
                      'LL',
                    )} - ${refreshToken.platform} - ${refreshToken.osName} - ${
                      refreshToken.browserName
                    }`}
                  />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UserCard;
