import React, { forwardRef, useState } from 'react';
import I18n from 'i18next';
import {
  Link as DomLink,
  Redirect as DomRedirect,
  useHistory,
} from 'react-router-dom';
import MuiLink from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import LanguageIcon from '@mui/icons-material/Language';
import i18next from 'i18next';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { useCustomerPath, useProPath } from './usePath';
import { useSelector } from 'react-redux';
import { sessionSelector } from '../containers/Customer/Shop/store/selectors';

export const initLanguage = (lang) => {
  if (I18n.language !== lang) {
    I18n.changeLanguage(lang);
  }
};

export const ProLink = forwardRef(
  (
    {
      children,
      className,
      component,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      style,
      sx,
      target,
      title,
      to,
      underline = 'none',
    },
    ref,
  ) => {
    const path = useProPath();
    return (
      <MuiLink
        className={className}
        component={component || DomLink}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        ref={ref}
        style={style}
        sx={sx}
        target={target}
        title={title}
        to={`${path}${to}`}
        underline={underline}
      >
        {children}
      </MuiLink>
    );
  },
);

export const Link = forwardRef(
  (
    {
      children,
      className,
      component,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onMouseOver,
      style,
      sx,
      target,
      title,
      to,
      underline = 'none',
    },
    ref,
  ) => {
    const path = useCustomerPath();
    return (
      <MuiLink
        className={className}
        component={component || DomLink}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOver={onMouseOver}
        ref={ref}
        style={style}
        sx={sx}
        target={target}
        title={title}
        to={`${path}${to}`}
        underline={underline}
      >
        {children}
      </MuiLink>
    );
  },
);

export const Redirect = ({ params, to }) => {
  const path = useCustomerPath();
  return <DomRedirect to={{ pathname: `${path}${to}`, ...params }} />;
};

export const ProRedirect = ({ params, to }) => {
  const path = useProPath();
  return <DomRedirect to={{ pathname: `${path}${to}`, ...params }} />;
};

export const LangSelector = () => {
  const path = useCustomerPath();
  const session = useSelector(sessionSelector);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [langs, setLangs] = useState(['en', 'fr']);

  // todo accept all languages or not ?
  // useEffect(() => {
  //   switch (session?.country) {
  //     case 'BE':
  //       setLangs(['fr', 'nl']);
  //       break;
  //     case 'CA':
  //       setLangs(['fr', 'en']);
  //       break;
  //     default:
  //       setLangs([]);
  //   }
  // }, [session?.country]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (lang) => {
    if (i18next.language !== lang) {
      history.push(
        `/${lang}-${session.country}${history.location.pathname.replace(
          path,
          '',
        )}`,
      );
      i18next.changeLanguage(lang);
    }
    setAnchorEl(null);
  };

  if (langs.length === 0) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down(450)]: { ml: '0 !important' },
      })}
    >
      <IconButton color={'primary'} onClick={handleOpen} variant={'text'}>
        <LanguageIcon sx={{ fontSize: 30 }} />
      </IconButton>
      <Menu
        TransitionComponent={Fade}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {langs.map((lang) => (
          <MenuItem key={lang} onClick={() => handleChange(lang)}>
            {lang.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export const ProLangSelector = () => {
  const path = useProPath();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const langs = ['fr', 'en'];

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChange = (lang) => {
    if (i18next.language !== lang) {
      history.push(`/${lang}${history.location.pathname.replace(path, '')}`);
      i18next.changeLanguage(lang);
    }
    setAnchorEl(null);
  };

  if (langs.length === 0) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        [theme.breakpoints.down(450)]: { ml: '0 !important' },
      })}
    >
      <IconButton color={'primary'} onClick={handleOpen} variant={'text'}>
        <LanguageIcon sx={{ fontSize: 30 }} />
      </IconButton>
      <Menu
        TransitionComponent={Fade}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {langs.map((lang) => (
          <MenuItem key={lang} onClick={() => handleChange(lang)}>
            {lang.toUpperCase()}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
