import React from 'react';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalShippingTwoToneIcon from '@mui/icons-material/LocalShippingTwoTone';
import StoreTwoToneIcon from '@mui/icons-material/StoreTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { red } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import makeStyles from '@mui/styles/makeStyles';
import { purple } from '@mui/material/colors';
import { blue } from '@mui/material/colors';
import useDateFns from '../../../../helpers/useDateFns';
import EventRepeatTwoToneIcon from '@mui/icons-material/EventRepeatTwoTone';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiCard-root': {
      '& .MuiCardContent-root': {
        '& .MuiSvgIcon-root': {
          fill: purple[900],
          fontSize: '1.8em',
        },
        '&:hover': {
          backgroundColor: grey[50],
          cursor: 'pointer',
        },
      },
      height: '100%',
    },
    '& .MuiButtonBase-root': {
      padding: '0 10px',
    },
    '& .mention': {
      marginLeft: 20,
    },
    '& .item': {
      '& .MuiGrid-container': {
        height: '100%',
      },
      '& .MuiCardContent-root': {
        height: '100%',
        padding: theme.spacing(1, 2),
      },
      '& .MuiCardContent-root:last-child': {
        paddingBottom: theme.spacing(1),
      },
      '& .headerNote': {
        borderBottom: `1px solid ${grey[400]}`,
      },
      '& .date': {
        fontSize: 14,
      },
      '& .note': {
        display: 'inline-block',
        borderRadius: 8,
        whiteSpace: 'pre-wrap',
      },
    },
  },
}));

const DeliveryList = ({ onClick, orders, withoutDetails }) => {
  const classes = useStyles();
  const dateFns = useDateFns();

  const getIcon = (order) => {
    let icon;
    let color = green[700];
    if (new Date() > dateFns.addDays(new Date(order.sent), 4)) {
      color = orange[700];
    }
    if (new Date() > dateFns.addDays(new Date(order.sent), 7)) {
      color = red[700];
    }

    switch (order.shippingStatus) {
      case 'waiting':
      case 'processing':
        icon = <ScheduleIcon style={{ fill: color }} />;
        break;
      case 'delivered':
      case 'noShipping':
        color = green[600];
        icon = <CheckCircleOutlineIcon style={{ fill: color }} />;
        break;
      case 'inTransit':
        icon = <LocalShippingTwoToneIcon style={{ fill: color }} />;
        break;
      case 'inPickupPoint':
        icon = <StoreTwoToneIcon style={{ fill: color }} />;
        break;
      case 'backToSender':
        color = red[600];
        if (order.status === 'gotBack' || order.refunded === 'totally') {
          color = green[600];
        }
        icon = <ReplayTwoToneIcon style={{ fill: color }} />;
        break;
      default:
        color = grey[600];
        icon = <EventNoteOutlinedIcon style={{ fill: color }} />;
    }
    if (
      order.status === 'saved' ||
      order.status === 'recommendation' ||
      order.status === 'admin'
    ) {
      icon = <EventNoteOutlinedIcon style={{ fill: blue[600] }} />;
    }
    if (order.status !== 'gotBack' && order.refunded === 'partially') {
      icon = <EventRepeatTwoToneIcon style={{ fill: color }} />;
    }
    if (
      order.status !== 'gotBack' &&
      (order.refunded === 'totally' || order.deleted)
    ) {
      icon = <EventBusyIcon style={{ fill: red[600] }} />;
    }
    return icon;
  };

  return (
    <Grid className={classes.root} container spacing={1}>
      {orders.map((order) => (
        <Grid
          className={'item'}
          item
          key={order.id}
          md={withoutDetails ? 12 : 6}
          xs={12}
        >
          <Card
            elevation={1}
            onClick={() => onClick(order)}
            rounded="true"
            square={false}
          >
            <CardContent>
              <Grid alignItems={'center'} container spacing={2}>
                <Grid item lg={1} md={2} xs={2}>
                  <Typography align={'center'}>{getIcon(order)}</Typography>
                </Grid>
                <Grid item lg={withoutDetails ? 10 : 5} md={10} xs={10}>
                  <Typography>
                    {dateFns.format(order.createdAt, 'LLL')}
                  </Typography>
                  {order.sent && (
                    <Grid alignItems={'center'} container>
                      <Grid item>
                        <ArrowRightAltIcon />
                      </Grid>
                      <Grid item>
                        <Typography>
                          {dateFns.format(order.sent, 'LLL')}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                  {order.status === 'recommendation' && (
                    <Typography>
                      {`Recommandation de ${order.pro.firstName} ${order.pro.lastName}`}
                    </Typography>
                  )}
                  {(order.status === 'admin' || order.status === 'saved') &&
                    order.paymentProvider !== 'bankTransfer' && (
                      <Typography>Recommandation de Sharks</Typography>
                    )}
                  {(order.status === 'admin' || order.status === 'saved') &&
                    order.paymentProvider === 'bankTransfer' && (
                      <Typography>En attente de virement bancaire</Typography>
                    )}
                  {!!order.invoice && (
                    <Typography>Facture n°{order.invoice.number}</Typography>
                  )}
                  {!!order.proformaInvoice && (
                    <Typography>
                      Proforma n°{order.proformaInvoice.number}
                    </Typography>
                  )}
                  {!withoutDetails && (
                    <Typography>
                      {order.client.firstName} {order.client.lastName}
                      {order.status === 'published' &&
                        order.userId === order.parentId &&
                        ` - Achat pro`}
                      {order.status === 'free' && ` - Commande offerte`}
                      {order.status === 'deferred' && ` - Commande différée`}
                    </Typography>
                  )}
                </Grid>
                {!withoutDetails && (
                  <Grid className={'note'} item lg={6} md={12} xs={12}>
                    {order.notes &&
                      order.notes
                        .slice(
                          order.notes.length > 3
                            ? Math.max(order.notes.length - 3, 1)
                            : 0,
                        )
                        .map((note) => (
                          <Grid item key={note.id} xs={12}>
                            <Grid
                              alignItems={'flex-end'}
                              className={'headerNote'}
                              container
                              justifyContent={'space-between'}
                            >
                              <Grid item>
                                <Typography className={'date'}>
                                  {`${dateFns.format(
                                    note.createdAt,
                                    'dd/MM HH:mm',
                                  )} - ${note.author.firstName} ${
                                    note.author.lastName
                                  }`}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Typography className={'note'} color={'error'}>
                              {note.content}
                            </Typography>
                          </Grid>
                        ))}
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default DeliveryList;
