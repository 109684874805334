import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

const AccountingRoutes = () => {
  const Dashboard = loadable(
    () => import('../containers/Accounting/Dashboard/Dashboard'),
  );
  const Invoices = loadable(() => import('../containers/Accounting/Invoices'));
  const CreditNotes = loadable(
    () => import('../containers/Accounting/CreditNotes'),
  );
  const CommissionsCreditNotes = loadable(
    () => import('../containers/Accounting/CommissionsCreditNotes'),
  );
  const Error = loadable(() => import('../containers/Pro/Error'));

  return (
    <Switch>
      <Route component={Dashboard} exact path="/accounting" />
      <Route component={Invoices} exact path="/accounting/invoices" />
      <Route component={CreditNotes} exact path="/accounting/creditNotes" />
      <Route
        component={CommissionsCreditNotes}
        exact
        path="/accounting/commissions"
      />
      <Route component={Error} path="*" />
    </Switch>
  );
};

export default AccountingRoutes;
