import ItemPrice from './ItemPrice';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useSelector } from 'react-redux';
import { giftCardSelector } from '../store/selectors';

const ItemPriceDisplay = ({ product, session, view }) => {
  const discountCode = useSelector(giftCardSelector);

  if (!session) {
    return null;
  }

  if (!session.isPro) {
    if (
      !!discountCode &&
      discountCode.currency === 'PERCENT' &&
      product.commissioned &&
      product.type !== 'formation' &&
      (!Array.isArray(discountCode.products) ||
        discountCode.products.length === 0 ||
        discountCode.products.includes(product.id))
    ) {
      return (
        <Typography className={'priceInfo'} component={'div'}>
          <ItemPrice
            className={'oldPriceInfo'}
            component={'span'}
            item={product}
            session={session}
            view={view}
            withTaxes
          />
          <ItemPrice
            className={'proPriceInfo'}
            component={'span'}
            item={product}
            session={session}
            view={view}
            withDiscount
            withTaxes
          />
        </Typography>
      );
    } else {
      return (
        <ItemPrice
          className={'priceInfo'}
          component={'p'}
          item={product}
          session={session}
          view={view}
          withTaxes
        />
      );
    }
  } else {
    if (product.commissioned && product.type !== 'formation') {
      return (
        <Typography className={'priceInfo'} component={'div'}>
          <ItemPrice
            className={'oldPriceInfo'}
            component={'span'}
            item={product}
            session={session}
            view={view}
            withTaxes
          />
          <ItemPrice
            className={'proPriceInfo'}
            component={'span'}
            item={product}
            session={session}
            view={view}
            withMultiplier
            withTaxes
          />
        </Typography>
      );
    } else {
      return (
        <ItemPrice
          className={'priceInfo'}
          component={'p'}
          item={product}
          session={session}
          view={view}
          withTaxes
        />
      );
    }
  }
};
export default ItemPriceDisplay;
