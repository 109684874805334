import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { ListItemButton, Stack } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { Link } from '../../../../../helpers/multilingual';
import { getFormalizedName } from '../../helpers/helper';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useSearchBar } from './SearchBarProvider';

const SearchBarDialog = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    closeDialog,
    isDialogOpen,
    items,
    popularSearches,
    search,
    setSearch,
  } = useSearchBar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleClear = () => {
    setSearch('');
  };

  const handleSelect = (search) => {
    setSearch(search);
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          position: 'absolute',
          top: 0,
        },
      }}
      fullScreen={fullScreen}
      onClose={closeDialog}
      open={isDialogOpen}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            width: { xl: 600, lg: 500, md: 500, sm: 500 },
          }}
        >
          <SearchIcon
            sx={{ color: 'action.active', mr: 1, my: 0.5, fontSize: '1.6em' }}
          />
          <TextField
            InputProps={{
              disableUnderline: true,
              sx: {
                fontSize: '1.2em',
                width: { sm: 'inherit', xs: 'calc(100% - 60px)' },
              },
            }}
            autoFocus
            fullWidth
            onChange={handleChange}
            placeholder={t('productSearchByText')}
            value={search}
            variant="standard"
          />
          {search.length > 0 && (
            <IconButton
              onClick={handleClear}
              sx={{
                position: 'absolute',
                right: 20,
                top: 11,
              }}
            >
              <CloseIcon
                sx={{
                  fontSize: '2.3rem',
                  color: 'action.active',
                }}
              />
            </IconButton>
          )}
          {fullScreen && search.length === 0 && (
            <IconButton
              onClick={closeDialog}
              sx={{
                position: 'absolute',
                right: 20,
                top: 17,
              }}
            >
              <LogoutIcon
                sx={{
                  fontSize: '1.7rem',
                  color: 'action.active',
                }}
              />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <Divider orientation={'horizontal'} />
      <DialogContent
        sx={{
          minHeight: '60vh',
          width: { xl: 768, lg: 768, md: 768, sm: 768 },
        }}
      >
        {search.length < 2 && (
          <>
            <Typography variant={'body1'}>{t('popularSearches')}</Typography>
            <List sx={{ pt: 0 }}>
              {popularSearches.map((search) => (
                <ListItem
                  alignItems="center"
                  key={search}
                  sx={{ pb: 0, pt: 0.5 }}
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Button
                        onClick={() => handleSelect(search)}
                        sx={{ fontSize: 16, color: blue[700] }}
                        variant={'text'}
                      >
                        {search}
                      </Button>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </>
        )}
        {search.length >= 2 && (
          <List sx={{ pt: 0 }}>
            {items.map((item) => (
              <React.Fragment key={item.id}>
                <ListItemButton
                  alignItems="center"
                  component={Link}
                  onClick={closeDialog}
                  to={`/shop/product/${getFormalizedName(item.detail.name)}/${
                    item.id
                  }`}
                >
                  <ListItemIcon sx={{ '& img': { borderRadius: 2 } }}>
                    <img
                      alt={item.detail.name}
                      height={60}
                      src={item.image[400]}
                      width={60}
                    />
                  </ListItemIcon>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant={'body1'}>
                        {item.detail.name}
                      </Typography>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography
                          sx={{
                            color: grey[700],
                            fontSize: '0.9rem',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            lineHeight: '1.2em',
                            maxHeight: '2.4em',
                          }}
                          variant={'body2'}
                        >
                          {item.detail.description}
                        </Typography>
                        <Stack
                          direction={'row'}
                          spacing={1}
                          sx={{
                            width: '100%',
                            overflow: 'hidden',
                            mt: 1,
                          }}
                        >
                          {item.categories.slice(0, 5).map((category) => (
                            <Chip
                              key={category.id}
                              label={category.name}
                              size={'small'}
                              sx={{ borderRadius: 2, fontSize: 12 }}
                            />
                          ))}
                        </Stack>
                      </React.Fragment>
                    }
                    sx={{ pl: 1 }}
                  />
                </ListItemButton>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};
export default SearchBarDialog;
