import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import MUIDataTable from 'mui-datatables';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { statsService } from '../store/services';
import { SnackbarFormattedError } from '../../../../helpers/snackbar';
import PageLoader from '../../../../components/PageLoader';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    '& .MuiTableCell-root:first-child': {
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
}));

const NetworkPerformancesDataTable = ({ user }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState(null);
  const [yearRange, setYearRange] = useState(false);

  useEffect(() => {
    setData(null);
    statsService
      .getNetworkPerformances(
        user.id,
        format(selectedDate, 'yyyy'),
        !yearRange && format(selectedDate, 'MM'),
      )
      .then((users) => setData(users.map((data) => createData(data))))
      .catch((e) => SnackbarFormattedError(e.error));
  }, [selectedDate, yearRange]);

  function createData(data) {
    let turnover = 0;
    data.orders.map((order) => {
      order.products.map((product) => {
        turnover += parseFloat(product.price);
      });
    });
    return {
      ...data,
      name: `${data.firstName} ${data.lastName}`,
      role: `${t(data.role)}`,
      career: `${t(data.career)}`,
      childrenCount: data.children.length,
      turnover: `${turnover.toFixed(2)}`.replace('.', ','),
    };
  }

  const handleYearChange = (e) => {
    setSelectedDate(e);
    setYearPickerOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    setMonthPickerOpen(false);
  };

  const handleChangeRangeSelector = (e) => {
    setYearRange(e.target.checked);
  };

  const handleCellClick = (col) => {
    const user = data.find((datum) => datum.name === col);
    if (user) {
      window.open(`${process.env.REACT_APP_WEB_URL}/admin/users/${user.id}`);
    }
  };

  const columns = [
    {
      name: 'name',
      label: t('name'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'role',
      label: t('role'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'career',
      label: t('career'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'turnover',
      label: t('turnover'),
      options: {
        filter: false,
        sort: true,
        sortCompare:
          (order) =>
          ({ data: val1 }, { data: val2 }) =>
            (parseFloat(val1.replace(',', '.')) -
              parseFloat(val2.replace(',', '.'))) *
            (order === 'asc' ? 1 : -1),
      },
    },
    {
      name: 'childrenCount',
      label: t('recruitment'),
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  return (
    <Card className={classes.root}>
      <CardHeader
        sx={{ height: 95 }}
        title={
          <Grid container justifyContent={'space-between'}>
            <Grid item>{t('networkPerformances')}</Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={yearRange}
                        name="range"
                        onChange={handleChangeRangeSelector}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      />
                    }
                    label={t('byYear')}
                  />
                </Grid>
                <Grid item>
                  {yearRange && (
                    <MobileDatePicker
                      maxDate={new Date()}
                      onChange={handleYearChange}
                      onClose={() => setYearPickerOpen(false)}
                      onMonthChange={(e) => handleYearChange(e)}
                      onOpen={() => setYearPickerOpen(true)}
                      open={isYearPickerOpen}
                      openTo="month"
                      slotProps={{
                        mobilePaper: {
                          elevation: 2,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: 1,
                          width: 120,
                        },
                      }}
                      value={selectedDate}
                      views={['year']}
                    />
                  )}
                  {!yearRange && (
                    <MobileDatePicker
                      maxDate={new Date()}
                      onChange={handleDateChange}
                      onClose={() => setMonthPickerOpen(false)}
                      onMonthChange={(e) => handleDateChange(e)}
                      onOpen={() => setMonthPickerOpen(true)}
                      open={isMonthPickerOpen}
                      openTo="month"
                      slotProps={{
                        mobilePaper: {
                          elevation: 2,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: 1,
                          width: 120,
                        },
                      }}
                      value={selectedDate}
                      views={['year', 'month']}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        {!data && <PageLoader height={200} />}
        {data && (
          <MUIDataTable
            columns={columns}
            data={data}
            options={{
              rowsPerPage: 50,
              filterType: 'checkbox',
              elevation: 0,
              selectableRows: 'none',
              downloadOptions: {
                filename: `performances-${
                  yearRange
                    ? format(selectedDate, 'yyyy')
                    : format(selectedDate, 'MM-yyyy')
                }`,
                separator: ';',
              },
              onCellClick: handleCellClick,
              print: false,
              textLabels: {
                body: {
                  noMatch: 'Sorry, no matching records found',
                  toolTip: 'Sort',
                  columnHeaderTooltip: (column) => `Sort for ${column.label}`,
                },
                pagination: {
                  next: 'Next Page',
                  previous: 'Previous Page',
                  rowsPerPage: 'Rows per page:',
                  displayRows: 'of',
                },
                toolbar: {
                  search: 'Search',
                  downloadCsv: 'Download CSV',
                  print: 'Print',
                  viewColumns: 'View Columns',
                  filterTable: 'Filter Table',
                },
                filter: {
                  all: 'All',
                  title: 'FILTERS',
                  reset: 'RESET',
                },
                viewColumns: {
                  title: 'Show Columns',
                  titleAria: 'Show/Hide Table Columns',
                },
                selectedRows: {
                  text: 'row(s) selected',
                  delete: 'Delete',
                  deleteAria: 'Delete Selected Rows',
                },
              },
            }}
          />
        )}
      </CardContent>
    </Card>
  );
};
export default NetworkPerformancesDataTable;
