import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { shopService } from '../../store/services';
import { SnackbarFormattedError } from '../../../../../helpers/snackbar';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const ProductsAutocomplete = ({
  autoFocus,
  country,
  disableCloseOnSelect,
  label,
  multiple,
  onChange,
  onSelect,
  onlyPackage,
  productIds,
  renderOption,
  variant = 'filled',
  withPrice,
  withoutRestriction,
}) => {
  const { i18n, t } = useTranslation();
  const [searchAutocompleteOpen, setSearchAutocompleteOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(undefined);

  useEffect(() => {
    if (Array.isArray(productIds)) {
      shopService
        .getProductsByIds('fr', country || 'BE', productIds)
        .then(setSelectedProducts)
        .catch((e) => SnackbarFormattedError(e));
    }
  }, []);

  useEffect(() => {
    if (search.length === 3 || (search.length > 3 && !products.length)) {
      setSearchLoading(true);
      shopService
        .searchProducts(
          i18n.language,
          country || 'BE',
          search,
          withoutRestriction,
        )
        .then((products) => {
          if (onlyPackage) {
            setProducts(products.filter((p) => p.type === 'package'));
          } else {
            setProducts(products);
          }
          setSearchLoading(false);
        })
        .catch((e) => {
          SnackbarFormattedError(e.response.data.error);
          setSearchLoading(false);
        });
    }
  }, [search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    onChange && onChange(e);
  };

  const handleSelectProduct = (e, value) => {
    if (value) {
      setSelectedProducts(value);
      onSelect && onSelect(value);
    }
  };

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      search.length > 4
        ? `${option.detail.name} ${option.detail.composition}`
        : `${option.detail.name}`,
  });

  if (productIds?.length > 0 && !selectedProducts) {
    return null;
  }

  return (
    <Autocomplete
      disableCloseOnSelect={disableCloseOnSelect}
      filterOptions={filterOptions}
      fullWidth
      getOptionLabel={(option) => option.detail.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      loading={searchLoading}
      multiple={multiple}
      noOptionsText={t('searchByNameOrComposition')}
      onChange={handleSelectProduct}
      onClose={() => {
        setSearchAutocompleteOpen(false);
      }}
      onOpen={() => {
        setSearchAutocompleteOpen(true);
      }}
      open={searchAutocompleteOpen}
      options={products}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {searchLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          autoFocus={autoFocus}
          fullWidth
          label={label}
          onChange={handleSearchChange}
          placeholder={t('searchProduct')}
          variant={variant}
        />
      )}
      renderOption={(props, option, { selected }) =>
        renderOption ? (
          renderOption(props, option, { selected })
        ) : (
          <Box key={option.id} {...props}>
            <Grid alignItems={'center'} container spacing={2}>
              <Grid item xs={2}>
                <Typography
                  align={'center'}
                  sx={{ backgroundColor: '#fff', borderRadius: 2 }}
                >
                  <img
                    alt={option.detail.name}
                    height={60}
                    src={option.image[200]}
                    width={60}
                  />
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Grid container>
                  <Grid item xs={12}>
                    {`${option.detail.name}`}
                  </Grid>
                  {withPrice && (
                    <>
                      <Grid item style={{ overflow: 'hidden' }} xs={12}>
                        <Typography sx={{ fontSize: '0.8em' }}>
                          {`${(
                            option[`priceAndTaxDetails`].base.price +
                            option[`priceAndTaxDetails`].base.taxes
                          ).toFixed(2)} ${option.currency} (${
                            option[`priceAndTaxDetails`].base.priceInfo
                          })`}
                        </Typography>
                      </Grid>
                      <Grid item style={{ overflow: 'hidden' }} xs={12}>
                        <Typography sx={{ fontSize: '0.8em' }}>
                          {`${(
                            option[`priceAndTaxDetails`].final.price +
                            option[`priceAndTaxDetails`].final.taxes
                          ).toFixed(2)} ${option.currency} (${
                            option[`priceAndTaxDetails`].final.priceInfo
                          })`}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )
      }
      value={selectedProducts}
    />
  );
};
export default ProductsAutocomplete;
