import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionsType from './constants';
import { academyActions } from './actions';
import { SnackbarError } from '../../../../helpers/snackbar';
import { academyService } from './services';
import i18next from 'i18next';

function* getProgram(action) {
  try {
    const program = yield call(
      academyService.getProgramByUrl,
      action.country,
      action.url,
    );
    yield put(academyActions.getAcademyProgramSuccess(program));
  } catch (e) {
    yield put(academyActions.getAcademyProgramFail());
    //SnackbarError(i18next.t('errorMessageProgramDoesNotExistInLanguage'))
  }
}

function* getPrograms(action) {
  try {
    const programs = yield call(academyService.getPrograms, action.lang);
    yield put(academyActions.getAcademyProgramsSuccess(programs));
  } catch (e) {
    yield put(academyActions.getAcademyProgramsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

export default function* watchAcademy() {
  yield takeEvery(actionsType.GET_ACADEMY_PROGRAM_REQUEST, getProgram);
  yield takeEvery(actionsType.GET_ACADEMY_PROGRAMS_REQUEST, getPrograms);
}
