import axios from '../../../../helpers/axios';

export const accountingService = {
  getOrders,
  getInvoices,
  getProformas,
  getQuotes,
  getCreditNotes,
  getOrder,
  addCreditNote,
  removeOrder,
  reloadInvoicePDF,
  reloadCreditNotePDF,
  reloadProformaPDF,
  reloadCommissionCreditNotePDF,
  transferOrder,
  distributeCommissions,
  collectCommissions,
  removeCommissions,
  getCommissionsCreditNotes,
  getUnpaidCommissionsCreditNotes,
  setCommissionsCreditNotePaid,
  getBankTransferOrders,
  setBankTransferOrderCanceled,
  setBankTransferOrderPaid,
  sendBankTransferOrderWaitingPayment,
};

function getOrders(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/orders/${year}/${month}`)
      .then(({ orders }) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getInvoices(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/invoices/${year}/${month}`)
      .then(({ invoices }) => {
        resolve(invoices);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProformas(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/proformas/${year}/${month}`)
      .then(({ proformas }) => {
        resolve(proformas);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getQuotes(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/quotes/${year}/${month}`)
      .then(({ quotes }) => {
        resolve(quotes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCreditNotes(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/creditNotes/${year}/${month}`)
      .then(({ invoices }) => {
        resolve(invoices);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCommissionsCreditNotes(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/commissions/creditNotes/${year}/${month}`)
      .then(({ creditNotes }) => {
        resolve(creditNotes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUnpaidCommissionsCreditNotes() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/commissions/creditNotes/unpaid`)
      .then(({ creditNotes }) => {
        resolve(creditNotes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getOrder(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/order/${orderId}`)
      .then(({ order }) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCreditNote(
  orderId,
  refundMethod,
  items,
  deferredItems,
  shipping,
  proFee,
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/order/${orderId}/creditNote/add`, {
        refundMethod,
        items,
        deferredItems,
        shipping,
        proFee,
      })
      .then(({ creditNote }) => {
        resolve(creditNote);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeOrder(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/admin/order/${orderId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setCommissionsCreditNotePaid(creditNoteId) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/commissions/creditNote/${creditNoteId}/paid`, {
        isPaid: true,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function reloadInvoicePDF(invoiceId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/invoice/${invoiceId}/reload`)
      .then(({ invoice }) => {
        resolve(invoice);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function reloadCreditNotePDF(creditNoteId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/creditNote/${creditNoteId}/reload`)
      .then(({ creditNote }) => {
        resolve(creditNote);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function reloadProformaPDF(proformaId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/proforma/${proformaId}/reload`)
      .then(({ proforma }) => {
        resolve(proforma);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function reloadCommissionCreditNotePDF(creditNoteId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/commissions/creditNote/${creditNoteId}/reload`)
      .then(({ creditNote }) => {
        resolve(creditNote);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function transferOrder(orderId, proId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/order/${orderId}/transfer/${proId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function distributeCommissions(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/order/${orderId}/commissions/distribute`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function collectCommissions(creditNoteId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/creditNote/${creditNoteId}/commissions/collect`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeCommissions(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/order/${orderId}/commissions/remove`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getBankTransferOrders() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/orders/bankTransfer/waiting`)
      .then(({ orders }) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setBankTransferOrderCanceled(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/admin/order/${orderId}/bankTransfer`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function setBankTransferOrderPaid(orderId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/order/${orderId}/bankTransfer/success`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
function sendBankTransferOrderWaitingPayment(orderId, note) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/order/${orderId}/bankTransfer/order/send`, { note })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
