import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import NetworkDataTable from './NetworkDataTable';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiTable-root': {
      '& td:first-child:hover': {
        cursor: 'pointer',
        color: '#000',
        textDecoration: 'underline',
      },
      '& td:nth-child(2):hover': {
        cursor: 'pointer',
        color: '#000',
        textDecoration: 'underline',
      },
    },
  },
}));

const UserNetworkTable = ({ user }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <NetworkDataTable user={user} />
      </CardContent>
    </Card>
  );
};
export default UserNetworkTable;
