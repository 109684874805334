import React, { useEffect, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
import EventNoteTwoToneIcon from '@mui/icons-material/EventNoteTwoTone';
import TrendingUpTwoToneIcon from '@mui/icons-material/TrendingUpTwoTone';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import BugReportTwoToneIcon from '@mui/icons-material/BugReportTwoTone';
import AllInclusiveTwoToneIcon from '@mui/icons-material/AllInclusiveTwoTone';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import SwapHorizontalCircleTwoToneIcon from '@mui/icons-material/SwapHorizontalCircleTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import Hidden from '@mui/material/Hidden';
import { grey, blue } from '@mui/material/colors';
import MenuItem from '../Menu/MenuItem';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import MarkunreadMailboxTwoToneIcon from '@mui/icons-material/MarkunreadMailboxTwoTone';
import SubjectTwoToneIcon from '@mui/icons-material/SubjectTwoTone';
import DraftsTwoToneIcon from '@mui/icons-material/DraftsTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import Menu from '../Menu/Menu';
import MenuSubItem from '../Menu/MenuSubItem';
import { useDispatch, useSelector } from 'react-redux';
import { menuActions } from '../Menu/store/actions';
import { menuIsOpenSelector } from '../Menu/store/selectors';
import {
  isGod,
  isMegaAdmin,
  isSuperAdmin,
} from '../../../containers/Authentication/store/selectors';
import { accountingService } from '../../../containers/Admin/Accounting/store/services';
import { mailingService } from '../../../containers/Admin/Mailings/store/services';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

const drawerWidth = 240;

const useStylesDrawerPro = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    '& .MuiToolbar-root': {
      '& img': {
        marginTop: 8,
        width: '100%',
      },
    },
  },
  drawerContainer: {
    overflow: 'auto',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& ul': {
      '& .active': {
        backgroundColor: grey[200],
      },
    },
    '& .MuiCollapse-wrapper': {
      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50,
      },
    },
    '& .MuiListItem-button': {
      height: '80px',
      '& .MuiSvgIcon-root': {
        fontSize: '1.7em',
        fill: blue[900],
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const MenuDrawer = () => {
  const classes = useStylesDrawerPro();
  const dispatch = useDispatch();
  const menuIsOpen = useSelector(menuIsOpenSelector);
  const userIsGod = useSelector(isGod);
  const userIsMegaAdmin = useSelector(isMegaAdmin);
  const userIsSuperAdmin = useSelector(isSuperAdmin);
  const [waitingTransfersCount, setWaitingTransfersCount] = useState(0);
  const [mailingsCount, setMailingsCount] = useState(0);

  useEffect(() => {
    if (userIsSuperAdmin) {
      accountingService
        .getBankTransferOrders()
        .then((reviews) => {
          setWaitingTransfersCount(reviews.length);
        })
        .catch((e) => console.error(e));
      mailingService
        .getMailingsQueued()
        .then((mailings) => {
          setMailingsCount(mailings.length);
        })
        .catch((e) => console.error(e));
    }
  }, []);

  const handleDrawerToggle = () => {
    dispatch(menuActions.toggleMenu());
  };

  const drawerList = (
    <div className={classes.drawerContainer}>
      <Menu onItemSelected={handleDrawerToggle}>
        <MenuItem
          icon={<SpeedTwoToneIcon />}
          title={'Dashboard'}
          to={'/admin'}
        />
        <MenuItem
          expandTo={'/admin/accounting'}
          hidden={!userIsSuperAdmin}
          icon={<AccountBalanceTwoToneIcon />}
          title={'Comptabilité'}
        >
          <MenuSubItem
            expandTo={'/admin/accounting/quotes'}
            icon={<AccountBalanceWalletTwoToneIcon />}
            title={'Devis'}
            to={'/admin/accounting/quotes'}
          />
          <MenuSubItem
            expandTo={'/admin/accounting/invoice'}
            icon={<AccountBalanceWalletTwoToneIcon />}
            title={'Factures'}
            to={'/admin/accounting/invoices'}
          />
          <MenuSubItem
            expandTo={'/admin/accounting/proforma'}
            icon={<AccountBalanceWalletTwoToneIcon />}
            title={'Proformas'}
            to={'/admin/accounting/proformas'}
          />
          <MenuSubItem
            expandTo={'/admin/accounting/creditNote'}
            icon={<AccountBalanceWalletTwoToneIcon />}
            title={'Notes de crédit'}
            to={'/admin/accounting/creditNotes'}
          />
          <MenuSubItem
            expandTo={'/admin/accounting/transfer'}
            icon={<SwapHorizontalCircleTwoToneIcon />}
            notificationsCount={waitingTransfersCount}
            title={'Virements bancaires'}
            to={'/admin/accounting/transfers'}
          />
          <MenuSubItem
            icon={<BusinessTwoToneIcon />}
            title={'Espace comptable'}
            to={'/accounting'}
          />
        </MenuItem>
        <MenuItem
          expandTo={'/admin/mailings'}
          hidden={!userIsSuperAdmin}
          icon={<MarkunreadMailboxTwoToneIcon />}
          notificationsCount={mailingsCount}
          title={'Mailings'}
        >
          <MenuSubItem
            icon={<SubjectTwoToneIcon />}
            title={'Créateur'}
            to={'/admin/mailings/creator'}
          />
          <MenuSubItem
            expandTo={'/admin/mailings/draft'}
            icon={<DraftsTwoToneIcon />}
            title={'Brouillons'}
            to={'/admin/mailings/draft'}
          />
          <MenuSubItem
            expandTo={'/admin/mailings/queued'}
            hidden={!userIsGod}
            icon={<DraftsTwoToneIcon />}
            notificationsCount={mailingsCount}
            title={"Liste d'attente"}
            to={'/admin/mailings/queued'}
          />
          <MenuSubItem
            expandTo={'/admin/mailings/completed'}
            icon={<EmailTwoToneIcon />}
            title={'Envoyés'}
            to={'/admin/mailings/completed'}
          />
        </MenuItem>
        <MenuItem
          expandTo={'/admin/stats'}
          icon={<AssessmentTwoToneIcon />}
          title={'Statistiques'}
        >
          <MenuSubItem
            hidden={!userIsSuperAdmin}
            icon={<TrendingUpTwoToneIcon />}
            title={'Ventes'}
            to={'/admin/stats/sales'}
          />
          <MenuSubItem
            icon={<TrendingUpTwoToneIcon />}
            title={'Stocks'}
            to={'/admin/stats/stocks'}
          />
        </MenuItem>
        <MenuItem
          expandTo={'/admin/shop'}
          icon={<StorefrontTwoToneIcon />}
          title={'Boutique'}
        >
          <MenuSubItem
            expandTo={'/admin/shop/product'}
            icon={<ListAltTwoToneIcon />}
            title={'Produits'}
            to={'/admin/shop/products'}
          />
          <MenuSubItem
            icon={<ListAltTwoToneIcon />}
            title={'Stocks'}
            to={'/admin/shop/stocks'}
          />
        </MenuItem>
        <MenuItem
          icon={<EventNoteTwoToneIcon />}
          title={'Logistique'}
          to={'/logistic/orders/todo'}
        />
        <MenuItem
          icon={<PeopleAltTwoToneIcon />}
          title={'Utilisateurs'}
          to={'/admin/users'}
        />
        <MenuItem
          expandTo={'/admin/errors'}
          hidden={!userIsMegaAdmin}
          icon={<WarningTwoToneIcon />}
          title={'Erreurs'}
          to={'/admin/errors'}
        />
        <MenuItem
          expandTo={'/admin/logs'}
          hidden={!userIsMegaAdmin}
          icon={<BugReportTwoToneIcon />}
          title={'Logs'}
        >
          <MenuSubItem
            icon={<AllInclusiveTwoToneIcon />}
            title={'Tous les logs'}
            to={'/admin/logs/all'}
          />
          <MenuSubItem
            icon={<ErrorTwoToneIcon />}
            title={'Erreurs'}
            to={'/admin/logs/error'}
          />
          <MenuSubItem
            icon={<InfoTwoToneIcon />}
            title={'Infos'}
            to={'/admin/logs/info'}
          />
          <MenuSubItem
            icon={<BugReportTwoToneIcon />}
            title={'Debug'}
            to={'/admin/logs/debug'}
          />
        </MenuItem>
      </Menu>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden implementation="js" lgUp>
        <Drawer
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          open={menuIsOpen}
          sx={(theme) => ({ zIndex: theme.zIndex.drawer + 2 })}
          variant="temporary"
        >
          <Toolbar>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.jpeg`}
            />
          </Toolbar>
          {drawerList}
        </Drawer>
      </Hidden>
      <Hidden implementation="js" lgDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          open
          variant="permanent"
        >
          <Toolbar />
          {drawerList}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MenuDrawer;
