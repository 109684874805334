import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { Link } from '../../../../helpers/multilingual';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 64,
    paddingTop: 32,
    paddingBottom: 32,
    backgroundColor: '#c8e1ea',
    '& h4': {
      color: '#016398 !important',
      marginBottom: '8px !important',
    },
    '& h6': {
      color: '#016398 !important',
      fontSize: 24,
      marginBottom: 16,
    },
    '& p': {
      color: '#016398 !important',
    },
    '& img': {
      maxWidth: '100%',
      width: 300,
      height: 'auto',
    },
    '& .MuiButton-root': {
      backgroundColor: '#1b76ba',
      fontSize: 18,
      marginTop: 32,
      marginBottom: 32,
    },
  },
}));
const ZenspireHowItWorks = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.root}>
      <Container maxWidth={'xl'}>
        <Grid alignItems={'center'} container justifyContent={'center'}>
          <Grid item lg={6} xs={12}>
            <Box>
              <Typography align={'center'} variant={'h4'}>
                {t('zenspireText9')}
              </Typography>
              <Typography align={'center'} variant={'h6'}>
                {t('zenspireText10')}
              </Typography>
              <Typography align={'center'}>{t('zenspireText11')}</Typography>
              <Typography align={'center'}>{t('zenspireText12')}</Typography>
              <Typography align={'center'} component={'div'}>
                <Button
                  color={'primary'}
                  component={Link}
                  onClick={() => window.scrollTo(0, 0)}
                  size={'large'}
                  to={
                    '/shop/category/technologie/a4c510fa-e2b6-48d4-8aaa-58f3f2e4d562'
                  }
                  variant={'contained'}
                >
                  {t('orderYourZenspire')}
                </Button>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              className={'mt-32'}
              container
              justifyContent={'center'}
              spacing={2}
            >
              <Grid item sm={3} xs={6}>
                <Typography align={'center'} component={'div'}>
                  <img
                    alt=""
                    height={676}
                    src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/zenspire/relax-FR.png`}
                    width={883}
                  />
                </Typography>
                <Typography align={'center'}>{t('zenspireText13')}</Typography>
              </Grid>
              <Grid item sm={3} xs={6}>
                <Typography align={'center'} component={'div'}>
                  <img
                    alt=""
                    height={676}
                    src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/zenspire/sleep-FR.png`}
                    width={883}
                  />
                </Typography>
                <Typography align={'center'}>{t('zenspireText14')}</Typography>
              </Grid>
              <Grid item sm={3} xs={6}>
                <Typography align={'center'} component={'div'}>
                  <img
                    alt=""
                    height={676}
                    src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/zenspire/start-FR.png`}
                    width={883}
                  />
                </Typography>
                <Typography align={'center'}>{t('zenspireText15')}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default ZenspireHowItWorks;
