import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LabelImportantTwoToneIcon from '@mui/icons-material/LabelImportantTwoTone';
import { Link } from '../../../../../helpers/multilingual';
import { getFormalizedName } from '../../../../../containers/Customer/Shop/helpers/helper';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import StoreIcon from '../../../../Icons/StoreIcon';
import MobileMenuItem from './MobileMenuItem';

const MobileShopMenu = ({ onClick }) => {
  const { t } = useTranslation();
  const [expended, setExpended] = useState(false);

  const handleToggle = () => {
    setExpended(!expended);
  };

  return (
    <>
      <MobileMenuItem
        icon={<StoreIcon />}
        onClick={handleToggle}
        title={t('shop')}
      />
      <Collapse
        addEndListener={() => {}}
        in={expended}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          <MobileMenuItem
            component={Link}
            icon={<LabelImportantTwoToneIcon color={'secondary'} />}
            onClick={onClick}
            title={t('allShop')}
            to={'/shop'}
          />
          <MobileMenuItem
            component={Link}
            icon={<LabelImportantTwoToneIcon color={'secondary'} />}
            onClick={onClick}
            title={t('shopPacks')}
            to={`/shop/category/${getFormalizedName(
              t('shopPacks'),
            )}/ff9992c1-92a6-4f8a-ad09-74eaeabf1c21`}
          />
          <MobileMenuItem
            component={Link}
            icon={<LabelImportantTwoToneIcon color={'secondary'} />}
            onClick={onClick}
            title={t('shopIndividualSupplements')}
            to={`/shop/category/${getFormalizedName(
              t('shopIndividualSupplements'),
            )}/11b21a14-7e8a-45f7-88ea-217fd9e34785`}
          />
          <MobileMenuItem
            component={Link}
            icon={<LabelImportantTwoToneIcon color={'secondary'} />}
            onClick={onClick}
            title={t('shopZenspire')}
            to={`/shop/category/${getFormalizedName(
              t('shopZenspire'),
            )}/a4c510fa-e2b6-48d4-8aaa-58f3f2e4d562`}
          />
          <MobileMenuItem
            component={Link}
            icon={<LabelImportantTwoToneIcon color={'secondary'} />}
            onClick={onClick}
            title={t('shopAromatherapy')}
            to={`/shop/category/${getFormalizedName(
              t('shopAromatherapy'),
            )}/5c18e980-4624-45b8-a985-166ea8180652`}
          />
          <MobileMenuItem
            component={Link}
            icon={<LabelImportantTwoToneIcon color={'secondary'} />}
            onClick={onClick}
            title={t('trainings')}
            to={`/shop/category/${getFormalizedName(
              t('trainings'),
            )}/d8d3165b-686e-4cab-a6cd-9e41f28396a5`}
          />
        </List>
      </Collapse>
    </>
  );
};
export default MobileShopMenu;
