import React from 'react';
import AccountingRoutes from '../routes/AccountingRoutes';
import MenuDrawer from '../components/Layout/Accounting/MenuDrawer';
import Header from '../components/Layout/Accounting/Header';
import NoSsr from '@mui/material/NoSsr';
import { initLanguage } from '../helpers/multilingual';
import accountingTheme from '../assets/styles/themes/accountingTheme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import useDateFns from '../helpers/useDateFns';

function AccountingLayout() {
  initLanguage('fr');
  const { locale, localeText } = useDateFns();

  return (
    <NoSsr>
      <ThemeProvider theme={accountingTheme}>
        <CssBaseline />
        <Header />
        <Box sx={{ display: 'flex' }}>
          <MenuDrawer />
          <Box
            component={'main'}
            sx={(theme) => ({
              flexGrow: 1,
              padding: theme.spacing(2),
              [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            })}
          >
            <Box sx={{ minHeight: 'calc(100vh - 64px)' }}>
              <LocalizationProvider
                adapterLocale={locale}
                dateAdapter={AdapterDateFns}
                localeText={localeText}
              >
                <AccountingRoutes />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </NoSsr>
  );
}

export default AccountingLayout;
