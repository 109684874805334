import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { userSelector } from './store/selectors';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import SignInForm from './components/SignInForm';
import Header from '../../components/Layout/Customer/Header/Header';
import { useSEO } from '../../helpers/useSEO';
import Box from '@mui/material/Box';

const SignIn = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const loggedUser = useSelector(userSelector);
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/auth/signin', {
    fr: '/auth/signin',
    nl: '/auth/signin',
    en: '/auth/signin',
  });

  useEffect(() => {
    if (loggedUser) {
      history.push(
        `${
          props.location && props.location.from
            ? props.location.from.pathname
            : ''
        }`,
      );
    }
  }, [loggedUser]);

  return (
    <Container component="main" maxWidth="xs">
      <Helmet>
        <title>{t('signIn')}</title>
        <meta content={t('signInMetaDescription')} name="description" />
        <meta content={t('signIn')} property="og:title" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Header />
      <CssBaseline />
      <Box
        sx={{
          mt: 16,
          pb: 1,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar
          sx={{
            m: 1,
            width: 100,
            height: 100,
            backgroundColor: '#fff',
            '& img': {
              width: 100,
              height: 100,
            },
          }}
        >
          <img
            alt=""
            src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo-min.svg`}
          />
        </Avatar>
        <Typography component="h1" variant="h5">
          <Trans i18nKey={'signIn'}>Sign in</Trans>
        </Typography>
        <SignInForm />
      </Box>
    </Container>
  );
};

export default SignIn;
