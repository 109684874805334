import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const AccountingIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        d="M21,23.29H6.55A1.56,1.56,0,0,1,5,21.73V4.78A1.56,1.56,0,0,1,6.55,3.22H21A1.56,1.56,0,0,1,22.5,4.78v17A1.56,1.56,0,0,1,21,23.29ZM6.55,4a.8.8,0,0,0-.81.8v17a.8.8,0,0,0,.81.8H21a.8.8,0,0,0,.8-.8V4.78A.8.8,0,0,0,21,4Z"
        fill="#fff"
      />
      <path
        d="M20.08,21.17H8.23a.89.89,0,0,1-.9-.89V18.23a.38.38,0,0,1,.38-.38h.58V15.78H7.71a.38.38,0,0,1-.38-.38V11a.38.38,0,0,1,.38-.38h.58V8.5H7.71a.37.37,0,0,1-.38-.37V6.23a.89.89,0,0,1,.9-.89H20.08a.89.89,0,0,1,.89.89V20.28A.89.89,0,0,1,20.08,21.17Zm-12-2.57v1.68a.15.15,0,0,0,.15.14H20.08a.14.14,0,0,0,.14-.14v-14a.14.14,0,0,0-.14-.14H8.23a.15.15,0,0,0-.15.14V7.75h.59A.38.38,0,0,1,9,8.13V11a.38.38,0,0,1-.37.38H8.08V15h.59A.38.38,0,0,1,9,15.4v2.83a.38.38,0,0,1-.37.37Z"
        fill="#fff"
      />
      <path
        d="M8.67,11.33H6.75A.38.38,0,0,1,6.37,11V8.13a.38.38,0,0,1,.38-.38H8.67A.38.38,0,0,1,9,8.13V11A.38.38,0,0,1,8.67,11.33Zm-1.55-.76H8.29V8.5H7.12Z"
        fill="#fff"
      />
      <path
        d="M8.67,18.6H6.75a.38.38,0,0,1-.38-.37V15.4A.38.38,0,0,1,6.75,15H8.67A.38.38,0,0,1,9,15.4v2.83A.38.38,0,0,1,8.67,18.6Zm-1.55-.75H8.29V15.78H7.12Z"
        fill="#fff"
      />
      <path
        d="M14.7,16.35A3.28,3.28,0,1,1,18,13.07,3.28,3.28,0,0,1,14.7,16.35Zm0-5.81a2.53,2.53,0,1,0,2.52,2.53A2.52,2.52,0,0,0,14.7,10.54Z"
        fill="#fff"
      />
      <path
        d="M14.7,13.44a.36.36,0,0,1-.37-.37V10.16a.36.36,0,0,1,.37-.37.37.37,0,0,1,.38.37v2.91A.37.37,0,0,1,14.7,13.44Z"
        fill="#fff"
      />
      <path
        d="M14.7,13.44a.34.34,0,0,1-.26-.11l-2-2.05a.37.37,0,0,1,.53-.53L15,12.8a.39.39,0,0,1,0,.53A.36.36,0,0,1,14.7,13.44Z"
        fill="#fff"
      />
      <path
        d="M14.7,13.44H11.81a.37.37,0,0,1-.38-.37.38.38,0,0,1,.38-.38H14.7a.38.38,0,0,1,.38.38A.37.37,0,0,1,14.7,13.44Z"
        fill="#fff"
      />
      <path
        d="M12.65,15.5a.38.38,0,0,1-.26-.11.37.37,0,0,1,0-.53l2-2.06a.37.37,0,0,1,.53,0,.39.39,0,0,1,0,.53l-2.05,2.06A.39.39,0,0,1,12.65,15.5Z"
        fill="#fff"
      />
      <path
        d="M14.7,16.35a.37.37,0,0,1-.37-.38v-2.9a.37.37,0,0,1,.37-.38.38.38,0,0,1,.38.38V16A.38.38,0,0,1,14.7,16.35Z"
        fill="#fff"
      />
      <path
        d="M16.75,15.5a.4.4,0,0,1-.27-.11l-2-2.06A.37.37,0,0,1,15,12.8l2,2.06a.37.37,0,0,1,0,.53A.36.36,0,0,1,16.75,15.5Z"
        fill="#fff"
      />
      <path
        d="M17.6,13.44H14.7a.36.36,0,0,1-.37-.37.37.37,0,0,1,.37-.38h2.9a.38.38,0,0,1,.37.38A.37.37,0,0,1,17.6,13.44Z"
        fill="#fff"
      />
      <path
        d="M14.7,13.44a.34.34,0,0,1-.26-.11.37.37,0,0,1,0-.53l2-2.05a.37.37,0,0,1,.53.53l-2,2.05A.36.36,0,0,1,14.7,13.44Z"
        fill="#fff"
      />
      <path
        d="M8.69,25.18H7.85a.9.9,0,0,1-.91-.9V22.91a.38.38,0,0,1,.38-.38H9.21a.38.38,0,0,1,.38.38v1.37A.9.9,0,0,1,8.69,25.18Zm-1-1.89v1a.15.15,0,0,0,.15.15h.84a.15.15,0,0,0,.15-.15v-1Z"
        fill="#fff"
      />
      <path
        d="M19.65,25.18h-.84a.9.9,0,0,1-.9-.9V22.91a.38.38,0,0,1,.37-.38h1.89a.38.38,0,0,1,.38.38v1.37A.9.9,0,0,1,19.65,25.18Zm-1-1.89v1a.15.15,0,0,0,.15.15h.84a.15.15,0,0,0,.15-.15v-1Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
export default AccountingIcon;
