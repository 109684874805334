import React, { useContext, useEffect, useState } from 'react';
import { SnackbarFormattedError } from '../../../helpers/snackbar';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { authenticationService } from '../store/services';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { FormValidatorContext } from '../../../components/Validators/FormValidator';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const useStyle = makeStyles(() => ({
  textField: {},
  option: {
    width: '100%',
    borderBottom: `1px dashed ${grey[600]}`,
    '& p': {
      margin: 0,
      padding: 0,
    },
    '& span': {
      marginRight: 5,
    },
    '& .identifier': {
      margin: 0,
      fontWeight: 'bold',
      fontSize: 12,
      textTransform: 'uppercase',
      color: grey[800],
    },
  },
}));

const SearchConsultantAutocomplete = ({
  autoFocus,
  error,
  label = null,
  onChange,
  required,
  target = 'all',
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { submitted } = useContext(FormValidatorContext);
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [hasRequiredError, setHasRequiredError] = useState(false);
  const requiredErrorMessage = t('validatorFieldRequired');
  const [userSearch, setUserSearch] = useState('');
  const [userSelected, setUserSelected] = useState(null);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (userSearch.length === 2 || (userSearch.length > 2 && !users.length)) {
      setUserSearchLoading(true);
      switch (target) {
        case 'all':
          authenticationService
            .getConsultantsBySearch(userSearch)
            .then((users) => {
              setUsers(users);
              setUserSearchLoading(false);
            })
            .catch((e) => {
              SnackbarFormattedError(e.response.data.error);
              setUserSearchLoading(false);
            });
          break;
        case 'certified':
          authenticationService
            .getCertifiedConsultantsBySearch(userSearch)
            .then((users) => {
              setUsers(users);
              setUserSearchLoading(false);
            })
            .catch((e) => {
              SnackbarFormattedError(e.response.data.error);
              setUserSearchLoading(false);
            });
          break;
        case 'profiling':
          authenticationService
            .getProfilingConsultantsBySearch(userSearch)
            .then((users) => {
              setUsers(users);
              setUserSearchLoading(false);
            })
            .catch((e) => {
              SnackbarFormattedError(e.response.data.error);
              setUserSearchLoading(false);
            });
          break;
      }
    } else if (userSearch.length < 2) {
      setUsers([]);
    }
  }, [userSearch]);

  useEffect(() => {
    setHasRequiredError(submitted && required && !userSelected);
  }, [submitted, required]);

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option.directLink}${option.company}${option.zip}${option.city}${option.firstName} ${option.lastName} ${option.firstName}`,
  });

  const handleUserSearchChange = (e) => {
    setUserSearch(e.target.value);
  };

  const handleChange = (e, value) => {
    setUserSelected(value);
    onChange(e, value);
  };

  return (
    <Autocomplete
      autoHighlight={true}
      filterOptions={filterOptions}
      getOptionLabel={(option) =>
        `${option.directLink ? `${option.directLink.toUpperCase()} - ` : ''} ${
          option.company ? `${option.company} - ` : ''
        } ${
          option.firstName && option.lastName
            ? `${option.firstName} ${option.lastName} - `
            : ''
        } ${option.zip} ${option.city}`
      }
      loading={userSearchLoading}
      noOptionsText={t('noNutritionConsultantFound')}
      onChange={handleChange}
      onClose={() => {
        setUserSearchOpen(false);
      }}
      onOpen={() => {
        setUserSearchOpen(true);
      }}
      open={userSearchOpen}
      options={users}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {userSearchLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          autoFocus={autoFocus}
          className={classes.textField}
          error={error || hasRequiredError}
          helperText={hasRequiredError && requiredErrorMessage}
          onChange={handleUserSearchChange}
          placeholder={label || t('searchByIdentifierCityOrPostalCode')}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <Box key={option.id} {...props}>
          <Box
            sx={{
              pr: 1,
              pl: 1,
              pt: 0.5,
              pb: 0.5,
              m: 0,
              width: '100%',
              borderBottom: `1px dashed ${grey[600]}`,
            }}
          >
            <Typography
              sx={{
                m: 0,
                fontWeight: 'bold',
                fontSize: 12,
                textTransform: 'uppercase',
                color: grey[800],
              }}
            >
              {option.directLink ? `[${option.directLink}] - ` : ``}
              {option.zip} {option.city} {option.country}
            </Typography>
            <Typography sx={{ m: 0, fontSize: 15 }}>
              {option.company ? (
                <Typography component={'span'} sx={{ m: 0 }}>
                  {`${option.company} - `}
                </Typography>
              ) : (
                ``
              )}
              {option.firstName} {option.lastName}
            </Typography>
          </Box>
        </Box>
      )}
      style={{ width: '100%' }}
    />
  );
};
export default SearchConsultantAutocomplete;
