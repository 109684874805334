import React, { useEffect, useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { Trans } from 'react-i18next';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import Cookie from '../../../helpers/cookie';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paperAnchorTop': {
      padding: 12,
      [theme.breakpoints.down('sm')]: {
        padding: '12px 40px 10px 40px',
      },
    },
    '& .MuiTypography-root': {
      margin: 14,
      fontSize: 16,
      [theme.breakpoints.down('sm')]: {
        fontSize: 15,
      },
    },
    '& .close-btn': {
      '& .MuiSvgIcon-root': {
        top: 20,
        right: 16,
        cursor: 'pointer',
        position: 'absolute',
        fontSize: 30,
        color: grey[500],
      },
    },
  },
}));
const SocialNetworksRedirection = () => {
  const classes = useStyle();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!Cookie.get(`follow-insta`)) {
      setTimeout(() => {
        Cookie.set(`follow-insta`, true);
        setOpen(true);
      }, 60000);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Drawer
      anchor={'top'}
      className={classes.root}
      disableEnforceFocus
      disableScrollLock
      keepMounted
      onClose={handleClose}
      open={open}
      variant="temporary"
    >
      <Grid alignItems={'center'} container justifyContent={'center'}>
        <Grid item>
          <Typography align={'center'}>
            <Trans i18nKey={'followUsInstagram'}>
              To not miss anything from Sharks news, follow us on Instagram
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <a
            href="https://www.instagram.com/sharks"
            rel="noreferrer"
            target={'_blank'}
          >
            <img
              alt="Instagram"
              height={30}
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/instagram-inline.svg`}
            />
          </a>
        </Grid>
      </Grid>
      <div className={'close-btn'}>
        <CloseIcon onClick={handleClose} />
      </div>
    </Drawer>
  );
};
export default SocialNetworksRedirection;
