import produce from 'immer';

const initialState = {
  deviceType: 'mobile',
};

export const globalReducerKey = 'global';

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'DEVICE_TYPE':
        draft.deviceType = !state.deviceType;
        break;

      default:
    }
  });
