import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const PartnershipIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        d="M21.06,15.69a.36.36,0,0,1-.27-.12.44.44,0,0,1,0-.59L23,12.57a2.56,2.56,0,0,0,.66-1.63l.13-2.8a.54.54,0,0,1,.11-.28L25,6.72a.36.36,0,0,1,.54,0,.44.44,0,0,1,0,.59l-.94,1L24.43,11a3.44,3.44,0,0,1-.89,2.18l-2.21,2.41A.36.36,0,0,1,21.06,15.69Z"
        fill="#fff"
      />
      <path
        d="M12.26,21.72a1.38,1.38,0,0,1-1-.44,1.69,1.69,0,0,1,0-2.23l1.34-1.46a1.44,1.44,0,0,1,1.06-.48,1.32,1.32,0,0,1,1,.44,1.69,1.69,0,0,1,0,2.23l-1.34,1.46A1.43,1.43,0,0,1,12.26,21.72Zm1.43-3.78a.69.69,0,0,0-.51.24l-1.34,1.46a.77.77,0,0,0,0,1,.65.65,0,0,0,1,0l1.34-1.46h0a.81.81,0,0,0,0-1.05A.61.61,0,0,0,13.69,17.94Z"
        fill="#fff"
      />
      <path
        d="M10.44,20.49a1.31,1.31,0,0,1-1-.44A1.56,1.56,0,0,1,9,19a1.65,1.65,0,0,1,.44-1.15L11.16,16a1.43,1.43,0,0,1,1.05-.48,1.35,1.35,0,0,1,1,.45A1.59,1.59,0,0,1,13.62,17a1.72,1.72,0,0,1-.44,1.15h0L11.49,20A1.42,1.42,0,0,1,10.44,20.49Zm1.77-4.15a.66.66,0,0,0-.5.23L10,18.41A.82.82,0,0,0,9.8,19a.73.73,0,0,0,.19.49.66.66,0,0,0,1,0l1.68-1.83a.84.84,0,0,0,.22-.56.76.76,0,0,0-.18-.49A.61.61,0,0,0,12.21,16.34Zm.7,1.54Z"
        fill="#fff"
      />
      <path
        d="M8.67,16.5a.37.37,0,0,1-.27-.12L5.45,13.16A3.43,3.43,0,0,1,4.57,11L4.45,8.34l-1-1a.44.44,0,0,1,0-.59.36.36,0,0,1,.54,0l1,1.14a.41.41,0,0,1,.11.28l.13,2.8A2.6,2.6,0,0,0,6,12.57l2.94,3.22a.44.44,0,0,1,0,.59A.36.36,0,0,1,8.67,16.5Z"
        fill="#fff"
      />
      <path
        d="M15.67,23.67a1.33,1.33,0,0,1-1-.42l-.45-.49a.47.47,0,0,1,0-.6.36.36,0,0,1,.54,0l.44.49a.62.62,0,0,0,.47.18.8.8,0,0,0,.55-.28,1,1,0,0,0,.25-.59.66.66,0,0,0-.17-.51l-.49-.54a.46.46,0,0,1,0-.6.36.36,0,0,1,.54,0l.49.54A1.54,1.54,0,0,1,17.27,22a1.83,1.83,0,0,1-.47,1.14,1.56,1.56,0,0,1-1.05.52Z"
        fill="#fff"
      />
      <path
        d="M17.85,22.47a1.28,1.28,0,0,1-1-.43l-.54-.59a.44.44,0,0,1,0-.59.36.36,0,0,1,.54,0l.54.59a.69.69,0,0,0,1-.09.85.85,0,0,0,.08-1.11L15.6,17.07a.44.44,0,0,1,0-.59.35.35,0,0,1,.54,0l2.92,3.18A1.73,1.73,0,0,1,19,22,1.53,1.53,0,0,1,17.85,22.47Z"
        fill="#fff"
      />
      <path
        d="M20,21.23a1.31,1.31,0,0,1-1-.42l-3.44-3.75a.44.44,0,0,1,0-.59.35.35,0,0,1,.54,0l3.44,3.74a.68.68,0,0,0,1-.09.83.83,0,0,0,.09-1.1l-3.44-3.75a.44.44,0,0,1,0-.59.36.36,0,0,1,.54,0l3.44,3.75a1.71,1.71,0,0,1-.08,2.28A1.53,1.53,0,0,1,20,21.23Z"
        fill="#fff"
      />
      <path
        d="M21.13,18.89a1.28,1.28,0,0,1-1-.43l-2.93-3.19a.44.44,0,0,1,0-.59.36.36,0,0,1,.54,0l2.93,3.19a.69.69,0,0,0,1-.09.85.85,0,0,0,.08-1.11l-5-5.45a.44.44,0,0,1,0-.59.35.35,0,0,1,.54,0l5,5.45a1.73,1.73,0,0,1-.08,2.29A1.53,1.53,0,0,1,21.13,18.89Z"
        fill="#fff"
      />
      <path
        d="M14.5,5.53a.36.36,0,0,1-.27-.12l-.7-.76a2.15,2.15,0,0,0-1.78-.72l-2.61.21A.39.39,0,0,1,8.84,4L7.68,2.76a.46.46,0,0,1,0-.6.36.36,0,0,1,.54,0l1,1.13L11.7,3.1a2.87,2.87,0,0,1,2.37,1l.7.76a.44.44,0,0,1,0,.59A.37.37,0,0,1,14.5,5.53Z"
        fill="#fff"
      />
      <path
        d="M10.93,12.92a2.48,2.48,0,0,1-1.81-.81,1.32,1.32,0,0,1,0-1.73l5.8-6.32a2.89,2.89,0,0,1,2.38-1l2.44.19,1-1.13a.36.36,0,0,1,.54,0,.46.46,0,0,1,0,.6L20.16,4a.39.39,0,0,1-.3.12l-2.62-.21a2.16,2.16,0,0,0-1.78.72L9.67,11a.41.41,0,0,0,0,.55,1.71,1.71,0,0,0,1.26.57,1.75,1.75,0,0,0,1.27-.57l1.56-1.71a.35.35,0,0,1,.5,0c.09.07,2.31,1.79,4,0a.36.36,0,0,1,.54,0,.44.44,0,0,1,0,.59,3.5,3.5,0,0,1-4.69.3l-1.34,1.47A2.48,2.48,0,0,1,10.93,12.92Z"
        fill="#fff"
      />
      <path
        d="M9.32,18.51a1.36,1.36,0,0,1-1-.44,1.69,1.69,0,0,1,0-2.23l.06-.06,1.28-1.4a1.42,1.42,0,0,1,1.05-.48,1.38,1.38,0,0,1,1,.44,1.59,1.59,0,0,1,.4,1.12,1.66,1.66,0,0,1-.43,1.11L10.36,18A1.42,1.42,0,0,1,9.32,18.51Zm1.42-3.78a.7.7,0,0,0-.51.24L8.89,16.44a.79.79,0,0,0,0,1,.62.62,0,0,0,.46.2.68.68,0,0,0,.5-.24l.06-.06L11.16,16h0a.84.84,0,0,0,.22-.54.74.74,0,0,0-.19-.51A.62.62,0,0,0,10.74,14.73Z"
        fill="#fff"
      />
      <path
        d="M14,23a1.34,1.34,0,0,1-1-.44,1.68,1.68,0,0,1-.23-1.87,1.83,1.83,0,0,1,.26-.36l1.07-1.17a1.4,1.4,0,0,1,1.05-.48,1.32,1.32,0,0,1,1,.45,1.68,1.68,0,0,1,0,2.23h0l-1.07,1.16a1.44,1.44,0,0,1-.34.28A1.36,1.36,0,0,1,14,23Zm1.16-3.48a.69.69,0,0,0-.51.23L13.58,21a.83.83,0,0,0-.13.18.77.77,0,0,0,.1.86.64.64,0,0,0,.79.11l.17-.14,1.07-1.17a.78.78,0,0,0,0-1A.58.58,0,0,0,15.16,19.55Zm.69,1.54Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
export default PartnershipIcon;
