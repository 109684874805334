import React, { forwardRef, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { FormValidatorContext } from './FormValidator';

const useStyle = makeStyles(() => ({
  root: {},
}));

const TextFieldValidator = forwardRef(
  (
    {
      FormHelperTextProps,
      InputLabelProps,
      InputProps,
      SelectProps,
      autoFocus,
      className,
      color,
      defaultValue,
      disabled,
      fullWidth,
      id,
      inputProps,
      inputRef,
      label,
      margin,
      multiline,
      name,
      onChange,
      placeholder,
      required,
      rows,
      select,
      size,
      sx,
      type,
      value,
      variant,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const { setValidationFields, submitted } = useContext(FormValidatorContext);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(
      t('validatorFieldRequired'),
    );

    useEffect(() => {
      validate(value);
    }, [value]);

    useEffect(() => {
      setValidationFields((fields) => ({ ...fields, [name]: error }));
    }, [error]);

    useEffect(() => {
      //COMPONENT WILL UNMOUNT
      return () => {
        setValidationFields((fields) => {
          delete fields[name];
          return fields;
        });
      };
    }, []);

    const validateEmail = (email) => {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
    };

    const validate = (targetValue) => {
      let hasError = false;
      if (required) {
        hasError = !targetValue;
        setError(hasError);
        setErrorMessage(t('validatorFieldRequired'));
      }
      if (!hasError) {
        switch (id) {
          case 'email':
            if (!validateEmail(targetValue)) {
              setError(true);
              setErrorMessage(t('validatorInvalidEmail'));
            }
            break;
        }
      }
    };

    const handleChange = (e) => {
      validate(e.target.value);
      {
        typeof onChange === 'function' && onChange(e);
      }
    };

    return (
      <TextField
        FormHelperTextProps={FormHelperTextProps}
        InputLabelProps={InputLabelProps}
        InputProps={InputProps}
        SelectProps={SelectProps}
        autoFocus={autoFocus}
        className={classnames(classes.root, className)}
        color={color}
        defaultValue={defaultValue}
        disabled={disabled}
        error={submitted && error}
        fullWidth={fullWidth}
        helperText={submitted && error && errorMessage}
        id={id}
        inputProps={inputProps}
        inputRef={inputRef}
        label={label}
        margin={margin}
        multiline={multiline}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        ref={ref}
        required={required}
        rows={rows}
        select={select}
        size={size}
        sx={sx}
        type={type ? type : 'text'}
        value={value}
        variant={variant ? variant : 'outlined'}
      />
    );
  },
);

export default TextFieldValidator;
