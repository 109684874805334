import React from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Breadcrumb from '../../../components/Layout/Customer/Breadcrumb';
import makeStyles from '@mui/styles/makeStyles';
import { useSEO } from '../../../helpers/useSEO';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingBottom: 40,
    backgroundImage: `url('${process.env.REACT_APP_API_RESOURCES_URL}/images/photo-1543069444-8cf1d66086d0.jpg')`,
    backgroundSize: 'cover',
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& h1': {
      color: '#fff',
      fontWeight: 'bold',
      marginBottom: 5,
    },
    '& .MuiBreadcrumbs-root': {
      color: '#fff',
      '& .MuiTypography-root': {
        color: '#fff',
      },
    },
    '& .MuiIconButton-root': {
      padding: 11,
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
  },
  content: {
    marginTop: 60,
    '& .MuiTypography-root': {
      color: '#fff',
      fontSize: 18,
    },
  },
  videos: {
    marginTop: 60,
  },
  video: {
    width: '100%',
    position: 'relative',
    paddingBottom: '56.25%',
    overflow: 'hidden',
    '& iframe': {
      borderRadius: 5,
      border: 'none',
      position: 'absolute',
      left: 0,
      top: 0,
      height: '100%',
      width: '100%',
    },
  },
}));

const EnvironmentalFriendly = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/environmental-friendly', {
    fr: '/environmental-friendly',
    nl: '/environmental-friendly',
    en: '/environmental-friendly',
  });

  return (
    <div className={classes.container}>
      <Helmet>
        <title itemProp={'name'}>{t('environmentalFriendly')}</title>
        <meta content={t('environmentalFriendly')} property="og:title" />
        <meta
          content={t('environmentalFriendlyMetaDescription')}
          name="description"
        />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Typography color={'secondary'} component="h1" variant="h4">
            <Trans i18nKey="environmentalFriendly">
              Environmental friendly
            </Trans>
          </Typography>
          <Breadcrumb
            parents={[
              {
                name: (
                  <Trans i18nKey="environmentalFriendly">
                    Environmental friendly
                  </Trans>
                ),
              },
            ]}
          />
        </div>
        <Grid className={classes.content} container spacing={2}>
          <Grid item sm={9} xs={12}>
            <Typography>
              <Trans i18nKey={'treesContributionInfo'}>
                Each order contributes to plant Moringa trees in Senegal to
                participate in the reduction of the carbon footprint. <br />
                (One tree = Reduction of 22kilo of CO2 / year).
              </Trans>
            </Typography>
          </Grid>
          <Grid item sm={9} xs={12}>
            <Typography>
              <Trans i18nKey={'treeProperties'}>
                Moringa is also an intelligent tree that has nutritional and
                therapeutic properties.
              </Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid className={classes.videos} container spacing={2}>
              <Grid item md={6} xs={12}>
                <Typography
                  className={classes.video}
                  component={'div'}
                  gutterBottom
                >
                  <iframe
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    height="360"
                    src={`https://player.vimeo.com/video/494229220?autoplay=0&color=ffffff&title=0&byline=0&portrait=0&modestbranding=1&rel=0&showinfo=0&texttrack=${props.match.params.lang}`}
                    width="640"
                  />
                </Typography>
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography
                  className={classes.video}
                  component={'div'}
                  gutterBottom
                >
                  <iframe
                    allow="autoplay; fullscreen"
                    allowFullScreen
                    height="360"
                    src={`https://player.vimeo.com/video/509809287?autoplay=0&color=ffffff&title=0&byline=0&portrait=0&modestbranding=1&rel=0&showinfo=0&texttrack=${props.match.params.lang}`}
                    width="640"
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default EnvironmentalFriendly;
