import React from 'react';
import classnames from 'classnames';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { useDispatch, useSelector } from 'react-redux';
import { mapSearchSelector } from '../store/selectors';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { history } from '../../../../helpers/store';
import { consultantsActions } from '../store/actions';
import { useLocation } from 'react-router-dom';
import { useCustomerPath } from '../../../../helpers/usePath';

const useStyles = makeStyles(() => ({
  darkStyle: {
    borderColor: '#fff',
    color: '#fff',
  },
  lightStyle: {
    borderColor: '#000',
    color: '#000',
  },
  button: {
    padding: '20px 30px',
    fontSize: 20,
    textDecoration: 'none',
    borderRadius: 10,
    '& .MuiButton-label': {
      lineHeight: '16px',
      fontSize: '14px !important',
    },
  },
  headerStyle: {
    color: '#000',
    padding: '8px 24px',
    marginRight: 10,
    fontSize: '0.875rem',
    borderRadius: 25,
    border: '1px solid #000',
    background: '#fff',
    height: 40,
    '&:hover': {
      border: '1px solid ' + grey[900],
      background: '#fff',
      //boxShadow: '0 2px 2px 1px rgba(150, 150, 150, .3)',
    },
  },
}));

const ConsultantsChangeLocationButton = ({ dark, header }) => {
  const path = useCustomerPath();
  const classes = useStyles();
  const dispatch = useDispatch();
  const domLocation = useLocation();
  const search = useSelector(mapSearchSelector);

  const handleOpen = () => {
    let regex = /([a-z]{2})\/?([a-z0-9-]+)?\/?([a-z0-9-]+)?\/?/g,
      match;

    match = regex.exec(domLocation.pathname);
    if (Array.isArray(match) && match.length > 0 && match[3] !== 'map') {
      history.push(
        `${path}/consultants/map/${search.coordinates.lat}/${search.coordinates.lng}/${search.address}`,
      );
    } else {
      dispatch(consultantsActions.setSearchBarOpen(true));
    }
  };

  if (!search) {
    return null;
  }

  return (
    <Button
      className={classnames(
        classes.button,
        header && classes.headerStyle,
        dark ? classes.darkStyle : classes.lightStyle,
      )}
      onClick={handleOpen}
      startIcon={<LocationOnIcon />}
      variant="outlined"
    >
      {search.address}
    </Button>
  );
};
export default ConsultantsChangeLocationButton;
