import { useTranslation } from 'react-i18next';
import { enUS, nl, fr } from 'date-fns/locale';
import { useEffect, useState } from 'react';
import * as dateFns from 'date-fns';

const getLocale = (locale) => {
  switch (locale) {
    case 'fr':
      return fr;
    case 'en':
    default:
      return enUS;
  }
};

const getLocaleText = (locale) => {
  switch (locale) {
    case 'fr':
      return {
        cancelButtonLabel: 'Annuler',
        clearButtonLabel: 'Effacer',
        okButtonLabel: 'OK',
        todayButtonLabel: "Aujourd'hui",
        datePickerToolbarTitle: 'Sélectionnez une date',
      };
    case 'en':
    default:
      return {
        cancelButtonLabel: 'Cancel',
        clearButtonLabel: 'Clear',
        okButtonLabel: 'OK',
        todayButtonLabel: 'Today',
        datePickerToolbarTitle: 'Select a Date',
      };
  }
};

const useDateFns = () => {
  const { i18n } = useTranslation();
  const [locale, setLocale] = useState(getLocale(i18n.language));
  const [localeText, setLocaleText] = useState(getLocaleText(i18n.language));

  useEffect(() => {
    setLocale(getLocale(i18n.language));
    setLocaleText(getLocaleText(i18n.language));
  }, [i18n.language]);

  const format = (date, format) => {
    if (!date) {
      return null;
    }
    switch (format) {
      case 'LLL':
      case 'PPPp':
        return dateFns.format(new Date(date), 'PPPp', { locale: locale });
      case 'LLLL':
        return dateFns.format(new Date(date), 'PPPpp', { locale: locale });
      case 'LL':
        return dateFns.format(new Date(date), 'PPP', { locale: locale });
      case 'YYYY':
        return dateFns.format(new Date(date), 'yyyy', { locale: locale });
      case 'DD-MM-YYYY':
      case 'YYYY-MM-DD':
      case 'L':
        return dateFns.format(
          new Date(date),
          i18n.language === 'fr' ? 'dd-MM-yyyy' : 'yyyy-MM-dd',
          { locale: locale },
        );
      default:
        return dateFns.format(new Date(date), format, { locale: locale });
    }
  };

  const formatDistanceToNow = (date) => {
    return dateFns.formatDistanceToNow(date, {
      addSuffix: true,
      locale: locale,
    });
  };

  return { ...dateFns, locale, localeText, format, formatDistanceToNow };
};
export default useDateFns;
