import { green, grey, red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import mediaQuery from 'css-mediaquery';

const customerTheme = (deviceType) => {
  const ssrMatchMedia = (query) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: deviceType === 'mobile' ? '0px' : '1024px',
    }),
  });

  return createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 1025,
        lg: 1280,
        xl: 1560,
        xxl: 2300,
      },
    },
    palette: {
      primary: {
        light: '#69696a',
        main: '#28282a',
        dark: '#1e1e1f',
      },
      secondary: {
        light: '#fff5f8',
        main: '#00207f',
        dark: '#001346',
      },
      warning: {
        main: '#ffc071',
        dark: '#ffb25e',
      },
      error: {
        light: red[50],
        main: red[500],
        dark: red[700],
      },
      success: {
        light: green[50],
        main: green[500],
        dark: green[700],
      },
    },
    typography: {
      fontFamily: [
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 700,
    },
    components: {
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
      MuiTypography: {
        styleOverrides: {
          h6: {
            fontWeight: 500,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            color: grey[900],
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow:
              'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
            borderRadius: 16,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          paper: {
            backdropFilter: 'blur(20px)',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            boxShadow:
              'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px',
            borderRadius: 16,
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontWeight: 400,
            textTransform: 'inherit',
            borderRadius: 8,
          },
          containedSecondary: {
            backgroundColor: '#00207f',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            width: '90%',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& .MuiFormLabel-root': {
              //left: 10,
            },
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            padding: '8px 24px',
          },
        },
      },
    },
  });
};

export default customerTheme;
