import React from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../../components/Layout/Admin/Breadcrumb';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CustomersDataTable from './components/UsersDataTable';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .jss-MuiTable-root': {
      '& td:nth-child(-n + 2)': {
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const UsersTable = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Clients</title>
      </Helmet>
      <Container fixed maxWidth="xl">
        <Grid alignItems={'center'} container justifyContent={'space-between'}>
          <Grid item>
            <div className={classes.title}>
              <Breadcrumb parents={[{ name: 'Utilisateurs' }]} />
              <Typography component="h1" variant="h4">
                Utilisateurs
              </Typography>
            </div>
          </Grid>
        </Grid>
        <CustomersDataTable />
      </Container>
    </div>
  );
};
export default UsersTable;
