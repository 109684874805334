import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Breadcrumb from '../../../components/Layout/Customer/Breadcrumb';
import { Trans, useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { magActions } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getArticlesSelector } from './store/selectors';
import { Link } from '../../../helpers/multilingual';
import { sessionSelector } from '../Shop/store/selectors';
import LazyLoadCardMedia from '../../../components/LazyLoad/LazyLoadCardMedia';
import Header from '../../../components/Layout/Customer/Header/Header';
import { useSEO } from '../../../helpers/useSEO';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 70,
    paddingBottom: 40,
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& h1': {
      fontWeight: '500',
      marginBottom: 5,
    },
    '& .MuiIconButton-root': {
      padding: 11,
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
  },
  articlesContainer: {
    '& .MuiPaper-rounded': {
      borderRadius: 32,
    },
    '& .MuiCard-root': {
      height: '100%',
    },
  },
}));

const Articles = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const session = useSelector(sessionSelector);
  const articles = useSelector(getArticlesSelector);
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/mag', {
    fr: '/mag',
    nl: '/mag',
    en: '/mag',
  });

  useEffect(() => {
    if (
      !Array.isArray(articles) ||
      (articles.length > 0 && articles[0].lang !== i18n.language)
    ) {
      dispatch(magActions.getArticlesRequest(i18n.language));
    }
  }, [i18n.language, session]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{t('theMAG')}</title>
        <meta content={t('theMAG')} property="og:title" />
        <meta content={t('magMetaDescription')} name="description" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Header />
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Typography color={'secondary'} component="h1" variant="h4">
                <Trans i18nKey={'theMAG'}>The MAG</Trans>
              </Typography>
              <Breadcrumb
                parents={[{ name: <Trans i18nKey={'theMAG'}>The MAG</Trans> }]}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          {articles?.map((article, key) => (
            <Grid
              className={classes.articlesContainer}
              item
              key={article.id}
              lg={4}
              md={4}
              sm={6}
              xl={3}
              xs={12}
            >
              {key <= 3 && (
                <Helmet>
                  <link as="image" href={article.cover[400]} rel="preload" />
                </Helmet>
              )}
              <Link to={`/mag/${article.url}`}>
                <Card elevation={2}>
                  <CardActionArea>
                    <LazyLoadCardMedia
                      alt={article.title}
                      component="img"
                      height="300"
                      image={article.cover[600]}
                      onError={(e) => {
                        e.target.src = `${process.env.REACT_APP_API_RESOURCES_URL}/mag/cover/default-600.jpg`;
                      }}
                      skeletonSrc={
                        key <= 3
                          ? article.cover[400]
                          : `${process.env.REACT_APP_API_RESOURCES_URL}/mag/cover/default-400.jpg`
                      }
                      title={article.title}
                    />
                    <CardContent>
                      <Typography component="h2" gutterBottom variant="h5">
                        {article.title}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        component="p"
                        variant="body2"
                      >
                        {article.metaDescription}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};
export default Articles;
