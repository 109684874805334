import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import HomeWorkIcon from '@mui/icons-material/HomeWork';

const useStyles = makeStyles(() => ({
  icon: {
    background: '#fff',
    borderRadius: 50,
    border: `1px solid #000`,
    padding: 4,
    cursor: 'pointer',
    height: 35,
    width: 35,
    '& > svg': {
      width: 25,
      height: 25,
      fill: '#000',
    },
    '&:hover': {
      background: '#000',
      border: `1px solid #fff`,
      '& > svg': {
        fill: '#fff',
      },
    },
  },
  iconHovered: {
    background: '#000',
    borderRadius: 50,
    border: `1px solid #fff`,
    padding: 4,
    cursor: 'pointer',
    height: 35,
    width: 35,
    '& > svg': {
      width: 25,
      height: 25,
      fill: '#fff',
    },
  },
}));

const PickupPointMarker = ({
  markerSelected,
  pickupPoint,
  setMarkerSelected,
}) => {
  const classes = useStyles();
  return (
    <div
      className={
        markerSelected.id === pickupPoint.id
          ? classes.iconHovered
          : classes.icon
      }
      onClick={() => setMarkerSelected(pickupPoint)}
    >
      <HomeWorkIcon />
    </div>
  );
};

export default PickupPointMarker;
