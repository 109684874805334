import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const TruckIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        d="M3.14,15.55V13.2a2.34,2.34,0,0,1,2.18-2.33L6.7,7.41A2.92,2.92,0,0,1,9.43,5.57h4l.29-1.77a1.18,1.18,0,0,1,1.18-1.17h14A1.17,1.17,0,0,1,30.09,3.8V17.9H26.42a3.51,3.51,0,0,0-6.62,0H13.5a3.51,3.51,0,0,0-6.63,0H3.14a1.18,1.18,0,1,1,0-2.35Zm9.4-4.7.68-4.11H9.43A1.76,1.76,0,0,0,7.79,7.85l-1.2,3Zm-2.35,5.88a2.33,2.33,0,1,1-2,1.17A2.37,2.37,0,0,1,10.19,16.73Zm0,3.52A1.18,1.18,0,1,0,9,19.08,1.18,1.18,0,0,0,10.19,20.25Zm12.92-3.52a2.36,2.36,0,1,1-2,1.17A2.34,2.34,0,0,1,23.11,16.73Zm0,3.52a1.18,1.18,0,1,0-1.17-1.17A1.18,1.18,0,0,0,23.11,20.25Z"
        fill="#FFFFFF"
      />
      <path
        d="M30.09,3.81v10l-16.71.12L15,4.05l0-.24a1.18,1.18,0,0,1,1.17-1.18h12.7A1.18,1.18,0,0,1,30.09,3.81Z"
        fill="#00207f"
      />
    </SvgIcon>
  );
};
export default TruckIcon;
