import {
  GET_WALLET_REQUEST,
  GET_WALLET_SUCCESS,
  GET_WALLET_ERROR,
  GET_WALLET_HISTORIES_REQUEST,
  GET_WALLET_HISTORIES_SUCCESS,
  GET_WALLET_HISTORIES_ERROR,
  GET_WALLET_COMMISSIONS_CREDIT_NOTES_REQUEST,
  GET_WALLET_COMMISSIONS_CREDIT_NOTES_SUCCESS,
  GET_WALLET_COMMISSIONS_CREDIT_NOTES_ERROR,
  TRANSFER_REQUEST,
  TRANSFER_SUCCESS,
  TRANSFER_ERROR,
  OPEN_TRANSFER_MODAL,
  CLOSE_TRANSFER_MODAL,
} from './constants';

export const walletActions = {
  getWalletRequest,
  getWalletSuccess,
  getWalletError,
  getWalletHistoriesRequest,
  getWalletHistoriesSuccess,
  getWalletHistoriesError,
  getWalletCommissionsCreditNotesRequest,
  getWalletCommissionsCreditNotesSuccess,
  getWalletCommissionsCreditNotesError,
  openTransferModal,
  closeTransferModal,
  transferRequest,
  transferSuccess,
  transferError,
};

function getWalletRequest() {
  return {
    type: GET_WALLET_REQUEST,
  };
}

function getWalletSuccess(wallet) {
  return {
    type: GET_WALLET_SUCCESS,
    wallet,
  };
}

function getWalletError(error) {
  return {
    type: GET_WALLET_ERROR,
    error,
  };
}

function getWalletHistoriesRequest(year, month) {
  return {
    type: GET_WALLET_HISTORIES_REQUEST,
    year,
    month,
  };
}

function getWalletHistoriesSuccess(data) {
  return {
    type: GET_WALLET_HISTORIES_SUCCESS,
    data,
  };
}

function getWalletHistoriesError(error) {
  return {
    type: GET_WALLET_HISTORIES_ERROR,
    error,
  };
}

function getWalletCommissionsCreditNotesRequest() {
  return {
    type: GET_WALLET_COMMISSIONS_CREDIT_NOTES_REQUEST,
  };
}

function getWalletCommissionsCreditNotesSuccess(data) {
  return {
    type: GET_WALLET_COMMISSIONS_CREDIT_NOTES_SUCCESS,
    data,
  };
}

function getWalletCommissionsCreditNotesError(error) {
  return {
    type: GET_WALLET_COMMISSIONS_CREDIT_NOTES_ERROR,
    error,
  };
}

function openTransferModal() {
  return {
    type: OPEN_TRANSFER_MODAL,
  };
}

function closeTransferModal() {
  return {
    type: CLOSE_TRANSFER_MODAL,
  };
}

function transferRequest() {
  return {
    type: TRANSFER_REQUEST,
  };
}

function transferSuccess(wallet) {
  return {
    type: TRANSFER_SUCCESS,
    wallet,
  };
}

function transferError(error) {
  return {
    type: TRANSFER_ERROR,
    error,
  };
}
