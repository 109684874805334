import React from 'react';
import {
  SnackbarContent,
  SnackbarFormattedError,
  SnackbarInfo,
} from '../../../../../helpers/snackbar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import {
  isPro,
  userSelector,
} from '../../../../Authentication/store/selectors';
import { shopService } from '../../store/services';
import makeStyles from '@mui/styles/makeStyles';
import LinkIcon from '@mui/icons-material/Link';
import { useCustomerPath } from '../../../../../helpers/usePath';

const useStyles = makeStyles(() => ({
  snackMessage: {
    padding: 20,
    '& h4': {
      fontSize: 20,
    },
    '& .action button': {
      backgroundColor: '#000',
    },
  },
}));

const ShareCartButton = ({ children, className, color, order, variant }) => {
  const path = useCustomerPath();
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const userIsPro = useSelector(isPro);

  const handleCopyToClipboard = () => {
    const elem = document.createElement('textarea');
    elem.value = `${process.env.REACT_APP_WEB_URL}${path}/${user.directLink}/shop/recommendation/${order.id}`;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    SnackbarInfo(t('linkCopiedInClipboard'));
  };

  const handleShowLink = () => {
    try {
      if (order.status === 'draft') {
        shopService.saveOrder(order?.id);
      }
      SnackbarContent(
        <Grid className={classes.snackMessage} container spacing={2}>
          <Grid item>
            <Typography align={'left'} variant={'h4'}>
              <Trans i18nKey={'shareLink'}>Share link</Trans>:
            </Typography>
          </Grid>
          <Grid item>
            <Typography align={'left'}>
              {`${process.env.REACT_APP_WEB_URL}${path}/${user.directLink}/shop/recommendation/${order.id}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align={'right'} className={'action'}>
              <Button
                color={'primary'}
                onClick={handleCopyToClipboard}
                size={'small'}
                variant={'contained'}
              >
                {t('copyInClipboard')}
              </Button>
            </Typography>
          </Grid>
        </Grid>,
        () => {},
        'large',
      );
    } catch (e) {
      SnackbarFormattedError(e.error);
    }
  };

  if (!userIsPro || !order || !user.directLink) {
    return null;
  }

  return (
    <Button
      className={className}
      color={color}
      onClick={handleShowLink}
      startIcon={<LinkIcon />}
      variant={variant}
    >
      {children}
    </Button>
  );
};
export default ShareCartButton;
