import React, { useContext } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import { MenuContext } from './Menu';
import Badge from '@mui/material/Badge';
import { Link } from 'react-router-dom';

const MenuSubItem = ({
  expandTo,
  hidden,
  href,
  icon,
  notificationsCount = 0,
  title,
  to,
}) => {
  const { onItemSelected, pathname } = useContext(MenuContext);

  const isActive = pathname === to || (expandTo && pathname.includes(expandTo));

  if (hidden) {
    return null;
  }

  if (href) {
    return (
      <ListItem
        button
        component={'a'}
        href={href}
        onClick={onItemSelected}
        target={'_blank'}
      >
        <ListItemIcon>
          {!!notificationsCount && (
            <Badge badgeContent={notificationsCount} color="error">
              {icon}
            </Badge>
          )}
          {!notificationsCount && icon}
        </ListItemIcon>
        <ListItemText primary={title} />
      </ListItem>
    );
  }

  return (
    <ListItem
      button
      className={isActive ? 'active' : 'inactive'}
      component={Link}
      onClick={onItemSelected}
      to={to}
    >
      <ListItemIcon>
        {!!notificationsCount && (
          <Badge badgeContent={notificationsCount} color="error">
            {icon}
          </Badge>
        )}
        {!notificationsCount && icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  );
};
export default MenuSubItem;
