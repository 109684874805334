import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as toastr } from 'react-redux-toastr';
import authentication from '../containers/Authentication/store/reducer';
import cookieConsent from '../../src/components/Layout/Customer/CookieConsent/store/reducer';
import consultants from '../../src/containers/Customer/Consultants/store/reducer';
import academy from '../../src/containers/Customer/Academy/store/reducer';
import global from './globalReducer';
import bank from './../../src/containers/Customer/Wallet/store/reducer';
import home from '../../src/containers/Customer/Home/store/reducer';
import shop from '../../src/containers/Customer/Shop/store/reducer';
import map from './../components/GoogleMap/store/reducer';
import mag from '../../src/containers/Customer/Mag/store/reducer';
import menu from '../../src/components/Layout/Menu/store/reducer';
import { history } from '../helpers/store';
import {
  SIGNOUT,
  SIGNOUT_ALL_DEVICES,
  SIGNOUT_INVALID_ACCESS_TOKEN,
} from '../containers/Authentication/store/constants';

const appReducer = (injectedReducers = {}) =>
  combineReducers({
    ...injectedReducers,
    authentication,
    cookieConsent,
    consultants,
    academy,
    global,
    bank,
    home,
    shop,
    map,
    mag,
    menu,
    toastr,
    router: connectRouter(history),
  });

export default function createReducer(injectedReducers = {}) {
  return (state, action) => {
    if (
      action.type === SIGNOUT ||
      action.type === SIGNOUT_ALL_DEVICES ||
      action.type === SIGNOUT_INVALID_ACCESS_TOKEN
    ) {
      state = undefined;
    }

    return appReducer(injectedReducers)(state, action);
  };
}
