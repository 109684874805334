import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from '../../../helpers/multilingual';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Header from '../../../components/Layout/Customer/Header/Header';
import { useSEO } from '../../../helpers/useSEO';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 140,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0
    },
    '& h2': {
      fontSize: '2.1em',
      fontWeight: '500',
      marginBottom: 32,
    },
    '& h3': {
      fontSize: '2em',
      fontWeight: '500',
      marginTop: 32,
      marginBottom: 16,
    },
    '& h4': {
      fontSize: '1.6em',
      marginTop: 32,
      marginBottom: 16,
    },
    '& .MuiTypography-body1': {
      marginBottom: 8,
    },
    '& .MuiList-root': {
      paddingLeft: 32,
      '& .MuiListItem-root': {
        paddingBottom: 0,
      },
    },
    '& .MuiLink-root': {
      fontSize: 18,
    },
  },
}));

const Ambassador = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/career-opportunity/ambassador', {
    fr: '/career-opportunity/ambassador',
    nl: '/career-opportunity/ambassador',
    en: '/career-opportunity/ambassador',
  });

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t('becomePartner')}</title>
        <meta content={t('becomePartner')} property="og:title" />
        <meta
          content={t('networkOpportunityMetaDescription')}
          name="description"
        />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
        <link
          as="image"
          href={`${process.env.REACT_APP_API_RESOURCES_URL}/images/social-network-amb.svg`}
          rel="preload"
        />
      </Helmet>
      <Header />
      <Container maxWidth={'xl'}>
        <Grid alignItems={'flex-start'} container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/social-network-amb.svg`}
              width={'100%'}
            />
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <Typography component={'h2'} variant={'h2'}>
              {t('ambassador1')}
            </Typography>

            <Typography>{t('ambassador2')}</Typography>
            <Typography component={'h4'} variant={'h4'}>
              {t('ambassador3')}
            </Typography>
            <Typography>
              <Trans i18nKey={'ambassador4'}>
                Sharks has no more secrets for you.
                <br />
                You can no longer do without our food supplements.
                <br />
                You like to benefit from exclusive advantages.
              </Trans>
            </Typography>
            <Typography component={'h4'} variant={'h4'}>
              {t('ambassador5')}
            </Typography>
            <Typography>
              <Trans i18nKey={'ambassador6'}>
                Professional: your passion has become your job.
                <br />
                Special: personal development is a hobby you can't live without
              </Trans>
            </Typography>
            <Typography component={'h4'} variant={'h4'}>
              {t('ambassador7')}
            </Typography>
            <Typography>{t('ambassador8')}</Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('ambassador9')}
            </Typography>
            <Typography>{t('ambassador10')}</Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('ambassador11')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('ambassador12')}</Typography>
              <Typography component={'li'}>{t('ambassador13')}</Typography>
              <Typography component={'li'}>{t('ambassador14')}</Typography>
              <Typography component={'li'}>{t('ambassador15')}</Typography>
              <Typography component={'li'}>{t('ambassador16')}</Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('ambassador17')}
            </Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('ambassador18')}</Typography>
              <Typography component={'li'}>{t('ambassador19')}</Typography>
              <Typography component={'li'}>{t('ambassador20')}</Typography>
              <Typography component={'li'}>{t('ambassador21')}</Typography>
              <Typography component={'li'}>{t('ambassador22')}</Typography>
            </Typography>
            <Typography component={'h3'} variant={'h3'}>
              {t('ambassador23')}
            </Typography>

            <Typography component={'ul'} style={{ marginBottom: 10 }}>
              <Typography component={'li'}>{t('ambassador24')}</Typography>
              <Typography component={'li'}>{t('ambassador25')}</Typography>
              <Typography component={'li'}>{t('ambassador26')}</Typography>
              <Typography component={'li'}>{t('ambassador27')}</Typography>
            </Typography>
            <Divider
              component={'li'}
              style={{ background: '#999', listStyle: 'none', margin: 20 }}
            />
            <Typography component={'ul'} style={{ marginBottom: 10 }}>
              <Typography component={'li'}>{t('ambassador28')}</Typography>
              <Typography component={'li'}>{t('ambassador29')}</Typography>
              <Typography component={'li'}>{t('ambassador30')}</Typography>
              <Typography component={'li'}>{t('ambassador31')}</Typography>
            </Typography>
            <Divider
              component={'li'}
              style={{ background: '#999', listStyle: 'none', margin: 20 }}
            />
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('ambassador32')}</Typography>
              <Typography component={'li'}>{t('ambassador33')}</Typography>
              <Typography component={'li'}>{t('ambassador34')}</Typography>
              <Typography component={'li'}>{t('ambassador35')}</Typography>
            </Typography>

            <Typography component={'h3'} variant={'h3'}>
              {t('ambassador36')}
            </Typography>
            <Button
              color={'secondary'}
              component={Link}
              to={'/contact'}
              variant={'contained'}
            >
              {t('ambassador37')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Ambassador;
