import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const AccountIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <circle
        cx="11.57"
        cy="6.54"
        fill="none"
        r="3.91"
        stroke="#fff"
        strokeMiterlimit="10"
      />
      <path
        d="M18.77,26.76s0-3.22,0-7.19a7.19,7.19,0,1,0-14.38,0c0,3.7,0,7.14,0,7.14"
        fill="#00207f"
        stroke="#fff"
        strokeMiterlimit="10"
      />
    </SvgIcon>
  );
};
export default AccountIcon;
