import currencies from './currencies';

export const getCurrency = (country) => {
  return 'SCR';
};

export const currencyConvert = (amount, fromCurrency, toCurrency) => {
  if (fromCurrency === toCurrency) {
    return parseFloat(amount);
  }

  let eur = parseFloat(amount) * parseFloat(currencies[fromCurrency]);
  return eur / parseFloat(currencies[toCurrency]);
};

export const hasTaxes = (user, shippingCountry) => {
  return !user.taxNumber || user.country === 'SCR' || shippingCountry === 'SCR';
};

export const inEurope = (country) => {
  return [
    'BE',
    'BG',
    'CZ',
    'DK',
    'DE',
    'EE',
    'IE',
    'EL',
    'ES',
    'FR',
    'HR',
    'IT',
    'CY',
    'LV',
    'LT',
    'HU',
    'MT',
    'NL',
    'AT',
    'PL',
    'PT',
    'RO',
    'SI',
    'SK',
    'FI',
    'SE',
    'LU',
  ].includes(country);
};

export const hexToRgb = (hex) => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  };
};

export const uuidToRgb = (uuid) => {
  if (!uuid) {
    return '#FFFFFF';
  }
  return hexToRgb(
    `#${uuid[1]}${uuid[2]}${uuid[3]}${uuid[4]}${uuid[5]}${uuid[6]}`,
  );
};

export const chartjsBarColorGenerator = (data) => {
  let backgrounds = [],
    borders = [];
  data.map(() => {
    let r = Math.round(Math.random() * 255);
    let g = Math.round(Math.random() * 255);
    let b = Math.round(Math.random() * 255);
    backgrounds.push(`rgba(${r},${g},${b},0.4)`);
    borders.push(`rgb(${r},${g},${b})`);
  });
  return {
    backgrounds: backgrounds,
    borders: borders,
  };
};

export const chartjsLineColorGenerator = (min, max) => {
  let r = Math.floor(Math.random() * (max - min + 1) + min);
  let g = Math.floor(Math.random() * (max - min + 1) + min);
  let b = Math.floor(Math.random() * (max - min + 1) + min);
  return {
    background: `rgba(${r},${g},${b},0.04)`,
    border: `rgb(${r},${g},${b})`,
  };
};

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const displayAmount = (amount, unit) => {
  switch (unit) {
    case 'g':
    case 'G':
    case 'ml':
    case 'mL':
    case 'ML':
      return amount / 1000;
    default:
      return amount;
  }
};
export const displayUnit = (unit) => {
  switch (unit) {
    case 'g':
    case 'G':
      return 'Kg';
    case 'ml':
    case 'mL':
    case 'ML':
      return 'L';
    default:
      return unit;
  }
};
