import axios from '../../../../helpers/axios';

export const userService = {
  getChildren,
  searchUsers,
  getUsers,
  getUserById,
  addFreeUserOrder,
  addUserOrder,
  transferUser,
  creditUser,
  findUsers,
  addUser,
  updateUser,
  getUserOrders,
  getUserShippingAddresses,
  addUserShippingAddress,
  updateUserShippingAddress,
  removeUserShippingAddress,
  updateMembership,
  updateCertification,
  getUserGiftCards,
  sendUserGiftCard,
  sendPartnershipRequest,
  findPartnershipEventUsers,
  deletePartnershipInvitation,
  sendPartnershipInvitations,
  setAuthorization,
};

function setAuthorization(userId, authorization, allow) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/admin/user/${userId}/authorization`, { authorization, allow })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getChildren(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${userId}/tree/children`)
      .then(({ children }) => {
        resolve(children);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function searchUsers(search, target = 'all') {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/search/${target}/${search}`)
      .then(({ users }) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUsers() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/all`)
      .then(({ users }) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserById(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/${id}`)
      .then(({ user }) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addFreeUserOrder(
  user,
  products,
  withShipping,
  shippingMethodId,
  shippingAddressId,
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/user/${user.id}/order/free`, {
        products,
        withShipping,
        shippingMethodId,
        shippingAddressId,
      })
      .then(({ order }) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUserOrder(
  user,
  products,
  shippingMethodId,
  shippingAddressId,
  paymentMethod,
  giftCard,
  displayedNote,
) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/user/${user.id}/order`, {
        products,
        shippingMethodId,
        shippingAddressId,
        paymentMethod,
        giftCard,
        displayedNote,
      })
      .then(({ order }) => {
        resolve(order);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function transferUser(user, pro) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/user/${user.id}/transfer/${pro.id}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function creditUser(user, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/user/wallet/${user.id}`, data)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findUsers() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/users`)
      .then(({ users }) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findPartnershipEventUsers() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/users/partnership/event`)
      .then(({ users }) => {
        resolve(users);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function deletePartnershipInvitation(userId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/admin/user/${userId}/partnership/event`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendPartnershipInvitations() {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/users/partnership/event/send`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUser(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user`, data)
      .then(({ user }) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateUser(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/user/${id}`, data)
      .then(({ user }) => {
        resolve(user);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserOrders(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/user/${userId}/orders`)
      .then(({ orders }) => {
        resolve(orders);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserShippingAddresses(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/user/${userId}/shippingAddresses`)
      .then(({ shippingAddresses }) => {
        resolve(shippingAddresses);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addUserShippingAddress(userId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/user/${userId}/shippingAddress`, data)
      .then(({ shippingAddress }) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateUserShippingAddress(userId, shippingAddressId, data) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/user/${userId}/shippingAddress/${shippingAddressId}`, data)
      .then(({ shippingAddress }) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeUserShippingAddress(userId, shippingAddressId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/admin/user/${userId}/shippingAddress/${shippingAddressId}`)
      .then(({ shippingAddress }) => {
        resolve(shippingAddress);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateMembership(userId, membershipEndAt) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/user/${userId}/membership`, {
        membershipEndAt,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateCertification(userId, isCertified) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/user/${userId}/certification`, {
        isCertified,
      })
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getUserGiftCards(userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/user/${userId}/gifts`)
      .then(({ giftCards }) => {
        resolve(giftCards);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendUserGiftCard(userId, giftId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/user/${userId}/gift/${giftId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function sendPartnershipRequest(userId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/user/${userId}/partnership/request`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
