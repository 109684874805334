import React from 'react';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import { Trans, useTranslation } from 'react-i18next';
import Breadcrumb from '../../../../components/Layout/Customer/Breadcrumb';
import { useSelector } from 'react-redux';
import { sessionSelector, shopCategorySelector } from '../store/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { useSEO } from '../../../../helpers/useSEO';
import { getFormalizedName } from '../helpers/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    '& h1': {
      fontWeight: '500',
      marginBottom: 5,
      '& span': {
        fontSize: '2.125rem',
      },
    },
    '& .MuiIconButton-root': {
      padding: 11,
    },
    [theme.breakpoints.down('sm')]: {
      '& h1': {
        fontSize: '1.8rem',
      },
      '& .MuiBreadcrumbs-separator': {
        marginLeft: 4,
      },
    },
    '& .deliveryCountry': {
      height: 50,
    },
    '& .proShop': {
      color: red[500],
      fontWeight: 'bold',
    },
  },
}));

const ShopTitle = ({ sx }) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const session = useSelector(sessionSelector);
  const category = useSelector(shopCategorySelector(i18n.language));
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags(
    `/shop${
      category
        ? `/category/${getFormalizedName(category.name)}/${category.id}`
        : ``
    }`,
    {
      fr: `/shop${
        category
          ? `/category/${getFormalizedName(category.alternates.fr)}/${
              category.id
            }`
          : ``
      }`,
      nl: `/shop${
        category
          ? `/category/${getFormalizedName(category.alternates.nl)}/${
              category.id
            }`
          : ``
      }`,
      en: `/shop${
        category
          ? `/category/${getFormalizedName(category.alternates.en)}/${
              category.id
            }`
          : ``
      }`,
    },
  );

  if (category) {
    return (
      <Box className={classes.root} sx={sx}>
        <Helmet>
          <title itemProp={'name'}>{category.name}</title>
          <meta content={category.name} property="og:title" />
          <meta
            content={`${category.name} - ${t('shopMetaDescription')}`}
            name="description"
          />
          {alternates.map((link, index) => (
            <link
              href={link.href}
              hrefLang={link.hrefLang}
              key={index}
              rel={link.rel}
            />
          ))}
        </Helmet>
        <Typography color={'secondary'} component="h1" variant="h4">
          {`${category.name}`}
          {session?.isPro && (
            <Typography
              color={'error'}
              component={'span'}
              sx={{ lineHeight: 'inherit' }}
            >
              {` -${Math.round((1 - parseFloat(session.multiplier)) * 100)}%`}
            </Typography>
          )}
        </Typography>
        <Breadcrumb
          parents={[
            {
              name: <Trans i18nKey="shopTitle">Shop</Trans>,
              uri: `/shop`,
            },
            { name: category.name },
          ]}
        />
      </Box>
    );
  }

  return (
    <Box className={classes.root} sx={sx}>
      <Helmet>
        <title itemProp={'name'}>{t('shopTitle')}</title>
        <meta content={t('shopTitle')} property="og:title" />
        <meta content={t('shopMetaDescription')} name="description" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Typography color={'secondary'} component="h1" variant="h4">
        <Trans i18nKey="shopTitle">Shop</Trans>
        {session?.isPro ? (
          <Typography
            color={'error'}
            component={'span'}
            sx={{ lineHeight: 'inherit' }}
          >
            {` -${Math.round((1 - parseFloat(session.multiplier)) * 100)}%`}
          </Typography>
        ) : (
          ``
        )}
      </Typography>
      <Breadcrumb
        parents={[{ name: <Trans i18nKey="shopTitle">Shop</Trans> }]}
      />
    </Box>
  );
};

export default ShopTitle;
