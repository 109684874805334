import { createSelector } from 'reselect';
import {
  ADMIN_AUTH,
  EDITOR_AUTH,
  LOGISTIC_AUTH,
  ACCOUNTANT_AUTH,
  ADMIN_ROLE,
  COACH_ROLE,
  CONSULTANT_ROLE,
  CUSTOMER_ROLE,
  DELEGATE_ROLE,
  DIRECTOR_ROLE,
  MANAGER_ROLE,
  PROSPECT_ROLE,
  SUPER_ADMIN_AUTH,
  MEGA_ADMIN_AUTH,
  GOD_AUTH,
  PARTNER_CAREER,
} from './constants';

export const userSelector = (state) => state.authentication.user;
export const contactSelector = (state) => state.authentication.contact;

export const getUserShippingAddressSelector = createSelector(
  [userSelector],
  (user) => {
    return user && user.shippingAddress ? user.shippingAddress : null;
  },
);

export const getUserRoleSelector = createSelector([userSelector], (user) => {
  return user && user.role ? user.role : null;
});

export const getUserCareerSelector = createSelector([userSelector], (user) => {
  return user && user.career ? user.career : null;
});

export const getUserAuthSelector = createSelector([userSelector], (user) => {
  return (user && user.authorizations) || [];
});

export const isSessionControl = createSelector([userSelector], (user) => {
  return user && !!user.isSessionControl;
});

export const isSuperAdmin = createSelector(
  [getUserAuthSelector],
  (authorizations) => {
    return authorizations.includes(SUPER_ADMIN_AUTH);
  },
);
export const isMegaAdmin = createSelector(
  [getUserAuthSelector],
  (authorizations) => {
    return authorizations.includes(MEGA_ADMIN_AUTH);
  },
);
export const isGod = createSelector([getUserAuthSelector], (authorizations) => {
  return authorizations.includes(GOD_AUTH);
});
export const isAdmin = createSelector(
  [getUserAuthSelector],
  (authorizations) => {
    return authorizations.includes(ADMIN_AUTH);
  },
);
export const isLogistic = createSelector(
  [getUserAuthSelector],
  (authorizations) => {
    return authorizations.includes(LOGISTIC_AUTH);
  },
);
export const isEditor = createSelector(
  [getUserAuthSelector],
  (authorizations) => {
    return authorizations.includes(EDITOR_AUTH);
  },
);
export const isAccountant = createSelector(
  [getUserAuthSelector],
  (authorizations) => {
    return authorizations.includes(ACCOUNTANT_AUTH);
  },
);
export const isPro = createSelector([getUserRoleSelector], (role) => {
  return (
    role &&
    (role === ADMIN_ROLE ||
      role === DIRECTOR_ROLE ||
      role === MANAGER_ROLE ||
      role === COACH_ROLE ||
      role === CONSULTANT_ROLE ||
      role === DELEGATE_ROLE)
  );
});
export const hasAuthManager = createSelector([getUserRoleSelector], (role) => {
  return (
    role &&
    (role === ADMIN_ROLE || role === DIRECTOR_ROLE || role === MANAGER_ROLE)
  );
});
export const hasAuthCoach = createSelector([getUserRoleSelector], (role) => {
  return (
    role &&
    (role === ADMIN_ROLE ||
      role === DIRECTOR_ROLE ||
      role === MANAGER_ROLE ||
      role === COACH_ROLE)
  );
});
export const hasAuthConsultant = createSelector(
  [getUserRoleSelector],
  (role) => {
    return (
      role &&
      (role === ADMIN_ROLE ||
        role === DIRECTOR_ROLE ||
        role === MANAGER_ROLE ||
        role === COACH_ROLE ||
        role === CONSULTANT_ROLE)
    );
  },
);
export const isPartner = createSelector([getUserCareerSelector], (career) => {
  return career === PARTNER_CAREER;
});
export const isDirector = createSelector([getUserRoleSelector], (role) => {
  return role && role === DIRECTOR_ROLE;
});
export const isManager = createSelector([getUserRoleSelector], (role) => {
  return role && role === MANAGER_ROLE;
});
export const isCoach = createSelector([getUserRoleSelector], (role) => {
  return role && role === COACH_ROLE;
});
export const isConsultant = createSelector([getUserRoleSelector], (role) => {
  return role && role === CONSULTANT_ROLE;
});
export const isDelegate = createSelector([getUserRoleSelector], (role) => {
  return role && role === DELEGATE_ROLE;
});
export const isCustomer = createSelector([getUserRoleSelector], (role) => {
  return (
    role &&
    (role === ADMIN_ROLE ||
      role === LOGISTIC_AUTH ||
      role === DIRECTOR_ROLE ||
      role === MANAGER_ROLE ||
      role === COACH_ROLE ||
      role === CONSULTANT_ROLE ||
      role === DELEGATE_ROLE ||
      role === CUSTOMER_ROLE)
  );
});
export const isRegistered = createSelector([getUserRoleSelector], (role) => {
  return (
    role &&
    (role === ADMIN_ROLE ||
      role === LOGISTIC_AUTH ||
      role === DIRECTOR_ROLE ||
      role === MANAGER_ROLE ||
      role === COACH_ROLE ||
      role === CONSULTANT_ROLE ||
      role === DELEGATE_ROLE ||
      role === CUSTOMER_ROLE ||
      role === PROSPECT_ROLE)
  );
});
export const isProspect = createSelector([getUserRoleSelector], (role) => {
  return role && role === PROSPECT_ROLE;
});
export const structureMembershipModalOpenSelector = (state) =>
  state.authentication.structureMembershipModalOpen;
export const partnerMembershipModalOpenSelector = (state) =>
  state.authentication.partnerMembershipModalOpen;
export const membershipAlertedSelector = (state) =>
  state.authentication.membershipAlerted;
export const membershipDaysRemainingSelector = createSelector(
  [userSelector],
  (user) => {
    return Math.round(
      (new Date(user.membershipEndAt) - Date.now()) / (1000 * 3600 * 24),
    );
  },
);
export const hasMembershipSelector = createSelector(
  [membershipDaysRemainingSelector],
  (daysRemaining) => {
    return daysRemaining > 0;
  },
);

export const updateUserLoading = (state) =>
  state.authentication.updateUserRequested;
export const reloadUserRequested = (state) =>
  state.authentication.reloadUserRequested;
