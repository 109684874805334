import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Breadcrumb from '../../../../components/Layout/Customer/Breadcrumb';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import InlineContent from './components/InlineContent';
import { shopActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { shopProductSelector, sessionSelector } from '../store/selectors';
import Container from '@mui/material/Container';
import AddToCart from './components/AddToCart';
import TabPanelContent from './components/TabPanelContent';
import { Helmet } from 'react-helmet-async';
import { red } from '@mui/material/colors';
import { getFormalizedName } from '../helpers/helper';
import LazyLoadImage from '../../../../components/LazyLoad/LazyLoadImage';
import SocialNetworksShareButtons from '../../../../components/SocialNetworksShareButtons';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import Header from '../../../../components/Layout/Customer/Header/Header';
import { useParams } from 'react-router-dom';
import { useCustomerPath } from '../../../../helpers/usePath';
import { useSEO } from '../../../../helpers/useSEO';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100vh',
    paddingBottom: 40,
    paddingTop: 70,
    '& .productContent': {
      '& .MuiTypography-h2': {
        marginBottom: 20,
        fontSize: '2.5rem',
      },
      '& .MuiTypography-h5': {
        marginBottom: 10,
      },
      '& .MuiTypography-body1': {
        fontSize: 16,
        whiteSpace: 'break-spaces',
      },
      '& .MuiDivider-root': {
        margin: '20px 0',
      },
      '& .infoPro': {
        color: red[500],
      },
    },
    '& .productContainer': {
      '& .rating': {
        paddingBottom: theme.spacing(2),
        '& .count': {
          fontSize: '1rem',
          marginLeft: 8,
          verticalAlign: 'super',
        },
      },
    },
    '& .productImage': {
      paddingTop: 20,
      position: 'relative',
      '& img': {
        maxWidth: '100%',
        height: 'auto',
      },
      '& .overlay-highlight': {
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.04,
        overflow: 'hidden',
        position: 'absolute',
        transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: 16,
        pointerEvents: 'none',
        backgroundColor: theme.palette.secondary.dark,
      },
    },
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
  },
}));

const ProductPage = () => {
  const path = useCustomerPath();
  const { country, productId } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const session = useSelector(sessionSelector);
  const product = useSelector(shopProductSelector);
  const { buildAlternatesLangTags } = useSEO();

  useEffect(() => {
    dispatch(shopActions.closeCartModal());
    if (typeof window === 'object') {
      window.scrollTo(0, 0);
    }
  }, [product?.id]);

  useEffect(() => {
    if (product) {
      const productData = {
        id: product.id,
        quantity: product.quantity,
        price: product.price,
      };
      import('react-facebook-pixel')
        .then((module) => module.default)
        .then((ReactPixel) => {
          ReactPixel.track('ViewContent', {
            value: product.price,
            currency: product.currency,
            content_type: 'product',
            content_name: product.detail.name,
            content_ids: [product.id],
            contents: [productData],
          });
        });
    }
  }, [product]);

  useEffect(() => {
    if (
      !product ||
      product.id !== productId ||
      product.country !== country.toUpperCase() ||
      product.detail.lang !== i18n.language
    ) {
      dispatch(
        shopActions.getProductRequest(
          i18n.language,
          country.toUpperCase(),
          productId,
        ),
      );
    }
  }, [i18n.language, productId, session?.country]);

  if (!product) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{product.detail.name}</title>
        <meta content={product.detail.name} property="og:title" />
        <meta content="article" property="og:type" />
        <meta content={product.image[800]} property="og:image" />
        <meta content={product.detail.description} property="og:description" />
        <meta
          content={`${
            process.env.REACT_APP_WEB_URL
          }${path}/shop/product/${getFormalizedName(product.detail.name)}/${
            product.id
          }`}
          property="og:url"
        />
        <meta content={`in stock`} property="og:availability" />
        <meta content={product.id} property="product:retailer_item_id" />
        <meta
          content={`${(product.price * (1 + product.taxesRate)).toFixed(2)}`}
          property="product:price:amount"
        />
        <meta content={product.currency} property="product:price:currency" />
        <meta content={product.detail.description} name="description" />
        <link as="image" href={product.image[800]} rel="preload" />
        {buildAlternatesLangTags(
          `/shop/product/${product.id}`,
          product.alternates.reduce((acc, alternate) => {
            acc[alternate.lang] = `/shop/product/${getFormalizedName(
              alternate.name,
            )}/${product.id}`;
            return acc;
          }, {}),
        ).map((props, index) => (
          <link {...props} key={index} />
        ))}
      </Helmet>
      <Header />
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Grid container justifyContent={'space-between'} spacing={2}>
            <Grid item lg={7}>
              <Breadcrumb
                parents={[
                  !product.proShop
                    ? {
                        name: <Trans i18nKey="shopTitle">Shop</Trans>,
                        uri: `/shop`,
                      }
                    : {
                        name: <Trans i18nKey="shopProTitle">Shop pro</Trans>,
                        uri: `/shop/pro`,
                      },
                  { name: product.detail.name },
                ]}
              />
            </Grid>
            <Grid item lg={5} md={12} sm={12} xs={12}>
              <SocialNetworksShareButtons
                align={'right'}
                title={product.detail.name}
                url={`/shop/product/${getFormalizedName(product.detail.name)}/${
                  product.id
                }`}
              />
            </Grid>
          </Grid>
        </div>
        <div className={'productContainer'}>
          <Grid container spacing={4}>
            <Grid item md={4} sm={12} xs={12}>
              <div className={'productImage'}>
                <LazyLoadImage
                  alt={product.detail.name}
                  height={800}
                  skeleton={
                    <img
                      alt={product.detail.name}
                      height={800}
                      src={product.image[200]}
                      width={800}
                    />
                  }
                  src={product.image[800]}
                  width={800}
                />
                <span className="overlay-highlight"></span>
                <AddToCart product={product} />
                {product.reviewsRating && (
                  <Typography align={'center'} className={'rating'}>
                    <Rating
                      disabled
                      name="disabled"
                      precision={0.25}
                      value={parseFloat(product.reviewsRating)}
                    />
                    <Typography className={'count'} component={'span'}>
                      {`${product.reviewsCount} ${t('reviewLabel', {
                        count: product.reviewsCount,
                      })}`}
                    </Typography>
                  </Typography>
                )}
              </div>
            </Grid>
            <Grid className={'productContent'} item md={8} sm={12}>
              <Hidden implementation={'css'} lgDown>
                <TabPanelContent product={product} session={session} />
              </Hidden>

              <Hidden implementation={'css'} lgUp>
                <InlineContent product={product} session={session} />
              </Hidden>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default ProductPage;
