import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Breadcrumb from '../../../components/Layout/Customer/Breadcrumb';
import Grid from '@mui/material/Grid';
import { shopActions } from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelector, shopProductSelector } from './store/selectors';
import Container from '@mui/material/Container';
import { grey } from '@mui/material/colors';
import { Helmet } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Link } from '../../../helpers/multilingual';
import i18next from 'i18next';
import { getFormalizedName } from './helpers/helper';
import LazyLoadImage from '../../../components/LazyLoad/LazyLoadImage';
import SocialNetworksShareButtons from '../../../components/SocialNetworksShareButtons';
import Header from '../../../components/Layout/Customer/Header/Header';
import { useCustomerPath } from '../../../helpers/usePath';

const useStylesProduct = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    paddingTop: 70,
    paddingBottom: 40,
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5,
    },
  },
  productContainer: {
    marginTop: 30,
  },
  productImage: {
    paddingTop: 20,
    position: 'relative',
    '& img': {
      maxWidth: '100%',
      height: 'auto',
    },
    '& .overlay-highlight': {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.04,
      overflow: 'hidden',
      position: 'absolute',
      transition: 'opacity 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      borderRadius: 16,
      pointerEvents: 'none',
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  addToCart: {
    paddingBottom: 20,
    '& .MuiOutlinedInput-root': {
      height: 38,
    },
    '& button': {
      borderRadius: 8,
      color: '#000',
      height: 40,
      padding: '8px 20px',
      margin: '4px',
      border: '1px solid ' + grey[500],
      '& .MuiSvgIcon-root': {
        margin: '0px 5px 0px 0px !important',
        fontSize: '1.1rem',
      },
    },
  },
  productContent: {
    '& .MuiTypography-h2': {
      marginBottom: 20,
    },
    '& .MuiTypography-h5': {
      marginBottom: 10,
    },
    '& .MuiTypography-body1': {
      fontSize: 16,
      whiteSpace: 'break-spaces',
    },
    '& .MuiDivider-root': {
      margin: '20px 0',
    },
    '& .video iframe': {
      maxWidth: '100%',
    },
  },
  menuTab: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    '& .MuiTabs-root': {
      overflow: 'inherit',
      '& .MuiTab-wrapper': {
        alignItems: 'flex-end',
      },
    },
    '& .MuiBox-root': {
      paddingTop: 0,
    },
    '& .tabs': {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    '& .oldPriceInfo': {
      fontSize: '1.8rem',
      textDecoration: 'line-through',
    },
    '& .proPriceInfo': {
      fontWeight: 'bold',
      color: 'red',
      marginLeft: 10,
    },
  },
  inlineTab: {
    '& .oldPriceInfo': {
      fontSize: '1.8rem',
      textDecoration: 'line-through',
    },
    '& .proPriceInfo': {
      fontWeight: 'bold',
      color: 'red',
      marginLeft: 10,
    },
  },
}));

const CatalogProduct = (props) => {
  const path = useCustomerPath();
  const classes = useStylesProduct();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const session = useSelector(sessionSelector);
  const product = useSelector(shopProductSelector);

  useEffect(() => {
    if (
      !product ||
      product.id !== props.match.params.productId ||
      product.detail.lang !== i18n.language
    ) {
      dispatch(
        shopActions.getProductRequest(
          i18n.language,
          'BE',
          props.match.params.productId,
        ),
      );
    }
  }, [i18n.language, props.match.params.productId, session && session.country]);

  if (!product) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{product.detail.name}</title>
        <meta content={product.detail.name} property="og:title" />
        <meta content="article" property="og:type" />
        <meta content={product.image[800]} property="og:image" />
        <meta content={product.detail.description} property="og:description" />
        <meta
          content={`${
            process.env.REACT_APP_WEB_URL
          }${path}/shop/catalog/product/${getFormalizedName(
            product.detail.name,
          )}/${product.id}`}
          property="og:url"
        />
        <meta content={`in stock`} property="og:availability" />
        <meta content={product.id} property="product:retailer_item_id" />
        <meta content={product.price} property="product:price:amount" />
        <meta content={product.currency} property="product:price:currency" />
        <meta content={product.detail.description} name="description" />
        <link as="image" href={product.image[800]} rel="preload" />
      </Helmet>
      <Header />
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Grid
            alignItems={'baseline'}
            container
            justifyContent={'space-between'}
            spacing={2}
          >
            <Grid item>
              <Breadcrumb
                parents={[
                  {
                    name: <Trans i18nKey="catalog">Catalog</Trans>,
                    uri: '/shop/catalog',
                  },
                  { name: product.detail.name },
                ]}
              />
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <SocialNetworksShareButtons
                align={'right'}
                title={product.detail.name}
                url={`/shop/catalog/product/${getFormalizedName(
                  product.detail.name,
                )}/${product.id}`}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.productContainer}>
          <Grid container spacing={4}>
            <Grid item md={4} sm={12} xs={12}>
              <div className={classes.productImage}>
                <LazyLoadImage
                  alt={product.detail.name}
                  height={800}
                  skeleton={
                    <img
                      alt={product.detail.name}
                      height={800}
                      src={product.image[200]}
                      width={800}
                    />
                  }
                  src={product.image[800]}
                  width={800}
                />
                <span className="overlay-highlight"></span>
              </div>
            </Grid>
            <Grid className={classes.productContent} item md={8} sm={12}>
              <div>
                <Grid container>
                  <Grid item spacing={2} xs={12}>
                    <Typography component="h2" variant="h2">
                      {product.detail.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      color={'secondary'}
                      component={Link}
                      size={'large'}
                      to={`/shop/product/${getFormalizedName(
                        product.detail.name,
                      )}/${product.id}`}
                      variant={'contained'}
                    >
                      <Trans i18nKey={'buyInShop'}>Buy in the shop</Trans>
                    </Button>
                  </Grid>
                </Grid>

                {!!product.detail.video && (
                  <>
                    <Divider />
                    <Typography
                      className={'video'}
                      component={'div'}
                      gutterBottom
                    >
                      <iframe
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        frameBorder="0"
                        height="360"
                        src={`${product.detail.video}?autoplay=0&color=ffffff&title=0&byline=0&portrait=0&modestbranding=1&rel=0&showinfo=0&texttrack=${i18next.language}`}
                        width="640"
                      />
                    </Typography>
                  </>
                )}
                <Divider />
                <Typography component="p">
                  {product.detail.description}
                </Typography>
                <Divider />
                <Typography component="p">
                  {product.detail.definition}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="recommendedDosage">
                    Recommended dosage :
                  </Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.recommendedDosage}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="contraindications">Contraindications :</Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.contraindications}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="guaranteedWithout">
                    Guaranteed without :
                  </Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.guaranteedWithout}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="precautionsForUse">
                    Precautions for use :
                  </Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.precautionsForUse}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="conservation">Conservation :</Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.conservation}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="composition">Composition :</Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.composition}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="excipients">Excipients :</Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.excipients}
                </Typography>
                <Divider />
                <Typography component="h4" variant="h5">
                  <Trans i18nKey="ingredients">Ingredients :</Trans>
                </Typography>
                <Typography component="p">
                  {product.detail.ingredients}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default CatalogProduct;
