import React from 'react';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { useSelector } from 'react-redux';
import {
  isPro,
  userSelector,
} from '../containers/Authentication/store/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { ProLink } from '../helpers/multilingual';
import { red } from '@mui/material/colors';
import LinkIcon from '@mui/icons-material/Link';
import Button from '@mui/material/Button';
import { SnackbarContent, SnackbarInfo } from '../helpers/snackbar';
import Grid from '@mui/material/Grid';
import { useCustomerPath } from '../helpers/usePath';

const useStyles = makeStyles(() => ({
  root: {
    '& ul': {
      padding: 0,
      margin: 0,
      display: 'flex',
      '& li': {
        marginRight: 10,
        listStyle: 'none',
        '& .buttonIcon': {
          borderRadius: '50%',
          minWidth: 0,
          padding: 4,
        },
      },
      '&.right': {
        justifyContent: 'flex-end',
      },
    },
    '& .directLinkInfo': {
      marginTop: 10,
      //maxWidth: 440,
      fontWeight: 'bold',
      color: blue[900],
      '& a': {
        textDecoration: 'underline',
      },
      '& .error': {
        padding: 3,
        backgroundColor: red[500],
        color: '#fff',
        fontWeight: 'bold',
      },
    },
  },
  snackMessage: {
    padding: 20,
    '& h4': {
      fontSize: 20,
    },
    '& .action button': {
      backgroundColor: '#000',
    },
  },
}));

const SocialNetworksShareButtons = ({ align = 'left', title, url }) => {
  const path = useCustomerPath();
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const userIsPro = useSelector(isPro);

  if (userIsPro && user.directLink && user.directLinkEnabled) {
    url = `${process.env.REACT_APP_WEB_URL}${path}/${user.directLink}${url}`;
  } else {
    url = `${process.env.REACT_APP_WEB_URL}${path}${url}`;
  }

  const handleCopyToClipboard = () => {
    const elem = document.createElement('textarea');
    elem.value = url;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
    SnackbarInfo(t('linkCopiedInClipboard'));
  };

  const handleShowLink = () => {
    SnackbarContent(
      <Grid className={classes.snackMessage} container spacing={2}>
        <Grid item>
          <Typography align={'left'} variant={'h4'}>
            <Trans i18nKey={'shareLink'}>Share link</Trans> :
          </Typography>
        </Grid>
        <Grid item>
          <Typography align={'left'}>{url}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align={'right'} className={'action'}>
            <Button
              color={'primary'}
              onClick={handleCopyToClipboard}
              size={'small'}
              variant={'contained'}
            >
              {t('copyInClipboard')}
            </Button>
          </Typography>
        </Grid>
      </Grid>,
      () => {},
      'large',
    );
  };

  return (
    <div className={classes.root}>
      <ul className={align}>
        <li>
          <FacebookShareButton url={url}>
            <FacebookIcon round size={32} />
          </FacebookShareButton>
        </li>

        <li>
          <TwitterShareButton title={title} url={url}>
            <TwitterIcon round size={32} />
          </TwitterShareButton>
        </li>

        <li>
          <WhatsappShareButton separator=":: " title={title} url={url}>
            <WhatsappIcon round size={32} />
          </WhatsappShareButton>
        </li>

        <li>
          <LinkedinShareButton url={url}>
            <LinkedinIcon round size={32} />
          </LinkedinShareButton>
        </li>

        <li>
          <EmailShareButton body="" subject={title} url={url}>
            <EmailIcon round size={32} />
          </EmailShareButton>
        </li>

        <li>
          <Button
            className={'buttonIcon'}
            color={'primary'}
            onClick={handleShowLink}
            variant={'contained'}
          >
            <LinkIcon />
          </Button>
        </li>
      </ul>
      {userIsPro && user.directLink && user.directLinkEnabled && (
        <Typography align={align} className={`directLinkInfo`}>
          <Trans i18nKey={'linksAboveAreProLinks'}>
            Pro links: Autologin to your shop
          </Trans>
          <br />
          <ProLink to={'/pro/settings/identifier'}>
            <Trans i18nKey={'customizeYourLink'}>Customize the link</Trans>
          </ProLink>
        </Typography>
      )}
      {userIsPro && (!user.directLink || !user.directLinkEnabled) && (
        <Typography align={align} className={`directLinkInfo`}>
          <span className={'error'}>
            <Trans i18nKey={'linksAreNotEnabled'}>Links are not enabled</Trans>
          </span>
          <br />
          <ProLink to={'/pro/settings/identifier'}>
            <Trans i18nKey={'customizeYourLink'}>Customize the link</Trans>
          </ProLink>
        </Typography>
      )}
    </div>
  );
};
export default SocialNetworksShareButtons;
