import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as actionsType from './constants';
import { shopActions } from './actions';
import { shopService } from './services';
import {
  SnackbarError,
  SnackbarFormattedError,
  SnackbarSuccess,
} from '../../../../helpers/snackbar';
import i18next from 'i18next';
import { history } from '../../../../helpers/store';
import Cookie from '../../../../helpers/cookie';

export const shopSagaKey = 'shop';

const shopSelector = (state) => state.shop;

function* getUserShippingAddresses() {
  try {
    let { shippingAddresses } = yield call(shopService.getShippingAddresses);

    yield put(shopActions.getShippingAddressesSuccess(shippingAddresses));
  } catch (e) {
    yield put(shopActions.getShippingAddressesFailed());
  }
}

function* addUserShippingAddress(action) {
  try {
    const { shippingAddress } = yield call(
      shopService.addShippingAddress,
      action.shippingAddress,
    );

    yield put(shopActions.addShippingAddressSuccess(shippingAddress));
    yield put(shopActions.setShippingAddressSelected(shippingAddress));
    //yield put(shopActions.reloadCartRequest(shippingAddress.country));
    yield call(SnackbarSuccess, i18next.t('addressAdded'));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.addShippingAddressFailed());
  }
}

function* updateUserShippingAddress(action) {
  try {
    const { shippingAddress } = yield call(
      shopService.updateShippingAddress,
      action.shippingAddressId,
      action.shippingAddress,
    );
    let { shippingAddresses } = yield call(shopService.getShippingAddresses);
    yield put(shopActions.getShippingAddressesSuccess(shippingAddresses));
    yield put(shopActions.updateShippingAddressSuccess(shippingAddress));
    yield call(SnackbarSuccess, i18next.t('addressUpdated'));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.updateShippingAddressFailed());
  }
}

function* removeUserShippingAddress(action) {
  try {
    yield call(shopService.removeShippingAddress, action.shippingAddress);

    yield put(shopActions.removeShippingAddressSuccess(action.shippingAddress));
    yield call(SnackbarSuccess, i18next.t('addressRemoved'));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.removeShippingAddressFailed());
  }
}

function* setDefaultUserShippingAddress(action) {
  try {
    yield call(shopService.setAsDefaultShippingAddress, action.shippingAddress);

    yield put(
      shopActions.setAsDefaultShippingAddressSuccess(action.shippingAddress),
    );
    //yield put(shopActions.reloadCartRequest(action.shippingAddress.country));
    //yield call(SnackbarSuccess, i18next.t("addressSelectedAsDefault"));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(shopActions.setAsDefaultShippingAddressFailed());
  }
}

function* getProducts(action) {
  try {
    const shop = yield select(shopSelector);
    const country = action.country ? action.country : 'BE';
    const productsKey = `${action.categoryId}-${action.country}-${action.lang}`;
    const { products } = yield call(
      shopService.getProducts,
      action.lang,
      country,
      action.categoryId,
      shop.products[productsKey]?.data
        ? Object.keys(shop.products[productsKey].data).length
        : 0,
    );

    yield put(
      shopActions.getProductsSuccess(
        action.lang,
        country,
        action.categoryId,
        products,
      ),
    );
  } catch (e) {
    yield put(shopActions.getProductsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getCatalogProducts(action) {
  try {
    const { products } = yield call(
      shopService.getCatalogProducts,
      action.lang,
    );

    yield put(shopActions.getCatalogProductsSuccess(products));
  } catch (e) {
    yield put(shopActions.getCatalogProductsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* searchProducts(action) {
  try {
    const country = action.country ? action.country : 'BE';
    let products = [];
    if (action.search.length >= 2) {
      products = yield call(
        shopService.searchProducts,
        action.lang,
        country,
        action.search,
      );
      yield put(shopActions.searchProductsSuccess(country, products));
    }
  } catch (e) {
    yield put(shopActions.searchProductsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getProduct(action) {
  try {
    const product = yield call(
      shopService.getProduct,
      action.lang,
      action.country,
      action.productId,
      action.allowHidden,
    );
    yield put(shopActions.getProductSuccess(product));
  } catch (e) {
    yield put(shopActions.getProductFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getCategories(action) {
  try {
    const categories = yield call(shopService.getCategories, action.lang);
    yield put(shopActions.getCategoriesSuccess(action.lang, categories));
  } catch (e) {
    yield put(shopActions.getCategoriesFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

/*
function* getCategory(action) {
  try {
    const category = yield call(
      shopService.getCategory,
      action.lang,
      action.categoryId,
    );
    yield put(shopActions.getCategorySuccess(category));
  } catch (e) {
    yield put(shopActions.getCategoryFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}
*/

function* saveCart() {
  const shop = yield select(shopSelector);
  try {
    localStorage.setItem('cart', JSON.stringify(shop.cart.items));
  } catch (e) {
    /* empty */
  }
  yield put(shopActions.openCartModal());
}

function* setCartFromOrder(action) {
  try {
    const items = yield call(
      shopService.getProductsByOrderId,
      i18next.language,
      action.orderId,
      action.country,
    );
    yield put(
      shopActions.setCartFromOrderSuccess(
        i18next.language,
        action.country,
        items,
      ),
    );
  } catch (e) {
    yield put(shopActions.setCartFromOrderFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* signInSession(action) {
  try {
    const session = yield call(
      shopService.signInSession,
      action.consultantId,
      action.countryCode,
    );
    Cookie.set('session', session);
    yield put(shopActions.signInSessionSuccess(session));

    if (action.redirect) {
      history.push(`${action.redirect}`);
    }
  } catch (e) {
    yield put(shopActions.signInSessionFail());
    if (e.error.type === 'session-error') {
      SnackbarError(i18next.t('errorMessageInvalidConsultantCode'));
    } else {
      SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
    }
  }
}

function* signInSessionByDirectLink(action) {
  try {
    const session = yield call(
      shopService.signInSessionByDirectLink,
      action.directLink,
      action.countryCode,
    );
    Cookie.set('session', session);
    yield put(shopActions.signInSessionByDirectLinkSuccess(session));
  } catch (e) {
    yield put(shopActions.signInSessionByDirectLinkFail());
    //SnackbarError(i18next.t('errorMessageInvalidConsultantLink'))
  }
}

function* getCheckout(action) {
  try {
    const checkout = yield call(
      shopService.getCheckout,
      action.lang,
      action.shippingMethodId,
      action.shippingAddressId,
      action.shippingPickupPoint,
      action.items,
      action.useWallet,
      action.giftCard,
    );
    yield put(shopActions.getCheckoutSuccess(checkout));
  } catch (e) {
    yield put(shopActions.getCheckoutFail());
    //SnackbarError(i18next.t('errorMessageAnErrorOccurred'))
  }
}

function* getShippingMethods(action) {
  try {
    const shippingMethods = yield call(
      shopService.getShippingMethods,
      action.country,
      action.weight,
    );
    yield put(shopActions.getShippingMethodsSuccess(shippingMethods));
  } catch (e) {
    yield put(shopActions.getShippingMethodsFail());
    SnackbarFormattedError(e.error);
  }
}

function* getOrders(action) {
  try {
    const orders = yield call(shopService.getOrders, action.lang);
    yield put(shopActions.getOrdersSuccess(orders));
  } catch (e) {
    yield put(shopActions.getOrdersFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getGiftCards() {
  try {
    const giftCards = yield call(shopService.getGiftCards);
    yield put(shopActions.getGiftCardsSuccess(giftCards));
  } catch (e) {
    yield put(shopActions.getGiftCardsFail());
    SnackbarError(i18next.t('errorMessageAnErrorOccurred'));
  }
}

function* getActiveDiscountCode() {
  try {
    const discountCode = yield call(shopService.getActiveDiscountCode);
    yield put(shopActions.getActiveDiscountCodeSuccess(discountCode));
  } catch (e) {
    yield put(shopActions.getActiveDiscountCodeFail());
  }
}

export default function* watchShop() {
  yield takeEvery(
    actionsType.GET_SHIPPING_ADDRESSES_REQUEST,
    getUserShippingAddresses,
  );
  yield takeEvery(
    actionsType.ADD_SHIPPING_ADDRESS_REQUEST,
    addUserShippingAddress,
  );
  yield takeEvery(
    actionsType.UPDATE_SHIPPING_ADDRESS_REQUEST,
    updateUserShippingAddress,
  );
  yield takeEvery(
    actionsType.REMOVE_SHIPPING_ADDRESS_REQUEST,
    removeUserShippingAddress,
  );
  yield takeEvery(
    actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST,
    setDefaultUserShippingAddress,
  );
  yield takeEvery(actionsType.GET_PRODUCTS_REQUEST, getProducts);
  yield takeEvery(actionsType.GET_CATALOG_PRODUCTS_REQUEST, getCatalogProducts);
  yield takeEvery(actionsType.SEARCH_PRODUCTS_REQUEST, searchProducts);
  yield takeEvery(actionsType.GET_PRODUCT_REQUEST, getProduct);
  yield takeEvery(actionsType.GET_CATEGORIES_REQUEST, getCategories);
  //yield takeEvery(actionsType.GET_CATEGORY_REQUEST, getCategory);
  yield takeEvery(actionsType.CART_ADD_ITEM, saveCart);
  yield takeEvery(actionsType.CART_REMOVE_ITEMS, saveCart);
  yield takeEvery(actionsType.CART_SET_ITEM_QUANTITY, saveCart);
  yield takeEvery(actionsType.SIGNIN_SESSION_REQUEST, signInSession);
  yield takeEvery(
    actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST,
    signInSessionByDirectLink,
  );
  yield takeEvery(actionsType.GET_CHECKOUT_REQUEST, getCheckout);
  yield takeEvery(actionsType.GET_SHIPPING_METHODS_REQUEST, getShippingMethods);
  yield takeEvery(actionsType.CART_SET_FROM_ORDER_REQUEST, setCartFromOrder);
  yield takeEvery(actionsType.CART_SET_FROM_ORDER_SUCCESS, saveCart);
  yield takeEvery(actionsType.GET_ORDERS_REQUEST, getOrders);
  yield takeEvery(actionsType.GET_GIFT_CARDS_REQUEST, getGiftCards);
  yield takeEvery(
    actionsType.GET_ACTIVE_DISCOUNT_CODE_REQUEST,
    getActiveDiscountCode,
  );
}
