import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import LineChartByYearAndMonth from '../../../../components/Charts/LineChartByYearAndMonth';
import Card from '@mui/material/Card';
import { statsActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  directRecruitmentDataSelector,
  directRecruitmentLinesGraphDataSelector,
  directRecurringSalesLinesGraphDataSelector,
  directRecurringTurnoverDataSelector,
  directSalesLinesGraphDataSelector,
  directTurnoverDataSelector,
} from '../store/selectors';
import { Trans, useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-select': {
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      paddingTop: 10,
      paddingBottom: 10,
      maxWidth: '70vw',
    },
    height: '100%',
  },
  menuItem: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
  loader: {
    height: 290,
  },
}));
const DirectCharts = ({ user }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [view, setView] = useState('directSales');
  const directSalesLinesGraphData = useSelector(
    directSalesLinesGraphDataSelector(user.id),
  );
  const directRecurringSalesLinesGraphData = useSelector(
    directRecurringSalesLinesGraphDataSelector(user.id),
  );
  const directRecruitmentLinesGraphData = useSelector(
    directRecruitmentLinesGraphDataSelector(user.id),
  );
  const directTurnoverData = useSelector(directTurnoverDataSelector(user.id));
  const directRecurringTurnoverData = useSelector(
    directRecurringTurnoverDataSelector(user.id),
  );
  const directRecruitmentData = useSelector(
    directRecruitmentDataSelector(user.id),
  );

  useEffect(() => {
    switch (view) {
      case 'directRecruitments':
        if (!directRecruitmentLinesGraphData) {
          dispatch(statsActions.getDirectRecruitmentRequest(user.id));
        }
        break;
      case 'directRecurringSales':
        if (!directRecurringSalesLinesGraphData) {
          dispatch(statsActions.getDirectRecurringSalesRequest(user.id));
        }
        break;
      case 'directSales':
      default:
        if (!directSalesLinesGraphData) {
          dispatch(statsActions.getDirectSalesRequest(user.id));
        }
    }
  }, [view]);

  const handleChangeView = (e) => {
    setView(e.target.value);
  };

  const getValues = () => {
    switch (view) {
      case 'directRecruitments':
        if (!directRecruitmentLinesGraphData) {
          return null;
        }
        return {
          data: directRecruitmentLinesGraphData,
          year: `${Math.round(directRecruitmentData.year * 100) / 100}`,
          month: `${Math.round(directRecruitmentData.month * 100) / 100}`,
        };
      case 'directRecurringSales':
        if (!directRecurringSalesLinesGraphData) {
          return null;
        }
        return {
          data: directRecurringSalesLinesGraphData,
          year: `SCR ${
            Math.round(directRecurringTurnoverData.year * 100) / 100
          }`,
          month: `SCR ${
            Math.round(directRecurringTurnoverData.month * 100) / 100
          }`,
        };
      case 'directSales':
      default:
        if (!directSalesLinesGraphData) {
          return null;
        }
        return {
          data: directSalesLinesGraphData,
          year: `SCR ${Math.round(directTurnoverData.year * 100) / 100}`,
          month: `SCR ${Math.round(directTurnoverData.month * 100) / 100}`,
        };
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        sx={{ height: 95 }}
        title={
          <Select
            defaultValue={view}
            onChange={handleChangeView}
            variant={'outlined'}
          >
            <MenuItem className={classes.menuItem} value="directSales">
              {t('directSales')}
            </MenuItem>
            <MenuItem className={classes.menuItem} value="directRecurringSales">
              {t('directRecurringSales')}
            </MenuItem>
            <MenuItem className={classes.menuItem} value="directRecruitments">
              {t('directRecruitments')}
            </MenuItem>
          </Select>
        }
      />
      <Divider />
      {!getValues() && (
        <Grid
          alignItems={'center'}
          className={classes.loader}
          container
          justifyContent={'center'}
        >
          <Grid item sm={4} xs={6}>
            <Typography align={'center'}>
              <Trans i18nKey={'loading'}>Loading</Trans> ...
            </Typography>
            <LinearProgress color="secondary" />
          </Grid>
        </Grid>
      )}
      {getValues() && (
        <>
          <CardContent>
            <LineChartByYearAndMonth
              data={getValues().data}
              heightLg={350}
              heightMd={300}
              heightSm={300}
              primaryColor={{
                border: 'rgb(89,80,236)',
                background: 'rgba(89,80,236, 0.04)',
              }}
            />
          </CardContent>
          <Grid container>
            <Grid item xs={6}>
              <Typography align={'center'} component={'div'} gutterBottom>
                <Trans i18nKey={'thisYear'}>This year</Trans>
                <Typography variant={'h6'}>{getValues().year}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align={'center'} component={'div'} gutterBottom>
                <Trans i18nKey={'thisMonth'}>This month</Trans>
                <Typography variant={'h6'}>{getValues().month}</Typography>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};
export default DirectCharts;
