import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import { ClickAwayListener } from '@mui/material';
import Map from '../../../../../components/GoogleMap/Map';
import { useSelector } from 'react-redux';
import useLoadGoogleMapApi from '../../../../../components/GoogleMap/useLoadGoogleMapApi';
import { shippingAddressSelectedSelector } from '../../store/selectors';
import RoomIcon from '@mui/icons-material/Room';
import { shopService } from '../../store/services';
import MapInfoWindow from '../../../../../components/GoogleMap/MapInfoWindow';
import PickupPointMarker from './PickupPointMarker';
import PickupPointInfoWindow from './PickupPointInfoWindow';
import List from '@mui/material/List';
import ExploreIcon from '@mui/icons-material/Explore';
import ExploreOffIcon from '@mui/icons-material/ExploreOff';
import DialogContent from '@mui/material/DialogContent';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        height: '80vh',
        '& .MuiListItem-root': {
          borderRight: '1px solid #DDD',
        },
        '& .MuiDivider-root': {
          margin: -1,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiCard-root': {
          overflow: 'auto',
        },
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden',
      },
      '& h4': {
        marginBottom: 20,
      },
    },
    '& #google-map': {
      height: 'calc(80vh - 160px) !important',
      [theme.breakpoints.down('sm')]: {
        height: 'calc(100vh - 180px) !important',
      },
    },
    '& #listItems': {
      '& li.MuiListItem-container': {
        '&:hover': {
          background: grey[100],
          cursor: 'pointer',
        },
        padding: theme.spacing(1),
      },
      overflow: 'auto',
      height: 'calc(80vh - 160px) !important',
      '& .MuiListItemSecondaryAction-root': {
        top: 'auto',
        right: 'auto',
        position: 'inherit',
        transform: 'inherit',
      },
      [theme.breakpoints.down('sm')]: {
        height: 'auto !important',
        '& .MuiListItemAvatar-root': {
          display: 'none',
        },
      },
      '& .openingHours': {
        display: 'flex',
      },
      '& .day': {
        fontSize: 14,
        fontWeight: 'bold',
      },
      '& .hour': {
        fontSize: 14,
      },
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const SelectPickupPointModal = ({ modalOpen, onSelect, setModalOpen }) => {
  useLoadGoogleMapApi();
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [locations, setLocations] = useState([]);
  const [locationsSelected, setLocationsSelected] = useState({});
  const [mapView, setMapView] = useState(true);
  const [markerSelected, setMarkerSelected] = useState(false);
  const shippingAddress = useSelector(shippingAddressSelectedSelector);
  const zoom = 14;
  const coordinates = {
    lat: shippingAddress.latitude,
    lng: shippingAddress.longitude,
  };

  const mapOptions = {
    fullscreenControl: false,
    mapTypeControl: false,
    panControl: false,
    streetViewControl: false,
    zoomControl: 'true',
    gestureHandling: 'greedy',
  };

  useEffect(() => {
    shopService.getPickupPoints(shippingAddress.id).then((locations) => {
      setLocations(locations);
    });
  }, []);

  useEffect(() => {
    setMapView(!fullScreen);
  }, [fullScreen]);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleViewChange = () => {
    setMapView(!mapView);
  };

  const handleSeeDetails = (location) => {
    return () => {
      setLocationsSelected({
        ...locationsSelected,
        [location.id]: !locationsSelected[location.id],
      });
    };
  };

  const handleSeeOnMap = (location) => {
    return () => {
      setMarkerSelected(location);
      setMapView(true);
    };
  };

  const handleClickAway = () => {
    setMarkerSelected(false);
  };

  const handleSelect = (location) => {
    return () => {
      onSelect(location);
    };
  };

  if (!shippingAddress.latitude || !shippingAddress.longitude) {
    setModalOpen(false);
    return null;
  }

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      maxWidth={mapView ? 'xl' : 'sm'}
      onClose={handleClose}
      open={modalOpen}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        <Trans i18nKey={'pickupPoint'}>Pickup point</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid
              alignItems={'center'}
              container
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item>
                <Typography>
                  <Trans i18nKey={'choosePickupPoint'}>
                    Choose your pickup point
                  </Trans>
                </Typography>
              </Grid>
              <Grid item>
                {!mapView && (
                  <Button
                    color={'primary'}
                    onClick={handleViewChange}
                    startIcon={<ExploreIcon />}
                    variant={'outlined'}
                  >
                    <Trans i18nKey={'viewOnMap'}>View on map</Trans>
                  </Button>
                )}
                {mapView && (
                  <Button
                    color={'primary'}
                    onClick={handleViewChange}
                    startIcon={<ExploreOffIcon />}
                    variant={'outlined'}
                  >
                    <Trans i18nKey={'viewInList'}>View in list</Trans>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {mapView && (
              <Typography component={'div'}>
                <Map
                  coordinates={coordinates}
                  onDragEnd={() => {}}
                  onZoomChanged={() => {}}
                  options={mapOptions}
                  zoom={zoom}
                >
                  <ClickAwayListener onClickAway={handleClickAway}>
                    <div>
                      <PickupPointInfoWindow
                        onSelect={onSelect}
                        pickupPoint={markerSelected}
                      />
                      {locations.map((location, index) => (
                        <MapInfoWindow
                          disableAutoPan
                          key={index}
                          position={{
                            lat: location.latitude,
                            lng: location.longitude,
                          }}
                          zIndex={0}
                        >
                          <PickupPointMarker
                            markerSelected={markerSelected}
                            pickupPoint={location}
                            setMarkerSelected={setMarkerSelected}
                          />
                        </MapInfoWindow>
                      ))}
                    </div>
                  </ClickAwayListener>
                </Map>
              </Typography>
            )}
            {!mapView && (
              <Typography component={'div'}>
                <List id={'listItems'}>
                  {locations.map((location, index) => (
                    <ListItem
                      disableGutters
                      key={index}
                      onClick={handleSeeDetails(location)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <RoomIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={location.name}
                        secondary={`${
                          location.country === 'BE'
                            ? `${location.street} ${location.number}`
                            : `${location.number} ${location.street}`
                        } ${location.zip} ${location.city}`}
                      />
                      <ListItemSecondaryAction>
                        {locationsSelected[location.id] && (
                          <>
                            <Typography>{t('openingTime')} :</Typography>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {location.hours.map((day, key) => (
                                  <Grid
                                    className={'openingHours'}
                                    container
                                    key={`openingHours${key}`}
                                  >
                                    <Grid item xs={4}>
                                      <Typography
                                        className={'day'}
                                        component={'span'}
                                      >
                                        {t(day.title)}
                                      </Typography>
                                    </Grid>
                                    {day.AMOpen &&
                                      day.PMClose &&
                                      !day.AMClose &&
                                      !day.PMOpen && (
                                        <Grid item xs={4}>
                                          <Typography
                                            className={'hour'}
                                            component={'span'}
                                          >
                                            {day.AMOpen} - {day.PMClose}
                                          </Typography>
                                        </Grid>
                                      )}
                                    {day.AMOpen && day.AMClose && (
                                      <Grid item xs={4}>
                                        <Typography
                                          className={'hour'}
                                          component={'span'}
                                        >
                                          {day.AMOpen} - {day.AMClose}
                                        </Typography>
                                      </Grid>
                                    )}
                                    {day.PMOpen && day.PMClose && (
                                      <Grid item xs={4}>
                                        <Typography
                                          className={'hour'}
                                          component={'span'}
                                        >
                                          {day.PMOpen} - {day.PMClose}
                                        </Typography>
                                      </Grid>
                                    )}
                                  </Grid>
                                ))}
                              </Grid>
                              {(location.closedFrom || location.closedTo) && (
                                <Grid item xs={12}>
                                  <Typography color={'error'}>
                                    {
                                      (t('closingFromTo'),
                                      {
                                        closedFrom: location.closedFrom,
                                        closedTo: location.closedTo,
                                      })
                                    }
                                  </Typography>
                                </Grid>
                              )}
                              {!fullScreen && (
                                <Grid item>
                                  <Button
                                    color={'primary'}
                                    fullWidth
                                    onClick={handleSeeOnMap(location)}
                                    startIcon={<ExploreIcon />}
                                    variant={'outlined'}
                                  >
                                    <Trans i18nKey={'viewOnMap'}>
                                      View on map
                                    </Trans>
                                  </Button>
                                </Grid>
                              )}
                              <Grid item>
                                <Button
                                  color={'primary'}
                                  fullWidth
                                  onClick={handleSelect(location)}
                                  variant={'contained'}
                                >
                                  <Trans i18nKey={'select'}>Select</Trans>
                                </Button>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default SelectPickupPointModal;
