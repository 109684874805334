import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Trans, useTranslation } from 'react-i18next';
import BeenhereOutlined from '@mui/icons-material/BeenhereOutlined';
import PhoneIphoneOutlined from '@mui/icons-material/PhoneIphoneOutlined';
import EmailOutlined from '@mui/icons-material/EmailOutlined';
import Breadcrumb from '../../components/Layout/Customer/Breadcrumb';
import FormValidator from '../../components/Validators/FormValidator';
import TextFieldValidator from '../../components/Validators/TextFieldValidator';
import { authenticationService } from '../Authentication/store/services';
import {
  SnackbarFormattedError,
  SnackbarSuccess,
} from '../../helpers/snackbar';
import { history } from '../../helpers/store';
import { useDispatch, useSelector } from 'react-redux';
import {
  contactSelector,
  userSelector,
} from '../Authentication/store/selectors';
import { authenticationActions } from '../Authentication/store/actions';
import { sessionSelector } from './Shop/store/selectors';
import SelectValidator from '../../components/Validators/SelectValidator';
import Header from '../../components/Layout/Customer/Header/Header';
import { useCustomerPath } from '../../helpers/usePath';
import { useSEO } from '../../helpers/useSEO';
import { Helmet } from 'react-helmet-async';

const useStylesContact = makeStyles((theme) => ({
  container: {
    paddingTop: 100,
    paddingBottom: 40,
    '& .container': {
      '& p': {
        fontSize: 18,
      },
      '& img': {
        maxWidth: '100%',
        borderRadius: '10px',
      },

      '& .MuiButton-root': {
        borderRadius: 8,
        fontSize: 16,
        height: 46,
        padding: '8px 20px',
        margin: theme.spacing(3, 0, 2),
        '& .MuiSvgIcon-root': {
          margin: '0px 5px 0px 0px !important',
          fontSize: '1.1rem',
        },
      },
    },
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: 0,
    },
  },
  form: {
    marginBottom: 30,
  },
  phoneAndAddress: {
    '& > .MuiGrid-container': {},
    '& .MuiSvgIcon-root': {
      fontSize: 60,
      border: '2px solid',
      borderRadius: '50%',
      padding: 10,
    },
    '& a': {
      textDecoration: 'inherit',
      color: 'inherit',
      fontSize: 18,
    },
  },
}));

const Contact = (props) => {
  const path = useCustomerPath();
  const classes = useStylesContact();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const session = useSelector(sessionSelector);
  const contact = useSelector(contactSelector);
  const [defaultContact, setDefaultContact] = useState({});
  const [firstName, setFirstName] = useState((user && user.firstName) || '');
  const [lastName, setLastName] = useState((user && user.lastName) || '');
  const [phone, setPhone] = useState((user && user.phone) || '');
  const [email, setEmail] = useState((user && user.email) || '');
  const [target, setTarget] = useState('sharks');
  const [message, setMessage] = useState('');
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/contact', {
    fr: '/contact',
    nl: '/contact',
    en: '/contact',
  });

  useEffect(() => {
    if (props.match.params.id || session?.parentId) {
      dispatch(
        authenticationActions.getUserContactRequest(
          props.match.params.id || session?.parentId,
        ),
      );
    }
  }, []);

  useEffect(() => {
    switch (i18n.language) {
      default:
        setDefaultContact({
          email: 'info@sharks.com',
          phone: '+32 486 522 600',
        });
    }
  }, [i18n.language]);

  const handleSubmit = () => {
    authenticationService
      .sendContactMessage({
        targetId: target === 'consultant' ? contact?.id : null,
        firstName,
        lastName,
        phone,
        email,
        message,
      })
      .then(() => {
        SnackbarSuccess(t('messageSent'));
        history.push(`${path}`);
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t('contactTitle')}</title>
        <meta content={t('contactMetaDescription')} name="description" />
        <meta content={t('contactTitle')} property="og:title" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Header />
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Typography color={'secondary'} component="h1" variant="h4">
            <Trans i18nKey="contactUsTitle">Contact us</Trans>
          </Typography>
          <Breadcrumb
            parents={[{ name: <Trans i18nKey="contactTitle">Contact</Trans> }]}
          />
        </div>
        <Grid
          alignItems={'center'}
          className={'container'}
          container
          justifyContent={'center'}
        >
          <Grid item lg={10} xs={12}>
            <Grid alignItems={'flex-start'} container justifyContent={'center'}>
              <Grid item md={6} sm={12} xl={6} xs={12}>
                <FormValidator
                  className={classes.form}
                  noValidate
                  onError={console.log}
                  onSubmit={handleSubmit}
                >
                  <Grid container spacing={2}>
                    {!user && (
                      <>
                        <Grid item sm={12} xs={12}>
                          <TextFieldValidator
                            autoFocus
                            fullWidth
                            id="firstName"
                            label={t('firstName')}
                            name="firstName"
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                            value={firstName}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <TextFieldValidator
                            fullWidth
                            id="lastName"
                            label={t('lastName')}
                            name="lastName"
                            onChange={(e) => setLastName(e.target.value)}
                            required
                            value={lastName}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <TextFieldValidator
                            fullWidth
                            id="phone"
                            label={t('phone')}
                            name="phone"
                            onChange={(e) => setPhone(e.target.value)}
                            value={phone}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item sm={12} xs={12}>
                          <TextFieldValidator
                            autoComplete="email"
                            fullWidth
                            id="email"
                            label={t('email')}
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            type="email"
                            value={email}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                    {!!contact &&
                      contact.id !==
                        process.env.REACT_APP_Sharks_DIRECTOR_ID && (
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <SelectValidator
                            fullWidth
                            label={t('whoToContact')}
                            onChange={(e) => setTarget(e.target.value)}
                            required
                            value={target}
                          >
                            <MenuItem value={'sharks'}>
                              <Trans i18nKey={'theSharksTeam'}>
                                The Sharks team
                              </Trans>
                            </MenuItem>
                            <MenuItem value={'consultant'}>
                              <Trans i18nKey={'yourNutritionConsultant'}>
                                Your nutrition consultant
                              </Trans>
                            </MenuItem>
                          </SelectValidator>
                        </Grid>
                      )}
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <TextFieldValidator
                        fullWidth
                        id="message"
                        label={t('message')}
                        multiline
                        name="message"
                        onChange={(e) => setMessage(e.target.value)}
                        required
                        rows={8}
                        value={message}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Button
                    color={'secondary'}
                    disableElevation
                    onClick={() => {}}
                    type="submit"
                    variant="contained"
                  >
                    <Trans i18nKey="contactSendMessageButton">
                      Send the message
                    </Trans>
                  </Button>
                </FormValidator>
              </Grid>
              <Grid
                className={classes.phoneAndAddress}
                item
                md={6}
                sm={12}
                xl={6}
                xs={12}
              >
                <Grid container justifyContent={'center'} spacing={4}>
                  <Grid item md={8} sm={6} xs={12}>
                    <Grid
                      alignItems={'center'}
                      container
                      justifyContent={'center'}
                    >
                      <Grid item md={3} sm={3} xs={4}>
                        <Grid
                          alignItems={'center'}
                          container
                          justifyContent={'center'}
                        >
                          <PhoneIphoneOutlined />
                        </Grid>
                      </Grid>
                      <Grid item md={9} sm={9} xs={6}>
                        <Grid container direction={'column'}>
                          <Typography component={'h3'} variant={'h6'}>
                            <Trans i18nKey={'phone'}>Phone</Trans>
                          </Typography>
                          <Typography
                            component={'a'}
                            href={`tel:${contact ? contact?.phone : ''}`}
                            variant={'body1'}
                          >
                            {target === 'consultant'
                              ? contact?.phone
                              : defaultContact.phone}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8} sm={6} xs={12}>
                    <Grid
                      alignItems={'center'}
                      container
                      justifyContent={'center'}
                    >
                      <Grid item md={3} sm={3} xs={4}>
                        <Grid
                          alignItems={'center'}
                          container
                          justifyContent={'center'}
                        >
                          <EmailOutlined />
                        </Grid>
                      </Grid>
                      <Grid item md={9} sm={9} xs={6}>
                        <Grid container direction={'column'}>
                          <Typography component={'h3'} variant={'h6'}>
                            <Trans i18nKey={'email'}>Email</Trans>
                          </Typography>
                          <Typography
                            component={'a'}
                            href={`tel:${
                              contact
                                ? contact?.email
                                : 'mailto:info@sharks.com'
                            }`}
                            variant={'body1'}
                          >
                            {target === 'consultant'
                              ? contact?.email
                              : defaultContact.email}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8} sm={6} xs={12}>
                    <Grid
                      alignItems={'center'}
                      container
                      justifyContent={'center'}
                    >
                      <Grid item md={3} sm={3} xs={4}>
                        <Grid
                          alignItems={'center'}
                          container
                          justifyContent={'center'}
                        >
                          <BeenhereOutlined />
                        </Grid>
                      </Grid>
                      <Grid item md={9} sm={9} xs={6}>
                        <Grid container direction={'column'}>
                          <Typography component={'h3'} variant={'h6'}>
                            {target === 'consultant' &&
                              !!contact?.company &&
                              `${contact?.company}`}
                            {target === 'consultant' &&
                              !!contact?.firstName &&
                              !!contact?.lastName &&
                              `${contact?.firstName} ${contact?.lastName}`}
                            {target === 'sharks' && `Sharks`}
                          </Typography>
                          <Typography component={'p'} variant={'body1'}>
                            {target === 'consultant' &&
                              `${contact?.zip} ${contact?.city}, ${contact?.country}`}
                            {target === 'sharks' &&
                              `28 chemin de la lecque, \n 13122 Ventabren, France`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Contact;
