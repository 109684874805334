import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  shippingAddressSelectedSelector,
  shippingMethodSelectedSelector,
  shippingMethodsSelector,
  weightFromCartSelector,
  isFreeShippingSelector,
  shippingPickupPointSelectedSelector,
  shippingTaxesRateSelector,
} from '../../store/selectors';
import { shopActions } from '../../store/actions';
import SelectPickupPointModal from './SelectPickupPointModal';
import { red } from '@mui/material/colors';
import PageLoader from '../../../../../components/PageLoader';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& label': {
      width: '100%',
      border: '1px solid #c4c4c4',
      margin: theme.spacing(0.8, 0),
      borderRadius: '5px',
      marginLeft: '0px',
      padding: '5px 0',
      '& .MuiFormControlLabel-label': {
        marginRight: 10,
        '& img': {
          maxHeight: 40,
          maxWidth: 100,
        },
      },
    },
  },
  methodImg: {
    width: 80,
    textAlign: 'center',
  },
  methodDetails: {
    '& .MuiTypography-body1': {
      lineHeight: '18px',
      minWidth: 200,
    },
  },
  pickupPoint: {
    lineHeight: '23px !important',
    fontWeight: '500',
  },
  pickupPointSelected: {
    lineHeight: '23px !important',
    color: red[500],
    fontWeight: '500',
  },
}));

const SelectShippingMethod = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [pickupPointModalOpen, setPickupPointModalOpen] = useState(false);
  const [pickupPointIdSelection, setPickupPointIdSelection] = useState(null);
  const methodSelected = useSelector(shippingMethodSelectedSelector);
  const isFreeShipping = useSelector(isFreeShippingSelector);
  const shippingAddress = useSelector(shippingAddressSelectedSelector);
  const shippingPickupPoint = useSelector(shippingPickupPointSelectedSelector);
  const shippingMethods = useSelector(shippingMethodsSelector);
  const totalWeight = useSelector(weightFromCartSelector);
  const shippingTaxesRate = useSelector(shippingTaxesRateSelector);

  useEffect(() => {
    if (shippingAddress) {
      dispatch(
        shopActions.getShippingMethodsRequest(
          shippingAddress.country,
          totalWeight,
        ),
      );
    }
  }, [totalWeight, shippingAddress]);

  useEffect(() => {
    if (!methodSelected && shippingMethods) {
      dispatch(
        shopActions.selectShippingMethod(
          shippingMethods.find((shippingMethod) => !shippingMethod.pickupPoint),
        ),
      );
    }
  }, [shippingMethods, shippingAddress]);

  const handleRadioChange = (e) => {
    const shippingMethod = shippingMethods.find(
      (method) => method.id === parseInt(e.target.value),
    );
    if (shippingMethod.pickupPoint) {
      setPickupPointIdSelection(shippingMethod);
      setPickupPointModalOpen(true);
    } else {
      dispatch(shopActions.selectShippingMethod(shippingMethod));
    }
  };

  const handleSelectPickupPoint = (location) => {
    dispatch(
      shopActions.selectShippingPickupPointMethod(
        pickupPointIdSelection,
        location,
      ),
    );
    setPickupPointIdSelection(null);
    setPickupPointModalOpen(false);
  };

  if (shippingMethods.length === 0) {
    return (
      <Grid container justifyContent={'center'} spacing={4}>
        <PageLoader height={150} />
      </Grid>
    );
  }

  return (
    <>
      <form className={classes.root}>
        <FormControl component="fieldset">
          <RadioGroup
            name="shippingMethod"
            onChange={handleRadioChange}
            value={parseInt(methodSelected?.id)}
          >
            {shippingMethods.map((shippingMethod) => (
              <FormControlLabel
                control={<Radio />}
                key={shippingMethod.id}
                label={
                  <Grid alignItems={'center'} container spacing={2}>
                    {shippingMethod.image && (
                      <Grid className={classes.methodImg} item>
                        <Grid alignItems={'center'} container>
                          <img alt="" src={shippingMethod.image} />
                        </Grid>
                      </Grid>
                    )}
                    <Grid className={classes.methodDetails} item>
                      {shippingMethod.pickupPoint === 1 && (
                        <Typography className={classes.pickupPoint}>
                          <Trans i18nKey={'pickupPoint'}>Pickup point</Trans>
                        </Typography>
                      )}
                      <Typography>
                        <b>
                          {`${
                            (isFreeShipping && shippingMethod.allowFree) ||
                            shippingMethod.price === 0
                              ? t('free')
                              : `${(
                                  shippingMethod.price *
                                  (1 + shippingTaxesRate / 100)
                                ).toFixed(2)} ${shippingMethod.currency}`
                          } - ${t(shippingMethod.name)}`}
                        </b>
                      </Typography>
                      {shippingMethod.name === 'withoutDelivery' && (
                        <Typography>
                          {t('collectTheOrderAtTheWarehouse')}
                        </Typography>
                      )}
                      {shippingMethod.deliveryDays !== '0' && (
                        <Typography>
                          <Trans
                            i18nKey="workingDays"
                            values={{ days: shippingMethod.deliveryDays }}
                          >
                            {shippingMethod.deliveryDays} working days
                          </Trans>
                        </Typography>
                      )}
                      {shippingPickupPoint &&
                        parseInt(methodSelected?.id) === shippingMethod.id && (
                          <Typography className={classes.pickupPointSelected}>
                            {shippingPickupPoint.name}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>
                }
                value={shippingMethod.id}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </form>
      {pickupPointModalOpen && (
        <SelectPickupPointModal
          modalOpen={pickupPointModalOpen}
          onSelect={handleSelectPickupPoint}
          setModalOpen={setPickupPointModalOpen}
        />
      )}
    </>
  );
};
export default SelectShippingMethod;
