import React from 'react';
import classnames from 'classnames';
import { Link } from '../../../helpers/multilingual';
import { Trans } from 'react-i18next';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles(() => ({
  darkStyle: {
    borderColor: '#fff',
    color: '#fff',
  },
  lightStyle: {
    borderColor: '#000',
    color: '#000',
  },
  button: {
    padding: '20px 30px',
    fontSize: 20,
    textDecoration: 'none',
    borderRadius: 10,
  },
  headerStyle: {
    color: '#000',
    padding: '8px 24px',
    marginRight: 10,
    fontSize: '0.875rem',
    borderRadius: 25,
    border: '1px solid #000',
    background: '#fff',
    height: 40,
    '&:hover': {
      border: '1px solid ' + grey[900],
      background: '#fff',
      boxShadow: '0 2px 2px 1px rgba(150, 150, 150, .3)',
    },
  },
}));

const ContactButton = ({ dark, header }) => {
  const classes = useStyles();

  if (header) {
    return (
      <Button
        className={classnames(
          classes.button,
          classes.headerStyle,
          dark ? classes.darkStyle : classes.lightStyle,
        )}
        component={Link}
        to={'/contact'}
        variant="outlined"
      >
        {'>'}
        <Trans i18nKey={'beContactedButton'}>Be contacted</Trans>
      </Button>
    );
  }
  return (
    <>
      <Typography component={'p'}>
        <Trans i18nKey={'beContactedButtonDescription'}>
          If you would like to be contacted by one of our experts, please click
          here :
        </Trans>
      </Typography>
      <Button component={Link} to={'/contact'} variant={'outlined'}>
        <Trans i18nKey={'beContactedByExpertButton'}>
          Be contacted by one of our experts
        </Trans>
      </Button>
    </>
  );
};
export default ContactButton;
