import React from 'react';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import Box from '@mui/material/Box';

const HeaderMenu = ({ logo, transparent }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  return (
    <Box component={'nav'} sx={{ pt: 2 }}>
      <DesktopMenu
        isMobileOpen={mobileOpen}
        logo={logo}
        setMobileOpen={setMobileOpen}
        transparent={transparent}
      />
      <MobileMenu isOpen={mobileOpen} setOpen={setMobileOpen} />
    </Box>
  );
};

export default HeaderMenu;
