import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const SettingsIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="cls-1"
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        className="cls-2"
        d="M21.25,18.8c-.41-.11-.7-.17-1-.27a1,1,0,0,0-1,.18,1.29,1.29,0,0,0-.68,1.43c.11.64-.21,1-.79,1.11a3.2,3.2,0,0,0-.55.17.64.64,0,0,1-.93-.27,1.3,1.3,0,0,0-.15-.18,1.59,1.59,0,0,0-1.88-.6,1.81,1.81,0,0,0-.69.73.77.77,0,0,1-1.09.4,3.58,3.58,0,0,0-.56-.16c-.52-.13-.8-.41-.7-1,.15-.91-.46-1.35-1.13-1.66a1.36,1.36,0,0,0-.92.16,1,1,0,0,1-1.38-.48c-.29-.47-.85-.9-.21-1.49s.4-1.33.07-2c-.12-.23-.53-.3-.81-.46s-.73-.27-.77-.46a4.05,4.05,0,0,1,0-1.7c.06-.23.6-.34.93-.48a1.42,1.42,0,0,0,.63-2c-.67-1-.65-1,0-2a.86.86,0,0,1,1.15-.41c.42.14.83.36,1.34,0a1.43,1.43,0,0,0,.72-1.48.85.85,0,0,1,.75-1.08,2.43,2.43,0,0,0,.38-.13c.53-.24,1-.2,1.26.37.41.86,1.18.74,1.87.62.27,0,.53-.42.7-.7a.84.84,0,0,1,1.2-.4,3.42,3.42,0,0,0,.54.18c.48.09.72.36.63.85a1.55,1.55,0,0,0,1.05,1.71,1.19,1.19,0,0,0,.65,0c.39-.07.9-.37,1.13-.22a4.72,4.72,0,0,1,1.06,1.27c.07.09,0,.29,0,.42a.94.94,0,0,1-.25.39c-.7.65-.35,1.35,0,2,.11.23.54.29.82.44s.71.25.74.43a4.73,4.73,0,0,1,0,1.76c0,.2-.57.31-.89.43a1.41,1.41,0,0,0-.65,2c.69,1,.7,1-.08,2A6.36,6.36,0,0,1,21.25,18.8ZM10,13a4.77,4.77,0,1,0,9.53,0A4.77,4.77,0,1,0,10,13Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
export default SettingsIcon;
