import React, { useRef, useEffect, useState } from 'react';
import useIntersectionObserver from './useIntersectionObserver';
import CardMedia from '@mui/material/CardMedia';

const _loaded = {};

const LazyLoadCardMedia = ({
  alt,
  className,
  component,
  height,
  image,
  skeletonSrc,
  title,
  width,
  ...rest
}) => {
  const elementRef = useRef(null);
  const [inView] = useIntersectionObserver(elementRef);
  const [loaded, setLoaded] = useState(_loaded[image]);

  useEffect(() => {
    if (inView) {
      if (_loaded[image]) {
        setLoaded(true);

        return;
      }

      const img = new Image();
      img.src = image;
      img.onload = () => {
        _loaded[image] = true;
        setLoaded(true);
      };
    }
  }, [inView]);

  return (
    <div className={className ? `${className}-wrapper` : null} ref={elementRef}>
      {!loaded ? (
        <CardMedia
          {...rest}
          alt={alt}
          component={component}
          height={height}
          image={skeletonSrc}
          title={title}
          width={width}
        />
      ) : (
        <CardMedia
          {...rest}
          alt={alt}
          component={component}
          height={height}
          image={image}
          title={title}
          width={width}
        />
      )}
    </div>
  );
};

export default LazyLoadCardMedia;
