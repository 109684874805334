import { createSelector } from 'reselect';
import { initialState, statsReducerKey } from './reducer';
import {
  differenceInDays,
  getDate,
  getDaysInMonth,
  getMonth,
  getYear,
} from 'date-fns';
import { providers } from '../../../../helpers/constants';

const statsSelector = (state) => state[statsReducerKey] || initialState;

export const turnoverLinesGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.turnoverByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.turnoverByMonth).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.turnoverByMonth[year][month]
              ? stats.turnoverByMonth[year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const recurringTurnoverLinesGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.recurringTurnoverByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.recurringTurnoverByMonth).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.recurringTurnoverByMonth[year][month]
              ? stats.recurringTurnoverByMonth[year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const recurringTurnoverDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.recurringTurnoverByMonth) {
      return {};
    }
    let year = 0;
    let month = 0;
    const currentYear = getYear(new Date());
    if (stats.recurringTurnoverByMonth[currentYear]) {
      stats.recurringTurnoverByMonth[currentYear].map((amount, key) => {
        year += amount;
        if (getMonth(new Date()) === key) {
          month += amount;
        }
      });
    }
    return { year, month };
  });

export const averageOrderAmountLinesGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.turnoverByMonth || !stats.ordersCountByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.turnoverByMonth).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.turnoverByMonth[year][month] &&
              stats.ordersCountByMonth[year][month]
              ? Math.round(
                  (stats.turnoverByMonth[year][month] /
                    stats.ordersCountByMonth[year][month]) *
                    100,
                ) / 100
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const averageTurnoverByActiveProsAmountLinesGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.turnoverByMonth || !stats.activeProsCountByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.turnoverByMonth).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.turnoverByMonth[year][month] &&
              stats.activeProsCountByMonth[year][month]
              ? Math.round(
                  (stats.turnoverByMonth[year][month] /
                    stats.activeProsCountByMonth[year][month]) *
                    100,
                ) / 100
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const averageTurnoverByActiveProsAmountSelector = () =>
  createSelector([statsSelector], (stats) => {
    let averageAmountByYear = {};
    if (!stats.turnoverByMonth || !stats.activeProsCountByMonth) {
      return averageAmountByYear;
    }
    let activeMonths = {};
    Object.keys(stats.turnoverByMonth).map((year) => {
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
        if (
          differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
        ) {
          activeMonths[year] = activeMonths[year] ? activeMonths[year] : 0;
          averageAmountByYear[year] = averageAmountByYear[year]
            ? averageAmountByYear[year]
            : 0;
          if (
            stats.turnoverByMonth[year][month] &&
            stats.activeProsCountByMonth[year][month]
          ) {
            if (
              parseInt(year) === getYear(new Date()) &&
              month === getMonth(new Date())
            ) {
              activeMonths[year] +=
                getDate(new Date()) / getDaysInMonth(new Date());
            } else {
              activeMonths[year] += 1;
            }
            averageAmountByYear[year] +=
              Math.round(
                (stats.turnoverByMonth[year][month] /
                  stats.activeProsCountByMonth[year][month]) *
                  100,
              ) / 100;
          }
        }
      });
    });
    Object.keys(averageAmountByYear).map((year) => {
      averageAmountByYear[year] =
        averageAmountByYear[year] / activeMonths[year];
    });
    return averageAmountByYear;
  });

export const networkAverageTurnoverByActiveProsAmountLinesGraphDataSelector = (
  userId,
) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.networkTurnoverByMonth[userId] ||
      !stats.networkActiveProsCountByMonth[userId]
    ) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkTurnoverByMonth[userId]).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.networkTurnoverByMonth[userId][year][month] &&
              stats.networkActiveProsCountByMonth[userId][year][month]
              ? Math.round(
                  (stats.networkTurnoverByMonth[userId][year][month] /
                    stats.networkActiveProsCountByMonth[userId][year][month]) *
                    100,
                ) / 100
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const networkAverageTurnoverByActiveProsAmountSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    let averageAmountByYear = {};
    if (
      !stats.networkTurnoverByMonth[userId] ||
      !stats.networkActiveProsCountByMonth[userId]
    ) {
      return averageAmountByYear;
    }
    let activeMonths = {};
    Object.keys(stats.networkTurnoverByMonth[userId]).map((year) => {
      [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
        if (
          differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
        ) {
          activeMonths[year] = activeMonths[year] ? activeMonths[year] : 0;
          averageAmountByYear[year] = averageAmountByYear[year]
            ? averageAmountByYear[year]
            : 0;
          if (
            stats.networkTurnoverByMonth[userId][year][month] &&
            stats.networkActiveProsCountByMonth[userId][year][month]
          ) {
            if (
              parseInt(year) === getYear(new Date()) &&
              month === getMonth(new Date())
            ) {
              activeMonths[year] +=
                getDate(new Date()) / getDaysInMonth(new Date());
            } else {
              activeMonths[year] += 1;
            }
            averageAmountByYear[year] +=
              Math.round(
                (stats.networkTurnoverByMonth[userId][year][month] /
                  stats.networkActiveProsCountByMonth[userId][year][month]) *
                  100,
              ) / 100;
          }
        }
      });
    });
    Object.keys(averageAmountByYear).map((year) => {
      averageAmountByYear[year] =
        averageAmountByYear[year] / activeMonths[year];
    });
    return averageAmountByYear;
  });

export const percentageActiveProsLinesGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.prosCountByMonth || !stats.activeProsCountByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.prosCountByMonth).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.prosCountByMonth[year][month] &&
              stats.activeProsCountByMonth[year][month]
              ? Math.round(
                  (stats.activeProsCountByMonth[year][month] /
                    stats.prosCountByMonth[year][month]) *
                    100,
                )
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const turnoverDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.turnoverByMonth) {
      return {};
    }
    let year = 0;
    let month = 0;
    const currentYear = getYear(new Date());
    if (stats.turnoverByMonth[currentYear]) {
      stats.turnoverByMonth[currentYear].map((amount, key) => {
        year += amount;
        if (getMonth(new Date()) === key) {
          month += amount;
        }
      });
    }
    return { year, month };
  });

export const averageOrderAmountDataSelector = () =>
  createSelector(
    [statsSelector, turnoverDataSelector()],
    (stats, turnoverData) => {
      if (
        !turnoverData.year ||
        !turnoverData.month ||
        !stats.ordersCountByMonth
      ) {
        return {};
      }
      let year = 0;
      let month = 0;
      const currentYear = getYear(new Date());
      if (stats.ordersCountByMonth[currentYear]) {
        stats.ordersCountByMonth[currentYear].map((amount, key) => {
          year += amount;
          if (getMonth(new Date()) === key) {
            month += amount;
          }
        });
      }
      return {
        year: turnoverData.year / year,
        month: turnoverData.month / month,
      };
    },
  );

export const recruitmentLinesGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.recruitmentByMonth) {
      return null;
    }
    let data = [];
    Object.keys(stats.recruitmentByMonth).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.recruitmentByMonth[year][month]
              ? stats.recruitmentByMonth[year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const packsLinesGraphDataSelector = (year) =>
  createSelector([statsSelector], (stats) => {
    if (!stats.packsAmountByMonth) {
      return null;
    }
    let packsAmountByMonth = {};
    stats.packsAmountByMonth.map((pack) => {
      packsAmountByMonth[pack.name] = packsAmountByMonth[pack.name]
        ? { ...packsAmountByMonth[pack.name], [pack.month]: pack.amount }
        : { [pack.month]: pack.amount };
    });
    let data = [];
    Object.keys(packsAmountByMonth).map((name) => {
      data.push({
        label: name,
        data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
          if (
            getYear(new Date()) !== parseInt(year) ||
            differenceInDays(
              new Date(),
              new Date(getYear(new Date()), month, 1),
            ) > 0
          ) {
            return packsAmountByMonth[name][month]
              ? packsAmountByMonth[name][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const packsBarGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.packsAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.packsAmount.map((pack) => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return { labels, data };
  });

export const productsBarGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.productsAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.productsAmount.map((product) => {
      labels.push(product.name);
      data.push(product.amount);
    });
    return { labels, data };
  });

export const productsAmountSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.productsAmount) {
      return { products: [], totalByProviders: {} };
    }
    const products = stats.productsAmount.map((product) => ({
      amount: parseInt(product.amount),
      purchasingPriceFormatted: parseFloat(product.purchasingPrice)
        .toFixed(2)
        .replace('.', ','),
      totalPurchasingPriceFormatted: (
        parseFloat(product.purchasingPrice) * parseInt(product.amount)
      )
        .toFixed(2)
        .replace('.', ','),
      salePriceFormatted: parseFloat(product.price)
        .toFixed(2)
        .replace('.', ','),
      ...product,
    }));
    const totalByProviders = {};
    providers.forEach((provider) => {
      totalByProviders[provider.value] = 0;
      products.forEach((product) => {
        if (product.provider === provider.value) {
          totalByProviders[provider.value] +=
            parseFloat(product.purchasingPrice) * parseInt(product.amount);
        }
      });
    });
    return { products, totalByProviders };
  });

export const turnoverByCareerDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.turnoverByCareer) {
      return [];
    }
    return stats.turnoverByCareer;
  });

export const turnoverByCareerGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.turnoverByCareer) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.turnoverByCareer.map((career) => {
      labels.push(`${career.name}`);
      data.push(career.amount);
    });
    return { labels, data };
  });

export const bestSellersBarGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.bestSellersAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.bestSellersAmount.map((user) => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return { labels, data };
  });

export const bestRecruitersBarGraphDataSelector = () =>
  createSelector([statsSelector], (stats) => {
    if (!stats.bestRecruitersAmount) {
      return {};
    }
    let labels = [];
    let data = [];
    stats.bestRecruitersAmount.map((user) => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return { labels, data };
  });

export const directSalesLinesGraphDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    if (!stats.directTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directTurnoverByMonth[userId]).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.directTurnoverByMonth[userId][year][month]
              ? stats.directTurnoverByMonth[userId][year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const directRecurringSalesLinesGraphDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    if (!stats.directRecurringTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directRecurringTurnoverByMonth[userId]).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.directRecurringTurnoverByMonth[userId][year][month]
              ? stats.directRecurringTurnoverByMonth[userId][year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const directTurnoverDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    let year = 0;
    let month = 0;
    if (stats.directTurnoverByMonth[userId]) {
      const currentYear = getYear(new Date());
      if (stats.directTurnoverByMonth[userId][currentYear]) {
        stats.directTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if (getMonth(new Date()) === key) {
            month += amount;
          }
        });
      }
    }
    return { year, month };
  });

export const directRecurringTurnoverDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    let year = 0;
    let month = 0;
    if (stats.directRecurringTurnoverByMonth[userId]) {
      const currentYear = getYear(new Date());
      if (stats.directRecurringTurnoverByMonth[userId][currentYear]) {
        stats.directRecurringTurnoverByMonth[userId][currentYear].map(
          (amount, key) => {
            year += amount;
            if (getMonth(new Date()) === key) {
              month += amount;
            }
          },
        );
      }
    }
    return { year, month };
  });

export const directRecruitmentDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    let year = 0;
    let month = 0;
    if (stats.directRecruitment[userId]) {
      const currentYear = getYear(new Date());
      if (stats.directRecruitment[userId][currentYear]) {
        stats.directRecruitment[userId][currentYear].map((amount, key) => {
          year += amount;
          if (getMonth(new Date()) === key) {
            month += amount;
          }
        });
      }
    }
    return { year, month };
  });

export const networkTurnoverDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    let year = 0;
    let month = 0;
    if (stats.networkTurnoverByMonth[userId]) {
      const currentYear = getYear(new Date());
      if (stats.networkTurnoverByMonth[userId][currentYear]) {
        stats.networkTurnoverByMonth[userId][currentYear].map((amount, key) => {
          year += amount;
          if (getMonth(new Date()) === key) {
            month += amount;
          }
        });
      }
    }
    return { year, month };
  });

export const networkRecurringTurnoverDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    let year = 0;
    let month = 0;
    if (stats.networkRecurringSales[userId]) {
      const currentYear = getYear(new Date());
      if (stats.networkRecurringSales[userId][currentYear]) {
        stats.networkRecurringSales[userId][currentYear].map((amount, key) => {
          year += amount;
          if (getMonth(new Date()) === key) {
            month += amount;
          }
        });
      }
    }
    return { year, month };
  });

export const networkRecruitmentDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    let year = 0;
    let month = 0;
    if (stats.networkRecruitment[userId]) {
      const currentYear = getYear(new Date());
      if (stats.networkRecruitment[userId][currentYear]) {
        stats.networkRecruitment[userId][currentYear].map((amount, key) => {
          year += amount;
          if (getMonth(new Date()) === key) {
            month += amount;
          }
        });
      }
    }
    return { year, month };
  });

export const networkSalesLinesGraphDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    if (!stats.networkTurnoverByMonth[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkTurnoverByMonth[userId]).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.networkTurnoverByMonth[userId][year][month]
              ? stats.networkTurnoverByMonth[userId][year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const networkRecurringSalesLinesGraphDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    if (!stats.networkRecurringSales[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkRecurringSales[userId]).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.networkRecurringSales[userId][year][month]
              ? stats.networkRecurringSales[userId][year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const directRecruitmentLinesGraphDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    if (!stats.directRecruitment[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.directRecruitment[userId]).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.directRecruitment[userId][year][month]
              ? stats.directRecruitment[userId][year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const networkRecruitmentLinesGraphDataSelector = (userId) =>
  createSelector([statsSelector], (stats) => {
    if (!stats.networkRecruitment[userId]) {
      return null;
    }
    let data = [];
    Object.keys(stats.networkRecruitment[userId]).map((year) => {
      data.push({
        label: year,
        data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((month) => {
          if (
            differenceInDays(new Date(), new Date(parseInt(year), month, 1)) > 0
          ) {
            return stats.networkRecruitment[userId][year][month]
              ? stats.networkRecruitment[userId][year][month]
              : 0;
          }
        }),
      });
    });
    return data;
  });

export const directBestSellersBarGraphDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.directBestSellersAmount[userId] ||
      !stats.directBestSellersAmount[userId][year] ||
      !stats.directBestSellersAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directBestSellersAmount[userId][year][month].map((user) => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return { labels, data };
  });

export const directBestRecruitersBarGraphDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.directBestRecruitersAmount[userId] ||
      !stats.directBestRecruitersAmount[userId][year] ||
      !stats.directBestRecruitersAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directBestRecruitersAmount[userId][year][month].map((user) => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return { labels, data };
  });

export const networkBestSellersBarGraphDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.networkBestSellersAmount[userId] ||
      !stats.networkBestSellersAmount[userId][year] ||
      !stats.networkBestSellersAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkBestSellersAmount[userId][year][month].map((user) => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return { labels, data };
  });

export const networkBestRecruitersBarGraphDataSelector = (
  userId,
  year,
  month,
) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.networkBestRecruitersAmount[userId] ||
      !stats.networkBestRecruitersAmount[userId][year] ||
      !stats.networkBestRecruitersAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkBestRecruitersAmount[userId][year][month].map((user) => {
      labels.push(`${user.firstName} ${user.lastName}`);
      data.push(user.amount);
    });
    return { labels, data };
  });

export const directPacksAmountBarGraphDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.directPacksAmount[userId] ||
      !stats.directPacksAmount[userId][year] ||
      !stats.directPacksAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directPacksAmount[userId][year][month].map((pack) => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return { labels, data };
  });

export const directProductsAmountBarGraphDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.directProductsAmount[userId] ||
      !stats.directProductsAmount[userId][year] ||
      !stats.directProductsAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.directProductsAmount[userId][year][month].map((pack) => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return { labels, data };
  });

export const networkPacksAmountBarGraphDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.networkPacksAmount[userId] ||
      !stats.networkPacksAmount[userId][year] ||
      !stats.networkPacksAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkPacksAmount[userId][year][month].map((pack) => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return { labels, data };
  });

export const networkProductsAmountBarGraphDataSelector = (
  userId,
  year,
  month,
) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.networkProductsAmount[userId] ||
      !stats.networkProductsAmount[userId][year] ||
      !stats.networkProductsAmount[userId][year][month]
    ) {
      return null;
    }
    let labels = [];
    let data = [];
    stats.networkProductsAmount[userId][year][month].map((pack) => {
      labels.push(pack.name);
      data.push(pack.amount);
    });
    return { labels, data };
  });

export const networkTurnoverByCareerDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.networkTurnoverByCareer[userId] ||
      !stats.networkTurnoverByCareer[userId][year] ||
      !stats.networkTurnoverByCareer[userId][year][month]
    ) {
      return [];
    }
    return stats.networkTurnoverByCareer[userId][year][month];
  });

export const networkTurnoverByCareerGraphDataSelector = (userId, year, month) =>
  createSelector([statsSelector], (stats) => {
    if (
      !stats.networkTurnoverByCareer[userId] ||
      !stats.networkTurnoverByCareer[userId][year] ||
      !stats.networkTurnoverByCareer[userId][year][month]
    ) {
      return [];
    }
    let labels = [];
    let data = [];
    stats.networkTurnoverByCareer[userId][year][month].map((career) => {
      labels.push(`${career.name}`);
      data.push(career.amount);
    });
    return { labels, data };
  });
