import React, { useEffect, useState } from 'react';
import OrderItem from './OrderItem';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

const OrderListItems = ({
  className,
  handleRemoveProduct,
  items,
  setProductQuantity,
}) => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    let amount = 0;
    Object.keys(items).map((key) => {
      amount +=
        (items[key].priceAndTaxDetails.final.price +
          items[key].priceAndTaxDetails.final.taxes) *
        items[key].quantity;
      setCurrency(items[key].currency);
    });
    setTotalPrice(amount);
  }, [items]);

  return (
    <List
      className={className}
      subheader={
        <Typography variant={'h6'}>
          Total : {totalPrice.toFixed(2)} {currency} TTC
        </Typography>
      }
    >
      {Object.keys(items).map((key) => (
        <React.Fragment key={key}>
          <OrderItem
            handleRemoveProduct={handleRemoveProduct}
            item={items[key]}
            setProductQuantity={setProductQuantity}
          />
          {items.length > 1 && <Divider />}
        </React.Fragment>
      ))}
    </List>
  );
};
export default OrderListItems;
