import React from 'react';
import ReduxToastr, { toastr } from 'react-redux-toastr';
//import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { useTranslation } from 'react-i18next';
import { format } from './formatError';

export const SnackbarSuccess = toastr.success;

export const SnackbarError = toastr.error;

export const SnackbarConfirm = (message, onOk, onCancel = () => {}) => {
  toastr.confirm(message, { onOk: onOk, onCancel: onCancel });
};

export const SnackbarContent = (component, callback, size, okText = 'Ok') => {
  toastr.confirm(null, {
    onOk: callback,
    disableCancel: true,
    okText: okText,
    component: () => component,
    id: size,
  });
};

export const SnackbarFormattedError = (error) => {
  return toastr.error(format(error));
};

export const SnackbarWarning = toastr.warning;

export const SnackbarInfo = toastr.info;

const SnackbarProvider = () => {
  const { t } = useTranslation();
  return (
    <ReduxToastr
      closeOnToastrClick
      confirmOptions={{
        okText: 'Ok',
        cancelText: t('cancelButton'),
      }}
      getState={(state) => state.toastr}
      newestOnTop={true}
      position="top-left"
      preventDuplicates
      progressBar
      timeOut={12000}
      transitionIn="bounceIn"
      transitionOut="bounceOut"
    />
  );
};

export default SnackbarProvider;
