import React, { useContext, useEffect, useState } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import { MenuContext } from './Menu';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import { Link } from 'react-router-dom';

const MenuItem = ({
  children,
  expandTo,
  hidden,
  icon,
  notificationsCount = 0,
  title,
  to,
}) => {
  const { onItemSelected, pathname } = useContext(MenuContext);
  const [expended, setExpended] = useState(false);

  useEffect(() => {
    setExpended(
      expandTo &&
        ((Array.isArray(expandTo) &&
          (pathname.includes(expandTo[0]) ||
            pathname.includes(expandTo[1]) ||
            pathname.includes(expandTo[2]) ||
            pathname.includes(expandTo[3]))) ||
          pathname.includes(expandTo)),
    );
  }, [pathname]);

  const isActive = pathname === to || expended;

  const handleToggleExpend = () => {
    setExpended(!expended);
  };

  if (hidden) {
    return null;
  }

  return (
    <React.Fragment>
      <ListItem
        button
        className={isActive ? 'active' : 'inactive'}
        component={children ? 'div' : Link}
        onClick={children ? handleToggleExpend : onItemSelected}
        to={to}
      >
        <ListItemIcon>
          {notificationsCount > 0 && (
            <Badge badgeContent={notificationsCount} color="error">
              {icon}
            </Badge>
          )}
          {!notificationsCount && icon}
        </ListItemIcon>
        <ListItemText primary={title} />
        {children && (expended ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {children && (
        <Collapse
          addEndListener={() => {}}
          in={expended}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            {children}
          </List>
        </Collapse>
      )}
      <Divider />
    </React.Fragment>
  );
};
export default MenuItem;
