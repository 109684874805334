import axios from '../../../../helpers/axios';

export const consultantsService = {
  findOfficesByPlace,
  findOfficesByPlaceAndZoom,
  getOfficeById,
};

function getOfficeById(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/office/${id}`)
      .then(({ office }) => {
        resolve(office);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findOfficesByPlace(place) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/pro/offices/${place.coordinates.lat}/${place.coordinates.lng}`)
      .then(({ offices, zoom }) => {
        resolve({ offices, zoom });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function findOfficesByPlaceAndZoom(place, zoom) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/pro/offices/${place.coordinates.lat}/${place.coordinates.lng}/${zoom}`,
      )
      .then(({ offices, zoom }) => {
        resolve({ offices, zoom });
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
