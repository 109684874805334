import produce from 'immer';
import * as actionsType from './constants';

const initialState = {
  treesCount: 0,
};

export const homeReducerKey = 'home';

export default (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionsType.GET_TREES_COUNT_SUCCESS:
        draft.treesCount = action.treesCount;
        break;

      default:
    }
  });
