import axios from '../../../../helpers/axios';

export const magService = {
  getArticleByUrl,
  getArticles,
};

function getArticleByUrl(url, lang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/mag/article/${lang}/${url}`)
      .then(({ article }) => {
        resolve(article);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getArticles(lang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/mag/articles/${lang}`)
      .then(({ articles }) => {
        resolve(articles);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
