import { createSelector } from 'reselect';
import { academyReducerKey } from './reducer';
import { initialState } from './initialState';

const academySelector = (globalState) =>
  globalState[academyReducerKey] || initialState.academy;

export const getAcademyProgramSelector = createSelector(
  [academySelector],
  (academy) => {
    return academy && academy.program ? academy.program : {};
  },
);

export const getAcademyProgramsSelector = createSelector(
  [academySelector],
  (academy) => {
    return academy && academy.programs ? academy.programs : null;
  },
);
