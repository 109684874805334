import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const StoreIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M8.21,1.9H24.79a1.79,1.79,0,0,1,1.66.64c.41.65,3.73,4.59,3.77,4.75s0,2.34,0,2.75a2.83,2.83,0,0,1-2.56,2.34A2.76,2.76,0,0,1,24.79,10,2.81,2.81,0,0,1,22,12.4,2.64,2.64,0,0,1,19.37,10a2.81,2.81,0,0,1-2.67,2.47A3,3,0,0,1,13.91,10a2.56,2.56,0,0,1-2.71,2.34A2.53,2.53,0,0,1,8.53,10,2.93,2.93,0,0,1,5.7,12.43c-2.06,0-2.59-2.31-2.59-2.31V7.33S6.83,2.66,7,2.42A4,4,0,0,1,8.21,1.9Z"
        fill="#00207f"
      />
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        d="M14.11,7.34V9.71a2.74,2.74,0,0,1-2.74,2.73h0A2.74,2.74,0,0,1,8.63,9.71V7.34"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.93px"
      />
      <path
        d="M19.58,7.34V9.71a2.73,2.73,0,0,1-2.74,2.73h0a2.73,2.73,0,0,1-2.73-2.73V7.34"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.93px"
      />
      <path
        d="M25.06,7.34V9.71a2.74,2.74,0,0,1-2.74,2.73h0a2.74,2.74,0,0,1-2.74-2.73V7.34"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.93px"
      />
      <path
        d="M25.06,7.34V9.71a2.73,2.73,0,0,0,2.74,2.73h0a2.73,2.73,0,0,0,2.73-2.73v-2a1.16,1.16,0,0,0-.22-.7l-3.8-4.74a.92.92,0,0,0-.76-.39H7.94a.93.93,0,0,0-.77.39L3.37,7a1.24,1.24,0,0,0-.22.7v2a2.74,2.74,0,0,0,2.74,2.73h0A2.74,2.74,0,0,0,8.63,9.71V7.34"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.95px"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.95px"
        x1="16.4"
        x2="16.4"
        y1="20.36"
        y2="21.19"
      />
      <rect
        fill="none"
        height="11.79"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.95px"
        width="7.01"
        x="14.54"
        y="15.46"
      />
    </SvgIcon>
  );
};
export default StoreIcon;
