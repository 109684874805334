import React from 'react';
import { Trans } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import i18next from 'i18next';
import Reviews from './Reviews';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import ItemPriceDisplay from '../../components/ItemPriceDisplay';
import List from '@mui/material/List';
import { ListItemButton } from '@mui/material';
import { getFormalizedName } from '../../helpers/helper';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(() => ({
  root: {
    '& .priceInfo': {
      position: 'relative',
      fontSize: '1.8rem !important',
    },
    '& .oldPriceInfo': {
      fontSize: '1.8rem !important',
      textDecoration: 'line-through',
    },
    '& .proPriceInfo': {
      fontWeight: 'bold',
      fontSize: '2.125rem !important',
      color: 'red',
      marginLeft: 10,
      '& .limitedCommissions.icon': {
        display: 'none',
      },
      '& .limitedCommissions': {
        display: 'block',
        marginLeft: 0,
        fontSize: 16,
        color: red[500],
      },
      '& .discount': {
        display: 'block',
        marginLeft: 0,
        fontSize: 20,
        color: red[500],
      },
    },
    '& .video': {
      width: '100%',
      position: 'relative',
      paddingBottom: '56.25%',
      overflow: 'hidden',
      '& iframe': {
        borderRadius: 5,
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
      },
    },
  },
}));
const InlineContent = ({ product, session }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography component="h2" variant="h2">
        {product.detail.name}
      </Typography>
      <Divider />
      {product.allowInCart && (
        <>
          <ItemPriceDisplay product={product} session={session} />
          <Divider />
        </>
      )}
      <Typography component="p">{product.detail.description}</Typography>
      {product.packageItems && (
        <List>
          {product.packageItems.map((item) => (
            <ListItemButton
              href={`/shop/product/${getFormalizedName(item.detail.name)}/${
                item.id
              }`}
              key={`${product.id}-${item.id}`}
              target={'_blank'}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar
                    alt={item.detail.name}
                    src={item.image[600]}
                    sx={{ borderRadius: 0, mr: 4, width: 100, height: 100 }}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={item.detail.name}
                  secondary={item.detail.description}
                  sx={{ height: 100, overflow: 'hidden' }}
                />
              </ListItem>
            </ListItemButton>
          ))}
        </List>
      )}
      <Divider />
      {!!session && session.isPro && product.detail.proInfo && (
        <>
          <Typography className={'infoPro'} component="h4" variant="h5">
            <Trans i18nKey="proInfo">Pro info :</Trans>
          </Typography>
          <Typography className={'infoPro'} component="p" variant="body1">
            {product.detail.proInfo}
          </Typography>
          <Divider />
        </>
      )}
      {product.detail.definition && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="definition">Definition :</Trans>
          </Typography>
          <Typography component="p">{product.detail.definition}</Typography>
          <Divider />
        </>
      )}
      {product.detail.indications && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="indications">Indications :</Trans>
          </Typography>
          <Typography component="p">{product.detail.indications}</Typography>
          <Divider />
        </>
      )}
      {product.detail.recommendedDosage && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="recommendedDosage">Recommended dosage :</Trans>
          </Typography>
          <Typography component="p">
            {product.detail.recommendedDosage}
          </Typography>
          <Divider />
        </>
      )}
      {product.detail.contraindications && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="contraindications">Contraindications :</Trans>
          </Typography>
          <Typography component="p">
            {product.detail.contraindications}
          </Typography>
          <Divider />
        </>
      )}
      {product.detail.guaranteedWithout && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="guaranteedWithout">Guaranteed without :</Trans>
          </Typography>
          <Typography component="p">
            {product.detail.guaranteedWithout}
          </Typography>
          <Divider />
        </>
      )}
      {product.detail.precautionsForUse && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="precautionsForUse">Precautions for use :</Trans>
          </Typography>
          <Typography component="p">
            {product.detail.precautionsForUse}
          </Typography>
          <Divider />
        </>
      )}
      {product.detail.conservation && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="conservation">Conservation :</Trans>
          </Typography>
          <Typography component="p">{product.detail.conservation}</Typography>
          <Divider />
        </>
      )}
      {product.detail.composition && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="composition">Composition :</Trans>
          </Typography>
          <Typography component="p">{product.detail.composition}</Typography>
          <Divider />
        </>
      )}
      {product.detail.excipients && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="excipients">Excipients :</Trans>
          </Typography>
          <Typography component="p">{product.detail.excipients}</Typography>
          <Divider />
        </>
      )}
      {product.detail.ingredients && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="ingredients">Ingredients :</Trans>
          </Typography>
          <Typography component="p">{product.detail.ingredients}</Typography>
          <Divider />
        </>
      )}
      {!!product.detail.video && (
        <>
          <Typography className={'video'} component={'div'} gutterBottom>
            <iframe
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              height="360"
              src={`${product.detail.video}?autoplay=0&color=ffffff&title=0&byline=0&portrait=0&modestbranding=1&rel=0&showinfo=0&texttrack=${i18next.language}`}
              width="640"
            />
          </Typography>
          <Divider />
        </>
      )}
      {product.reviewsRating && (
        <>
          <Typography component="h4" variant="h5">
            <Trans i18nKey="reviews">Reviews</Trans>
          </Typography>
          <div>
            <Typography component="span" variant="h2">
              {parseFloat(product.reviewsRating).toFixed(1)}
            </Typography>
            <Typography component="span" sx={{ ml: 1 }} variant="h4">
              /5
            </Typography>
          </div>
          <Typography component="i">
            <Trans
              i18nKey={'calculatedFromReview'}
              values={{ count: product.reviewsCount }}
            >
              Calculated from {product.reviewsCount} customer review(s)
            </Trans>
          </Typography>
          <Divider />
          <Reviews product={product} />
        </>
      )}
    </div>
  );
};

export default InlineContent;
