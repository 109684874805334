import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { SnackbarFormattedError } from '../../../../helpers/snackbar';
import PageLoader from '../../../../components/PageLoader';
import { Link } from '../../../../helpers/multilingual';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { academyService } from '../../Academy/store/services';

const useStyle = makeStyles((theme) => ({
  root: {
    '& img': {
      width: 130,
      maxWidth: '100%',
      borderRadius: 16,
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    '& .MuiButton-root': {
      marginTop: 16,
    },
  },
}));

const Trainings = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const [programs, setPrograms] = useState(null);

  useEffect(() => {
    academyService
      .getUserPrograms()
      .then((programs) => setPrograms(programs))
      .catch((e) => SnackbarFormattedError(e.error));
  }, []);

  if (!programs) {
    return <PageLoader height={100} />;
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {programs.length === 0 && (
            <Typography>{t('noTrainingFound')}</Typography>
          )}
        </Grid>
        {programs.map((program) => (
          <Grid item key={program.id} xs={12}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item md={2} sm={3} xs={12}>
                    <Typography align={'center'} component={'div'}>
                      <img alt={program.title} src={program.image[400]} />
                    </Typography>
                  </Grid>
                  <Grid item md={10} sm={9} xs={12}>
                    <Typography variant={'h5'}>{program.title}</Typography>
                    <Typography gutterBottom>
                      {program.shortDescription}
                    </Typography>
                    <Button
                      className={'enterBtn'}
                      color={'primary'}
                      component={Link}
                      size={'small'}
                      startIcon={<OpenInNewIcon />}
                      to={`/academy/${program.url}/chapter`}
                      variant={'contained'}
                    >
                      {t('enterIntoFormation')}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
export default Trainings;
