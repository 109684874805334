import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { Trans, useTranslation } from 'react-i18next';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { authenticationActions } from '../../../Authentication/store/actions';
import {
  SnackbarConfirm,
  SnackbarFormattedError,
  SnackbarInfo,
  SnackbarSuccess,
} from '../../../../helpers/snackbar';
import { authenticationService } from '../../../Authentication/store/services';
import { userSelector } from '../../../Authentication/store/selectors';
import { mailingService } from '../../Mailing/store/services';

const Settings = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const [mailingSubscription, setMailingSubscription] = useState(false);
  const [mailingProSubscription, setMailingProSubscription] = useState(false);

  useEffect(() => {
    setMailingSubscription(user.allowMailing);
    setMailingProSubscription(user.allowProMailing);
  }, [user]);

  const handleSignOutAllDevices = () => {
    SnackbarConfirm(t('signOutAllDevices'), () => {
      dispatch(authenticationActions.signOutAllDevices());
    });
  };

  const handleSendPasswordRecoveryEmail = () => {
    authenticationService.passwordRecovery(user.email).then(() => {
      SnackbarSuccess(t('updatePasswordEmailSent', { email: user.email }));
    });
  };

  const handleChangeMailingSubscription = (e) => {
    if (e.target.checked) {
      mailingService
        .subscribe()
        .then(() => {
          dispatch(authenticationActions.setMailingSubscription(true));
          SnackbarSuccess(t('emailAddedToMailingList'));
        })
        .catch((err) => {
          SnackbarFormattedError(err.error);
        });
    } else {
      mailingService
        .unsubscribe(user.email, user.id)
        .then(() => {
          dispatch(authenticationActions.setMailingSubscription(false));
          SnackbarInfo(t('emailRemovedFromMailingList'));
        })
        .catch((err) => {
          SnackbarFormattedError(err.error);
        });
    }
  };

  const handleChangeMailingProSubscription = (e) => {
    if (e.target.checked) {
      mailingService
        .subscribePro()
        .then(() => {
          dispatch(authenticationActions.setMailingProSubscription(true));
          SnackbarSuccess(t('emailAddedToMailingList'));
        })
        .catch((err) => {
          SnackbarFormattedError(err.error);
        });
    } else {
      mailingService
        .unsubscribePro(user.email, user.id)
        .then(() => {
          dispatch(authenticationActions.setMailingProSubscription(false));
          SnackbarInfo(t('emailRemovedFromMailingList'));
        })
        .catch((err) => {
          SnackbarFormattedError(err.error);
        });
    }
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl component="fieldset" sx={{ mb: 4 }}>
            <FormLabel
              component="legend"
              sx={{ fontSize: 20, marginBottom: 1 }}
            >
              <Trans i18nKey={'emailNotifications'}>Email notifications</Trans>
            </FormLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={mailingSubscription}
                    color={'secondary'}
                    name="mailingSubscription"
                    onChange={handleChangeMailingSubscription}
                  />
                }
                label={t('mailingCampaignFromAGS')}
              />
            </FormGroup>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={mailingProSubscription}
                    color={'secondary'}
                    name="mailingProSubscription"
                    onChange={handleChangeMailingProSubscription}
                  />
                }
                label={t('mailingCampaignFromAdvisor')}
              />
            </FormGroup>
            <FormHelperText>
              <Trans i18nKey={'youCanChangeThisAtEveryMoment'}>
                You can change your choice at any time from this page
              </Trans>
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <FormLabel
              component="legend"
              sx={{ fontSize: 20, marginBottom: 1 }}
            >
              <Trans i18nKey={'security'}>Security</Trans>
            </FormLabel>
            <Button
              color={'secondary'}
              onClick={() => handleSendPasswordRecoveryEmail()}
              sx={{ fontSize: '1rem', justifyContent: 'flex-start' }}
            >
              <Trans i18nKey="askForEmailPassword">
                Request a password change email
              </Trans>
            </Button>
            <Button
              color={'secondary'}
              onClick={() => handleSignOutAllDevices()}
              sx={{ fontSize: '1rem', justifyContent: 'flex-start' }}
            >
              <Trans i18nKey="signOutAllDevices">
                Sign out from all devices
              </Trans>
            </Button>
            <Button
              color={'secondary'}
              onClick={() => {
                dispatch(authenticationActions.signOut());
              }}
              sx={{ fontSize: '1rem', justifyContent: 'flex-start' }}
            >
              <Trans i18nKey="signOut">Sign out</Trans>
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
export default Settings;
