import { createSelector } from 'reselect';
import { shopReducerKey } from './reducer';
import { initialState } from './initialState';
import {
  userSelector,
  getUserShippingAddressSelector,
} from '../../../Authentication/store/selectors';
import { SHIPPING_FREE_THRESHOLD_AMOUNT } from './constants';
import { hasTaxes } from '../../../../helpers/utils';

const shopSelector = (globalState) =>
  globalState[shopReducerKey] || initialState.shop;

export const userShippingSelector = createSelector([shopSelector], (shop) => {
  return shop && shop.userShipping ? shop.userShipping : {};
});
export const userShippingAddressesSelector = createSelector(
  [userShippingSelector],
  (shipping) => {
    return shipping.addresses;
  },
);
export const userShippingAddressesLoaderSelector = createSelector(
  [userShippingSelector],
  (shipping) => {
    return shipping.addressesRequested;
  },
);
export const addShippingAddressLoaderSelector = createSelector(
  [userShippingSelector],
  (shipping) => {
    return shipping.addAddressRequested;
  },
);

export const shippingAddressSelectedSelector = createSelector(
  [shopSelector, getUserShippingAddressSelector],
  (shop, userShippingAddress) => {
    if (shop.cart.shipping.address) {
      return shop.cart.shipping.address;
    }
    return userShippingAddress;
  },
);

export const shippingPickupPointSelectedSelector = createSelector(
  [shopSelector],
  (shop) => {
    if (shop.cart.shipping.pickupPoint) {
      return {
        id: shop.cart.shipping.pickupPoint.id,
        name: shop.cart.shipping.pickupPoint.name,
        type: shop.cart.shipping.pickupPoint.type,
        street: shop.cart.shipping.pickupPoint.street,
        number: shop.cart.shipping.pickupPoint.number,
        zip: shop.cart.shipping.pickupPoint.zip,
        city: shop.cart.shipping.pickupPoint.city,
        country: shop.cart.shipping.pickupPoint.country,
      };
    }
    return null;
  },
);

export const shippingMethodsSelector = createSelector(
  [shopSelector, shippingAddressSelectedSelector],
  (shop, shippingAddress) => {
    if (!shippingAddress.latitude || !shippingAddress.longitude) {
      return shop.cart.shipping.methods.filter(
        (address) => address.pickupPoint === 0,
      );
    }
    return shop.cart.shipping.methods;
  },
);

export const useWalletSelector = createSelector([shopSelector], (shop) => {
  return !!shop.cart.useWallet;
});

export const shippingMethodSelectedSelector = createSelector(
  [shopSelector],
  (shop) => {
    return shop.cart.shipping.method;
  },
);

export const sessionSelector = createSelector(
  [shopSelector, userSelector, shippingAddressSelectedSelector],
  (shop, user, shippingAddress) => {
    if (user) {
      const country = shippingAddress ? shippingAddress.country : 'BE';
      return {
        parentId: user.parentId,
        country: country,
        currency: shippingAddress ? shippingAddress.currency : 'SCR',
        hasTaxes: hasTaxes(user, country),
        isPro: user.isPro,
        role: user.role,
        multiplier: user.multiplier,
        allowFreeShipping: user.allowFreeShipping,
        consultant: user.consultant,
        registered: true,
      };
    }
    if (shop.session) {
      return { ...shop.session, isPro: false, registered: false };
    }
    return {
      parentId: null,
      country: 'SC',
      currency: 'SCR',
      hasTaxes: true,
      isPro: false,
      multiplier: 1,
      allowFreeShipping: true,
      consultant: {},
      registered: false,
    };
  },
);

export const sessionErrorSelector = createSelector([shopSelector], (shop) => {
  return shop.sessionError;
});

export const shopProductsLoaderSelector = createSelector(
  [shopSelector],
  (shop) => shop.productsRequested,
);

export const shopProductsSelector = (categoryId, country, lang) =>
  createSelector(
    [shopSelector],
    (shop) => shop.products[`${categoryId}-${country}-${lang}`]?.data,
  );

export const endOfProductsSelector = (categoryId, country, lang) =>
  createSelector(
    [shopSelector],
    (shop) => shop.products[`${categoryId}-${country}-${lang}`]?.endOfProducts,
  );

export const shopCatalogProductsSelector = createSelector(
  [shopSelector],
  (shop) => shop.catalog,
);

export const shopCategoriesSelector = createSelector(
  [shopSelector],
  (shop) => shop.categories,
);

export const shopCategorySelector = (lang) =>
  createSelector([shopSelector], (shop) =>
    shop.categories[lang]?.find((category) => shop.categoryId === category.id),
  );

export const shopProductSelector = createSelector(
  [shopSelector],
  (shop) => shop.product,
);

export const cartSelector = createSelector([shopSelector], (shop) => shop.cart);

export const itemsFromCartSelector = createSelector(
  [cartSelector],
  (cart) => cart.items,
);

export const countItemsFromCartSelector = createSelector(
  [itemsFromCartSelector],
  (items) => {
    let count = 0;
    items &&
      Object.keys(items).map((key) => {
        count += items[key].quantity;
      });
    return count;
  },
);

export const activeDiscountCodeSelector = createSelector(
  [sessionSelector, shopSelector],
  (session, shop) => !session?.isPro && shop.activeDiscountCode,
);

export const giftCardsSelector = createSelector(
  [shopSelector],
  (shop) => shop.giftCards || [],
);

export const giftCardSelector = createSelector(
  [shopSelector],
  (shop) => shop.giftCard,
);

export const cartDetailsSelector = createSelector(
  [itemsFromCartSelector, sessionSelector, giftCardSelector],
  (items, session, discountCode) => {
    let discount = 0;
    let taxes = {};
    let total = 0;
    const currency = session.currency;
    Object.keys(items).map((key) => {
      let discountPercentage = 0;
      let discountMultiplier = 1;
      if (
        discountCode &&
        discountCode.currency === 'PERCENT' &&
        items[key].commissioned &&
        items[key].type !== 'formation' &&
        (!Array.isArray(discountCode.products) ||
          discountCode.products.length === 0 ||
          discountCode.products.includes(items[key].id))
      ) {
        discountPercentage = discountCode.amount / 100;
        discountMultiplier = 1 - discountPercentage;
      }

      if (!items[key].priceAndTaxDetails) {
        return;
      }

      let itemFinalPriceInclTaxes =
        (Math.round(
          (items[key].priceAndTaxDetails.final.price +
            items[key].priceAndTaxDetails.final.taxes) *
            discountMultiplier *
            10000,
        ) /
          10000) *
        items[key].quantity;
      discount =
        (Math.round(
          (items[key].priceAndTaxDetails.final.price +
            items[key].priceAndTaxDetails.final.taxes) *
            discountPercentage *
            10000,
        ) /
          10000) *
        items[key].quantity;

      Object.keys(items[key].priceAndTaxDetails.final.taxesByRates).forEach(
        (taxesRate) => {
          taxes[taxesRate] = taxes[taxesRate]
            ? taxes[taxesRate] +
              items[key].priceAndTaxDetails.final.taxesByRates[taxesRate] *
                items[key].quantity *
                discountMultiplier
            : items[key].priceAndTaxDetails.final.taxesByRates[taxesRate] *
              items[key].quantity *
              discountMultiplier;
        },
      );

      total += itemFinalPriceInclTaxes;
    });
    const isFreeShipping = SHIPPING_FREE_THRESHOLD_AMOUNT[currency] - total < 0;
    Object.keys(taxes).map((taxRate) => {
      taxes[taxRate] = Math.round(taxes[taxRate] * 100) / 100;
    });
    return {
      items,
      shippingPrice: 0,
      shippingTaxes: 0,
      discount,
      taxes,
      total,
      currency,
      isFreeShipping,
      allowFreeShipping: session.allowFreeShipping,
    };
  },
);

export const checkoutSelector = createSelector([shopSelector], (shop) =>
  shop.checkout ? shop.checkout : null,
);

export const itemsFromCheckoutSelector = createSelector(
  [checkoutSelector],
  (checkout) => {
    let items = {};
    if (checkout && checkout.order) {
      checkout.order.products.forEach((item) => {
        items[item.productId] = {
          ...item.originalProduct,
          currency: item.currency,
          quantity: item.quantity,
          discount: item.discount,
          multiplier: checkout.order.isProOrder
            ? 1 - item.directSaleMultiplier
            : 1,
        };
      });
    }
    return items;
  },
);

export const checkoutDetailsSelector = createSelector(
  [checkoutSelector],
  (checkout) => {
    if (!checkout || !checkout.order) {
      return null;
    }
    return {
      ...checkout.order,
      allowFreeShipping: checkout.order.allowFreeShipping,
      isFreeShipping: checkout.order.shippingPrice === 0,
      subTotal: checkout.order.totalPrice,
      taxes: checkout.order.taxesDetails,
      total: checkout.order.totalPrice + checkout.order.taxes,
    };
  },
);

export const itemsFromCartOrCheckoutSelector = createSelector(
  [itemsFromCartSelector, itemsFromCheckoutSelector],
  (itemFromCart, itemFromCheckout) => {
    return itemFromCheckout ? itemFromCheckout : itemFromCart;
  },
);

export const weightFromCartOrCheckoutSelector = createSelector(
  [itemsFromCartOrCheckoutSelector],
  (items) => {
    let weight = 0;
    Object.keys(items).map((itemId) => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight;
  },
);

export const needDeliverySelector = createSelector(
  [itemsFromCartSelector, itemsFromCheckoutSelector],
  (itemsFromCart, itemsFromCheckout) => {
    const items =
      Object.keys(itemsFromCheckout) > 0 ? itemsFromCheckout : itemsFromCart;
    let weight = 0;
    Object.keys(items).map((itemId) => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight > 0;
  },
);

export const weightFromCheckoutSelector = createSelector(
  [itemsFromCheckoutSelector],
  (items) => {
    let weight = 0;
    Object.keys(items).map((itemId) => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight;
  },
);

export const weightFromCartSelector = createSelector(
  [itemsFromCartSelector],
  (items) => {
    let weight = 0;
    Object.keys(items).map((itemId) => {
      weight += items[itemId].weight * items[itemId].quantity;
    });
    return weight;
  },
);

export const cartOrCheckoutDetailsSelector = createSelector(
  [cartDetailsSelector, checkoutDetailsSelector],
  (cartDetails, checkoutDetails) => {
    return checkoutDetails ? checkoutDetails : cartDetails;
  },
);

export const countShippingFreeThresholdLeftSelector = createSelector(
  [cartOrCheckoutDetailsSelector],
  ({ allowFreeShipping, currency, shippingPrice, shippingTaxes, total }) => {
    return {
      currency,
      allowFreeShipping,
      amountLeft:
        SHIPPING_FREE_THRESHOLD_AMOUNT[currency] -
        total +
        shippingPrice +
        shippingTaxes,
    };
  },
);

export const isFreeShippingSelector = createSelector(
  [countShippingFreeThresholdLeftSelector],
  ({ allowFreeShipping, amountLeft }) => {
    return allowFreeShipping && amountLeft <= 0;
  },
);

export const ordersSelector = createSelector(
  [shopSelector],
  (shop) => shop.orders,
);

export const isCartModalOpenedSelector = createSelector(
  [cartSelector],
  (cart) => cart.isModalOpened,
);

export const shippingTaxesRateSelector = createSelector(
  [cartDetailsSelector],
  ({ taxes }) => {
    let minRate = 1;
    Object.keys(taxes).map((rate) => {
      if (minRate < rate) {
        minRate = rate;
      }
    });
    return minRate;
  },
);
