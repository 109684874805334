import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

const UserMenuItem = ({ hidden, icon, title, ...props }) => {
  if (hidden) {
    return null;
  }
  return (
    <MenuItem
      sx={(theme) => ({
        height: 43,
        '&:hover': {
          backgroundColor: theme.palette.secondary.main,
          '& .MuiListItemIcon-root, & .MuiListItemText-root': {
            color: '#fff',
          },
        },
      })}
      {...props}
    >
      <ListItemIcon
        sx={{
          minWidth: 56,
          color: '#000',
          mr: 2,
          '& svg': {
            fontSize: '1.5em',
            width: '1.5em',
          },
        }}
      >
        {icon}
      </ListItemIcon>
      <ListItemText primary={title} />
    </MenuItem>
  );
};
export default UserMenuItem;
