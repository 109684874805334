import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { magActions } from './store/actions';
import { getArticleSelector } from './store/selectors';
import ContactButton from '../../../components/Layout/Customer/ContactButton';
import { sessionSelector } from '../Shop/store/selectors';
import SocialNetworksShareButtons from '../../../components/SocialNetworksShareButtons';
import MagBanner from './components/MagBanner';
import { useCustomerPath } from '../../../helpers/usePath';
import { useSEO } from '../../../helpers/useSEO';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 40,
  },
  content: {
    minHeight: '60vh',
    marginTop: 30,
    //fontSize: 16,
    '& img': {
      maxWidth: '100%',
    },
    '& strong': {
      fontWeight: '500',
    },
    '& table': {
      float: 'none',
      [theme.breakpoints.down('md')]: {
        width: '100% !important',
        '& th': {},
        '& td': {
          '& p': {
            maxWidth: '100% !important',
            wordBreak: 'break-word',
          },
        },
      },
    },
    '& div': {
      [theme.breakpoints.down('sm')]: {
        padding: '0 !important',
        display: 'block !important',
        maxWidth: '100% !important',
        width: '100% !important',
      },
    },
    '& p, span': {
      fontSize: '20px',
      fontFamily: 'inherit !important',
    },
    '& h2, h3, h4, h5, h6, strong': {
      fontWeight: '500 !important',
      fontFamily: 'inherit !important',
    },
  },
  errorMessage: {
    marginTop: 40,
  },
  socialNetworksButtons: {},
  contactButton: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      '& p': {
        display: 'none',
      },
    },
  },
}));
const Article = (props) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const path = useCustomerPath();
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const article = useSelector(getArticleSelector);
  const { buildAlternatesLangTags } = useSEO();

  useEffect(() => {
    if (
      !article?.url ||
      props.match.params.url !== article.url ||
      i18n.language !== article.lang
    ) {
      dispatch(
        magActions.getArticleRequest(props.match.params.url, i18n.language),
      );
    }
  }, [i18n.language, props.match.params.lang, session]);

  if (!article?.url) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{article.title}</title>
        <meta content={article.title} property="og:title" />
        <meta content="article" property="og:type" />
        <meta
          content={`${process.env.REACT_APP_WEB_URL}${path}/mag/${article.url}`}
          property="og:url"
        />
        <meta content={article.cover[800]} property="og:image" />
        <meta content={article.metaDescription} property="og:description" />
        <meta content={article.metaDescription} name="description" />
        {buildAlternatesLangTags(`/mag/${article.url}`, {
          [article.lang]: `/mag/${article.url}`,
        }).map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <MagBanner article={article} />
      <Container fixed maxWidth="lg">
        <Grid
          alignItems={'flex-start'}
          container
          direction={!session || !session.isPro ? 'row-reverse' : 'row'}
          justifyContent={'space-between'}
          spacing={2}
        >
          <Grid item>
            {(!session || !session.isPro) && (
              <Typography align={'right'} className={classes.contactButton}>
                <ContactButton />
              </Typography>
            )}
          </Grid>
          <Grid item>
            <div className={classes.socialNetworksButtons}>
              <SocialNetworksShareButtons
                align={'right'}
                title={article.title}
                url={`/mag/${article.url}`}
              />
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.content} container>
          <Grid item xs={12}>
            <Typography component={'div'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: article.content || '',
                }}
              ></div>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default Article;
