import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { statsActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  networkBestRecruitersBarGraphDataSelector,
  networkBestSellersBarGraphDataSelector,
} from '../store/selectors';
import { Trans, useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import BarChart from '../../../../components/Charts/BarChart';
import LinearProgress from '@mui/material/LinearProgress';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-select': {
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      paddingTop: 10,
      paddingBottom: 10,
      maxWidth: '70vw',
    },
    height: '100%',
  },
  menuItem: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
  loader: {
    height: 340,
  },
}));
const BestLvl2AndMoreCharts = ({ user }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [view, setView] = useState('networkBestSellers');
  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
  const [yearRange, setYearRange] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const networkBestSellersLinesGraphData = useSelector(
    networkBestSellersBarGraphDataSelector(
      user.id,
      format(selectedDate, 'yyyy'),
      !yearRange && format(selectedDate, 'MM'),
    ),
  );
  const networkBestRecruitersLinesGraphData = useSelector(
    networkBestRecruitersBarGraphDataSelector(
      user.id,
      format(selectedDate, 'yyyy'),
      !yearRange && format(selectedDate, 'MM'),
    ),
  );

  useEffect(() => {
    switch (view) {
      case 'networkBestRecruiters':
        if (!networkBestRecruitersLinesGraphData) {
          dispatch(
            statsActions.getNetworkBestRecruitersAmountRequest(
              user.id,
              format(selectedDate, 'yyyy'),
              !yearRange && format(selectedDate, 'MM'),
            ),
          );
        }
        break;
      case 'networkBestSellers':
      default:
        if (!networkBestSellersLinesGraphData) {
          dispatch(
            statsActions.getNetworkBestSellersAmountRequest(
              user.id,
              format(selectedDate, 'yyyy'),
              !yearRange && format(selectedDate, 'MM'),
            ),
          );
        }
    }
  }, [view, selectedDate, yearRange]);

  const handleYearChange = (e) => {
    setSelectedDate(e);
    setYearPickerOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    setMonthPickerOpen(false);
  };

  const handleChangeRangeSelector = (e) => {
    setYearRange(e.target.checked);
  };

  const handleChangeView = (e) => {
    setView(e.target.value);
  };

  const getValues = () => {
    switch (view) {
      case 'networkBestRecruiters':
        return networkBestRecruitersLinesGraphData;
      case 'networkBestSellers':
      default:
        return networkBestSellersLinesGraphData;
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        sx={{ height: 95 }}
        title={
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              <Select
                defaultValue={view}
                onChange={handleChangeView}
                variant={'outlined'}
              >
                <MenuItem
                  className={classes.menuItem}
                  value="networkBestSellers"
                >
                  {t('networkBestSellers')}
                </MenuItem>
                <MenuItem
                  className={classes.menuItem}
                  value="networkBestRecruiters"
                >
                  {t('networkBestRecruiters')}
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Grid alignItems={'center'} container justifyContent={'flex-end'}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={yearRange}
                        name="range"
                        onChange={handleChangeRangeSelector}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      />
                    }
                    label="Par année"
                  />
                </Grid>
                <Grid item>
                  {yearRange && (
                    <MobileDatePicker
                      maxDate={new Date()}
                      onChange={handleYearChange}
                      onClose={() => setYearPickerOpen(false)}
                      onMonthChange={(e) => handleYearChange(e)}
                      onOpen={() => setYearPickerOpen(true)}
                      open={isYearPickerOpen}
                      openTo="month"
                      slotProps={{
                        mobilePaper: {
                          elevation: 2,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: 1,
                          width: 120,
                        },
                      }}
                      value={selectedDate}
                      views={['year']}
                    />
                  )}
                  {!yearRange && (
                    <MobileDatePicker
                      maxDate={new Date()}
                      onChange={handleDateChange}
                      onClose={() => setMonthPickerOpen(false)}
                      onMonthChange={(e) => handleDateChange(e)}
                      onOpen={() => setMonthPickerOpen(true)}
                      open={isMonthPickerOpen}
                      openTo="month"
                      slotProps={{
                        mobilePaper: {
                          elevation: 2,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: 1,
                          width: 120,
                        },
                      }}
                      value={selectedDate}
                      views={['year', 'month']}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Divider />
      {!getValues() && (
        <Grid
          alignItems={'center'}
          className={classes.loader}
          container
          justifyContent={'center'}
        >
          <Grid item sm={4} xs={6}>
            <Typography align={'center'}>
              <Trans i18nKey={'loading'}>Loading</Trans> ...
            </Typography>
            <LinearProgress color="secondary" />
          </Grid>
        </Grid>
      )}
      {getValues() && (
        <CardContent>
          <BarChart
            color={{
              border: 'rgb(13,71,161)',
              background: 'rgba(13,71,161, 0.04)',
            }}
            data={getValues().data}
            displayLegend={false}
            heightLg={350}
            heightMd={300}
            heightSm={300}
            labels={getValues().labels}
            title={t(view)}
          />
        </CardContent>
      )}
    </Card>
  );
};
export default BestLvl2AndMoreCharts;
