import React from 'react';
import { InfoWindow } from '@react-google-maps/api';

const MapInfoWindow = ({
  children,
  disableAutoPan = false,
  pixelOffsetX,
  pixelOffsetY,
  position,
  zIndex,
}) => {
  const options = {
    disableAutoPan: disableAutoPan,
    pixelOffset: new window.google.maps.Size(pixelOffsetX, pixelOffsetY),
  };

  return (
    <InfoWindow options={options} position={position} zIndex={zIndex}>
      {children}
    </InfoWindow>
  );
};

export default MapInfoWindow;
