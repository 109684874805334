import React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link } from '../../../helpers/multilingual';
import HomeIcon from '@mui/icons-material/Home';
import makeStyles from '@mui/styles/makeStyles';

const useStyleBreadcrumb = makeStyles(() => ({
  root: {
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}));

const Breadcrumb = ({ parents }) => {
  const classes = useStyleBreadcrumb();

  const links = parents.map((link, key) => {
    if (link.uri)
      return (
        <Link key={`breadcrumb${key}`} to={link.uri}>
          {link.name}
        </Link>
      );
    else
      return (
        <Typography color="textPrimary" key={key}>
          {link.name}
        </Typography>
      );
  });

  return (
    <Breadcrumbs aria-label="breadcrumb" className={classes.root}>
      <Link to="">
        <HomeIcon className={classes.icon} />
      </Link>
      {links}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
