import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { grey } from '@mui/material/colors';
import SearchUserAutocomplete from '../../../containers/Admin/Users/components/SearchUserAutocomplete';
import ProductsAutocomplete from '../../../containers/Customer/Shop/components/Search/ProductsAutocomplete';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vw',
    maxWidth: 500,
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      maxWidth: 'unset',
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const SearchDrawer = ({ searchDrawerOpen, setSearchDrawerOpen }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    setSearchDrawerOpen(false);
  };

  const handleSelectUser = (e, value) => {
    if (value.id) {
      history.push(`/admin/users/${value.id}`);
      setSearchDrawerOpen(false);
    }
  };

  const handleSelectProduct = (value) => {
    if (value.id) {
      history.push(`/admin/shop/product/${value.id}`);
      setSearchDrawerOpen(false);
    }
  };

  return (
    <Drawer
      anchor={'right'}
      onClose={() => setSearchDrawerOpen(false)}
      open={searchDrawerOpen}
      sx={(theme) => ({ zIndex: theme.zIndex.drawer + 2 })}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <div className={classes.root} role="presentation">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography component="h1" variant="h4">
              <Trans i18nKey={'search'}>Search</Trans>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SearchUserAutocomplete
              autoFocus
              onChange={handleSelectUser}
              target={'all'}
            />
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <ProductsAutocomplete
              label={'Produits'}
              onChange={handleSelectProduct}
              onSelect={handleSelectProduct}
              variant={'outlined'}
              withoutRestriction
            />
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};
export default SearchDrawer;
