import React from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import {
  isAdmin,
  userSelector,
} from '../../../../Authentication/store/selectors';
import { Link } from '../../../../../helpers/multilingual';
import { checkoutSelector, needDeliverySelector } from '../../store/selectors';
import Button from '@mui/material/Button';
import WalletAmountSelector from './WalletAmountSelector';
import DeliveryMethodSelector from './DeliveryMethodSelector';
import ShippingAddressSelector from './ShippingAddressSelector';
import Payment from './Payment';
import NoPayment from './NoPayment';
import GiftCardSelector from './GiftCardSelector';

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: 'inherit',
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.primary.dark,
    },
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  shippingAddressSelector: {
    marginTop: 10,
    marginBottom: 10,
  },
}));

const RegisteredCard = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const user = useSelector(userSelector);
  const userIsAdmin = useSelector(isAdmin);
  const needDelivery = useSelector(needDeliverySelector);
  const checkout = useSelector(checkoutSelector);

  return (
    <Card className={classes.root}>
      <CardContent>
        {!userIsAdmin && !user.parentId && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                alignItems={'center'}
                container
                justifyContent={'space-between'}
              >
                <Grid item>
                  <Typography component="h3" variant={'h6'}>
                    <Trans i18nKey={'error'}>Error</Trans> :
                  </Typography>
                </Grid>
              </Grid>
              <Typography component={'p'} variant="body1">
                <Trans i18nKey={'errorMessageAccount'}>
                  There is an error with your account, please contact us
                </Trans>
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                component={Link}
                disableElevation
                to={'/contact'}
                variant="contained"
              >
                <Trans i18nKey="contact">Contact</Trans>
              </Button>
            </Grid>
          </Grid>
        )}
        {(userIsAdmin || user.parentId) && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                alignItems={'center'}
                container
                justifyContent={'space-between'}
              >
                <Grid item>
                  <Typography component="h3" variant={'h6'}>
                    <Trans i18nKey={'billedTo'}>Billed to</Trans> :
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.link}
                    color={'inherit'}
                    component={Link}
                    to="/user/account"
                    variant="body1"
                  >
                    <Trans i18nKey="updateMyInformation">
                      Update my information
                    </Trans>
                  </Typography>
                </Grid>
              </Grid>
              <Typography component={'p'} variant="body1">
                {user.firstName} {user.lastName}
              </Typography>
              {user.company != null && user.company !== '' && (
                <Typography component={'p'} variant="body1">
                  {user.company} <br />
                </Typography>
              )}
              {user.taxNumber != null && user.taxNumber !== '' && (
                <Typography component={'p'} variant="body1">
                  {t('vat')}: {user.taxNumber} <br />
                </Typography>
              )}
              <Typography component={'p'} variant="body1">
                {user.address}
              </Typography>
            </Grid>
            {needDelivery && (
              <>
                <Grid item xs={12}>
                  <ShippingAddressSelector />
                </Grid>
                <Grid item xs={12}>
                  <DeliveryMethodSelector />
                </Grid>
              </>
            )}

            <GiftCardSelector />

            <WalletAmountSelector />

            {checkout?.order && checkout.noPayment && (
              <Grid item xs={12}>
                <NoPayment />
              </Grid>
            )}
            {checkout?.order && !checkout.noPayment && (
              <Grid item key={checkout?.paymentIntent?.client_secret} xs={12}>
                <Payment />
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
export default RegisteredCard;
