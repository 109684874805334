import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const AdminIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <rect
        fill="none"
        height="4.12"
        stroke="#fff"
        strokeLinejoin="round"
        width="4.12"
        x="12.29"
        y="11.04"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="12.29"
        x2="12.29"
        y1="8.34"
        y2="4.22"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="16.42"
        x2="16.42"
        y1="4.22"
        y2="8.34"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="16.42"
        x2="16.42"
        y1="17.88"
        y2="22"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="12.29"
        x2="12.29"
        y1="22"
        y2="17.88"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="19.03"
        x2="23.15"
        y1="11.04"
        y2="11.04"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="23.15"
        x2="19.03"
        y1="15.16"
        y2="15.16"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="9.6"
        x2="5.48"
        y1="15.16"
        y2="15.16"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinejoin="round"
        x1="5.48"
        x2="9.6"
        y1="11.04"
        y2="11.04"
      />
      <rect
        fill="none"
        height="9.48"
        stroke="#fff"
        strokeLinejoin="round"
        width="9.48"
        x="9.61"
        y="8.36"
      />
    </SvgIcon>
  );
};
export default AdminIcon;
