import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const OrdersIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="cls-1"
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        className="cls-2"
        d="M9.61,9.06A.86.86,0,0,1,9,8.82L7.51,7.31A.83.83,0,0,1,8.69,6.13L9.61,7l2-2a.83.83,0,0,1,1.18,1.18L10.2,8.82A.84.84,0,0,1,9.61,9.06Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M9.61,14.32a.83.83,0,0,1-.6-.25l-1.5-1.5a.84.84,0,0,1,0-1.19.83.83,0,0,1,1.18,0l.92.91,2-2a.84.84,0,1,1,1.18,1.19L10.2,14.07A.81.81,0,0,1,9.61,14.32Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M9.61,19.57a.86.86,0,0,1-.6-.24l-1.5-1.51a.83.83,0,0,1,1.18-1.18l.92.91,2-2a.83.83,0,0,1,1.18,1.18L10.2,19.33A.84.84,0,0,1,9.61,19.57Z"
        fill="#fff"
      />
      <line
        className="cls-3"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="15.14"
        x2="24.88"
        y1="18.28"
        y2="18.28"
      />
      <line
        className="cls-3"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="15.14"
        x2="24.88"
        y1="12.58"
        y2="12.58"
      />
      <line
        className="cls-3"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        x1="15.14"
        x2="24.88"
        y1="6.88"
        y2="6.88"
      />
    </SvgIcon>
  );
};
export default OrdersIcon;
