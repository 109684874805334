import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import I18n from 'i18next';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import {
  needDeliverySelector,
  shippingAddressSelectedSelector,
  shippingMethodSelectedSelector,
} from '../../store/selectors';
import makeStyles from '@mui/styles/makeStyles';
import { checkoutSelector } from '../../store/selectors';
import CheckoutForm from './CheckoutForm';
import PageLoader from '../../../../../components/PageLoader';

const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  payment: {
    marginTop: 10,
  },
}));

const Payment = () => {
  const classes = useStyles();

  const checkout = useSelector(checkoutSelector);
  const [stripePromise, setStripePromise] = useState(null);
  const [options, setOptions] = useState(null);
  const needDelivery = useSelector(needDeliverySelector);
  const shippingMethodSelected = useSelector(shippingMethodSelectedSelector);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);

  useEffect(() => {
    if (checkout && checkout.paymentIntent) {
      setOptions({
        clientSecret: checkout.paymentIntent.client_secret,
        locale: I18n.language,
        loader: 'auto',
        appearance: {
          theme: 'stripe',

          variables: {
            colorPrimary: '#007675',
            colorText: '#000000',
            colorDanger: '#df1b41',
            fontFamily: 'Ideal Sans, system-ui, sans-serif',
            spacingUnit: '5px 10px',
            borderRadius: '5px',
          },
          rules: {
            '.Tab': {
              border: '1px solid #c4c4c4',
            },
            '.Tab:hover': {
              boxShadow:
                '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 1px 1px rgba(18, 42, 66, 0.02), 0 0 0 1px rgba(0, 0, 0, 0.1)',
            },
            '.Tab:focus': {
              boxShadow:
                '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },
            '.Tab--selected:hover': {
              boxShadow:
                '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },
            '.Tab--selected:focus': {
              boxShadow:
                '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)',
            },
            '.Input': {
              border: '1px solid #c4c4c4',
            },
            '.Input:focus': {
              border: '1px solid #c4c4c4',
              boxShadow: 'none',
            },
          },
        },
      });
    }
  }, [checkout]);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));
  }, []);

  if (!checkout || !checkout.paymentIntent) {
    return (
      <Grid container justifyContent={'center'} spacing={4}>
        <PageLoader height={150} />
      </Grid>
    );
  }

  return (
    <>
      <Grid alignItems={'center'} container justifyContent={'space-between'}>
        <Grid item>
          <Typography component="h4" variant={'h6'}>
            <Trans i18nKey="payment">Payment</Trans> :
          </Typography>
        </Grid>
      </Grid>

      {!shippingAddressSelected.address && needDelivery && (
        <Typography>
          <Trans i18nKey={'shippingAddressRequired'}>
            You need to add a shipping address to access the payment form
          </Trans>
        </Typography>
      )}
      {!shippingMethodSelected && needDelivery && (
        <Typography>
          <Trans i18nKey={'deliveryMethodRequired'}>
            You need to select a delivery method to access the payment form
          </Trans>
        </Typography>
      )}
      {((shippingAddressSelected.address && shippingMethodSelected) ||
        !needDelivery) && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm className={classes.payment} order={checkout.order} />
        </Elements>
      )}
    </>
  );
};
export default Payment;
