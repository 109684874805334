import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import SearchCustomerAutocomplete from './SearchCustomerAutocomplete';
import Button from '@mui/material/Button';
import { customersService } from '../store/services';
import {
  SnackbarFormattedError,
  SnackbarSuccess,
} from '../../../../helpers/snackbar';
import useDateFns from '../../../../helpers/useDateFns';

const useStyle = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    minWidth: 400,
    paddingBottom: 16,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20,
    },
    '& img': {
      maxWidth: '100%',
      maxHeight: 80,
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30,
  },
}));

const CustomerGiftCardModal = ({ giftCard, onSubmit, open, setOpen }) => {
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dateFns = useDateFns();
  const [userSelected, setUserSelected] = useState(null);

  const handleSendGiftCard = () => {
    customersService
      .sendCustomerGiftCard(userSelected.id, giftCard.id)
      .then(() => {
        SnackbarSuccess(t('giftCardSent'));
        setOpen(false);
        onSubmit();
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
      });
  };

  const handleResendGiftCard = () => {
    customersService
      .sendCustomerGiftCard(giftCard.customer.id, giftCard.id)
      .then(() => {
        SnackbarSuccess(t('giftCardSent'));
        setOpen(false);
        onSubmit();
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectUser = (e, user) => {
    setUserSelected(user);
  };

  if (!giftCard) {
    return null;
  }

  const isAvailable =
    !giftCard.usedAt &&
    (!giftCard.sentAt ||
      dateFns.isBefore(new Date(giftCard.endAt), new Date()));

  return (
    <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        <Trans i18nKey="giftCard">Gift card</Trans>
      </DialogTitle>
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid alignItems={'center'} container>
              <Grid item sm={3} xs={12}>
                <Typography component={'b'}>{t('amount')} :</Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Typography component={'span'} variant={'h6'}>
                  {giftCard.amount} {giftCard.currency}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid alignItems={'center'} container>
              <Grid item sm={3} xs={12}>
                <Typography component={'b'}>{t('code')} :</Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Typography component={'span'} variant={'h6'}>
                  {giftCard.code}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid alignItems={'center'} container>
              <Grid item sm={3} xs={12}>
                <Typography component={'b'}>{t('createdAt')} :</Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                <Typography component={'i'}>
                  {dateFns.format(giftCard.createdAt, 'LLL')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid alignItems={'center'} container>
              <Grid item sm={3} xs={12}>
                <Typography component={'b'}>{t('name')} :</Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                {giftCard.customer && (
                  <Typography component={'i'}>
                    {giftCard.customer.firstName} {giftCard.customer.lastName}
                  </Typography>
                )}
                {!giftCard.customer && (
                  <Typography component={'i'}>
                    <Trans i18nKey={'cardWasNotGivenToACustomer'}>
                      The card was not given to a customer
                    </Trans>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid alignItems={'center'} container>
              <Grid item sm={3} xs={12}>
                <Typography component={'b'}>{t('sentAt')} :</Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                {giftCard.sentAt && (
                  <Typography component={'i'}>
                    {dateFns.format(giftCard.sentAt, 'LLL')}
                  </Typography>
                )}
                {!giftCard.sentAt && (
                  <Typography component={'i'}>
                    <Trans i18nKey={'cardHasNotBeenSent'}>
                      The card has not been sent
                    </Trans>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid alignItems={'center'} container>
              <Grid item sm={3} xs={12}>
                <Typography component={'b'}>{t('usedAt')} :</Typography>
              </Grid>
              <Grid item sm={9} xs={12}>
                {giftCard.usedAt && (
                  <Typography component={'i'}>
                    {dateFns.format(giftCard.usedAt, 'LLL')}
                  </Typography>
                )}
                {!giftCard.usedAt && (
                  <>
                    <Typography
                      color={!isAvailable ? 'error' : 'primary'}
                      component={'i'}
                    >
                      <Trans i18nKey={'cardHasNotBeenUsed'}>
                        The card has not been used
                      </Trans>
                    </Typography>
                    {!isAvailable && (
                      <Typography>
                        {t('giftCardRecoveryDescription', {
                          date: dateFns.format(giftCard.endAt, 'LLL'),
                        })}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {isAvailable && (
            <Grid item xs={12}>
              <Grid alignItems={'center'} container spacing={2}>
                <Grid item sm={3} xs={12}>
                  <Typography component={'b'}>
                    {t('sendTheGiftCard')} :
                  </Typography>
                </Grid>
                <Grid item sm={9} xs={12}>
                  <SearchCustomerAutocomplete onChange={handleSelectUser} />
                </Grid>
                {userSelected && (
                  <>
                    <Grid item sm={3} xs={12}></Grid>
                    <Grid item sm={9} xs={12}>
                      <Button
                        color={'primary'}
                        onClick={handleSendGiftCard}
                        variant={'contained'}
                      >
                        <Trans i18nKey={'sendTheGiftCard'}>
                          Send the gift card
                        </Trans>
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}
          {!isAvailable && !giftCard.usedAt && (
            <Grid item xs={12}>
              <Grid alignItems={'center'} container spacing={2}>
                <Grid item sm={3} xs={12}>
                  <Typography component={'b'}>
                    {t('sendTheGiftCard')} :
                  </Typography>
                </Grid>
                <Grid item sm={9} xs={12}>
                  <Button
                    color={'primary'}
                    onClick={handleResendGiftCard}
                    variant={'contained'}
                  >
                    <Trans i18nKey={'resendTheGiftCard'}>
                      Resend the gift card
                    </Trans>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default CustomerGiftCardModal;
