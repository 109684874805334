import { createSelector } from 'reselect';
import { magReducerKey } from './reducer';
import { initialState } from './initialState';

const magSelector = (globalState) =>
  globalState[magReducerKey] || initialState.mag;

export const getArticleSelector = createSelector([magSelector], (mag) => {
  return mag && mag.article ? mag.article : null;
});

export const getArticlesSelector = createSelector([magSelector], (mag) => {
  return mag && mag.articles ? mag.articles : null;
});
