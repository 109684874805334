import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionsType from './constants';
import { homeActions } from './actions';
import { homeService } from './services';

export const homeSagaKey = 'home';

function* getTreesCount() {
  const count = yield call(homeService.getTreesCount);
  yield put(homeActions.getTreesCountSuccess(count));
}

export default function* watchShop() {
  yield takeEvery(actionsType.GET_TREES_COUNT_REQUEST, getTreesCount);
}
