import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import makeStyles from '@mui/styles/makeStyles';
import Hidden from '@mui/material/Hidden';
import MenuItem from '../Menu/MenuItem';
import SpeedTwoToneIcon from '@mui/icons-material/SpeedTwoTone';
import Menu from '../Menu/Menu';
import { grey } from '@mui/material/colors';
import { orange } from '@mui/material/colors';
import AccountBalanceWalletTwoToneIcon from '@mui/icons-material/AccountBalanceWalletTwoTone';
import { useDispatch, useSelector } from 'react-redux';
import { menuIsOpenSelector } from '../Menu/store/selectors';
import { menuActions } from '../Menu/store/actions';

const drawerWidth = 240;

const useStylesDrawerPro = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('lg')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    '& .MuiToolbar-root': {
      '& img': {
        marginTop: 8,
        width: '100%',
      },
    },
  },
  drawerContainer: {
    overflow: 'auto',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
    '& ul': {
      '& .active': {
        backgroundColor: grey[200],
      },
    },
    '& .MuiCollapse-wrapper': {
      '& .MuiListItem-button': {
        height: 50,
        paddingLeft: 50,
      },
    },
    '& .MuiListItem-button': {
      height: '80px',
      '& .MuiSvgIcon-root': {
        fontSize: '1.7em',
        fill: orange[900],
      },
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const MenuDrawer = () => {
  const classes = useStylesDrawerPro();
  const dispatch = useDispatch();
  const menuIsOpen = useSelector(menuIsOpenSelector);

  const handleDrawerToggle = () => {
    dispatch(menuActions.toggleMenu());
  };

  const drawerList = (
    <div className={classes.drawerContainer}>
      <Menu onItemSelected={handleDrawerToggle}>
        <MenuItem
          icon={<SpeedTwoToneIcon />}
          title={'Dashboard'}
          to={'/accounting'}
        />
        <MenuItem
          icon={<AccountBalanceWalletTwoToneIcon />}
          title={'Factures'}
          to={'/accounting/invoices'}
        />
        <MenuItem
          icon={<AccountBalanceWalletTwoToneIcon />}
          title={'Notes de crédit'}
          to={'/accounting/creditNotes'}
        />
      </Menu>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden implementation="js" lgUp>
        <Drawer
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          className={classes.drawer}
          classes={{
            paper: classes.drawerPaper,
          }}
          onClose={handleDrawerToggle}
          open={menuIsOpen}
          sx={(theme) => ({ zIndex: theme.zIndex.drawer + 2 })}
          variant="temporary"
        >
          <Toolbar>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo-accounting.svg`}
            />
          </Toolbar>
          {drawerList}
        </Drawer>
      </Hidden>
      <Hidden implementation="js" lgDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          open
          variant="permanent"
        >
          <Toolbar />
          {drawerList}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MenuDrawer;
