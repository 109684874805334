import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import HomeBanner from './components/HomeBanner';
import { useSEO } from '../../../helpers/useSEO';

const HomePage = () => {
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('', { fr: '', nl: '', en: '' });

  return (
    <div>
      <Helmet>
        <title itemProp={'name'}>{t('fullBrandNameSharks')}</title>
        <meta content={t('fullBrandNameSharks')} property="og:title" />
        <meta content={t('homeMetaDescription')} name="description" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <HomeBanner />
    </div>
  );
};

export default HomePage;
