import { useTranslation } from 'react-i18next';

export const useSEO = () => {
  const { i18n } = useTranslation();

  const langPaths = {
    fr: ['fr-FR', 'fr-BE', 'fr-CH', 'fr-CA', 'fr-LU'],
    en: ['en-US', 'en-CA', 'en-GB'],
    nl: ['nl-BE', 'nl-NL'],
  };

  const countryPaths = {
    fr: ['fr-FR'],
    be: ['fr-BE', 'nl-BE'],
    ch: ['fr-CH'],
    ca: ['fr-CA', 'en-CA'],
    lu: ['fr-LU'],
    us: ['en-US'],
    gb: ['en-GB'],
    nl: ['nl-NL'],
  };

  const buildAlternatesLangTags = (link, links) => {
    const tags = Object.keys(links).flatMap((lang) =>
      langPaths[lang].map((hrefLang) => ({
        rel: 'alternate',
        href: `${process.env.REACT_APP_WEB_URL}/${hrefLang}${links[lang]}`,
        hrefLang: hrefLang,
      })),
    );

    return [
      ...tags,
      {
        rel: 'alternate',
        href: `${process.env.REACT_APP_WEB_URL}${link}`,
        hrefLang: 'x-default',
      },
      {
        rel: 'canonical',
        href: `${process.env.REACT_APP_WEB_URL}/${langPaths[i18n.language][0]}${
          links[i18n.language]
        }`,
      },
    ];
  };

  const buildAlternatesCountryTags = (link, links) => {
    const tags = Object.keys(links).flatMap((country) =>
      countryPaths[country].map((hrefLang) => ({
        rel: 'alternate',
        href: `${process.env.REACT_APP_WEB_URL}/${hrefLang}${links[country]}`,
        hrefLang: hrefLang,
      })),
    );

    return [
      ...tags,
      {
        rel: 'alternate',
        href: `${process.env.REACT_APP_WEB_URL}${link}`,
        hrefLang: 'x-default',
      },
    ];
  };

  return { buildAlternatesLangTags, buildAlternatesCountryTags };
};
