import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const AcademyIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <defs>
        <clipPath id="clip-path">
          <rect
            className="cls-1"
            fill="none"
            height="11.49"
            width="5.53"
            x="8.07"
            y="9.63"
          />
        </clipPath>
        <clipPath id="clip-path-2">
          <rect
            className="cls-1"
            fill="none"
            height="11.49"
            transform="translate(34.73 30.74) rotate(180)"
            width="6.36"
            x="14.18"
            y="9.63"
          />
        </clipPath>
      </defs>
      <g data-name="Calque 2" id="Calque_2">
        <g data-name="Calque 1" id="Calque_1-2">
          <path
            className="cls-2"
            d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
            fill="#00207f"
          />
          <g className="cls-3" clipPath="url(#clip-path)">
            <path
              className="cls-4"
              d="M15.29,14.67h.42v-.1c0-1.68-.63-2.29-2.12-2.29-.46,0-1.57.11-1.57.74v.55H10.29v-1c0-1.57,2.39-1.8,3.31-1.8,3.11,0,3.94,1.63,3.94,3.75v4.07a.38.38,0,0,0,.42.42h.77v1.57H17c-.85,0-1.19-.42-1.19-1.1a4,4,0,0,1,0-.57h0a3.28,3.28,0,0,1-3.05,1.9,3,3,0,0,1-3.25-2.92c0-3.08,4.16-3.27,5.77-3.27m-2.12,4.71c1.55,0,2.56-1.63,2.56-3.05V16h-.48c-1.38,0-3.88.08-3.88,1.84a1.6,1.6,0,0,0,1.8,1.53"
              fill="#fff"
            />
          </g>
          <g className="cls-5" clipPath="url(#clip-path-2)">
            <path
              className="cls-4"
              d="M15.85,14.67h.42v-.1c0-1.68-.63-2.29-2.12-2.29-.46,0-1.58.11-1.58.74v.55H10.85v-1c0-1.57,2.39-1.8,3.31-1.8,3.11,0,3.94,1.63,3.94,3.75v4.07a.38.38,0,0,0,.42.42h.77v1.57H17.57c-.85,0-1.19-.42-1.19-1.1a4,4,0,0,1,0-.57h0a3.28,3.28,0,0,1-3,1.9,3,3,0,0,1-3.25-2.92c0-3.08,4.16-3.27,5.77-3.27m-2.12,4.71c1.55,0,2.55-1.63,2.55-3.05V16h-.47c-1.38,0-3.88.08-3.88,1.84a1.6,1.6,0,0,0,1.8,1.53"
              fill="#fff"
            />
          </g>
          <path
            className="cls-2"
            d="M1.89,20.24a3.47,3.47,0,0,0,3.45,3.49H7.08v-.62a3.47,3.47,0,0,0-3.44-3.49H1.89Z"
            fill="#00207f"
          />
          <path
            className="cls-4"
            d="M3,13.9a3.47,3.47,0,0,0,1.46,4.68L6,19.39l.29-.55a3.47,3.47,0,0,0-1.46-4.68L3.3,13.35Z"
            fill="#fff"
          />
          <path
            className="cls-4"
            d="M5.66,7.73a3.47,3.47,0,0,0-.85,4.83l1,1.43.5-.36A3.47,3.47,0,0,0,7.18,8.8l-1-1.43Z"
            fill="#fff"
          />
          <path
            className="cls-4"
            d="M10.88,3.92a3.46,3.46,0,0,0-3,3.89l.24,1.74.61-.09a3.47,3.47,0,0,0,3-3.9L11.5,3.83Z"
            fill="#fff"
          />
          <path
            className="cls-4"
            d="M25,13.19l-1.55.8A3.48,3.48,0,0,0,22,18.67l.29.55,1.55-.8a3.46,3.46,0,0,0,1.46-4.68Z"
            fill="#fff"
          />
          <path
            className="cls-4"
            d="M22,7.41,21,8.84a3.47,3.47,0,0,0,.85,4.83l.51.36,1-1.43a3.47,3.47,0,0,0-.85-4.83Z"
            fill="#fff"
          />
          <path
            className="cls-4"
            d="M16.52,3.83l-.24,1.73a3.47,3.47,0,0,0,3,3.9l.61.09.24-1.74a3.46,3.46,0,0,0-3-3.89Z"
            fill="#fff"
          />
          <path
            className="cls-4"
            d="M26.33,19.37H24.58a3.47,3.47,0,0,0-3.45,3.49v.62h1.75A3.48,3.48,0,0,0,26.33,20Z"
            fill="#fff"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
export default AcademyIcon;
