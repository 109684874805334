import axios from '../../../../helpers/axios';

export const shopService = {
  getProduct,
  getProducts,
  addProduct,
  updateProduct,
  addProductPurchasingPrice,
  removeProductPurchasingPrice,
  setProductStatus,
  getProductDetails,
  addOrUpdateProductDetails,
  addOrUpdateOpenaiProductDetails,
  getCategories,
  getCategory,
  addCategory,
  updateCategory,
  setCategoryStatus,
  setCategoryPromoted,
  getStockHistoriesByDates,
  getStockLogsByDates,
  addStockProductQuantity,
  getStockProductsAmounts,
  getStockProcessingProductsAmount,
  getPackage,
  addPackageItem,
  removePackageItem,
  getDiscountCode,
  getDiscountCodes,
  getDiscountCodesAvailable,
  createDiscountCode,
  updateDiscountCode,
};

function getProduct(productId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/product/${productId}`)
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProducts() {
  return new Promise((resolve, reject) => {
    axios
      .get('/shop/admin/products')
      .then(({ products }) => {
        resolve(products);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addProduct(product) {
  return new Promise((resolve, reject) => {
    axios
      .post('/shop/admin/product', product)
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateProduct(productId, product) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop/admin/product/${productId}`, product)
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addProductPurchasingPrice(productId, purchasingPrice) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop/admin/product/${productId}/purchasingPrice`, purchasingPrice)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removeProductPurchasingPrice(productId, id) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/shop/admin/product/${productId}/purchasingPrice/${id}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setProductStatus(productId, status) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop/admin/product/${productId}/status`, status)
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProductDetails(productId, productLang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/product/${productId}/details/${productLang}`)
      .then(({ productDetails }) => {
        resolve(productDetails);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addOrUpdateProductDetails(productId, productLang, productDetails) {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `/shop/admin/product/${productId}/details/${productLang}`,
        productDetails,
      )
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addOrUpdateOpenaiProductDetails(productId, productLang) {
  return new Promise((resolve, reject) => {
    axios
      .put(`/shop/admin/openai/product/${productId}/details/${productLang}`)
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategories() {
  return new Promise((resolve, reject) => {
    axios
      .get('/shop/admin/categories')
      .then(({ categories }) => {
        resolve(categories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addCategory(category) {
  return new Promise((resolve, reject) => {
    axios
      .post('/shop/admin/category', category)
      .then(({ category }) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getCategory(categoryId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/category/${categoryId}`)
      .then(({ category }) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateCategory(categoryId, category) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop/admin/category/${categoryId}`, category)
      .then(({ category }) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setCategoryStatus(categoryId, status) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop/admin/category/${categoryId}/status`, status)
      .then(({ category }) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function setCategoryPromoted(categoryId, promoted) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop/admin/category/${categoryId}/promoted`, promoted)
      .then(({ category }) => {
        resolve(category);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockHistoriesByDates(id = null, start, end) {
  // TODO DEPRECATED
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/stocks${id ? `/${id}` : ''}/histories/${start}/${end}`)
      .then(({ histories }) => {
        resolve(histories);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockLogsByDates(id = null, start, end) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/stocks${id ? `/${id}` : ''}/logs/${start}/${end}`)
      .then(({ logs }) => {
        resolve(logs);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addStockProductQuantity(productId, quantity, note) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/shop/admin/stocks/product/${productId}/quantity`, {
        quantity,
        note,
      })
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockProductsAmounts() {
  return new Promise((resolve, reject) => {
    axios
      .get('/shop/admin/stocks/products/amounts')
      .then(({ stocks }) => {
        resolve(stocks);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getStockProcessingProductsAmount() {
  return new Promise((resolve, reject) => {
    axios
      .get('/shop/admin/stocks/processing/products/amounts')
      .then(({ stocks }) => {
        resolve(stocks);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPackage(productId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/admin/package/${productId}`)
      .then(({ product }) => {
        resolve(product);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function addPackageItem(productId, item) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/shop/admin/product/${productId}/package/item`, item)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function removePackageItem(productId, itemId) {
  return new Promise((resolve, reject) => {
    axios
      .delete(`/shop/admin/product/${productId}/package/item/${itemId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDiscountCode(id) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/discountCode/${id}`)
      .then(({ discountCode }) => {
        resolve(discountCode);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDiscountCodes() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/discountCodes`)
      .then(({ discountCodes }) => {
        resolve(discountCodes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getDiscountCodesAvailable() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/admin/discountCodes/available`)
      .then(({ discountCodes }) => {
        resolve(discountCodes);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function createDiscountCode(data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/admin/discountCode`, data)
      .then(({ discountCode }) => {
        resolve(discountCode);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function updateDiscountCode(id, data) {
  return new Promise((resolve, reject) => {
    axios
      .patch(`/admin/discountCode/${id}`, data)
      .then(({ discountCode }) => {
        resolve(discountCode);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
