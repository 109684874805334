import NativeAxios from 'axios';
import { history } from './store';
import Cookie from './cookie';

// eslint-disable-next-line @typescript-eslint/no-empty-function
function Axios() {}

Axios.prototype = NativeAxios.create();

Axios.prototype.token = null;
Axios.prototype.isRefreshingToken = false;
Axios.prototype.refreshingTokenError = false;

Axios.prototype.init = function () {
  this.setBaseURL(process.env.REACT_APP_API_URL);
  this.setToken(Cookie.get('token'));
  this.setInterceptors();
  return this;
};

Axios.prototype.getToken = function () {
  return this.token;
};

Axios.prototype.setBaseURL = function (baseURL) {
  this.defaults.baseURL = baseURL;
};

Axios.prototype.setToken = function (token) {
  if (token) {
    this.token = token;
    this.defaults.headers.common['Authorization'] = `Bearer ${this.getToken()}`;
  }
};

Axios.prototype.destroyToken = function () {
  this.token = null;
  this.defaults.headers.common['Authorization'] = null;
};

Axios.prototype.refreshToken = function (id) {
  return new Promise((resolve, reject) => {
    if (this.isRefreshingToken) {
      const interval = setInterval(() => {
        if (!this.isRefreshingToken) {
          clearInterval(interval);
          resolve();
        }
        if (this.refreshingTokenError) {
          clearInterval(interval);
          reject({
            response: { data: { error: { type: 'invalid-accesstoken' } } },
          });
        }
      }, 200);
      return;
    }

    this.isRefreshingToken = id;
    let token = Cookie.get('refresh-token');
    if (token === undefined) {
      history.push(`/auth/signout/unauthorized`);
      return;
    }

    NativeAxios.post(`${process.env.REACT_APP_API_URL}/auth/refreshtoken`, {
      token,
    })
      .then(({ data }) => {
        this.setToken(data.token);
        Cookie.set('token', data.token);
        Cookie.set('storage-token', data.storageToken);
        this.isRefreshingToken = false;
        resolve();
      })
      .catch((e) => {
        if (e.response.status === 401) {
          this.refreshingTokenError = true;
          reject({
            response: { data: { error: { type: 'invalid-accesstoken' } } },
          });
          history.push(`/auth/signout/unauthorized`);
        } else {
          reject({ response: { data: { error: { type: 'network-error' } } } });
        }
      });
  });
};

Axios.prototype.setInterceptors = function () {
  this.interceptors.response.use(
    ({ data, status }) => {
      return { status, ...data };
    },
    async (error) => {
      const config = error.config;
      if (
        error.response &&
        error.response.status === 401 &&
        !this.refreshingTokenError &&
        this.isRefreshingToken !== config.url
      ) {
        await this.refreshToken(config.url);
        return await this[config.method](config.url, config.data, {
          headers: {
            ...this.defaults.headers,
            'Content-Type': error.config.headers['Content-Type'],
            Accept: error.config.headers['Accept'],
          },
        });
      } else if (!error.response) {
        return Promise.reject({
          response: { data: { error: { type: 'network-error' } } },
        });
      } else {
        return Promise.reject(error);
      }
    },
  );
};

export default new Axios().init();
