import produce from 'immer';
import * as actionsType from './constants';
import { initialState } from './initialState';

export const academyReducerKey = 'academy';

export default (state = initialState.academy, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case actionsType.GET_ACADEMY_PROGRAM_REQUEST:
        draft.loading = true;
        break;

      case actionsType.GET_ACADEMY_PROGRAM_SUCCESS:
        draft.loading = false;
        draft.program = action.program;
        break;

      case actionsType.GET_ACADEMY_PROGRAM_FAILED:
        draft.loading = false;
        draft.program = null;
        break;

      case actionsType.GET_ACADEMY_PROGRAMS_REQUEST:
        draft.loading = true;
        break;

      case actionsType.GET_ACADEMY_PROGRAMS_SUCCESS:
        draft.loading = false;
        draft.programs = action.programs;
        break;

      case actionsType.GET_ACADEMY_PROGRAMS_FAILED:
        draft.loading = false;
        draft.program = null;
        break;

      default:
    }
  });
