import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import { userService } from '../store/services';
import { SnackbarFormattedError } from '../../../../helpers/snackbar';
import Button from '@mui/material/Button';
import UserOfferGiftCardModal from './UserOfferGiftCardModal';
import CustomerGiftCardModal from '../../../Pro/Customers/components/CustomerGiftCardModal';
import useDateFns from '../../../../helpers/useDateFns';

const UserGiftCardsCard = ({ user }) => {
  const { t } = useTranslation();
  const dateFns = useDateFns();
  const [giftCards, setGiftCards] = useState([]);
  const [giftCardSelected, setGiftCardSelected] = useState(null);
  const [giftCardModalOpen, setGiftCardModalOpen] = useState(false);
  const [isOfferGiftModalOpen, setOfferGiftModalOpen] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(
      window.location.hash?.substring(1),
    );
    if (searchParams.get('checkoutResponse')) {
      setOfferGiftModalOpen(true);
    }
  }, []);

  useEffect(() => {
    loadGiftCards();
  }, [user.id]);

  const loadGiftCards = () => {
    userService
      .getUserGiftCards(user.id)
      .then((giftCards) => setGiftCards(giftCards))
      .catch((e) => SnackbarFormattedError(e.error));
  };

  const handleOfferGiftCartModalOpen = () => {
    setOfferGiftModalOpen(true);
  };

  const handleOpenGiftCardModal = (giftCard) => {
    return () => {
      setGiftCardSelected(giftCard);
      setGiftCardModalOpen(true);
    };
  };

  const handleSendGiftCardModal = () => {
    loadGiftCards();
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent={'center'} spacing={1}>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Grid
              alignItems={'center'}
              container
              justifyContent={'space-between'}
            >
              <Grid item xs={6}>
                <Typography component="h3" variant={'h6'}>
                  <Trans i18nKey={'giftCards'}>Gift cards</Trans> :
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align={'right'}>
                  <Button
                    color={'primary'}
                    disableElevation
                    onClick={handleOfferGiftCartModalOpen}
                    size={'large'}
                    variant="outlined"
                  >
                    <Trans i18nKey={'offerGiftCard'}>Offer gift card</Trans>
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            {!giftCards ||
              (giftCards.length === 0 && (
                <Typography>
                  <Trans i18nKey={'noGiftCard'}>No gift card</Trans>
                </Typography>
              ))}
            {giftCards && (
              <List>
                {giftCards.map((giftCard) => (
                  <ListItem
                    button
                    key={giftCard.id}
                    onClick={handleOpenGiftCardModal(giftCard)}
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <CardGiftcardIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={`${t('sentAt')} ${dateFns.format(
                        giftCard.sentAt,
                        'LLL',
                      )}`}
                      secondary={
                        giftCard.usedAt
                          ? `${t('usedAt')} ${dateFns.format(
                              giftCard.usedAt,
                              'LLL',
                            )}`
                          : `${t('cardHasNotBeenUsed')}`
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CustomerGiftCardModal
        giftCard={giftCardSelected}
        onSubmit={handleSendGiftCardModal}
        open={giftCardModalOpen}
        setOpen={setGiftCardModalOpen}
      />
      <UserOfferGiftCardModal
        customer={user}
        onChange={handleSendGiftCardModal}
        open={isOfferGiftModalOpen}
        setOpen={setOfferGiftModalOpen}
      />
    </Card>
  );
};
export default UserGiftCardsCard;
