import React from 'react';
import AdminRoutes from '../routes/AdminRoutes';
import Header from '../components/Layout/Admin/Header';
import DrawerPro from '../components/Layout/Admin/MenuDrawer';
import NoSsr from '@mui/material/NoSsr';
import { initLanguage } from '../helpers/multilingual';
import statsReducer, {
  statsReducerKey,
} from '../containers/Admin/Stats/store/reducer';
import statsSaga, { statsSagaKey } from '../containers/Admin/Stats/store/saga';
import { useInjectReducers } from '../helpers/injectors/injectReducer';
import { useInjectSagas } from '../helpers/injectors/injectSaga';
import { ThemeProvider } from '@mui/material/styles';
import adminTheme from '../assets/styles/themes/adminTheme';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import useDateFns from '../helpers/useDateFns';

const AdminReducers = [{ key: statsReducerKey, reducer: statsReducer }];
const AdminSagas = [{ key: statsSagaKey, saga: statsSaga }];

function AdminLayout() {
  initLanguage('fr');
  const { locale, localeText } = useDateFns();

  useInjectReducers({ key: 'adminState', reducers: AdminReducers });
  useInjectSagas({ key: 'adminSaga', sagas: AdminSagas });

  return (
    <NoSsr>
      <ThemeProvider theme={adminTheme}>
        <CssBaseline />
        <Header />
        <Box sx={{ display: 'flex' }}>
          <DrawerPro />
          <Box
            component={'main'}
            sx={(theme) => ({
              flexGrow: 1,
              padding: theme.spacing(2),
              [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            })}
          >
            <LocalizationProvider
              adapterLocale={locale}
              dateAdapter={AdapterDateFns}
              localeText={localeText}
            >
              <AdminRoutes />
            </LocalizationProvider>
          </Box>
        </Box>
      </ThemeProvider>
    </NoSsr>
  );
}

export default AdminLayout;
