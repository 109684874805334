import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import { Link } from '../../../../helpers/multilingual';
import { useDispatch } from 'react-redux';
import { shopActions } from '../store/actions';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Card from '@mui/material/Card';
import { getFormalizedName } from '../helpers/helper';
import LazyLoadCardMedia from '../../../../components/LazyLoad/LazyLoadCardMedia';
import { SnackbarConfirm } from '../../../../helpers/snackbar';
import Rating from '@mui/material/Rating';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import ItemPriceDisplay from './ItemPriceDisplay';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    borderRadius: 10,
    '& .MuiCardActionArea-root': {
      paddingBottom: 16,
    },
    '& h3': {
      fontWeight: '500',
    },
    '& .MuiCardMedia-img': {
      objectFit: 'contain',
    },
    '& .MuiCardContent-root': {
      padding: 16,
      height: 110,
      overflow: 'hidden',
      '& a': {
        textDecoration: 'none',
        '& .MuiTypography-h6': {
          color: '#444',
          fontSize: '1.1rem',
          marginBottom: 2,
          lineHeight: '24px',
        },
        '& .MuiTypography-body2': {
          height: 58,
          overflow: 'hidden',
        },
      },
    },
    '& .add-to-cart': {
      position: 'absolute',
      top: 5,
      right: 5,
      minWidth: 0,
      color: grey[600],
      border: `1px solid ${grey[400]}`,
      borderRadius: 8,
      background: '#fff',
      '&:hover': {
        backgroundColor: '#007676',
        borderRadius: 8,
        border: `1px solid #007676`,
        color: 'white',
      },
    },
    '& .rating': {
      display: 'flex',
      '& .count': {
        marginLeft: 8,
        fontSize: '0.8rem',
      },
      position: 'absolute',
      top: 3,
      left: 8,
    },
    '& .priceInfo': {
      position: 'absolute',
      bottom: 111,
      right: 20,
      fontWeight: '500',
    },
    '& .oldPriceInfo': {
      textDecoration: 'line-through',
    },
    '& .proPriceInfo': {
      fontWeight: '500',
      color: 'red',
      marginLeft: 10,
      '& .limitedCommissions': {
        position: 'absolute',
        top: -20,
        right: 0,
        fontSize: 12,
        padding: ' 0 5px',
        backgroundColor: red[500],
        color: '#fff',
      },
      '& .discount': {
        position: 'absolute',
        top: -20,
        right: 0,
        fontSize: 12,
        padding: ' 0 5px',
        backgroundColor: red[500],
        color: '#fff',
      },
    },
  },
}));
const Item = ({ product, session }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const addToCart = (product) => {
    const productData = {
      id: product.id,
      quantity: product.quantity,
      price: product.price,
    };
    import('react-facebook-pixel')
      .then((module) => module.default)
      .then((ReactPixel) => {
        ReactPixel.track('AddToCart', {
          value: product.price,
          currency: product.currency,
          content_type: 'product',
          content_name: product.detail.name,
          content_ids: [product.id],
          contents: [productData],
        });
      });
    if (product.outOfStock && product.type !== 'formation') {
      SnackbarConfirm(t('outOfStockWarning'), () => {
        dispatch(shopActions.addToCart(product, 1));
      });
    } else {
      dispatch(shopActions.addToCart(product, 1));
    }
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <Link
          to={`/shop/product/${getFormalizedName(product.detail.name)}/${
            product.id
          }`}
        >
          <LazyLoadCardMedia
            alt={product.detail.name}
            component="img"
            height="200"
            image={product.image[400]}
            skeletonSrc={product.image[200]}
            title={product.detail.name}
          />
        </Link>
        <CardContent>
          <Link
            to={`/shop/product/${getFormalizedName(product.detail.name)}/${
              product.id
            }`}
          >
            <Typography component="h3" gutterBottom variant="h6">
              {product.detail.name}
            </Typography>
            <Typography color="textSecondary" component="p" variant="body2">
              {product.detail.description}
            </Typography>
          </Link>
        </CardContent>
        <Link
          to={`/shop/product/${getFormalizedName(product.detail.name)}/${
            product.id
          }`}
        >
          <ItemPriceDisplay
            product={product}
            session={session}
            view={'small'}
          />
          {product.reviewsRating && (
            <Typography align={'center'} className={'rating'}>
              <Rating
                disabled
                name="disabled"
                precision={0.25}
                size={'small'}
                value={parseFloat(product.reviewsRating)}
              />
              <Typography className={'count'} component={'span'}>
                {`${product.reviewsCount} ${t('reviewLabel', {
                  count: product.reviewsCount,
                })}`}
              </Typography>
            </Typography>
          )}
        </Link>
      </CardActionArea>
      {product.allowInCart && (
        <Button
          className={'add-to-cart'}
          onClick={() => addToCart(product)}
          title={t('addToCart')}
        >
          <AddShoppingCart />
        </Button>
      )}
    </Card>
  );
};

export default Item;
