import React from 'react';
import Grid from '@mui/material/Grid';
import UserCard from './UserCard';

const UserAccount = ({ user }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <UserCard user={user} />
      </Grid>
    </Grid>
  );
};
export default UserAccount;
