import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import PhoneIcon from '@mui/icons-material/Phone';
import TelegramIcon from '@mui/icons-material/Telegram';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import StorefrontIcon from '@mui/icons-material/Storefront';
import LoopIcon from '@mui/icons-material/Loop';
import Button from '@mui/material/Button';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sessionSelector } from '../Shop/store/selectors';
import { green } from '@mui/material/colors';
import { consultantsActions } from './store/actions';
import { officeSelector } from './store/selectors';
import { Helmet } from 'react-helmet-async';
import { shopActions } from '../Shop/store/actions';
import Header from '../../../components/Layout/Customer/Header/Header';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 100,
    paddingBottom: 50,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  marginTop20: {
    marginTop: 20,
  },
  cover: {
    height: 370,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      height: 260,
    },
  },
  coverImage: {
    width: '100%',
    height: 300,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
  profileImage: {
    position: 'absolute',
    top: 180,
    left: 40,
    height: 180,
    width: 180,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '4px solid white',
    borderRadius: '50%',
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      left: 'calc(50% - 75px)',
      top: 100,
      height: 150,
      width: 150,
    },
  },
  content: {
    position: 'relative',
    padding: '0 30px 30px',
    '& h2': {
      fontSize: '1.6rem',
      fontWeight: 500,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& h4': {
      fontSize: '1.2rem',
      marginBottom: 10,
      fontWeight: 500,
    },
    '& .description': {
      whiteSpace: 'pre-wrap',
    },
    '& a.website': {
      display: 'block',
      wordBreak: 'break-all',
      fontSize: '1.2rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& p': {
      fontSize: '1.2rem',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    '& i': {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        textAlign: 'center',
      },
    },
    '& .MuiListItemText-primary': {
      fontSize: '1.2rem',
    },
    '& ul': {
      padding: 0,
      '& li': {
        paddingTop: 0,
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
          padding: 0,
          textAlign: 'center',
        },
      },
    },
  },
  buttons: {
    margin: theme.spacing(3, 0, 2),
    '& button': {
      fontSize: 16,
      height: 46,
      padding: '8px 20px',
    },
    '& a': {
      fontSize: 16,
      height: 46,
      padding: '8px 20px',
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  consultantButton: {
    '& .MuiButton-label': {
      lineHeight: 'normal',
    },
  },
  consultantSelected: {
    color: green[800],
    fontWeight: 500,
  },
}));

const ConsultantOffice = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const session = useSelector(sessionSelector);
  const [loading, setLoading] = useState(false);
  const office = useSelector(officeSelector);

  useEffect(() => {
    if (!office || office.id !== props.match.params.userId) {
      dispatch(consultantsActions.getOfficeRequest(props.match.params.userId));
    }
  }, []);

  const handleChooseConsultant = async () => {
    setLoading(true);
    dispatch(
      shopActions.signInSessionRequest(
        props.match.params.userId,
        null,
        '/shop',
      ),
    );
  };

  if (!office) {
    return null;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${office.name} - ${office.specialities}`}</title>
        <meta
          content={`${office.name} - ${office.specialities}`}
          property="og:title"
        />
        <meta content="article" property="og:type" />
        <meta
          content={`${process.env.REACT_APP_WEB_URL}${location.pathname}`}
          property="og:url"
        />
        <meta content={`${office.coverPicture}`} property="og:image" />
        <meta
          content={`${office.specialities} - ${office.description}`}
          property="og:description"
        />
        <meta
          content={`${office.specialities} - ${office.description}`}
          name="description"
        />
      </Helmet>
      <Header />
      <Container fixed maxWidth="md">
        <Card>
          <div className={classes.cover}>
            <div
              className={classes.coverImage}
              style={{ backgroundImage: `url(${office.coverPicture})` }}
            />
            <img
              alt="profile picture"
              className={classes.profileImage}
              src={office.profilePicture}
            />
          </div>
          <div className={classes.content}>
            <Grid container justifyContent={'space-between'} spacing={2}>
              <Grid item xs={12}>
                <Typography component={'h2'} gutterBottom>
                  {office.name}
                </Typography>
                <Typography gutterBottom>{office.specialities}</Typography>
                <Typography gutterBottom>{office.address}</Typography>
                {!!office.website && (
                  <Typography
                    className={'website'}
                    component={'a'}
                    gutterBottom
                    href={
                      office.website.startsWith('http')
                        ? office.website
                        : `//${office.website}`
                    }
                    target={'_blank'}
                  >
                    {office.website}
                  </Typography>
                )}
                <Typography
                  className={'description'}
                  component={'i'}
                  gutterBottom
                >
                  {office.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid className={classes.buttons} container spacing={2}>
              {!!office.phone && (
                <Grid item>
                  <Button
                    color={'secondary'}
                    component={'a'}
                    href={`tel:${office.phone}`}
                    startIcon={<PhoneIcon />}
                    target="_blank"
                    variant={'outlined'}
                  >
                    {office.phone}
                  </Button>
                </Grid>
              )}
              {!!office.email && (
                <Grid item>
                  <Button
                    color={'secondary'}
                    component={'a'}
                    href={`mailto:${office.email}`}
                    startIcon={<TelegramIcon />}
                    target="_blank"
                    variant={'outlined'}
                  >
                    {office.email}
                  </Button>
                </Grid>
              )}
              {(!session || !session.registered) && (
                <Grid item>
                  <Button
                    className={classes.consultantButton}
                    color={'secondary'}
                    disabled={loading}
                    onClick={handleChooseConsultant}
                    startIcon={loading ? <LoopIcon /> : <StorefrontIcon />}
                    variant={'contained'}
                  >
                    <Trans i18nKey={'visitTheShop'}>Visit the shop</Trans>
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid
              className={classes.marginTop20}
              container
              justifyContent={'space-between'}
              spacing={2}
            >
              <Grid item md={6} xs={12}>
                <Typography component={'h4'} variant={'h4'}>
                  <Trans i18nKey={'services'}>Services</Trans> :
                </Typography>
                {!!office.services && (
                  <List dense>
                    {office.services.map((service, key) => (
                      <ListItem key={`service${key}`}>
                        <ListItemText
                          primary={`${service.name} ${
                            service.price
                              ? `- ${service.price} ${office.currency}`
                              : ''
                          }`}
                        />
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography component={'h4'} variant={'h4'}>
                  <Trans i18nKey={'openingTime'}>Opening time</Trans> :
                </Typography>
                {!!office.openingTime && (
                  <Typography>{office.openingTime}</Typography>
                )}
              </Grid>
            </Grid>
          </div>
        </Card>
      </Container>
    </div>
  );
};
export default ConsultantOffice;
