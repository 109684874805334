import Box from '@mui/material/Box';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: 64,
    backgroundColor: '#c8e1ea',
    textAlign: 'center',
    paddingTop: 16,
    paddingBottom: 32,
    '& img': {
      paddingRight: 16,
      paddingLeft: 16,
      maxWidth: '100%',
    },
  },
}));

const ZenspireImage = () => {
  const classes = useStyles();
  const { i18n } = useTranslation();

  return (
    <Box className={classes.root}>
      <img
        alt=""
        height={200}
        src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/zenspire/zenspire-banner-${i18n.language}.svg`}
        width={600}
      />
    </Box>
  );
};
export default ZenspireImage;
