import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Breadcrumb from '../../../../components/Layout/Customer/Breadcrumb';
import PaymentResponse from './components/PaymentResponse';
import { useParams } from 'react-router-dom';
import Header from '../../../../components/Layout/Customer/Header/Header';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    paddingTop: 70,
    paddingBottom: 40,
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
  },
}));

const CheckoutResponse = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { orderId } = useParams();

  return (
    <div className={classes.container}>
      <Header />
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Breadcrumb
            parents={[
              {
                name: <Trans i18nKey="shopTitle">Shop</Trans>,
                uri: `/shop`,
              },
              { name: t('checkoutTitle') },
            ]}
          />
        </div>
        <PaymentResponse orderId={orderId} />
      </Container>
    </div>
  );
};

export default CheckoutResponse;
