import * as actionsType from './constants';

export const cookieConsentActions = {
  consent,
};

export function consent() {
  return {
    type: actionsType.CONSENT,
  };
}
