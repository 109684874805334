import React from 'react';
import Box from '@mui/material/Box';
import Header from '../../../../components/Layout/Customer/Header/Header';

const HomeBanner = () => {
  return (
    <>
      <Header transparent={true} />
      <Box
        sx={{
          width: '100%',
          height: {
            lg: 700,
            md: 600,
            xs: 400,
          },
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Box
          style={{
            display: 'flex',
          }}
          sx={{
            height: '100%',
            alignItems: 'center',
            alignContent: 'center',
            justifyContent: 'center',
            '& img': {
              maxHeight: '300px',
            },
          }}
        >
          <img
            alt="home"
            src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.jpeg`}
          />
        </Box>
      </Box>
    </>
  );
};
export default HomeBanner;
