import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { RouteSuperAdmin } from '../containers/Authentication/store/helper';
import UserLayout from '../containers/Admin/Users/UserLayout';
import UsersTable from '../containers/Admin/Users/UsersTable';

const AdminRoutes = () => {
  const Dashboard = loadable(
    () => import('../containers/Admin/Dashboard/Dashboard'),
  );
  const Invoices = loadable(
    () => import('../containers/Admin/Accounting/Invoices'),
  );
  const Proformas = loadable(
    () => import('../containers/Admin/Accounting/Proformas'),
  );
  const Quotes = loadable(
    () => import('../containers/Admin/Accounting/Quotes'),
  );
  const CreditNotes = loadable(
    () => import('../containers/Admin/Accounting/CreditNotes'),
  );
  const Order = loadable(() => import('../containers/Admin/Accounting/Order'));
  const CommissionsCreditNotes = loadable(
    () => import('../containers/Admin/Accounting/CommissionsCreditNotes'),
  );
  const BankTransfers = loadable(
    () => import('../containers/Admin/Accounting/BankTransfers'),
  );
  const Products = loadable(
    () => import('../containers/Admin/Shop/Products/Products'),
  );
  const Product = loadable(
    () => import('../containers/Admin/Shop/Products/Product'),
  );
  const AddProduct = loadable(
    () => import('../containers/Admin/Shop/Products/AddProduct'),
  );
  const ProductDetails = loadable(
    () => import('../containers/Admin/Shop/Products/ProductDetails'),
  );
  const Categories = loadable(
    () => import('../containers/Admin/Shop/Categories/Categories'),
  );
  const Category = loadable(
    () => import('../containers/Admin/Shop/Categories/Category'),
  );
  const AddCategory = loadable(
    () => import('../containers/Admin/Shop/Categories/AddCategory'),
  );
  const Stocks = loadable(
    () => import('../containers/Admin/Shop/Stocks/Stocks'),
  );
  const StocksOld = loadable(
    () => import('../containers/Admin/Shop/Stocks/StocksOld'),
  );
  const DiscountCodes = loadable(
    () => import('../containers/Admin/Shop/DiscountCodes/DiscountCodes'),
  );
  const UsersMap = loadable(() => import('../containers/Admin/Users/UsersMap'));
  const AddUser = loadable(() => import('../containers/Admin/Users/AddUser'));
  const NetworkTree = loadable(
    () => import('../containers/Admin/Users/NetworkTree'),
  );
  const NetworkTable = loadable(
    () => import('../containers/Admin/Users/NetworkTable'),
  );
  const UsersTable = loadable(
    () => import('../containers/Admin/Users/UsersTable'),
  );
  const PsychomedTable = loadable(
    () => import('../containers/Admin/Users/PsychomedTable'),
  );
  const PartnershipEventTable = loadable(
    () => import('../containers/Admin/Users/PartnershipEventTable'),
  );
  const NetworkStats = loadable(
    () => import('../containers/Admin/Stats/Network'),
  );
  const StocksStatsOld = loadable(
    () => import('../containers/Admin/Stats/StocksOld'),
  );
  const StocksStats = loadable(
    () => import('../containers/Admin/Stats/Stocks'),
  );
  const SalesStats = loadable(() => import('../containers/Admin/Stats/Sales'));
  const OrganicStats = loadable(
    () => import('../containers/Admin/Stats/Organic'),
  );
  const MailingCreator = loadable(
    () => import('../containers/Admin/Mailings/MailingCreator'),
  );
  const MailingDraft = loadable(
    () => import('../containers/Admin/Mailings/MailingDraft'),
  );
  const MailingsDraft = loadable(
    () => import('../containers/Admin/Mailings/MailingsDraft'),
  );
  const MailingQueued = loadable(
    () => import('../containers/Admin/Mailings/MailingQueued'),
  );
  const MailingsQueued = loadable(
    () => import('../containers/Admin/Mailings/MailingsQueued'),
  );
  const MailingCompleted = loadable(
    () => import('../containers/Admin/Mailings/MailingCompleted'),
  );
  const MailingsCompleted = loadable(
    () => import('../containers/Admin/Mailings/MailingsCompleted'),
  );
  const SMSCampaigns = loadable(
    () => import('../containers/Admin/SMSCampaigns/SMSCampaigns'),
  );
  const AddSMSCampaign = loadable(
    () => import('../containers/Admin/SMSCampaigns/AddSMSCampaign'),
  );
  const SMSCampaign = loadable(
    () => import('../containers/Admin/SMSCampaigns/SMSCampaign'),
  );
  const Errors = loadable(() => import('../containers/Admin/Error/Errors'));
  const Logs = loadable(() => import('../containers/Admin/Logs/Logs'));
  const Error404 = loadable(() => import('../containers/Pro/Error'));

  return (
    <Switch>
      <Route component={Dashboard} exact path="/admin" />
      <RouteSuperAdmin
        component={Invoices}
        exact
        path="/admin/accounting/invoices"
      />
      <RouteSuperAdmin
        component={Proformas}
        exact
        path="/admin/accounting/proformas"
      />
      <RouteSuperAdmin
        component={Quotes}
        exact
        path="/admin/accounting/quotes"
      />
      <RouteSuperAdmin
        component={CreditNotes}
        exact
        path="/admin/accounting/creditNotes"
      />
      <RouteSuperAdmin
        component={Order}
        exact
        path="/admin/accounting/order/:orderId"
      />
      <RouteSuperAdmin
        component={CommissionsCreditNotes}
        exact
        path="/admin/accounting/commissions"
      />
      <RouteSuperAdmin
        component={BankTransfers}
        exact
        path="/admin/accounting/transfers"
      />
      <Route component={Categories} exact path="/admin/shop/categories" />
      <Route component={AddCategory} exact path="/admin/shop/category/add" />
      <Route
        component={Category}
        exact
        path="/admin/shop/category/:categoryId"
      />
      <Route component={Products} exact path="/admin/shop/products" />
      <Route component={AddProduct} exact path="/admin/shop/product/add" />
      <Route
        component={ProductDetails}
        exact
        path="/admin/shop/product/:productId/details/:productLang"
      />
      <Route component={Product} exact path="/admin/shop/product/:productId" />
      <Route component={Stocks} exact path="/admin/shop/stocks" />
      <Route component={StocksOld} exact path="/admin/shop/stocks/old" />
      <Route component={DiscountCodes} exact path="/admin/shop/discounts" />
      <RouteSuperAdmin component={UsersMap} exact path="/admin/map" />
      <RouteSuperAdmin
        component={NetworkTree}
        exact
        path="/admin/network/tree"
      />
      <RouteSuperAdmin
        component={NetworkTable}
        exact
        path="/admin/network/table"
      />
      <RouteSuperAdmin component={UsersTable} exact path="/admin/users" />
      <RouteSuperAdmin
        component={PsychomedTable}
        exact
        path="/admin/psychomed"
      />
      <RouteSuperAdmin
        component={PartnershipEventTable}
        exact
        path="/admin/partnership/event/table"
      />
      <RouteSuperAdmin component={AddUser} exact path="/admin/users/add" />
      <RouteSuperAdmin
        component={UserLayout}
        exact
        path="/admin/users/:userId"
      />
      <RouteSuperAdmin
        component={UserLayout}
        exact
        path="/admin/users/:userId/*"
      />
      <RouteSuperAdmin
        component={NetworkStats}
        exact
        path="/admin/stats/network"
      />
      <Route component={StocksStatsOld} exact path="/admin/stats/stocks/old" />
      <Route component={StocksStats} exact path="/admin/stats/stocks" />
      <Route component={SalesStats} exact path="/admin/stats/sales" />
      <Route component={OrganicStats} exact path="/admin/stats/organic" />
      <RouteSuperAdmin
        component={MailingCreator}
        exact
        path="/admin/mailings/creator"
      />
      <RouteSuperAdmin
        component={MailingsDraft}
        exact
        path="/admin/mailings/draft"
      />
      <RouteSuperAdmin
        component={MailingDraft}
        exact
        path="/admin/mailings/draft/:mailingId"
      />
      <RouteSuperAdmin
        component={MailingsQueued}
        exact
        path="/admin/mailings/queued"
      />
      <RouteSuperAdmin
        component={MailingQueued}
        exact
        path="/admin/mailings/queued/:mailingId"
      />
      <RouteSuperAdmin
        component={MailingsCompleted}
        exact
        path="/admin/mailings/completed"
      />
      <RouteSuperAdmin
        component={MailingCompleted}
        exact
        path="/admin/mailings/completed/:mailingId"
      />
      <RouteSuperAdmin
        component={SMSCampaigns}
        exact
        path="/admin/sms/campaigns"
      />
      <RouteSuperAdmin
        component={AddSMSCampaign}
        exact
        path="/admin/sms/campaign/add"
      />
      <RouteSuperAdmin
        component={SMSCampaign}
        exact
        path="/admin/sms/campaign/:campaignId"
      />
      <Route component={Errors} exact path="/admin/errors" />
      <RouteSuperAdmin component={Logs} exact path="/admin/logs/:level" />
      <Route component={Error404} path="*" />
    </Switch>
  );
};

export default AdminRoutes;
