import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Rating from '@mui/material/Rating';
import i18n from 'i18next';
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';
import { green } from '@mui/material/colors';
import useDateFns from '../../../../../helpers/useDateFns';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .author': {
      fontSize: 14,
      margin: theme.spacing(1),
    },
    '& .review': {
      display: 'inline-block',
      minWidth: '40vw',
      borderRadius: 8,
      whiteSpace: 'pre-wrap',
      margin: theme.spacing(1),
    },
    '& .answer': {
      color: green[800],
      display: 'inline-block',
      minWidth: '40vw',
      borderRadius: 8,
      whiteSpace: 'pre-wrap',
      margin: theme.spacing(1),
    },
  },
}));

const Review = ({ review }) => {
  const classes = useStyles();
  const dateFns = useDateFns();

  const [translation, setTranslation] = useState(false);

  const handleShowTranslation = () => {
    setTranslation(true);
  };

  const handleHideTranslation = () => {
    setTranslation(false);
  };

  return (
    <div className={classes.root}>
      <Typography className={'author'}>
        {review.author} - {dateFns.format(review.createdAt, 'LL')}
      </Typography>
      <div>
        <Rating
          disabled
          name="disabled"
          precision={0.5}
          value={parseFloat(review.rating)}
        />
      </div>
      <Typography className={`review`} gutterBottom>
        {!translation ? review.review : review.translation}
      </Typography>
      {review.answer && (
        <Typography className={'answer'} component={'div'}>
          <Typography>Sharks -</Typography>
          {!translation ? review.answer.review : review.answer.translation}
        </Typography>
      )}
      {review.translation && review.lang !== i18n.language && !translation && (
        <div>
          <Button
            onClick={handleShowTranslation}
            size={'small'}
            variant={'outlined'}
          >
            <Trans i18nKey={'translate'}>Translate</Trans>
          </Button>
        </div>
      )}
      {review.review && review.lang !== i18n.language && translation && (
        <div>
          <Button
            onClick={handleHideTranslation}
            size={'small'}
            variant={'outlined'}
          >
            <Trans i18nKey={'showOriginal'}>Show original</Trans>
          </Button>
        </div>
      )}
    </div>
  );
};
export default Review;
