import { Helmet } from 'react-helmet-async';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import ZenspireBanner from './components/ZenspireBanner';
import Box from '@mui/material/Box';
import ZenspireImage from './components/ZenspireImage';
import ZenspireContent from './components/ZenspireContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from '../../../helpers/multilingual';
import Button from '@mui/material/Button';
import ZenspireHowItWorks from './components/ZenspireHowItWorks';
import { useSEO } from '../../../helpers/useSEO';
import ZenspireShop from './components/ZenspireShop';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 40,
    '& .container': {
      '& h4': {
        color: '#1b76ba',
        marginBottom: 32,
      },
      '& p': {
        color: '#1b76ba',
        fontSize: 18,
        marginTop: 16,
        fontWeight: 'bold',
      },
      '& li': {
        color: '#1b76ba',
        fontSize: 18,
        fontWeight: 'bold',
      },
      '& .MuiButton-root': {
        backgroundColor: '#1b76ba',
        fontSize: 18,
        marginTop: 32,
      },
      '& .mt-64': {
        marginTop: 64,
      },
      '& .mt-32': {
        marginTop: 32,
      },
    },
  },
}));

const ZenspireHome = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/zenspire', {
    fr: '/zenspire',
    nl: '/zenspire',
    en: '/zenspire',
  });

  return (
    <div className={classes.root}>
      <Helmet>
        <title itemProp={'name'}>{'Zenspire'}</title>
        <meta content={'Zenspire'} property="og:title" />
        <meta content={t('zenspireText22')} name="description" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <ZenspireBanner />
      <div className={'container'}>
        <Container maxWidth={'xl'}>
          <Grid alignItems={'center'} className={'mt-64'} container>
            <Grid item sm={6} xs={12}>
              <ZenspireContent />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box>
                <Typography variant={'h4'}>{t('zenspireText1')}</Typography>
                <Typography>{t('zenspireText2')}</Typography>
                <Typography>{t('zenspireText3')}</Typography>
                <Typography>{t('zenspireText4')}</Typography>
                <Typography>{t('zenspireText5')}</Typography>
                <Button
                  color={'primary'}
                  component={Link}
                  onClick={() => window.scrollTo(0, 0)}
                  size={'large'}
                  to={
                    '/shop/category/technologie/a4c510fa-e2b6-48d4-8aaa-58f3f2e4d562'
                  }
                  variant={'contained'}
                >
                  {t('orderYourZenspire')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} sx={{ mt: 10 }}>
                <Grid item xs={12}>
                  <Typography sx={{ textTransform: 'uppercase' }}>
                    {t('zenspireText16')}
                  </Typography>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Box
                    style={{ padding: '56.21% 0 0 0', position: 'relative' }}
                  >
                    <iframe
                      allow="autoplay; fullscreen; picture-in-picture"
                      frameBorder="0"
                      src="https://player.vimeo.com/video/912987444?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      title="Préparez votre rentrée"
                    ></iframe>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Box
                    style={{ padding: '56.21% 0 0 0', position: 'relative' }}
                  >
                    <iframe
                      allow="autoplay; fullscreen; picture-in-picture"
                      frameBorder="0"
                      src="https://player.vimeo.com/video/912987291?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                      }}
                      title="zenspire-stress"
                    ></iframe>
                  </Box>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <Box
                    style={{
                      width: '100%',
                      position: 'relative',
                      paddingBottom: '56.25%',
                      overflow: 'hidden',
                      backgroundColor: '#000',
                    }}
                  >
                    <iframe
                      allow="autoplay; fullscreen; picture-in-picture"
                      frameBorder="0"
                      src="https://player.vimeo.com/video/913338995?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                      style={{
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        height: '100%',
                        width: '100%',
                      }}
                      title="zenspire-sleep"
                    ></iframe>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <ZenspireImage />
        <Container className={'mt-64'} maxWidth={'xl'}>
          <Grid
            container
            justifyContent={'space-between'}
            maxWidth={'lg'}
            spacing={2}
          >
            <Grid item sm={6} xs={12}>
              <Box>
                <Box style={{ padding: '56.21% 0 0 0', position: 'relative' }}>
                  <iframe
                    allow="autoplay; fullscreen; picture-in-picture"
                    frameBorder="0"
                    src="https://player.vimeo.com/video/913369744?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                    title="Préparez votre rentrée"
                  ></iframe>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box>
                <List sx={{ p: 0 }}>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText primary={t('zenspireText17')} />
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText primary={t('zenspireText18')} />
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText primary={t('zenspireText19')} />
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText primary={t('zenspireText20')} />
                  </ListItem>
                  <ListItem sx={{ p: 0 }}>
                    <ListItemText primary={t('zenspireText21')} />
                  </ListItem>
                  <Typography>{t('zenspireText22')}</Typography>
                </List>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <ZenspireHowItWorks />
      </div>
      <ZenspireShop />
    </div>
  );
};
export default ZenspireHome;
