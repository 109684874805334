import React from 'react';
import { Trans } from 'react-i18next';
import Button from '@mui/material/Button';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { checkoutSelector } from '../../store/selectors';
import { shopService } from '../../store/services';
import { SnackbarFormattedError } from '../../../../../helpers/snackbar';
import { useCustomerPath } from '../../../../../helpers/usePath';

const useStyles = makeStyles((theme) => ({
  btn: {
    borderRadius: 8,
    fontSize: 16,
    height: 46,
    padding: '8px 20px',
    margin: theme.spacing(3, 0, 2),
  },
}));

const NoPayment = () => {
  const path = useCustomerPath();
  const classes = useStyles();
  const checkout = useSelector(checkoutSelector);

  const handleValidateOrder = () => {
    shopService
      .checkoutValidate(checkout.order)
      .then((invoice) => {
        window.location.replace(
          `${process.env.REACT_APP_WEB_URL}${path}/shop/order/${invoice.orderId}/verify`,
        );
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Button
      className={classes.btn}
      color={'secondary'}
      onClick={handleValidateOrder}
      variant={'contained'}
    >
      <Trans i18nKey={'validateOrder'}>Validate order</Trans>
    </Button>
  );
};
export default NoPayment;
