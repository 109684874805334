import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isSuperAdmin,
  isAdmin,
  isEditor,
  isCustomer,
  isLogistic,
  isPro,
  isRegistered,
  isAccountant,
  hasMembershipSelector,
  reloadUserRequested,
  userSelector,
} from './selectors';
import { Route } from 'react-router-dom';
import { Redirect } from '../../../helpers/multilingual';
import { history } from '../../../helpers/store';
import { sessionSelector } from '../../Customer/Shop/store/selectors';
import { authenticationActions } from './actions';
import { useTranslation } from 'react-i18next';
import { SnackbarError } from '../../../helpers/snackbar';
import { PARTNER_CAREER } from './constants';
import { useProPath } from '../../../helpers/usePath';
import { Redirect as DomRedirect } from 'react-router-dom';

export const ReloadUser = () => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userHasBeenReloaded = useSelector(reloadUserRequested);

  useEffect(() => {
    if (user && !userHasBeenReloaded) {
      dispatch(authenticationActions.reloadUserRequest());
    }
  }, []);
};

export const LockWithoutMembership = ({ exception = false, redirect }) => {
  const path = useProPath();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hasMembership = useSelector(hasMembershipSelector);

  useEffect(() => {
    if (!hasMembership && !exception) {
      SnackbarError(t('needMembership'));
      dispatch(authenticationActions.setMembershipModalOpen(true));
      history.push(`${path}${redirect}`);
    }
  }, [exception]);
};

export const ExcludePartner = ({ redirect }) => {
  const path = useProPath();
  const { t } = useTranslation();
  const { career } = useSelector(userSelector);

  useEffect(() => {
    if (career === PARTNER_CAREER) {
      SnackbarError(t('permissionDenied'));
      history.push(`${path}${redirect}`);
    }
  }, []);
};

export const RouteSuperAdmin = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasRoleSuperAdmin = useSelector(isSuperAdmin);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasRoleSuperAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RouteAdmin = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasRoleAdmin = useSelector(isAdmin);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasRoleAdmin ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RoutePro = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasRolePro = useSelector(isPro);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasRolePro ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RouteLogistic = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasRoleLogistic = useSelector(isLogistic);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasRoleLogistic ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RouteEditor = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasRoleEditor = useSelector(isEditor);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasRoleEditor ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RouteAccounting = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasRoleAccountant = useSelector(isAccountant);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasRoleAccountant ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RouteCustomer = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasRoleCustomer = useSelector(isCustomer);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasRoleCustomer ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RouteRegistered = ({ component: Component, ...rest }) => {
  const user = useSelector(userSelector);
  const hasBeenRegistered = useSelector(isRegistered);
  return (
    <Route
      {...rest}
      render={(props) => {
        return hasBeenRegistered ? (
          <Component {...props} />
        ) : (
          <Redirect
            params={{ from: props.location }}
            to={!user ? '/auth/signin' : '/'}
          />
        );
      }}
    />
  );
};

export const RouteShopSession = ({ component: Component, ...rest }) => {
  const session = useSelector(sessionSelector);
  return (
    <Route
      {...rest}
      render={(props) => {
        return session ? (
          <Component {...props} />
        ) : (
          <Redirect params={{ from: props.location }} to={'/shop'} />
        );
      }}
    />
  );
};

export const RouteNewUrl = ({ ...rest }) => {
  const { i18n } = useTranslation();
  const session = useSelector(sessionSelector);
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <DomRedirect
            to={{
              pathname: `/${props.match.params.lang || i18n.language}-${
                session?.country || 'BE'
              }/${props.match.params.path || ''}`,
            }}
          />
        );
      }}
    />
  );
};
