import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  RoutePro,
  RouteRegistered,
  RouteShopSession,
} from '../containers/Authentication/store/helper';
import loadable from '@loadable/component';
import Home from '../containers/Customer/Home/Home';
import CareerOpportunity from '../containers/Customer/CareerOpportunity/CareerOpportunity';
import PartnerWay from '../containers/Customer/CareerOpportunity/Partnership';
import AmbassadorWay from '../containers/Customer/CareerOpportunity/Ambassador';
import MicroFranchiseeWay from '../containers/Customer/CareerOpportunity/MicroFranchisee';
import SignIn from '../containers/Authentication/SignIn';
import Contact from '../containers/Customer/Contact';
import TermsOfSales from '../containers/Customer/TermsOfSales';
import Article from '../containers/Customer/Mag/Article';
import Articles from '../containers/Customer/Mag/Articles';
import ConsultantOffice from '../containers/Customer/Consultants/ConsultantOffice';
import DirectLinkLayout from '../layouts/DirectLinkLayout';
import Catalog from '../containers/Customer/Shop/Catalog';
import CatalogProduct from '../containers/Customer/Shop/CatalogProduct';
import Shop from '../containers/Customer/Shop/Shop';
import Product from '../containers/Customer/Shop/Product/Product';
import EnvironmentalFriendly from '../containers/Customer/EnvironmentalFriendly/EnvironmentalFriendly';
import Checkout from '../containers/Customer/Shop/Checkout/Checkout';
import Account from '../containers/Customer/Account/Account';
import CheckoutResponse from '../containers/Customer/Shop/Checkout/CheckoutResponse';
import AcademyPrograms from '../containers/Customer/Academy/AcademyPrograms';
import AcademyProgramPresentation from '../containers/Customer/Academy/AcademyProgramPresentation';
import ZenspireHome from '../containers/Customer/Zenspire/ZenspireHome';

const CustomerRoutes = () => {
  //const Account = loadable(() => import("../containers/Customer/Account/Account"));
  const ShopPro = loadable(() => import('../containers/Customer/Shop/ShopPro'));
  //const Checkout = loadable(() => import("../containers/Customer/Shop/Checkout/Checkout"));
  const Reviews = loadable(
    () => import('../containers/Customer/Shop/Reviews/Reviews'),
  );
  //const CheckoutResponse = loadable(() => import("../containers/Customer/Shop/Checkout/CheckoutResponse"));
  const Profiling = loadable(
    () =>
      import('../containers/Customer/CareerOpportunity/Profiling/Profiling'),
  );
  const Welcome = loadable(
    () => import('../containers/Authentication/Welcome'),
  );
  const Partnership = loadable(
    () => import('../containers/Authentication/Partnership'),
  );
  const Event = loadable(() => import('../containers/Customer/Event'));
  const SignInJwt = loadable(
    () => import('../containers/Authentication/SignInJwt'),
  );
  const ForgotPassword = loadable(
    () => import('../containers/Authentication/ForgotPassword'),
  );
  const PasswordUpdate = loadable(
    () => import('../containers/Authentication/PasswordUpdate'),
  );
  const EmailUpdate = loadable(
    () => import('../containers/Authentication/EmailUpdate'),
  );
  const SignOut = loadable(
    () => import('../containers/Authentication/SignOut'),
  );
  const ConsultantMap = loadable(
    () => import('../containers/Customer/Consultants/ConsultantsMap'),
  );
  const SimplifiedAnamneses = loadable(
    () =>
      import('../containers/Customer/SimplifiedAnamnesis/SimplifiedAnamneses'),
  );
  const AddSimplifiedAnamnesis = loadable(
    () =>
      import(
        '../containers/Customer/SimplifiedAnamnesis/AddSimplifiedAnamnesis'
      ),
  );
  const SimplifiedAnamnesis = loadable(
    () =>
      import('../containers/Customer/SimplifiedAnamnesis/SimplifiedAnamnesis'),
  );
  const SimplifiedAnamnesisResults = loadable(
    () =>
      import(
        '../containers/Customer/SimplifiedAnamnesis/SimplifiedAnamnesisResults'
      ),
  );
  const MailingUnsubscribe = loadable(
    () => import('../containers/Customer/Mailing/MailingUnsubscribe'),
  );
  const MailingProUnsubscribe = loadable(
    () => import('../containers/Customer/Mailing/MailingProUnsubscribe'),
  );
  const AcademyProgram = loadable(
    () => import('../containers/Customer/Academy/AcademyProgram'),
  );
  const PartnershipEvent = loadable(
    () => import('../containers/Customer/PartnershipEvent'),
  );
  const PartnershipEventWelcome = loadable(
    () => import('../containers/Customer/PartnershipEventWelcome'),
  );
  const Error = loadable(() => import('../containers/Customer/Error'));

  return (
    <Switch>
      <Route component={Home} exact path="/:lang(en|fr|nl)-:country" />
      <Route
        component={SignIn}
        exact
        path="/:lang(en|fr|nl)-:country/auth/signin"
      />
      <Route
        component={SignInJwt}
        exact
        path="/:lang(en|fr|nl)-:country/auth/signin/:userId/jwt/:jwt/redirect/:redirect+"
      />
      <Route
        component={ForgotPassword}
        exact
        path="/:lang(en|fr|nl)-:country/auth/forgot-password"
      />
      <Route
        component={SignIn}
        exact
        path="/:lang(en|fr|nl)-:country/auth/failed"
      />
      <Route
        component={SignOut}
        exact
        path="/:lang(en|fr|nl)-:country/auth/signout"
      />
      <Route
        component={SignOut}
        exact
        path="/:lang(en|fr|nl)-:country/auth/signout/:status"
      />
      <Route
        component={SignIn}
        exact
        path="/:lang(en|fr|nl)-:country/auth/success/:jwt"
      />
      <Route
        component={Welcome}
        exact
        path="/:lang(en|fr|nl)-:country/auth/welcome/:jwt"
      />
      <Route
        component={PasswordUpdate}
        exact
        path="/:lang(en|fr|nl)-:country/auth/password/update/:jwt"
      />
      <Route
        component={EmailUpdate}
        exact
        path="/:lang(en|fr|nl)-:country/auth/email/update/:jwt"
      />
      <Route
        component={MailingUnsubscribe}
        exact
        path="/:lang(en|fr|nl)-:country/mailings/unsubscribe/:email/:userId"
      />
      <Route
        component={Contact}
        exact
        path="/:lang(en|fr|nl)-:country/contact"
      />
      <Route component={Error} path="*" />
    </Switch>
  );
};

export default CustomerRoutes;
