import List from '@mui/material/List';
import MobileMenuItem from './MobileMenuItem';
import { Link, ProLink } from '../../../../../helpers/multilingual';
import AcademyIcon from '../../../../Icons/AcademyIcon';
import PartnershipIcon from '../../../../Icons/PartnershipIcon';
import MagIcon from '../../../../Icons/MagIcon';
import ContactIcon from '../../../../Icons/ContactIcon';
import Divider from '@mui/material/Divider';
import { Link as DomLink } from 'react-router-dom';
import AdminIcon from '../../../../Icons/AdminIcon';
import AccountingIcon from '../../../../Icons/AccountingIcon';
import CalendarIcon from '../../../../Icons/CalendarIcon';
import ProIcon from '../../../../Icons/ProIcon';
import AccountIcon from '../../../../Icons/AccountIcon';
import HeartIcon from '../../../../Icons/HeartIcon';
import OrdersIcon from '../../../../Icons/OrdersIcon';
import AwardIcon from '../../../../Icons/AwardIcon';
import TruckIcon from '../../../../Icons/TruckIcon';
import SettingsIcon from '../../../../Icons/SettingsIcon';
import SignOutIcon from '../../../../Icons/SignOutIcon';
import Drawer from '@mui/material/Drawer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isAccountant,
  isAdmin,
  isEditor,
  isLogistic,
  isPro,
  isSessionControl,
  userSelector,
} from '../../../../../containers/Authentication/store/selectors';
import { authenticationActions } from '../../../../../containers/Authentication/store/actions';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import MobileShopMenu from './MobileShopMenu';

const MobileMenu = ({ isOpen, setOpen }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector(userSelector);
  const userIsAdmin = useSelector(isAdmin);
  const userIsLogistic = useSelector(isLogistic);
  const userIsEditor = useSelector(isEditor);
  const userIsAccountant = useSelector(isAccountant);
  const userIsPro = useSelector(isPro);
  const userIsSessionControl = useSelector(isSessionControl);

  const handleRestoreSessionControl = () => {
    setOpen(false);
    dispatch(authenticationActions.restoreControlRequest());
  };

  const handleSignOut = () => {
    setOpen(false);
    dispatch(authenticationActions.signOut());
  };

  const handleClose = () => {
    setOpen(false);
  };

  const CloseIcon = (props) => {
    return (
      <SvgIcon {...props}>
        <path
          d="M12 4c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm4 10.59L14.59 16 12 13.41 9.41 16 8 14.59 10.59 12 8 9.41 9.41 8 12 10.59 14.59 8 16 9.41 13.41 12 16 14.59z"
          fill="#FFFFFF"
          opacity=".3"
        ></path>
        <path
          d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"
          fill="#007876"
        ></path>
      </SvgIcon>
    );
  };

  return (
    <Drawer
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      PaperProps={{
        sx: {
          width: 300,
        },
      }}
      anchor={'left'}
      onClose={() => setOpen(false)}
      open={isOpen}
      sx={{
        display: {
          md: 'none',
          sm: 'block',
        },
      }}
      variant="temporary"
    >
      <div>
        <Box sx={(theme) => theme.mixins.toolbar} />
        <List>
          <MobileMenuItem
            hidden={!userIsSessionControl}
            icon={<CloseIcon />}
            onClick={handleRestoreSessionControl}
            title={'Arrêter la session'}
          />
          <MobileShopMenu onClick={handleClose} />
          <MobileMenuItem
            component={Link}
            icon={<AcademyIcon />}
            onClick={handleClose}
            title={t('academy')}
            to={'/academy'}
          />
          <MobileMenuItem
            component={Link}
            icon={<PartnershipIcon />}
            onClick={handleClose}
            title={t('becomePartner')}
            to={'/career-opportunity'}
          />
          <MobileMenuItem
            component={Link}
            icon={<MagIcon />}
            onClick={handleClose}
            title={t('theMAG')}
            to={'/mag'}
          />
          <MobileMenuItem
            component={Link}
            icon={<ContactIcon />}
            onClick={handleClose}
            title={t('contact')}
            to={'/contact'}
          />
        </List>
        <Divider />
        <List>
          <MobileMenuItem
            component={DomLink}
            hidden={!userIsAdmin}
            icon={<AdminIcon />}
            title={'Admin'}
            to={'/admin'}
          />
          <MobileMenuItem
            component={DomLink}
            hidden={userIsAdmin || !userIsEditor}
            icon={<AdminIcon />}
            title={'Manager'}
            to={'/manager'}
          />
          <MobileMenuItem
            component={DomLink}
            hidden={userIsAdmin || !userIsAccountant}
            icon={<AccountingIcon />}
            title={'Comptabilité'}
            to={'/accounting'}
          />
          <MobileMenuItem
            component={DomLink}
            hidden={!userIsLogistic}
            icon={<CalendarIcon />}
            title={'Logistique'}
            to={'/logistic/orders/todo'}
          />
          <MobileMenuItem
            component={Link}
            hidden={!!user}
            icon={<AccountIcon />}
            onClick={handleClose}
            title={t('signIn')}
            to={'/auth/signin'}
          />
          <MobileMenuItem
            component={Link}
            hidden={!user}
            icon={<AccountIcon />}
            onClick={handleClose}
            title={user?.firstName.substring(0, 10)}
            to={'/user/account'}
          />
          <MobileMenuItem
            component={Link}
            icon={<HeartIcon />}
            onClick={handleClose}
            title={t('healthCheck')}
            to={'/anamnesis/graph'}
          />
          <MobileMenuItem
            component={Link}
            hidden={!user}
            icon={<OrdersIcon />}
            onClick={handleClose}
            title={t('myOrders')}
            to={'/user/account/orders'}
          />
          <MobileMenuItem
            component={Link}
            hidden={!user}
            icon={<AwardIcon />}
            onClick={handleClose}
            title={t('myTrainings')}
            to={'/user/account/trainings'}
          />
          <MobileMenuItem
            component={Link}
            hidden={!user}
            icon={<TruckIcon />}
            onClick={handleClose}
            title={t('myShippingAddresses')}
            to={'/user/account/shipping'}
          />
          <MobileMenuItem
            component={Link}
            hidden={!user}
            icon={<SettingsIcon />}
            onClick={handleClose}
            title={t('settings')}
            to={'/user/account/settings'}
          />
          <MobileMenuItem
            hidden={!user}
            icon={<SignOutIcon />}
            onClick={handleSignOut}
            title={t('signOut')}
          />
        </List>
      </div>
    </Drawer>
  );
};
export default MobileMenu;
