import * as actionsType from './constants';

export const academyActions = {
  getAcademyProgramRequest,
  getAcademyProgramSuccess,
  getAcademyProgramFail,
  getAcademyProgramsRequest,
  getAcademyProgramsSuccess,
  getAcademyProgramsFail,
};

export function getAcademyProgramRequest(country, url) {
  return {
    type: actionsType.GET_ACADEMY_PROGRAM_REQUEST,
    country,
    url,
  };
}

export function getAcademyProgramSuccess(program) {
  return {
    type: actionsType.GET_ACADEMY_PROGRAM_SUCCESS,
    program,
  };
}

export function getAcademyProgramFail() {
  return {
    type: actionsType.GET_ACADEMY_PROGRAMS_FAILED,
  };
}

export function getAcademyProgramsRequest(lang) {
  return {
    type: actionsType.GET_ACADEMY_PROGRAMS_REQUEST,
    lang,
  };
}

export function getAcademyProgramsSuccess(programs) {
  return {
    type: actionsType.GET_ACADEMY_PROGRAMS_SUCCESS,
    programs,
  };
}

export function getAcademyProgramsFail() {
  return {
    type: actionsType.GET_ACADEMY_PROGRAMS_FAILED,
  };
}
