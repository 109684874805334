import axios from 'axios';

export const getFormalizedName = (name) => {
  return name
    .normalize('NFD')
    .replace(/ /g, '-')
    .replace(/\//g, '-')
    .replace(/[^\-\w\s]/gi, '')
    .toLowerCase();
};

export const getGeolocationCountryCode = (defaultCountryCode) => {
  return new Promise((resolve) => {
    axios
      .get('https://ipapi.co/json/')
      .then(({ data: { country_code } }) => {
        resolve(country_code || defaultCountryCode);
      })
      .catch(() => {
        resolve(defaultCountryCode);
      });
  });
};
