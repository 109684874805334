import React, { useEffect, useState } from 'react';
import CardContent from '@mui/material/CardContent';
import FormValidator from '../../../components/Validators/FormValidator';
import Grid from '@mui/material/Grid';
import TextFieldValidator from '../../../components/Validators/TextFieldValidator';
import Card from '@mui/material/Card';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { SnackbarError } from '../../../helpers/snackbar';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';
import { authenticationActions } from '../store/actions';
import Divider from '@mui/material/Divider';
import SignInModal from './SignInModal';
import AutocompletePlaces from '../../../components/GoogleAutocomplete/AutocompletePlaces';
import { sessionSelector } from '../../Customer/Shop/store/selectors';
import SelectValidator from '../../../components/Validators/SelectValidator';
import MenuItem from '@mui/material/MenuItem';
import SearchConsultantAutocomplete from './SearchConsultantAutocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  submit: {
    borderRadius: 8,
    fontSize: 16,
    height: 46,
    padding: '8px 20px',
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}));

const RegistrationCard = () => {
  const { i18n, t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [signInModalOpen, setSignInModalOpen] = useState(false);
  const [sameAsBillingAddress, setSameAsBillingAddress] = useState(true);
  const [rememberMe, setRememberMe] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [userValues, setUserValues] = useState({});
  const [providerSelect, setProviderSelect] = useState('');

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (session?.parentId) {
      setUserValues({
        ...userValues,
        parentId: session.parentId,
      });
    }
  }, [session?.parentId]);

  const handleChange = (e) => {
    setUserValues({
      ...userValues,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectProvider = (e) => {
    setProviderSelect(e.target.value);
    if (e.target.value === 'googleSearch') {
      setUserValues({
        ...userValues,
        ['provider']: 'Google search',
      });
    } else {
      setUserValues({
        ...userValues,
        ['provider']: '',
      });
    }
  };

  const handleBillingAddressChanged = (place) => {
    setUserValues({
      ...userValues,
      ['billingAddress']: place,
    });
  };

  const handleShippingAddressChanged = (place) => {
    setUserValues({
      ...userValues,
      ['shippingAddress']: place,
    });
  };

  const handleSameAsBillingAddressChange = (event) => {
    setSameAsBillingAddress(event.target.checked);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleSelectConsultant = (e, consultant) => {
    setUserValues({
      ...userValues,
      parentId: consultant?.id,
    });
  };

  const handleSubmit = () => {
    setFormSubmitted(true);
    if (
      !userValues.billingAddress ||
      (!sameAsBillingAddress && !userValues.shippingAddress) ||
      (!userValues.provider &&
        (!userValues.parentId ||
          userValues.parentId === process.env.REACT_APP_AGS_DIRECTOR_ID ||
          userValues.parentId === process.env.REACT_APP_AGS_INSTAGRAM_ID))
    ) {
      SnackbarError(t('errorMessageRequiredFields'));
      return false;
    }
    let data = userValues;
    if (sameAsBillingAddress) {
      handleShippingAddressChanged(userValues.billingAddress);
      data = { ...userValues, shippingAddress: userValues.billingAddress };
    }

    dispatch(
      authenticationActions.signUpCredentialsRequest(
        { ...data, lang: i18n.language },
        rememberMe,
      ),
    );
  };

  return (
    <Card>
      <CardContent>
        <FormValidator
          noValidate
          onError={(errors) => {
            setFormSubmitted(true);
            console.log('ERRORS', errors);
          }}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid
                alignItems={'center'}
                container
                justifyContent={'space-between'}
              >
                <Grid item>
                  <Typography component={'h2'} variant={'h6'}>
                    <Trans i18nKey="createMyAccount">Create my account</Trans>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    className={classes.link}
                    color={'inherit'}
                    onClick={() => setSignInModalOpen(true)}
                    variant="body1"
                  >
                    <Trans i18nKey="signIn">Sign In</Trans>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                id="email"
                label={t('email')}
                name="email"
                onChange={handleChange}
                required
                type="email"
                value={userValues.email ? userValues.email : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
                label={t('password')}
                name="password"
                onChange={handleChange}
                required
                type={showPassword ? 'text' : 'password'}
                value={userValues.password}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('firstName')}
                name="firstName"
                onChange={handleChange}
                required
                value={userValues.firstName ? userValues.firstName : ''}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('lastName')}
                name="lastName"
                onChange={handleChange}
                required
                value={userValues.lastName ? userValues.lastName : ''}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                autoComplete="phone"
                fullWidth
                label={t('phone')}
                name="phone"
                onChange={handleChange}
                required
                value={userValues.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <AutocompletePlaces
                customFieldsEnabled
                error={formSubmitted && !userValues.billingAddress}
                fullWidth
                helperText={
                  formSubmitted &&
                  !userValues.billingAddress &&
                  t('validatorFieldRequired')
                }
                label={t('billingAddress')}
                name="address"
                onPlaceChanged={handleBillingAddressChanged}
                placeholder=""
                preview
                required
                requiredFields={['streetName', 'city', 'country']}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('company')}
                name="company"
                onChange={handleChange}
                value={userValues.company}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                label={t('taxNumber')}
                name="taxNumber"
                onChange={handleChange}
                value={userValues.taxNumber}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={sameAsBillingAddress}
                    color="primary"
                    onChange={handleSameAsBillingAddressChange}
                    value="true"
                  />
                }
                label={t('sameAsBillingAddress')}
              />
            </Grid>
            {!sameAsBillingAddress && (
              <>
                <Grid item xs={12}>
                  <FormLabel component="p">
                    <Trans i18nKey="shipping">Shipping information</Trans>
                  </FormLabel>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextFieldValidator
                    fullWidth
                    label={t('firstName')}
                    name="shippingFirstName"
                    onChange={handleChange}
                    required
                    value={
                      userValues.shippingFirstName
                        ? userValues.shippingFirstName
                        : ''
                    }
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextFieldValidator
                    fullWidth
                    label={t('lastName')}
                    name="shippingLastName"
                    onChange={handleChange}
                    required
                    value={
                      userValues.shippingLastName
                        ? userValues.shippingLastName
                        : ''
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <AutocompletePlaces
                    customFieldsEnabled
                    fullWidth
                    label={t('shippingAddress')}
                    name="address"
                    onPlaceChanged={handleShippingAddressChanged}
                    placeholder=""
                    preview
                    required={!sameAsBillingAddress}
                    requiredFields={['streetName', 'city', 'country']}
                    variant="outlined"
                  />
                </Grid>
              </>
            )}

            {(!session ||
              !session.parentId ||
              session.parentId === process.env.REACT_APP_AGS_DIRECTOR_ID ||
              session.parentId === process.env.REACT_APP_AGS_INSTAGRAM_ID) && (
              <>
                <Grid item xs={12}>
                  <SelectValidator
                    fullWidth
                    label={t('howDidYouKnowAGS')}
                    name="provider"
                    onChange={handleSelectProvider}
                    required
                    value={providerSelect}
                  >
                    <MenuItem value={'identifier'}>
                      {`${t('identifier')} / ${t('advisor')} / ${t('doctor')}`}
                    </MenuItem>
                    <MenuItem value={'socialNetwork'}>
                      {t('socialNetworks')}
                    </MenuItem>
                    <MenuItem value={'googleSearch'}>
                      {t('googleSearch')}
                    </MenuItem>
                    <MenuItem value={'other'}>{t('other')}</MenuItem>
                  </SelectValidator>
                </Grid>
                {providerSelect === 'identifier' && (
                  <Grid item xs={12}>
                    <SearchConsultantAutocomplete
                      onChange={handleSelectConsultant}
                      required
                    />
                  </Grid>
                )}
                {(providerSelect === 'socialNetwork' ||
                  providerSelect === 'other') && (
                  <Grid item xs={12}>
                    <TextFieldValidator
                      fullWidth
                      label={t(`${providerSelect}NameRegistration`)}
                      name="provider"
                      onChange={handleChange}
                      required
                      value={userValues.provider ? userValues.provider : ''}
                    />
                  </Grid>
                )}
              </>
            )}

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rememberMe}
                    color="primary"
                    onChange={handleRememberMeChange}
                    value="true"
                  />
                }
                label={t('rememberMe')}
              />
              <br />
              <Button
                className={classes.submit}
                color={'secondary'}
                type="submit"
                variant="contained"
              >
                <Trans i18nKey={'createMyAccount'}>Create my account</Trans>
              </Button>
            </Grid>
          </Grid>
        </FormValidator>
      </CardContent>
      <SignInModal
        setSignInModalOpen={setSignInModalOpen}
        signInModalOpen={signInModalOpen}
      />
    </Card>
  );
};
export default RegistrationCard;
