import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';
import { SnackbarFormattedError } from '../../../../helpers/snackbar';
import { customersService } from '../store/services';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SearchCustomerAutocomplete = ({ autoFocus, onChange }) => {
  const { t } = useTranslation();
  const [userSearchOpen, setUserSearchOpen] = useState(false);
  const [userSearchLoading, setUserSearchLoading] = useState(false);
  const [userSearch, setUserSearch] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (userSearch.length === 2 || (userSearch.length > 2 && !users.length)) {
      setUserSearchLoading(true);
      customersService
        .getCustomersBySearch(userSearch)
        .then((users) => {
          setUsers(users);
          setUserSearchLoading(false);
        })
        .catch((e) => {
          SnackbarFormattedError(e.response.data.error);
          setUserSearchLoading(false);
        });
    } else if (userSearch.length < 2) {
      setUsers([]);
    }
  }, [userSearch]);

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      `${option.company}${option.zip}${option.city}${option.firstName} ${option.lastName} ${option.firstName}`,
  });

  const handleUserSearchChange = (e) => {
    setUserSearch(e.target.value);
  };

  return (
    <Autocomplete
      autoHighlight={true}
      filterOptions={filterOptions}
      getOptionLabel={(option) =>
        `${option.company ? `${option.company} - ` : ''}${
          option.firstName && option.lastName
        } ${option.zip} ${option.city}`
      }
      loading={userSearchLoading}
      noOptionsText={t('noCustomerFound')}
      onChange={onChange}
      onClose={() => {
        setUserSearchOpen(false);
      }}
      onOpen={() => {
        setUserSearchOpen(true);
      }}
      open={userSearchOpen}
      options={users}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {userSearchLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          autoFocus={autoFocus}
          label={t('customers')}
          onChange={handleUserSearchChange}
          placeholder={t('searchByNameCityOrPostalCode')}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <Box key={option.id} {...props}>
          <Box
            sx={{
              pr: 1,
              pl: 1,
              pt: 0.5,
              pb: 0.5,
              m: 0,
              width: '100%',
              borderBottom: `1px dashed ${grey[600]}`,
            }}
          >
            <Typography
              sx={{
                m: 0,
                fontWeight: 'bold',
                fontSize: 12,
                textTransform: 'uppercase',
                color: grey[800],
              }}
            >
              {`[${t(option.role)}] - ${option.zip} ${option.city} ${
                option.country
              }`}
            </Typography>
            <Typography sx={{ m: 0, fontSize: 15 }}>
              {`${option.company ? `${option.company} - ` : ``}${
                option.firstName
              } ${option.lastName}`}
            </Typography>
          </Box>
        </Box>
      )}
      style={{ width: '100%' }}
    />
  );
};
export default SearchCustomerAutocomplete;
