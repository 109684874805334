import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cartDetailsSelector,
  sessionSelector,
  isCartModalOpenedSelector,
} from '../../store/selectors';
import { shopActions } from '../../store/actions';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import { Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import { Link } from '../../../../../helpers/multilingual';
import CartContent from './CartContent';
import { grey } from '@mui/material/colors';
import DialogContent from '@mui/material/DialogContent';
import { green } from '@mui/material/colors';

const useStylesCart = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      '& .MuiDialogContent-root': {
        paddingBottom: 0,
        flex: 'initial',
      },
      '& .MuiDialogActions-root': {
        padding: theme.spacing(2, 2, 2, 2),
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      },
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
        minWidth: 760,
        '& .MuiListItem-root': {},
        '& .MuiDivider-root': {
          margin: -1,
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& .MuiDivider-root': {
          display: 'none',
        },
        '& .MuiCard-root': {
          overflow: 'auto',
        },
      },
      '& .MuiTypography-subtitle1': {
        maxHeight: 42,
        lineHeight: '20px',
        overflow: 'hidden',
      },
      '& .bold': {
        fontWeight: 'bold',
      },
      '& .highlight': {
        color: green[800],
        fontWeight: 'bold',
      },
    },
    '& .priceInfo': {
      display: 'inline',
    },
    '& .oldPriceInfo': {
      textDecoration: 'line-through',
    },
    '& .proPriceInfo': {
      fontWeight: 'bold',
      color: 'red',
      display: 'block',
      marginLeft: 10,
      '& .discount': {
        display: 'none',
      },
    },
    '& img': {
      borderRadius: 16,
      maxWidth: '100%',
      maxHeight: 80,
      height: 'auto',
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
}));

const CartModal = () => {
  const isOpen = useSelector(isCartModalOpenedSelector);
  const theme = useTheme();
  const classes = useStylesCart();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const session = useSelector(sessionSelector);
  const cart = useSelector(cartDetailsSelector);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(shopActions.closeCartModal());
  };

  return (
    <Dialog
      className={classes.root}
      fullScreen={fullScreen}
      onClose={handleClose}
      open={isOpen || false}
    >
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        <Trans i18nKey="shoppingCart">Shopping cart</Trans>
      </DialogTitle>
      <DialogContent>
        <CartContent cart={cart} session={session} />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClose} sx={{ fontSize: '1rem' }}>
          <Trans i18nKey="cartContinueShoppingButton">Continue shopping</Trans>
        </Button>
        <Button
          autoFocus
          color="secondary"
          component={Link}
          size={'large'}
          sx={{ fontSize: '1rem', textAlign: 'center' }}
          to="/shop/checkout"
          variant={'contained'}
        >
          <Trans i18nKey="cartOrderButton">Order</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CartModal;
