import React from 'react';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { SnackbarFormattedError } from '../../../../helpers/snackbar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import { userService } from '../store/services';
import {
  ACCOUNTANT_AUTH,
  ADMIN_AUTH,
  ADMIN_ROLE,
  GOD_AUTH,
  LOGISTIC_AUTH,
  MEGA_ADMIN_AUTH,
  SUPER_ADMIN_AUTH,
} from '../../../Authentication/store/constants';

const UserAuthorizations = ({ user }) => {
  const [authorizations, setAuthorizations] = React.useState(
    user.authorizations || [],
  );
  const handleChange = (authorization) => {
    return (e) => {
      const isChecked = e.target.checked;
      userService
        .setAuthorization(user.id, authorization, isChecked)
        .then(() => {
          if (isChecked) {
            setAuthorizations([...authorizations, authorization]);
          } else {
            setAuthorizations(
              authorizations.filter((a) => a !== authorization),
            );
          }
        })
        .catch((e) => {
          console.log({ e });
          SnackbarFormattedError(e.error);
        });
    };
  };

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          {user.role === ADMIN_ROLE && (
            <>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={authorizations.includes(ADMIN_AUTH)}
                          onChange={handleChange(ADMIN_AUTH)}
                        />
                      }
                      label={
                        'Admin - Fonctions de base - Voir les statistiques'
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={authorizations.includes(SUPER_ADMIN_AUTH)}
                          onChange={handleChange(SUPER_ADMIN_AUTH)}
                        />
                      }
                      label={'Admin - Comptabilité - Mailings'}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={authorizations.includes(MEGA_ADMIN_AUTH)}
                          onChange={handleChange(MEGA_ADMIN_AUTH)}
                        />
                      }
                      label={'Admin - Utilisateurs - Stocks - Boutique'}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={authorizations.includes(GOD_AUTH)}
                          onChange={handleChange(GOD_AUTH)}
                        />
                      }
                      label={
                        'Admin - Valider un mailing - Modifier les autorisations'
                      }
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={authorizations.includes(ACCOUNTANT_AUTH)}
                      onChange={handleChange(ACCOUNTANT_AUTH)}
                    />
                  }
                  label={'Accès à la comptabilité'}
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={authorizations.includes(LOGISTIC_AUTH)}
                      onChange={handleChange(LOGISTIC_AUTH)}
                    />
                  }
                  label={'Accès à la logistique'}
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default UserAuthorizations;
