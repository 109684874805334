import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from '../../../../helpers/multilingual';
import Button from '@mui/material/Button';

const useStylesProfilingSection = makeStyles(() => ({
  darkSection: {
    padding: 0,
    marginTop: 0,
    backgroundColor: '#000',
    '& h3': {
      margin: '0 32px !important',
    },
    '& .MuiTypography-h4': {
      marginBottom: 0,
    },
    '& p': {
      marginBottom: 14,
      fontSize: 18,
    },
    '& img': {
      maxWidth: '100%',
      borderRadius: 10,
    },
    '& .MuiTypography-root': {
      color: '#fff',
    },
  },
  lightSection: {
    paddingBottom: 16,
    marginTop: 32,
    marginBottom: 16,
    //borderBottom: '1px solid',
  },
}));

const ProfilingSection = ({ dark }) => {
  const classes = useStylesProfilingSection();
  const { t } = useTranslation();

  return (
    <section className={dark ? classes.darkSection : classes.lightSection}>
      <Button
        className={classnames(
          classes.profilingButton,
          dark ? classes.darkStyle : classes.lightStyle,
        )}
        component={Link}
        to={'/profiling'}
        variant="outlined"
      >
        {t('profilingSection1')}
      </Button>
    </section>
  );
};

export default ProfilingSection;
