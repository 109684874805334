import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {
  SnackbarConfirm,
  SnackbarFormattedError,
  SnackbarSuccess,
} from '../../../../helpers/snackbar';
import { userService } from '../store/services';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';

const useStyle = makeStyles((theme) => ({
  root: {
    overflow: 'auto',
    minWidth: 400,
    '& .MuiContainer-root': {
      marginTop: 20,
      marginBottom: 20,
    },
    '& .giftCardItem': {
      '& .MuiTypography-h6': {
        whiteSpace: 'nowrap',
      },
    },
  },
  closeButton: {
    top: 10,
    right: 15,
    cursor: 'pointer',
    position: 'absolute',
    fontSize: 36,
    color: grey[500],
    [theme.breakpoints.down('sm')]: {
      top: 10,
      right: 20,
      fontSize: 40,
    },
  },
  addProductButton: {
    marginBottom: 30,
  },
}));

const UserOfferGiftCardModal = ({ customer, onChange, open, setOpen }) => {
  const classes = useStyle();
  const theme = useTheme();
  const { t } = useTranslation();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
    onChange();
  };

  const handleConfirmAndSendGiftCardModal = (amount) => {
    SnackbarConfirm(t('sendTheGiftCard'), () => {
      handleSendGiftCardModal(amount);
    });
  };

  const handleSendGiftCardModal = (amount) => {
    userService
      .sendUserGiftCard(customer.id, amount)
      .then(() => {
        SnackbarSuccess(t('giftCardSent'));
        handleClose();
      })
      .catch((e) => {
        SnackbarFormattedError(e.error);
      });
  };

  return (
    <Dialog fullScreen={fullScreen} onClose={handleClose} open={open}>
      <CloseIcon className={classes.closeButton} onClick={handleClose} />
      <DialogTitle id="responsive-dialog-title">
        <Trans i18nKey="offerGiftCard">Offer a gift card</Trans>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={3} xs={6}>
            <Card className={'giftCardItem'}>
              <CardContent>
                <Typography align={'center'} component={'div'} gutterBottom>
                  <Typography>
                    <Trans
                      i18nKey={'amountGiftCard'}
                      values={{ count: 5, currency: customer.currency }}
                    >
                      5 gift cards
                    </Trans>
                  </Typography>
                  <Divider />
                  <Button
                    color={'secondary'}
                    fullWidth
                    onClick={() => handleConfirmAndSendGiftCardModal(5)}
                  >
                    <EmailOutlinedIcon />
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Card className={'giftCardItem'}>
              <CardContent>
                <Typography align={'center'} component={'div'} gutterBottom>
                  <Typography>
                    <Trans
                      i18nKey={'amountGiftCard'}
                      values={{ count: 10, currency: customer.currency }}
                    >
                      10 gift cards
                    </Trans>
                  </Typography>
                  <Divider />
                  <Button
                    color={'secondary'}
                    fullWidth
                    onClick={() => handleConfirmAndSendGiftCardModal(10)}
                  >
                    <EmailOutlinedIcon />
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Card className={'giftCardItem'}>
              <CardContent>
                <Typography align={'center'} component={'div'} gutterBottom>
                  <Typography>
                    <Trans
                      i18nKey={'amountGiftCard'}
                      values={{ count: 15, currency: customer.currency }}
                    >
                      15 gift cards
                    </Trans>
                  </Typography>
                  <Divider />
                  <Button
                    color={'secondary'}
                    fullWidth
                    onClick={() => handleConfirmAndSendGiftCardModal(15)}
                  >
                    <EmailOutlinedIcon />
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Card className={'giftCardItem'}>
              <CardContent>
                <Typography align={'center'} component={'div'} gutterBottom>
                  <Typography>
                    <Trans
                      i18nKey={'amountGiftCard'}
                      values={{ count: 20, currency: customer.currency }}
                    >
                      20 gift cards
                    </Trans>
                  </Typography>
                  <Divider />
                  <Button
                    color={'secondary'}
                    fullWidth
                    onClick={() => handleConfirmAndSendGiftCardModal(20)}
                  >
                    <EmailOutlinedIcon />
                  </Button>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default UserOfferGiftCardModal;
