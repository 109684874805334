import Box from '@mui/material/Box';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
    paddingLeft: 32,
    paddingRight: 32,
    '& img': {
      borderRadius: 32,
      maxWidth: '100%',
      height: 'auto',
    },
  },
}));

const ZenspireContent = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <img
        alt=""
        height={960}
        src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/zenspire/zenspire_content.jpeg`}
        width={1280}
      />
    </Box>
  );
};
export default ZenspireContent;
