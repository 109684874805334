import React, { useContext, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@react-google-maps/api';
import { isGoogleMapApiLoaded } from '../GoogleMap/store/selectors';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { Trans, useTranslation } from 'react-i18next';
import { FormValidatorContext } from '../Validators/FormValidator';
import { SnackbarError } from '../../helpers/snackbar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextFieldValidator from '../Validators/TextFieldValidator';
import useLoadGoogleMapApi from '../GoogleMap/useLoadGoogleMapApi';
import { red } from '@mui/material/colors';

const useStylesAutocomplete = makeStyles(() => ({
  autocomplete: {
    width: '100%',
    '& > div': {
      //width: '100%',
      '& .pac-target-input': {
        animationName: 'inherit',
      },
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      fontSize: '0.75rem',
      color: 'green',
      '&.Mui-error': {
        color: red[600],
      },
    },
  },
}));

const AutocompletePlaces = ({
  FormHelperTextProps,
  InputProps,
  SelectProps,
  autoFocus,
  className,
  color,
  customFieldsEnabled,
  defaultValue,
  disabled,
  error,
  initialCustomFieldAdditionalInfo,
  initialCustomFieldCity,
  initialCustomFieldCountry,
  initialCustomFieldStreetName,
  initialCustomFieldStreetNumber,
  initialCustomFieldZip,
  initialValue,
  inputProps,
  label,
  margin,
  multiline,
  name,
  onPlaceChanged,
  placeholder,
  preview,
  required,
  requiredFields,
  rows,
  select,
  shrink,
  size,
  variant,
}) => {
  const classes = useStylesAutocomplete();
  const { t } = useTranslation();
  const textFieldRef = useRef();
  const { submitted } = useContext(FormValidatorContext);
  const isGoogleMapAPILoaded = useSelector(isGoogleMapApiLoaded);
  const [hasRequiredError, setHasRequiredError] = useState(false);
  const requiredErrorMessage = t('validatorFieldRequired');
  const [value, setValue] = useState(initialValue);
  const [place, setPlace] = useState(null);
  const [customPlace, setCustomPlace] = useState(null);
  const [customFieldsSelected, switchToCustom] = useState(true);
  const [customFields, setCustomFields] = useState({
    streetNumber: initialCustomFieldStreetNumber || '',
    streetName: initialCustomFieldStreetName || '',
    additionalInfo: initialCustomFieldAdditionalInfo || '',
    zip: initialCustomFieldZip || '',
    city: initialCustomFieldCity || '',
    country: initialCustomFieldCountry || 'SC',
  });

  useLoadGoogleMapApi();
  let autocompleteRef = useRef(null);

  useEffect(() => {
    setHasRequiredError(submitted && required && !place);
  }, [submitted, required, place]);

  const changeCustomPlace = () => {
    const placeFormatted = {
      address: `${customFields.streetNumber || ''} ${
        customFields.streetName
      }, ${customFields.zip || ''} ${customFields.city}, ${
        customFields.country
      }`,
      coordinates: { lat: null, lng: null },
      components: customFields,
    };
    setCustomPlace(placeFormatted);
    onPlaceChanged(placeFormatted);
  };

  useEffect(() => {
    if (customFieldsSelected) {
      changeCustomPlace();
    }
  }, [customFields]);

  const handlePlaceChanged = () => {
    let place = autocompleteRef.current.state.autocomplete.getPlace();
    if (
      !place.geometry ||
      !place.address_components ||
      !place.formatted_address
    )
      return;
    let lat = place.geometry.location.lat();
    let lng = place.geometry.location.lng();
    let components = {
      country: null,
      city: null,
      zip: null,
      streetName: null,
      streetNumber: null,
    };
    place.address_components.map((component) => {
      component.types.map((type) => {
        switch (type) {
          case 'country':
            components.country = component.short_name;
            break;
          case 'locality':
            components.city = component.long_name;
            break;
          case 'postal_code':
            components.zip = component.long_name;
            break;
          case 'route':
            components.streetName = component.long_name;
            break;
          case 'street_number':
            components.streetNumber = component.long_name;
            break;
        }
      });
    });
    let requiredErrors = 0;
    requiredFields.map((field) => {
      if (!components[field]) {
        SnackbarError(
          t('requiredFieldInAddressSnackbar', { field: t(field + 'Field') }),
        );
        ++requiredErrors;
      }
    });
    if (requiredErrors) {
      if (customFieldsEnabled) {
        switchToCustom(true);
        setCustomFields({
          streetNumber: components.streetNumber,
          streetName: components.streetName,
          additionalInfo: '',
          zip: components.zip,
          city: components.city,
          country: components.country,
        });
      }
      return false;
    }
    let placeFormatted = {
      address: place.formatted_address,
      coordinates: { lat, lng },
      components: components,
    };
    setPlace(placeFormatted);
    setValue(placeFormatted.address);
    onPlaceChanged(placeFormatted);
  };

  const removeAutoComplete = () => {
    textFieldRef.current.setAttribute(
      'autocomplete',
      'disable-' + Date.now() + 'disable',
    );
    textFieldRef.current.setAttribute('id', 'disable' + Date.now() + 'disable');
    textFieldRef.current.setAttribute(
      'name',
      'disable' + Date.now() + 'disable',
    );
  };

  const handleChange = (e) => {
    setValue(e.target.value);
    setPlace(null);
    onPlaceChanged(null);
  };

  const handleSwitchToCustom = () => {
    switchToCustom(!customFieldsSelected);
    setValue('');
    setPlace(null);
    setCustomPlace(null);
    onPlaceChanged(null);
  };

  const handleChangeCustomField = (field) => {
    return (e) => {
      let value = e.target.value;
      if (field === 'country') {
        value = value.substring(0, 2);
      }
      setCustomFields((fields) => ({ ...fields, [field]: value }));
    };
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      let arrowDownEvent = new KeyboardEvent('keydown', {
        keyCode: 40,
        which: 40,
      });
      textFieldRef.current.dispatchEvent(arrowDownEvent);
      let enterEvent = new KeyboardEvent('keydown', {
        keyCode: 13,
        which: 13,
      });
      textFieldRef.current.dispatchEvent(enterEvent);
    }
  };

  let inputLabelProps = shrink
    ? {
        shrink: true,
        style: {
          color: '#000',
        },
      }
    : {};

  if (!isGoogleMapAPILoaded) {
    return null;
  }

  return (
    <div className={classes.autocomplete}>
      {!customFieldsSelected && (
        <>
          <Autocomplete
            className="place-autocomplete"
            onPlaceChanged={handlePlaceChanged}
            ref={autocompleteRef}
          >
            <TextField
              FormHelperTextProps={FormHelperTextProps}
              InputLabelProps={inputLabelProps}
              InputProps={InputProps}
              SelectProps={SelectProps}
              autoComplete="new-password"
              autoFocus={autoFocus}
              className={className}
              color={color}
              defaultValue={defaultValue}
              disabled={disabled}
              error={error || hasRequiredError}
              fullWidth
              helperText={hasRequiredError && requiredErrorMessage}
              id="field1"
              inputProps={inputProps}
              inputRef={textFieldRef}
              label={label}
              margin={margin}
              multiline={multiline}
              name={name}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onMouseEnter={removeAutoComplete}
              onSelect={removeAutoComplete}
              placeholder={placeholder}
              rows={rows}
              select={select}
              size={size}
              type="text"
              value={value}
              variant={variant}
            />
          </Autocomplete>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              {preview && place && (
                <FormHelperText>{place.address}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              {customFieldsEnabled && (
                <Button
                  onClick={handleSwitchToCustom}
                  size={'small'}
                  variant={'text'}
                >
                  <Trans i18nKey={'useCustomFields'}>
                    Enter custom address
                  </Trans>
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
      {customFieldsSelected && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                name={'streetName'}
                onChange={handleChangeCustomField('streetName')}
                placeholder={t('streetName')}
                required={requiredFields.includes('streetName')}
                type="text"
                value={customFields['streetName']}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldValidator
                fullWidth
                name={'additionalInfo'}
                onChange={handleChangeCustomField('additionalInfo')}
                placeholder={t('additionalInfo')}
                type="text"
                value={customFields['additionalInfo']}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldValidator
                fullWidth
                name={'zip'}
                onChange={handleChangeCustomField('zip')}
                placeholder={t('zip')}
                required={requiredFields.includes('zip')}
                type="text"
                value={customFields['zip']}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldValidator
                fullWidth
                name={'city'}
                onChange={handleChangeCustomField('city')}
                placeholder={t('city')}
                required={requiredFields.includes('city')}
                type="text"
                value={customFields['city']}
                variant={'outlined'}
              />
            </Grid>
            <Grid item xs={4}>
              <TextFieldValidator
                fullWidth
                name={'country'}
                onChange={handleChangeCustomField('country')}
                placeholder={`${t('country')} (${t('countryFieldInfo')})`}
                required={requiredFields.includes('country')}
                type="text"
                value={customFields['country']}
                variant={'outlined'}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent={'space-between'}>
            <Grid item>
              {preview && customPlace && (
                <FormHelperText>{customPlace.address}</FormHelperText>
              )}
            </Grid>
            <Grid item>
              {customFieldsEnabled && (
                <Button
                  onClick={handleSwitchToCustom}
                  size={'small'}
                  variant={'text'}
                >
                  <Trans i18nKey={'useAutocompleteField'}>
                    Use autocomplete field
                  </Trans>
                </Button>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};
export default AutocompletePlaces;
