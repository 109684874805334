import axios from '../../../../helpers/axios';

export const homeService = {
  getTreesCount,
};

function getTreesCount() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/shop/trees/count`)
      .then(({ trees }) => {
        resolve(trees);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
