import React from 'react';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import { Trans } from 'react-i18next';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { isCookieConsentedSelector } from './store/selectors';
import { cookieConsentActions } from './store/actions';
import Cookie from '../../../../helpers/cookie';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paperAnchorBottom': {
      '& .MuiTypography-root': {
        margin: 14,
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
          margin: 8,
          fontSize: 15,
        },
      },
      '& button': {
        margin: 7,
        fontSize: 16,
        [theme.breakpoints.down('md')]: {
          marginTop: 0,
          marginBottom: 8,
          fontSize: 15,
        },
      },
    },
  },
}));
const CookieConsent = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const isCookieConsented = useSelector(isCookieConsentedSelector);

  const handleCookieConsent = () => {
    Cookie.set(`cookie-consent`, true);
    dispatch(cookieConsentActions.consent());
  };

  return (
    <Drawer
      anchor={'bottom'}
      className={classes.root}
      open={!isCookieConsented}
      variant="persistent"
    >
      <Grid alignItems={'center'} container justifyContent={'center'}>
        <Grid item>
          <Typography align={'center'}>
            <Trans i18nKey={'cookieConsentMessage'}>
              This website uses cookies to enhance the user experience.
            </Trans>
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="secondary"
            disableElevation
            onClick={handleCookieConsent}
            type="submit"
            variant="contained"
          >
            <Trans i18nKey={'iUnderstand'}>I understand</Trans>
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};
export default CookieConsent;
