import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ViewListIcon from '@mui/icons-material/ViewList';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SettingsIcon from '@mui/icons-material/Settings';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Breadcrumb from '../../../components/Layout/Customer/Breadcrumb';
import Account from './components/Account';
import ShippingAddresses from './components/ShippingAddresses/ShippingAddresses';
import Orders from './components/Orders/Orders';
import Settings from './components/Settings';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Header from '../../../components/Layout/Customer/Header/Header';
import SchoolIcon from '@mui/icons-material/School';
import Trainings from './components/Trainings';
import { useCustomerPath } from '../../../helpers/usePath';
import { useSEO } from '../../../helpers/useSEO';

const useStylesAccount = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    boxShadow: 'none',
    marginBottom: 40,
    '& .MuiTab-root': {
      [theme.breakpoints.down('md')]: {
        fontSize: 12,
      },
    },
    '& .MuiTabs-flexContainer': {
      '& .MuiTab-root': {
        [theme.breakpoints.up('lg')]: {
          flexGrow: 1,
          maxWidth: 'none',
          flexBasis: 0,
          flexShrink: 1,
        },
      },
    },
    '& .MuiTab-wrapper': {
      [theme.breakpoints.down(350)]: {
        height: 15,
        display: 'block',
      },
    },
  },
  container: {
    paddingTop: 100,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
    '& .MuiTabPanel-root': {
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },
  },
  pageTitle: {
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
  },
}));

const AccountPage = (props) => {
  const path = useCustomerPath();
  const classes = useStylesAccount();
  const history = useHistory();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('info');
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/user/account', {
    fr: '/user/account',
    nl: '/user/account',
    en: '/user/account',
  });

  useEffect(() => {
    setSelectedTab(props.match.params.tab || 'info');
  }, [props.match.params.tab]);

  const handleTabChange = (event, newValue) => {
    history.push(`${path}/user/account/${newValue}`);
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t('myAccount')}</title>
        <meta content={t('myAccount')} property="og:title" />
        <meta content={t('accountMetaDescription')} name="description" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Header />
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Typography
            className={classes.title}
            color={'secondary'}
            component="h1"
            variant="h4"
          >
            <Trans i18nKey="myAccount">My Account</Trans>
          </Typography>
          <Breadcrumb
            parents={[{ name: <Trans i18nKey="myAccount">My account</Trans> }]}
          />
        </div>
        <TabContext value={selectedTab}>
          <Paper className={classes.root} elevation={0} square>
            <Tabs
              indicatorColor="secondary"
              onChange={handleTabChange}
              scrollButtons="auto"
              textColor="secondary"
              value={selectedTab}
              variant="scrollable"
            >
              <Tab
                icon={<AccountCircleIcon />}
                label={t('account')}
                value="info"
              />
              <Tab
                icon={<LocalShippingIcon />}
                label={t('addresses')}
                value="shipping"
              />
              <Tab icon={<ViewListIcon />} label={t('orders')} value="orders" />
              <Tab
                icon={<SchoolIcon />}
                label={t('trainings')}
                value="trainings"
              />
              <Tab
                icon={<SettingsIcon />}
                label={t('settings')}
                value="settings"
              />
            </Tabs>
          </Paper>
          <TabPanel value="info">
            <Account />
          </TabPanel>
          <TabPanel value="shipping">
            <ShippingAddresses />
          </TabPanel>
          <TabPanel value="orders">
            <Orders />
          </TabPanel>
          <TabPanel value="trainings">
            <Trainings />
          </TabPanel>
          <TabPanel value="settings">
            <Settings />
          </TabPanel>
        </TabContext>
      </Container>
    </div>
  );
};

export default AccountPage;
