import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from '../../../helpers/multilingual';
import LazyLoadCardMedia from '../../../components/LazyLoad/LazyLoadCardMedia';
import { useDispatch, useSelector } from 'react-redux';
import { getAcademyProgramsSelector } from './store/selectors';
import { academyActions } from './store/actions';
import AcademyBanner from './components/AcademyBanner';
import Box from '@mui/material/Box';
import { useSEO } from '../../../helpers/useSEO';
import { userSelector } from '../../Authentication/store/selectors';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .pageTitle': {
      paddingTop: 50,
      paddingBottom: 60,
      fontWeight: 800,
      fontSize: 26,
      color: '#f5a331',
      lineHeight: '30px',
      textTransform: 'uppercase',
    },
    '& .program': {
      marginTop: 40,
      marginBottom: 40,
      '&.right .MuiGrid-container': {
        flexDirection: 'row-reverse',
        '& .learn-more-btn-container': {
          textAlign: 'right',
          [theme.breakpoints.down('sm')]: {
            textAlign: 'center',
          },
        },
      },
      '& .image-container': {
        textAlign: 'center',
        '& img': {
          margin: 'auto',
          width: 'calc(100% - 10px)',
          borderRadius: '16px 0',
          maxWidth: 240,
          border: '4px solid #f5a331',
        },
      },
      '& .program-container': {
        '& .battery': {
          marginBottom: 20,
          fontWeight: 900,
          fontSize: 26,
          lineHeight: '30px',
          textTransform: 'uppercase',
        },
        '& .modules': {
          marginBottom: 20,
          display: 'inline-block',
          padding: '4px 32px',
          fontWeight: 900,
          fontSize: 24,
          lineHeight: '26px',
          color: '#fff',
          backgroundColor: theme.palette.secondary.main,
          textTransform: 'uppercase',
        },
        '& .program-name': {
          marginBottom: 20,
          fontWeight: 900,
          fontSize: 22,
          lineHeight: '26px',
          whiteSpace: 'pre-wrap',
        },
        '& .author': {
          marginTop: 20,
          '& .author-name': {
            fontWeight: 900,
            fontSize: 16,
            lineHeight: '26px',
          },
          '& .author-description': {},
        },
        '& .important': {
          marginTop: 20,
          color: '#f5a331',
          fontWeight: 600,
          fontSize: 22,
          lineHeight: '26px',
        },
        '& .learn-more-btn': {
          display: 'inline-block',
          marginTop: 16,
          backgroundColor: '#f5a331',
          padding: '8px 32px',
          borderRadius: '0 16px 0 16px',
          lineHeight: '20px',
          boxShadow: '0 1px 2px rgba(0,0,0,0.08), 0 4px 12px rgba(0,0,0,0.05)',
          '&:hover': {
            backgroundColor: '#ce8a29',
            boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
          },
          '& .button': {
            color: '#fff',
            fontWeight: 900,
          },
        },
        [theme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
    },
  },
}));

const AcademyPrograms = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const academyPrograms = useSelector(getAcademyProgramsSelector);
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/academy', {
    fr: '/academy',
    nl: '/academy',
    en: '/academy',
  });

  useEffect(() => {
    if (
      !Array.isArray(academyPrograms) ||
      (academyPrograms.length > 0 && academyPrograms[0].lang !== i18n.language)
    ) {
      dispatch(academyActions.getAcademyProgramsRequest(i18n.language));
    }
  }, [i18n.language, user?.isPro]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title itemProp={'name'}>{t('academy')}</title>
        <meta content={t('academy')} property="og:title" />
        <meta content={t('academyMetaDescription')} name="description" />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <AcademyBanner />
      <Container fixed maxWidth="md">
        <Typography align={'center'} className={'pageTitle'} component={'h3'}>
          <Trans i18nKey={'academyDescription'}>
            Sharks is a global health support provider, which is why we make it
            a point to provide you with solid training.
          </Trans>
        </Typography>
        <Grid container spacing={2}>
          {academyPrograms?.map((program, key) => (
            <Grid item key={program.id} xs={12}>
              {key <= 3 && (
                <Helmet>
                  <link as="image" href={program.image[400]} rel="preload" />
                </Helmet>
              )}
              <Box className={`program ${key % 2 ? 'right' : ''}`}>
                <Grid alignItems={'center'} container spacing={2}>
                  <Grid item sm={4} xs={12}>
                    <Typography className={'image-container'} component={'div'}>
                      <LazyLoadCardMedia
                        alt={program.title}
                        className={'img-box'}
                        component="img"
                        height="300"
                        image={program.image[600]}
                        onError={(e) => {
                          e.target.src = `${process.env.REACT_APP_API_RESOURCES_URL}/academy/image/default-600.jpg`;
                        }}
                        skeletonSrc={
                          key <= 3
                            ? program.image[400]
                            : `${process.env.REACT_APP_API_RESOURCES_URL}/academy/image/default-400.jpg`
                        }
                        title={program.title}
                      />
                    </Typography>
                  </Grid>
                  <Grid item sm={8} xs={12}>
                    <Typography
                      className={'program-container'}
                      component={'div'}
                    >
                      <Typography className={'battery'} color={'secondary'}>
                        {program.battery}
                      </Typography>
                      <Typography className={'modules'}>
                        {program.modules}
                      </Typography>
                      <Typography
                        className={'program-name'}
                        color={'secondary'}
                      >
                        {program.title}
                      </Typography>
                      <Box className={'author'}>
                        <Typography className={'author-name'}>
                          {program.author}
                        </Typography>
                        <Typography className={'author-description'}>
                          {program.authorDescription}
                        </Typography>
                      </Box>
                      {program.status === 'available-soon' && (
                        <Typography className={'important'}>
                          <Trans i18nKey={'availableSoon'}>
                            Available soon ...
                          </Trans>
                        </Typography>
                      )}
                      {program.status === 'published' && (
                        <Typography
                          className={'learn-more-btn-container'}
                          component={'div'}
                        >
                          <Link
                            className={'learn-more-btn'}
                            to={`/academy/${program.url}`}
                          >
                            <Typography className={'button'} component={'span'}>
                              {t('learnMore')}
                            </Typography>
                          </Link>
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default AcademyPrograms;
