import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useDispatch, useSelector } from 'react-redux';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { academyActions } from './store/actions';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { getAcademyProgramSelector } from './store/selectors';
import { Link } from '../../../helpers/multilingual';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { shopActions } from '../Shop/store/actions';
import HeaderAcademy from '../../../components/Layout/Customer/Header/HeaderAcademy';
import { userSelector } from '../../Authentication/store/selectors';
import { authenticationActions } from '../../Authentication/store/actions';
import { sessionSelector } from '../Shop/store/selectors';
import { useCustomerPath } from '../../../helpers/usePath';
import { useSEO } from '../../../helpers/useSEO';
import { academyService } from './store/services';
import { SnackbarFormattedError } from '../../../helpers/snackbar';
import { useHistory } from 'react-router-dom';
import PageLoader from '../../../components/PageLoader';
import Header from '../../../components/Layout/Customer/Header/Header';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 140,
    paddingBottom: 40,
    '& .cover': {
      margin: 16,
      width: 'calc(100% - 10px)',
      borderRadius: '16px 0',
      maxWidth: 240,
      border: '4px solid #f5a331',
    },
    '& .divider': {
      marginTop: 40,
      backgroundColor: '#000',
    },
    '& .author': {
      fontWeight: 900,
      fontSize: 16,
      lineHeight: '26px',
    },
    '& .author-description': {
      fontStyle: 'italic',
    },
    '& .program-name': {
      marginBottom: 20,
      fontWeight: 900,
      fontSize: 24,
      lineHeight: '26px',
      whiteSpace: 'pre-wrap',
    },
    '& .program-short-description': {
      marginBottom: 20,
      fontSize: 18,
      lineHeight: '26px',
      whiteSpace: 'pre-wrap',
    },
    '& .addToCartBtn': {},
    '& .enterBtn': {},
    '& .important': {
      marginTop: 20,
      color: '#f5a331',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '26px',
    },
  },
}));
const AcademyProgramPresentation = (props) => {
  const path = useCustomerPath();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const session = useSelector(sessionSelector);
  const program = useSelector(getAcademyProgramSelector);
  const { buildAlternatesLangTags } = useSEO();

  useEffect(() => {
    if (
      !program.url ||
      props.match.params.url !== program.url ||
      i18n.language !== program.lang
    ) {
      dispatch(
        academyActions.getAcademyProgramRequest(
          (session && session.country) || 'BE',
          props.match.params.url,
        ),
      );
    }
  }, [i18n.language, props.match.params.lang, props.match.params.url]);

  useEffect(() => {
    if (user) {
      dispatch(authenticationActions.reloadUserRequest());
    }
  }, []);

  const takeFreeFormation = () => {
    academyService
      .takeFreeFormation(program.id)
      .then(() => {
        history.push(`${path}/academy/${program.url}/chapter`);
      })
      .catch((e) => SnackbarFormattedError(e.error));
  };

  const addToCart = () => {
    const productData = {
      id: program.product.id,
      quantity: program.product.quantity,
      price: program.product.price,
    };
    import('react-facebook-pixel')
      .then((module) => module.default)
      .then((ReactPixel) => {
        ReactPixel.track('AddToCart', {
          value: program.product.price,
          currency: program.product.currency,
          content_type: 'product',
          content_name: program.product.detail.name,
          content_ids: [program.product.id],
          contents: [productData],
        });
      });
    dispatch(shopActions.addToCart(program.product, 1));
  };

  if (props.match.params.url !== program?.url) {
    return (
      <div className={classes.root}>
        <Header />
        <Container fixed maxWidth="lg">
          <PageLoader />
        </Container>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{program.title}</title>
        <meta content={program.title} property="og:title" />
        <meta content="program" property="og:type" />
        <meta
          content={`${process.env.REACT_APP_WEB_URL}${path}/academy/${program.url}`}
          property="og:url"
        />
        <meta content={program.image[800]} property="og:image" />
        <meta content={program.shortDescription} property="og:description" />
        <meta content={program.shortDescription} name="description" />
        {buildAlternatesLangTags(`/academy/${program.url}`, {
          [program.lang]: `/academy/${program.url}`,
        }).map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Header />
      <Container fixed maxWidth="lg">
        <Grid alignItems={'center'} container spacing={2}>
          <Grid item sm={4} xs={12}>
            <Typography align={'center'} component={'div'}>
              <img alt="" className={'cover'} src={program.image[800]} />
              <Typography className={'author'}>{program.author}</Typography>
              <Typography className={'author-description'}>
                {program.authorDescription}
              </Typography>
            </Typography>
          </Grid>
          <Grid item sm={8} xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  className={'program-name'}
                  color={'secondary'}
                  component="h1"
                  gutterBottom
                  variant="h4"
                >
                  {program.title}
                </Typography>
                <Typography
                  className={'program-short-description'}
                  color={'primary'}
                  component="h2"
                  variant="h6"
                >
                  {program.shortDescription}
                </Typography>
              </Grid>
              {program.status === 'available-soon' && (
                <Grid item xs={12}>
                  <Typography className={'important'}>
                    <Trans i18nKey={'availableSoon'}>Available soon ...</Trans>
                  </Typography>
                </Grid>
              )}
              {program.status === 'published' && (
                <Grid item xs={12}>
                  {user?.formations.includes(program.id) && (
                    <Button
                      className={'enterBtn'}
                      color={'secondary'}
                      component={Link}
                      size={'large'}
                      startIcon={<OpenInNewIcon />}
                      to={`/academy/${program.url}/chapter`}
                      variant={'contained'}
                    >
                      {t('enterIntoFormation')}
                    </Button>
                  )}
                  {!user?.formations.includes(program.id) && (
                    <Grid container spacing={2}>
                      {program.product?.id && (
                        <Grid item>
                          <Button
                            className={'addToCartBtn'}
                            color={'primary'}
                            onClick={addToCart}
                            size={'large'}
                            startIcon={<AddShoppingCartIcon />}
                            variant={'contained'}
                          >
                            {t('buyFormation')}
                          </Button>
                        </Grid>
                      )}
                      {!program.product?.id && (
                        <Grid item>
                          <Button
                            className={'addToCartBtn'}
                            color={'primary'}
                            onClick={takeFreeFormation}
                            size={'large'}
                            startIcon={<OpenInNewIcon />}
                            variant={'contained'}
                          >
                            {t('takeFormation')}
                          </Button>
                        </Grid>
                      )}
                      {program.steps[0]?.free && (
                        <Grid item>
                          <Button
                            className={'enterBtn'}
                            color={'secondary'}
                            component={Link}
                            size={'large'}
                            startIcon={<YouTubeIcon />}
                            to={`/academy/${program.url}/chapter`}
                            variant={'contained'}
                          >
                            {t('WatchIntroductionVideoFormation')}
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider className={'divider'} />
          </Grid>
          <Grid item xs={12}>
            <Typography component={'div'}>
              <div
                dangerouslySetInnerHTML={{
                  __html: program.description || '',
                }}
              ></div>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default AcademyProgramPresentation;
