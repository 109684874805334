import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import { LangSelector, Link } from '../../../../../helpers/multilingual';
import SearchBarButton from '../../../../../containers/Customer/Shop/components/Search/SearchBarButton';
import CartIcon from '../../../../../containers/Customer/Shop/components/Cart/CartIcon';
import UserMenu from './UserMenu';
import ConsultantsChangeLocationButton from '../../../../../containers/Customer/Consultants/components/ConsultantsChangeLocationButton';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Stack } from '@mui/material';
import DesktopMenuItem from './DesktopMenuItem';
import DesktopShopMenu from './DesktopShopMenu';

const DesktopMenu = ({ isMobileOpen, logo, setMobileOpen, transparent }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const handleDrawerToggle = () => {
    setMobileOpen(!isMobileOpen);
  };

  const MenuIcon = (props) => {
    return (
      <SvgIcon {...props}>
        <path d="M30,7.5V10H0V7.5H30z M30,0v2.5H0V0H30z" />
        <g>
          <path d="M7.2,19.5C7.1,18.4,7.1,17,7.1,16h0c-0.3,0.9-0.6,2-1,3.1L4.6,23H3.8l-1.3-3.8C2.1,18,1.8,17,1.6,16h0c0,1-0.1,2.4-0.2,3.6l-0.2,3.5h-1L0.7,15h1.3l1.4,3.9c0.3,1,0.6,1.9,0.8,2.7h0c0.2-0.8,0.5-1.7,0.9-2.7L6.6,15h1.3l0.5,8.1h-1L7.2,19.5z" />
          <path d="M14.2,19.3h-3.1v2.9h3.5v0.9H10V15h4.4v0.9h-3.3v2.6h3.1V19.3z" />
          <path d="M15.9,23.1V15h1.1l2.6,4.1c0.6,0.9,1.1,1.8,1.5,2.6l0,0c-0.1-1.1-0.1-2.1-0.1-3.3V15h1v8.1H21L18.4,19c-0.6-0.9-1.1-1.8-1.5-2.7l0,0c0.1,1,0.1,2,0.1,3.3v3.5H15.9z" />
          <path d="M24.9,15v4.8c0,1.8,0.8,2.6,1.9,2.6c1.2,0,2-0.8,2-2.6V15h1.1v4.7c0,2.5-1.3,3.5-3.1,3.5c-1.7,0-2.9-0.9-2.9-3.5V15H24.9z" />
        </g>
      </SvgIcon>
    );
  };

  return (
    <Grid container justifyContent={'space-between'} spacing={2}>
      <Grid item sx={{ maxWidth: '35%' }} xs={5}>
        <Stack direction={'row'} spacing={2}>
          <IconButton
            color="inherit"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none', xs: 'inherit' } }}
          >
            <MenuIcon
              sx={{
                width: '1em',
                height: '1em',
                fontSize: '2.4rem',
                alignItems: 'center',
              }}
              viewBox={'0 0 30 20'}
            />
          </IconButton>
        </Stack>
      </Grid>
      <Grid item sx={{ pt: '4px !important', maxWidth: '30%' }} xs={2}>
        {logo}
      </Grid>
      <Grid item sx={{ maxWidth: '35%' }} xs={5}>
        <Stack
          direction={'row'}
          justifyContent={'flex-end'}
          spacing={{ sm: 2, xs: 1 }}
        >
          <LangSelector className={'langSelectorButton'} preview={true} />
          <UserMenu />
        </Stack>
      </Grid>
    </Grid>
  );
};
export default DesktopMenu;
