import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConnectedRouter } from 'connected-react-router';
import { StylesProvider } from '@mui/styles';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import configureStore, { history, hydrateState } from './helpers/store';
import loadable from '@loadable/component';

const SnackbarProvider = loadable(() => import('./helpers/snackbar'));

const Root = () => {
  // Grab the state from a global variable injected into the server-generated HTML
  let preloadedState = window.__PRELOADED_STATE__;

  // Allow the passed state to be garbage-collected
  delete window.__PRELOADED_STATE__;

  const store = configureStore(hydrateState(preloadedState));

  React.useEffect(() => {
    const jssStyles = document.getElementById('jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <Provider store={store}>
      <SnackbarProvider />
      <ConnectedRouter history={history}>
        <StylesProvider>
          <HelmetProvider>
            <App />
          </HelmetProvider>
        </StylesProvider>
      </ConnectedRouter>
    </Provider>
  );
};
const domNode = document.getElementById('root');
if (process.env.NODE_ENV === 'development') {
  createRoot(domNode).render(<Root />);
} else {
  hydrateRoot(domNode, <Root />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
