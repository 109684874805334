import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ListItem from '@mui/material/ListItem';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

const OrderItem = ({ item, setProductQuantity }) => {
  const { t } = useTranslation();

  const handleChangeItemQuantity = (item, quantity) => {
    setProductQuantity(item, quantity);
  };

  const quantityOptions = () => {
    let quantityOptions = [];
    for (let i = 0; i <= 100; ++i) {
      quantityOptions.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>,
      );
    }
    return quantityOptions;
  };

  return (
    <ListItem>
      <Grid alignItems={'center'} container justifyContent={'space-between'}>
        <Grid item xs={11}>
          <Grid alignItems={'center'} container spacing={2}>
            <Grid item zeroMinWidth>
              <FormControl variant={'outlined'}>
                <InputLabel>{t('qtyCart')}</InputLabel>
                <Select
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  label={t('qtyCart')}
                  name={'quantity'}
                  onChange={(e) =>
                    handleChangeItemQuantity(item, e.target.value)
                  }
                  value={item.quantity}
                >
                  {quantityOptions()}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <img
                alt={item.detail.name}
                height={80}
                src={item.image[200]}
                width={80}
              />
            </Grid>
            <Grid item sm={8} xs={12}>
              <Typography color={'inherit'} variant="subtitle1">
                {`${item.detail.name}`}
              </Typography>
              <Typography color={'inherit'} variant="subtitle1">
                {`${(
                  item.priceAndTaxDetails.final.price +
                  item.priceAndTaxDetails.final.taxes
                ).toFixed(2)} ${item.currency} (${
                  item.priceAndTaxDetails.final.priceInfo
                })`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1} zeroMinWidth>
          <IconButton
            color="primary"
            onClick={() => handleChangeItemQuantity(item, 0)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
};
export default OrderItem;
