import React from 'react';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from 'react-redux';
import { countShippingFreeThresholdLeftSelector } from '../../store/selectors';
import { red } from '@mui/material/colors';

const useStyle = makeStyles((theme) => ({
  info: {
    color: theme.palette.primary.main,
    fontSize: 18,
  },
  alert: {
    color: red[600],
    fontSize: 18,
  },
}));
const FreeShippingInfo = () => {
  const classes = useStyle();
  const { allowFreeShipping, amountLeft, currency } = useSelector(
    countShippingFreeThresholdLeftSelector,
  );

  if (!allowFreeShipping) {
    return null;
  }

  if (amountLeft <= 0) {
    return (
      <Typography className={classes.info}>
        <Trans i18nKey={'freeDeliveryBenefit'}>
          You benefit from free delivery
        </Trans>
      </Typography>
    );
  }
  return (
    <Typography className={classes.alert}>
      <Trans
        i18nKey={'freeShippingInfo'}
        values={{
          amount: `${(Math.round(amountLeft * 100) / 100).toFixed(
            2,
          )} ${currency}`,
        }}
      >
        {`${(Math.round(amountLeft * 100) / 100).toFixed(2)} ${currency}`} left
        to get free shipping
      </Trans>
    </Typography>
  );
};
export default FreeShippingInfo;
