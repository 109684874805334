import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import WaysOfPartnership from './components/WaysOfPartnership';
import Container from '@mui/material/Container';
import Header from '../../../components/Layout/Customer/Header/Header';
import { useSEO } from '../../../helpers/useSEO';

const useStylesCareerOpportunity = makeStyles((theme) => ({
  container: {
    paddingTop: 140,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0
    },
  },
}));

const CareerOpportunityPage = () => {
  const classes = useStylesCareerOpportunity();
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags('/career-opportunity', {
    fr: '/career-opportunity',
    nl: '/career-opportunity',
    en: '/career-opportunity',
  });

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t('becomePartner')}</title>
        <meta content={t('becomePartner')} property="og:title" />
        <meta
          content={t('networkOpportunityMetaDescription')}
          name="description"
        />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
      </Helmet>
      <Header />
      <Container maxWidth={'xl'}>
        <WaysOfPartnership />
      </Container>
    </div>
  );
};

export default CareerOpportunityPage;
