import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const SignOutIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <line
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="0.95px"
        x1="11.41"
        x2="11.41"
        y1="14.11"
        y2="14.93"
      />
      <rect
        fill="none"
        height="22.81"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.95px"
        width="12.67"
        x="8.88"
        y="4.44"
      />
    </SvgIcon>
  );
};
export default SignOutIcon;
