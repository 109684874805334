import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { activeDiscountCodeSelector } from '../../../containers/Customer/Shop/store/selectors';

const useStyle = makeStyles((theme) => ({
  root: {
    '& .banner': {
      '& p': {
        margin: 0,
      },
      '& strong': {
        fontWeight: 'bold',
      },
      '& .ql-size-small': {
        fontSize: '0.75em',
      },
      '& .ql-size-large': {
        fontSize: '1.5em',
      },
    },
    '& .MuiDrawer-paperAnchorBottom': {
      padding: theme.spacing(4, 1),
      color: '#fff',
      '& .MuiTypography-root': {
        margin: 0,
        fontSize: 22,
      },
      '& button': {
        margin: theme.spacing(2),
        fontSize: 22,
        borderColor: '#fff',
        color: '#fff',
      },
    },
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent', // Makes the backdrop transparent
    },
  },
}));
const ActiveDiscountDrawer = () => {
  const classes = useStyle();
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const activeDiscount = useSelector(activeDiscountCodeSelector);

  const handleClose = () => {
    setIsOpen(false);
  };

  if (
    !activeDiscount ||
    !activeDiscount.displayedName[i18n.language] ||
    !activeDiscount.displayedMessage[i18n.language]
  ) {
    return null;
  }

  return (
    <Drawer
      PaperProps={{
        style: {
          backgroundColor: `${activeDiscount.displayedColor}`,
        },
      }}
      anchor={'bottom'}
      className={classes.root}
      disableEnforceFocus
      disableScrollLock
      elevation={2}
      keepMounted
      onClose={handleClose}
      open={isOpen}
      variant="temporary"
    >
      <Typography
        align={'center'}
        className={'banner'}
        component={'div'}
        dangerouslySetInnerHTML={{
          __html: activeDiscount.displayedMessage[i18n.language],
        }}
      />
    </Drawer>
  );
};
export default ActiveDiscountDrawer;
