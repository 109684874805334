import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Link } from '../../../helpers/multilingual';
import Button from '@mui/material/Button';
import Header from '../../../components/Layout/Customer/Header/Header';
import { useSEO } from '../../../helpers/useSEO';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 140,
    paddingBottom: 40,
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0
    },
    '& h2': {
      fontSize: '2.1em',
      fontWeight: '500',
      marginBottom: 32,
    },
    '& h3': {
      fontSize: '1.7em',
      fontWeight: '500',
      marginTop: 32,
      marginBottom: 16,
    },
    '& .MuiTypography-body1': {
      marginBottom: 16,
    },
    '& .hashtags': {
      paddingLeft: 32,
      '& li': {
        listStyle: 'none',
        fontWeight: '500',
      },
    },
    '& .MuiList-root.advantages': {
      paddingLeft: 0,
      '& .MuiListItem-root': {
        paddingLeft: 0,
      },
    },
    '& .MuiLink-root': {
      fontSize: 18,
    },
  },
}));

const Partnership = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { buildAlternatesLangTags } = useSEO();
  const alternates = buildAlternatesLangTags(
    '/career-opportunity/partnership',
    {
      fr: '/career-opportunity/partnership',
      nl: '/career-opportunity/partnership',
      en: '/career-opportunity/partnership',
    },
  );

  return (
    <div className={classes.container}>
      <Helmet>
        <title>{t('partnership1')}</title>
        <meta content={t('partnership1')} property="og:title" />
        <meta
          content={t('networkOpportunityMetaDescription')}
          name="description"
        />
        {alternates.map((link, index) => (
          <link
            href={link.href}
            hrefLang={link.hrefLang}
            key={index}
            rel={link.rel}
          />
        ))}
        <link
          as="image"
          href={`${process.env.REACT_APP_API_RESOURCES_URL}/images/partnership-illustration.svg`}
          rel="preload"
        />
      </Helmet>
      <Header />
      <Container maxWidth={'xl'}>
        <Grid alignItems={'flex-start'} container spacing={2}>
          <Grid item md={4} sm={4} xs={12}>
            <img
              alt=""
              src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/partnership-illustration.svg`}
              width={'100%'}
            />
          </Grid>
          <Grid item md={8} sm={8} xs={12}>
            <Typography component={'h2'} variant={'h2'}>
              {t('partnership1')}
            </Typography>
            <Typography>{t('partnership2')}</Typography>
            <Typography>{t('partnership3')}</Typography>

            <Typography component={'h3'} variant={'h3'}>
              {t('partnership5')}
            </Typography>
            <Typography>{t('partnership6')}</Typography>
            <Typography>{t('partnership6a')}</Typography>
            <Typography component={'ul'}>
              <Typography component={'li'}>{t('partnership6b')}</Typography>
              <Typography component={'li'}>{t('partnership6c')}</Typography>
              <Typography component={'li'}>{t('partnership6d')}</Typography>
            </Typography>
            <Typography>{t('partnership7')}</Typography>
            <Typography className={'hashtags'} component={'ul'}>
              <Typography component={'li'}>{t('partnership8')}</Typography>
              <Typography component={'li'}>{t('partnership9')}</Typography>
              <Typography component={'li'}>{t('partnership10')}</Typography>
              <Typography component={'li'}>{t('partnership11')}</Typography>
            </Typography>

            <Typography component={'h3'} variant={'h3'}>
              {t('partnership12')}
            </Typography>
            <Typography className={'advantages'} component={'ul'}>
              <Typography component={'li'}>{t('partnership13')}</Typography>
              <Typography component={'li'}>{t('partnership14')}</Typography>
              <Typography component={'li'}>{t('partnership15')}</Typography>
              <Typography component={'li'}>{t('partnership16')}</Typography>
              <Typography component={'li'}>{t('partnership17')}</Typography>
            </Typography>

            <Typography component={'h3'} variant={'h3'}>
              {t('partnership18')}
            </Typography>
            <Button
              color={'secondary'}
              component={Link}
              to={'/contact'}
              variant={'contained'}
            >
              {t('partnership19')}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Partnership;
