import * as actionsType from './constants';

export const shopActions = {
  getShippingAddressesRequest,
  getShippingAddressesSuccess,
  getShippingAddressesFailed,
  addShippingAddressRequest,
  addShippingAddressSuccess,
  addShippingAddressFailed,
  updateShippingAddressRequest,
  updateShippingAddressSuccess,
  updateShippingAddressFailed,
  removeShippingAddressRequest,
  removeShippingAddressSuccess,
  removeShippingAddressFailed,
  setAsDefaultShippingAddressRequest,
  setAsDefaultShippingAddressSuccess,
  setAsDefaultShippingAddressFailed,
  getProductsRequest,
  getProductsSuccess,
  getProductsFail,
  getCatalogProductsRequest,
  getCatalogProductsSuccess,
  getCatalogProductsFail,
  searchProductsRequest,
  searchProductsSuccess,
  searchProductsFail,
  searchDrawerOpen,
  getCategoriesRequest,
  getCategoriesSuccess,
  getCategoriesFail,
  getCategoryRequest,
  getCategorySuccess,
  getCategoryFail,
  getProductRequest,
  getProductSuccess,
  getProductFail,
  addToCart,
  removeItemsFromCart,
  setCartItemQuantity,
  //DEPRECATED initCartRequest,
  //DEPRECATED initCartSuccess,
  //DEPRECATED initCartFail,
  setCartFromOrderRequest,
  setCartFromOrderSuccess,
  setCartFromOrderFail,
  openCartModal,
  closeCartModal,
  signInSessionRequest,
  signInSessionSuccess,
  signInSessionFail,
  signInSessionByDirectLinkRequest,
  signInSessionByDirectLinkSuccess,
  signInSessionByDirectLinkFail,
  updateSessionRequest,
  setShippingAddressSelected,
  selectShippingMethod,
  selectShippingPickupPointMethod,
  useWalletAmount,
  getShippingMethodsRequest,
  getShippingMethodsSuccess,
  getShippingMethodsFail,
  getCheckoutRequest,
  getCheckoutSuccess,
  getCheckoutFail,
  cleanCheckout,
  getOrdersRequest,
  getOrdersSuccess,
  getOrdersFail,
  getGiftCardsRequest,
  getGiftCardsSuccess,
  getGiftCardsFail,
  selectGiftCard,
  getActiveDiscountCodeRequest,
  getActiveDiscountCodeSuccess,
  getActiveDiscountCodeFail,
};

export function getShippingAddressesRequest() {
  return {
    type: actionsType.GET_SHIPPING_ADDRESSES_REQUEST,
  };
}

export function getShippingAddressesSuccess(shippingAddresses) {
  return {
    type: actionsType.GET_SHIPPING_ADDRESSES_SUCCESS,
    shippingAddresses,
  };
}

export function getShippingAddressesFailed() {
  return {
    type: actionsType.GET_SHIPPING_ADDRESSES_FAILED,
  };
}

export function addShippingAddressRequest(shippingAddress) {
  return {
    type: actionsType.ADD_SHIPPING_ADDRESS_REQUEST,
    shippingAddress,
  };
}

export function addShippingAddressSuccess(shippingAddress) {
  return {
    type: actionsType.ADD_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress,
  };
}

export function addShippingAddressFailed() {
  return {
    type: actionsType.ADD_SHIPPING_ADDRESS_FAILED,
  };
}

export function updateShippingAddressRequest(
  shippingAddressId,
  shippingAddress,
) {
  return {
    type: actionsType.UPDATE_SHIPPING_ADDRESS_REQUEST,
    shippingAddressId,
    shippingAddress,
  };
}

export function updateShippingAddressSuccess(shippingAddress) {
  return {
    type: actionsType.UPDATE_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress,
  };
}

export function updateShippingAddressFailed() {
  return {
    type: actionsType.UPDATE_SHIPPING_ADDRESS_FAILED,
  };
}

export function removeShippingAddressRequest(shippingAddress) {
  return {
    type: actionsType.REMOVE_SHIPPING_ADDRESS_REQUEST,
    shippingAddress,
  };
}

export function removeShippingAddressSuccess(shippingAddress) {
  return {
    type: actionsType.REMOVE_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress,
  };
}

export function removeShippingAddressFailed() {
  return {
    type: actionsType.REMOVE_SHIPPING_ADDRESS_FAILED,
  };
}

export function setAsDefaultShippingAddressRequest(shippingAddress) {
  return {
    type: actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_REQUEST,
    shippingAddress,
  };
}

export function setAsDefaultShippingAddressSuccess(shippingAddress) {
  return {
    type: actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
    shippingAddress,
  };
}

export function setAsDefaultShippingAddressFailed() {
  return {
    type: actionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_FAILED,
  };
}

export function getProductsRequest(lang, country, categoryId = null) {
  return {
    type: actionsType.GET_PRODUCTS_REQUEST,
    lang,
    country,
    categoryId,
  };
}

export function getProductsSuccess(lang, country, categoryId, products) {
  return {
    type: actionsType.GET_PRODUCTS_SUCCESS,
    lang,
    country,
    categoryId,
    products,
  };
}

export function getProductsFail() {
  return {
    type: actionsType.GET_PRODUCTS_FAIL,
  };
}

export function getCatalogProductsRequest(lang) {
  return {
    type: actionsType.GET_CATALOG_PRODUCTS_REQUEST,
    lang,
  };
}

export function getCatalogProductsSuccess(products) {
  return {
    type: actionsType.GET_CATALOG_PRODUCTS_SUCCESS,
    products,
  };
}

export function getCatalogProductsFail() {
  return {
    type: actionsType.GET_CATALOG_PRODUCTS_FAIL,
  };
}

export function searchProductsRequest(lang, country, search) {
  return {
    type: actionsType.SEARCH_PRODUCTS_REQUEST,
    lang,
    country,
    search,
  };
}

export function searchProductsSuccess(country, products) {
  return {
    type: actionsType.SEARCH_PRODUCTS_SUCCESS,
    country,
    products,
  };
}

export function searchProductsFail() {
  return {
    type: actionsType.SEARCH_PRODUCTS_FAIL,
  };
}

export function searchDrawerOpen(isOpen) {
  return {
    type: actionsType.SEARCH_DRAWER_OPEN,
    isOpen,
  };
}

export function getCategoriesRequest(lang) {
  return {
    type: actionsType.GET_CATEGORIES_REQUEST,
    lang,
  };
}

export function getCategoriesSuccess(lang, categories) {
  return {
    type: actionsType.GET_CATEGORIES_SUCCESS,
    lang,
    categories,
  };
}

export function getCategoriesFail() {
  return {
    type: actionsType.GET_CATEGORIES_FAIL,
  };
}

export function getCategoryRequest(lang, categoryId) {
  return {
    type: actionsType.GET_CATEGORY_REQUEST,
    categoryId,
  };
}

export function getCategorySuccess(category) {
  return {
    type: actionsType.GET_CATEGORY_SUCCESS,
    category,
  };
}

export function getCategoryFail() {
  return {
    type: actionsType.GET_CATEGORY_FAIL,
  };
}

export function getProductRequest(
  lang,
  country,
  productId,
  allowHidden = false,
) {
  return {
    type: actionsType.GET_PRODUCT_REQUEST,
    lang,
    country,
    productId,
    allowHidden,
  };
}

export function getProductSuccess(product) {
  return {
    type: actionsType.GET_PRODUCT_SUCCESS,
    product,
  };
}

export function getProductFail() {
  return {
    type: actionsType.GET_PRODUCT_FAIL,
  };
}

export function addToCart(item, quantity) {
  return {
    type: actionsType.CART_ADD_ITEM,
    item,
    quantity,
  };
}

export function removeItemsFromCart() {
  return {
    type: actionsType.CART_REMOVE_ITEMS,
  };
}

export function setCartItemQuantity(item, quantity) {
  return {
    type: actionsType.CART_SET_ITEM_QUANTITY,
    item,
    quantity,
  };
}

export function setCartFromOrderRequest(orderId, country) {
  return {
    type: actionsType.CART_SET_FROM_ORDER_REQUEST,
    orderId,
    country,
  };
}

export function setCartFromOrderSuccess(lang, country, items) {
  return {
    type: actionsType.CART_SET_FROM_ORDER_SUCCESS,
    lang,
    country,
    items,
  };
}

export function setCartFromOrderFail() {
  return {
    type: actionsType.CART_SET_FROM_ORDER_FAIL,
  };
}

export function openCartModal() {
  return {
    type: actionsType.CART_OPEN_MODAL,
  };
}

export function closeCartModal() {
  return {
    type: actionsType.CART_CLOSE_MODAL,
  };
}

export function signInSessionRequest(
  consultantId,
  countryCode,
  redirect = null,
) {
  return {
    type: actionsType.SIGNIN_SESSION_REQUEST,
    consultantId,
    countryCode,
    redirect,
  };
}

export function signInSessionSuccess(session) {
  return {
    type: actionsType.SIGNIN_SESSION_SUCCESS,
    session,
  };
}

export function signInSessionFail() {
  return {
    type: actionsType.SIGNIN_SESSION_FAIL,
  };
}

export function signInSessionByDirectLinkRequest(
  directLink,
  countryCode = null,
) {
  return {
    type: actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_REQUEST,
    directLink,
    countryCode,
  };
}

export function signInSessionByDirectLinkSuccess(session) {
  return {
    type: actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_SUCCESS,
    session,
  };
}

export function signInSessionByDirectLinkFail() {
  return {
    type: actionsType.SIGNIN_SESSION_BY_DIRECT_LINK_FAIL,
  };
}

export function updateSessionRequest(updating) {
  return {
    type: actionsType.UPDATE_SESSION_REQUEST,
    updating,
  };
}

export function setShippingAddressSelected(shippingAddress) {
  return {
    type: actionsType.SET_SHIPPING_ADDRESS_SELECTED,
    shippingAddress,
  };
}

export function selectShippingMethod(shippingMethod) {
  return {
    type: actionsType.SET_SHIPPING_METHOD_SELECTED,
    shippingMethod,
  };
}

export function selectShippingPickupPointMethod(shippingMethod, pickupPoint) {
  return {
    type: actionsType.SET_SHIPPING_PICKUP_POINT_SELECTED,
    shippingMethod,
    pickupPoint,
  };
}

export function useWalletAmount(useWallet) {
  return {
    type: actionsType.SET_USE_WALLET_SELECTED,
    useWallet,
  };
}

export function getShippingMethodsRequest(country, weight) {
  return {
    type: actionsType.GET_SHIPPING_METHODS_REQUEST,
    country,
    weight,
  };
}

export function getShippingMethodsSuccess(shippingMethods) {
  return {
    type: actionsType.GET_SHIPPING_METHODS_SUCCESS,
    shippingMethods,
  };
}

export function getShippingMethodsFail() {
  return {
    type: actionsType.GET_SHIPPING_METHODS_FAIL,
  };
}

export function getCheckoutRequest(
  lang,
  shippingMethodId,
  shippingAddressId,
  shippingPickupPoint,
  items,
  useWallet,
  giftCard,
) {
  return {
    type: actionsType.GET_CHECKOUT_REQUEST,
    lang,
    shippingMethodId,
    shippingAddressId,
    shippingPickupPoint,
    items,
    useWallet,
    giftCard,
  };
}

export function getCheckoutSuccess(checkout) {
  return {
    type: actionsType.GET_CHECKOUT_SUCCESS,
    checkout,
  };
}

export function getCheckoutFail() {
  return {
    type: actionsType.GET_CHECKOUT_FAIL,
  };
}

export function cleanCheckout() {
  return {
    type: actionsType.CLEAN_CHECKOUT,
  };
}

export function getOrdersRequest(lang) {
  return {
    type: actionsType.GET_ORDERS_REQUEST,
    lang,
  };
}

export function getOrdersSuccess(orders) {
  return {
    type: actionsType.GET_ORDERS_SUCCESS,
    orders,
  };
}

export function getOrdersFail() {
  return {
    type: actionsType.GET_ORDERS_FAIL,
  };
}

export function getGiftCardsRequest() {
  return {
    type: actionsType.GET_GIFT_CARDS_REQUEST,
  };
}

export function getGiftCardsSuccess(giftCards) {
  return {
    type: actionsType.GET_GIFT_CARDS_SUCCESS,
    giftCards,
  };
}

export function getGiftCardsFail() {
  return {
    type: actionsType.GET_GIFT_CARDS_FAIL,
  };
}

export function selectGiftCard(giftCard) {
  return {
    type: actionsType.SELECT_GIFT_CARD,
    giftCard,
  };
}

export function getActiveDiscountCodeRequest() {
  return {
    type: actionsType.GET_ACTIVE_DISCOUNT_CODE_REQUEST,
  };
}

export function getActiveDiscountCodeSuccess(activeDiscountCode) {
  return {
    type: actionsType.GET_ACTIVE_DISCOUNT_CODE_SUCCESS,
    activeDiscountCode,
  };
}

export function getActiveDiscountCodeFail() {
  return {
    type: actionsType.GET_ACTIVE_DISCOUNT_CODE_FAIL,
  };
}
