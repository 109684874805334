import React, { useRef, useEffect, useState } from 'react';
import useIntersectionObserver from './useIntersectionObserver';

const _loaded = {};

const LazyLoadImage = ({
  alt,
  className,
  height,
  skeleton = null,
  src,
  width,
  ...rest
}) => {
  const elementRef = useRef(null);
  const [inView] = useIntersectionObserver(elementRef);
  const [loaded, setLoaded] = useState(_loaded[src]);

  useEffect(() => {
    if (inView) {
      if (_loaded[src]) {
        setLoaded(true);

        return;
      }

      const img = new Image();
      img.src = src;
      img.onload = () => {
        _loaded[src] = true;
        setLoaded(true);
      };
    }
  }, [inView]);

  return (
    <div className={className ? `${className}-wrapper` : null} ref={elementRef}>
      {!loaded ? (
        skeleton
      ) : (
        <img
          {...rest}
          alt={alt}
          className={className}
          height={height}
          src={src}
          width={width}
        />
      )}
    </div>
  );
};

export default LazyLoadImage;
