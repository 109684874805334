import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { shopActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumb from '../../../../components/Layout/Customer/Breadcrumb';
import {
  cartOrCheckoutDetailsSelector,
  itemsFromCartSelector,
  itemsFromCheckoutSelector,
  sessionSelector,
  shippingAddressSelectedSelector,
  shippingMethodSelectedSelector,
  isCartModalOpenedSelector,
  needDeliverySelector,
  useWalletSelector,
  checkoutSelector,
  shippingPickupPointSelectedSelector,
  giftCardSelector,
} from '../store/selectors';
import RegistrationCard from '../../../Authentication/components/RegistrationCard';
import RegisteredCard from './components/RegisteredCard';
import CartListItems from '../components/Cart/CartListItems';
import Divider from '@mui/material/Divider';
import CartDetails from '../components/Cart/CartDetails';
import Typography from '@mui/material/Typography';
import ShareCartButton from './components/ShareCartButton';
import { green } from '@mui/material/colors';
import NoSsr from '@mui/material/NoSsr';
import Header from '../../../../components/Layout/Customer/Header/Header';
import { red } from '@mui/material/colors';

const useStylesCheckout = makeStyles((theme) => ({
  container: {
    paddingTop: 70,
    minHeight: '100vh',
    paddingBottom: 40,
    '& .highlight': {
      color: green[800],
      fontWeight: 'bold',
    },
    '& .bold': {
      fontWeight: 'bold',
    },
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& > h1': {
      fontWeight: 'bold',
      marginBottom: 5,
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
  },
  listItems: {
    '& img': {
      borderRadius: 16,
      maxHeight: 70,
    },
    '& .priceInfo': {
      display: 'inline',
    },
    '& .oldPriceInfo': {
      textDecoration: 'line-through',
    },
    '& .proPriceInfo': {
      fontWeight: 'bold',
      color: 'red',
      display: 'block',
      marginLeft: 10,
      '& .limitedCommissions.tab': {
        display: 'none',
      },
      '& .limitedCommissions.icon': {
        display: 'block',
        color: red[500],
      },
      '& .discount': {
        display: 'none',
      },
    },
    '& .discount': {
      color: green[800],
      fontWeight: '500',
    },
  },
}));

const Checkout = (props) => {
  const classes = useStylesCheckout();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const isOpen = useSelector(isCartModalOpenedSelector);
  const session = useSelector(sessionSelector);
  const needDelivery = useSelector(needDeliverySelector);
  const useWallet = useSelector(useWalletSelector);
  const giftCard = useSelector(giftCardSelector);
  const shippingAddressSelected = useSelector(shippingAddressSelectedSelector);
  const shippingPickupPointSelected = useSelector(
    shippingPickupPointSelectedSelector,
  );
  const shippingMethodSelected = useSelector(shippingMethodSelectedSelector);
  const checkout = useSelector(checkoutSelector);
  const itemsFromCart = useSelector(itemsFromCartSelector);
  const itemsFromCheckout = useSelector(itemsFromCheckoutSelector);
  const {
    allowFreeShipping,
    currency,
    discount,
    giftCardAmount,
    isFreeShipping,
    proFee,
    proFeeTaxes,
    shippingPrice,
    shippingTaxes,
    subTotal,
    taxes,
    total,
    walletAmount,
  } = useSelector(cartOrCheckoutDetailsSelector);

  useEffect(() => {
    if (typeof window === 'object') {
      window.scrollTo(0, 0);
    }
  }, []);

  useEffect(() => {
    const contents = Object.values(itemsFromCart).map((item) => ({
      id: item.id,
      quantity: item.quantity,
      price: item.price,
    }));
    import('react-facebook-pixel')
      .then((module) => module.default)
      .then((ReactPixel) => {
        ReactPixel.track('InitiateCheckout', {
          content_ids: contents.map((item) => item.id),
          content_type: 'product',
          contents: contents,
          currency: 'SCR',
          value: contents.reduce(
            (sum, item) => sum + item.quantity * item.price,
            0,
          ),
        });
      });
  }, []);

  useEffect(() => {
    if (props.match.params.orderId && !!shippingAddressSelected) {
      dispatch(
        shopActions.setCartFromOrderRequest(
          props.match.params.orderId,
          shippingAddressSelected.country,
        ),
      );
    }
  }, [props.match.params.orderId, shippingAddressSelected]);

  useEffect(() => {
    if (
      session.registered &&
      Object.keys(itemsFromCart).length &&
      shippingAddressSelected &&
      (shippingMethodSelected || !needDelivery)
    ) {
      dispatch(
        shopActions.getCheckoutRequest(
          i18n.language,
          shippingMethodSelected?.id,
          shippingAddressSelected.id,
          shippingPickupPointSelected,
          itemsFromCart,
          useWallet,
          giftCard ? `${giftCard.code}` : ``,
        ),
      );
    }
  }, [
    session.registered,
    itemsFromCart,
    shippingMethodSelected,
    shippingAddressSelected,
    useWallet,
    giftCard,
  ]);

  useEffect(() => {
    if (isOpen) {
      dispatch(shopActions.closeCartModal());
    }
  }, [isOpen]);

  return (
    <NoSsr>
      <Header />
      <div className={classes.container}>
        <Container fixed maxWidth="xl">
          <div className={classes.pageTitle}>
            <Grid container justifyContent={'space-between'} spacing={2}>
              <Grid item>
                <Breadcrumb
                  parents={[
                    {
                      name: <Trans i18nKey="shopTitle">Shop</Trans>,
                      uri: `/shop`,
                    },
                    { name: t('checkoutTitle') },
                  ]}
                />
              </Grid>
              <Grid item>
                <ShareCartButton
                  color={'primary'}
                  order={checkout?.order}
                  variant={'outlined'}
                >
                  <Trans i18nKey={'shareThisOrder'}>Share this order</Trans>
                </ShareCartButton>
              </Grid>
            </Grid>
          </div>
          <Grid container justifyContent={'center'} spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              {session.registered && <RegisteredCard {...props} />}
              {!session.registered && <RegistrationCard />}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <Grid container justifyContent={'center'}>
                <Grid item sm={10} xs={12}>
                  <Grid container justifyContent={'center'}>
                    {Object.keys(itemsFromCheckout).length === 0 &&
                      Object.keys(itemsFromCart).length === 0 && (
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                          <Typography>{t('yourCartIsEmpty')}</Typography>
                        </Grid>
                      )}
                    {Object.keys(itemsFromCheckout).length === 0 &&
                      Object.keys(itemsFromCart).length > 0 && (
                        <>
                          <Grid item lg={10} md={12} sm={12} xs={12}>
                            <Typography component={'h4'} variant={'h6'}>
                              <Trans i18nKey={'yourOrder'}>Your order</Trans>
                            </Typography>
                            <CartListItems
                              className={classes.listItems}
                              items={itemsFromCart}
                              session={session}
                            />
                            <Divider />
                          </Grid>
                          <Grid item lg={8} md={10} sm={12} xs={12}>
                            <CartDetails
                              allowFreeShipping={allowFreeShipping}
                              currency={currency}
                              discount={discount}
                              isFreeShipping={isFreeShipping}
                              shippingPrice={shippingPrice}
                              subTotal={subTotal}
                              taxes={taxes}
                              total={total}
                            />
                          </Grid>
                        </>
                      )}
                    {Object.keys(itemsFromCheckout).length > 0 && (
                      <>
                        <Grid item lg={10} md={12} sm={12} xs={12}>
                          <Typography component={'h4'} variant={'h6'}>
                            <Trans i18nKey={'yourOrder'}>Your order</Trans>
                          </Typography>
                          <CartListItems
                            className={classes.listItems}
                            items={itemsFromCheckout}
                            session={session}
                          />
                          <Divider />
                        </Grid>
                        <Grid item lg={8} md={10} sm={12} xs={12}>
                          <CartDetails
                            allowFreeShipping={allowFreeShipping}
                            currency={currency}
                            discount={discount}
                            giftCardAmount={giftCardAmount}
                            isFreeShipping={isFreeShipping}
                            proFee={proFee}
                            proFeeTaxes={proFeeTaxes}
                            shippingPrice={shippingPrice}
                            shippingTaxes={shippingTaxes}
                            subTotal={subTotal}
                            taxes={taxes}
                            total={total}
                            walletAmount={walletAmount}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </NoSsr>
  );
};

export default Checkout;
