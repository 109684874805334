import React, { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Breadcrumb from '../../../components/Layout/Admin/Breadcrumb';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { Helmet } from 'react-helmet-async';
import { userService } from './store/services';
import UserActionsCard from './components/UserActionsCard';
import { Route, Switch } from 'react-router-dom';
import UserShippingAddresses from './components/UserShippingAddresses';
import UserStats from './components/UserStats';
import UserNetworkTable from './components/UserNetworkTable';
import UserAccount from './components/UserAccount';
import UserOrdersCard from './components/UserOrdersCard';
import UserUpdateCard from './components/UserUpdateCard';
import UserGiftCardsCard from './components/UserGiftCardsCard';
import UserAuthorizations from './components/UserAuthorizations';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const UserLayout = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!user || user.id !== props.match.params.userId) {
      loadUser();
    }
  }, [props.match.params.userId]);

  const loadUser = () => {
    userService
      .getUserById(props.match.params.userId)
      .then((user) => setUser(user));
  };

  if (!user || !user.id) {
    return (
      <Grid container justifyContent={'center'} spacing={4}>
        <CircularProgress color={'secondary'} />
      </Grid>
    );
  }

  return (
    <div>
      <Helmet>
        <title>{`${user.firstName} ${user.lastName}`}</title>
      </Helmet>
      <Container fixed maxWidth="xl">
        <div className={classes.title}>
          <Breadcrumb
            parents={[
              { name: 'Utilisateurs' },
              { name: `${user.firstName} ${user.lastName}` },
            ]}
          />
          <Typography component="h1" variant="h4">
            {user.firstName} {user.lastName}
          </Typography>
        </div>
        <Grid container spacing={2}>
          <Grid item lg={4} md={12} sm={12} xl={4} xs={12}>
            <UserActionsCard onChange={loadUser} user={user} />
          </Grid>
          <Grid item lg={8} md={12} sm={12} xl={8} xs={12}>
            <Switch>
              <Route
                component={() => UserUpdateCard({ user, onChange: loadUser })}
                exact
                path="/admin/users/:userId/update"
              />
              <Route
                component={() =>
                  UserAuthorizations({ user, onChange: loadUser })
                }
                exact
                path="/admin/users/:userId/authorizations"
              />
              <Route
                component={() =>
                  UserShippingAddresses({ user, onChange: loadUser })
                }
                exact
                path="/admin/users/:userId/shipping"
              />
              <Route
                component={() => UserStats({ user })}
                exact
                path="/admin/users/:userId/stats"
              />
              <Route
                component={() => UserNetworkTable({ user })}
                exact
                path="/admin/users/:userId/network"
              />
              <Route
                component={() => UserOrdersCard({ user })}
                exact
                path="/admin/users/:userId/orders"
              />
              <Route
                component={() => UserGiftCardsCard({ user })}
                exact
                path="/admin/users/:userId/gifts"
              />
              <Route
                component={() => UserAccount({ user })}
                exact
                path="/admin/users/:userId"
              />
            </Switch>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default UserLayout;
