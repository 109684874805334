import axios from '../../../../helpers/axios';

export const walletService = {
  getWallet,
  getWalletHistories,
  getWalletCommissionsCreditNotes,
  transfer,
};

function getWallet() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/wallet`)
      .then(({ wallet }) => {
        resolve(wallet);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getWalletHistories(year, month) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/wallet/histories/${year}/${month}`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getWalletCommissionsCreditNotes() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/wallet/commissions-credit-notes`)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function transfer() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/wallet/transfer`)
      .then(({ wallet }) => {
        resolve(wallet);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
