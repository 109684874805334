import React from 'react';
import Box from '@mui/material/Box';
import { Link } from '../../../../helpers/multilingual';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import HeaderMenu from './components/HeaderMenu';

const Header = ({ transparent }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: 75,
        zIndex: 300,
        transition: 'all 0.3s',
        backgroundColor: transparent ? 'transparent' : 'white',
        boxShadow: transparent
          ? 'none'
          : 'rgba(200, 200, 200, 0.5) 0px 1px 4px',
      }}
    >
      <Container maxWidth={'xl'}>
        <HeaderMenu
          logo={
            <Box
              sx={{
                textAlign: 'center',
                display: transparent ? 'none' : 'block',
                '& img': {
                  height: 70,
                },
              }}
            >
              <Link title={t('homepage')} to={''}>
                <img
                  alt="home"
                  src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/logo/logo.jpeg`}
                />
              </Link>
            </Box>
          }
          transparent={transparent}
        />
      </Container>
    </Box>
  );
};
export default Header;
