import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { userService } from '../store/services';
import { useTranslation } from 'react-i18next';
import { SnackbarFormattedError } from '../../../../helpers/snackbar';
import PageLoader from '../../../../components/PageLoader';

const UsersDataTable = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    userService
      .getUsers()
      .then((users) => setUsers(users))
      .catch((e) => SnackbarFormattedError(e.error));
  }, []);

  useEffect(() => {
    if (users) {
      setData(users.map((child) => createData(child)));
    }
  }, [users]);

  function createData(child) {
    return {
      ...child,
      name: `${child.firstName} ${child.lastName}`,
      role: `${t(child.role)}`,
    };
  }

  const columns = [
    {
      name: 'name',
      label: t('name'),
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: 'role',
      label: t('role'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'email',
      label: t('email'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'phone',
      label: t('phone'),
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'address',
      label: t('address'),
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  if (!data) {
    return <PageLoader height={200} />;
  }

  return (
    <MUIDataTable
      columns={columns}
      data={data}
      options={{
        rowsPerPage: 500,
        filterType: 'checkbox',
        elevation: 0,
        selectableRows: 'none',
        downloadOptions: {
          filename: `network`,
          separator: ';',
          filterOptions: {
            useDisplayedColumnsOnly: true,
            useDisplayedRowsOnly: true,
          },
        },
        onRowClick: (rowData, row) => {
          const user = data[row.rowIndex];
          console.log(user);
          if (user) {
            window.open(
              `${process.env.REACT_APP_WEB_URL}/admin/users/${user.id}`,
            );
          }
        },
        print: false,
        textLabels: {
          body: {
            noMatch: 'Sorry, no matching records found',
            toolTip: 'Sort',
            columnHeaderTooltip: (column) => `Sort for ${column.label}`,
          },
          pagination: {
            next: 'Next Page',
            previous: 'Previous Page',
            rowsPerPage: 'Rows per page:',
            displayRows: 'of',
          },
          toolbar: {
            search: 'Search',
            downloadCsv: 'Download CSV',
            print: 'Print',
            viewColumns: 'View Columns',
            filterTable: 'Filter Table',
          },
          filter: {
            all: 'All',
            title: 'FILTERS',
            reset: 'RESET',
          },
          viewColumns: {
            title: 'Show Columns',
            titleAria: 'Show/Hide Table Columns',
          },
          selectedRows: {
            text: 'row(s) selected',
            delete: 'Delete',
            deleteAria: 'Delete Selected Rows',
          },
        },
      }}
    />
  );
};
export default UsersDataTable;
