import { ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import React from 'react';

const MobileMenuItem = ({ hidden, icon, title, ...props }) => {
  if (hidden) {
    return null;
  }
  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={{
          height: 60,
          px: 3,
          '& svg': {
            width: '1.5em',
            fontSize: 29,
          },
        }}
        {...props}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </ListItem>
  );
};
export default MobileMenuItem;
