import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionsType from './constants';
import { statsActions } from './actions';
import { statsService } from './services';

export const statsSagaKey = 'stats';

function* getTurnoverByMonth() {
  try {
    let data = yield call(statsService.getTurnoverByMonth);
    yield put(statsActions.getTurnoverByMonthSuccess(data));
  } catch (e) {
    yield put(statsActions.getTurnoverByMonthError());
  }
}

function* getRecurringTurnoverByMonth() {
  try {
    let data = yield call(statsService.getRecurringTurnoverByMonth);
    yield put(statsActions.getRecurringTurnoverByMonthSuccess(data));
  } catch (e) {
    yield put(statsActions.getRecurringTurnoverByMonthError());
  }
}

function* getRecruitmentByMonth() {
  try {
    let data = yield call(statsService.getRecruitmentByMonth);
    yield put(statsActions.getRecruitmentSuccess(data));
  } catch (e) {
    yield put(statsActions.getRecruitmentError());
  }
}

function* getPacksAmount(action) {
  try {
    let data = yield call(
      statsService.getPacksAmount,
      action.year,
      action.month,
    );
    yield put(statsActions.getPacksAmountSuccess(data));
  } catch (e) {
    yield put(statsActions.getPacksAmountError());
  }
}

function* getProductsAmount(action) {
  try {
    let data = yield call(
      statsService.getProductsAmount,
      action.year,
      action.month,
    );
    yield put(statsActions.getProductsAmountSuccess(data));
  } catch (e) {
    yield put(statsActions.getProductsAmountError());
  }
}

function* getPacksAmountByMonth(action) {
  try {
    let data = yield call(statsService.getPacksAmountByMonth, action.year);
    yield put(statsActions.getPacksAmountByMonthSuccess(data));
  } catch (e) {
    yield put(statsActions.getPacksAmountByMonthError());
  }
}

function* getBestSellersAmount(action) {
  try {
    let data = yield call(
      statsService.getBestSellersAmount,
      action.year,
      action.month,
      action.productType,
    );
    yield put(statsActions.getBestSellersAmountSuccess(data));
  } catch (e) {
    yield put(statsActions.getBestSellersAmountError());
  }
}

function* getBestRecruitersAmount(action) {
  try {
    let data = yield call(
      statsService.getBestRecruitersAmount,
      action.year,
      action.month,
    );
    yield put(statsActions.getBestRecruitersAmountSuccess(data));
  } catch (e) {
    yield put(statsActions.getBestRecruitersAmountError());
  }
}

function* getOrdersCountByMonth() {
  try {
    let data = yield call(statsService.getOrdersCountByMonth);
    yield put(statsActions.getOrdersCountByMonthSuccess(data));
  } catch (e) {
    yield put(statsActions.getOrdersCountByMonthError());
  }
}

function* getProsCountByMonth() {
  try {
    let data = yield call(statsService.getProsCountByMonth);
    yield put(statsActions.getProsCountByMonthSuccess(data));
  } catch (e) {
    yield put(statsActions.getProsCountByMonthError());
  }
}

function* getActiveProsCountByMonth() {
  try {
    let data = yield call(statsService.getActiveProsCountByMonth);
    yield put(statsActions.getActiveProsCountByMonthSuccess(data));
  } catch (e) {
    yield put(statsActions.getActiveProsCountByMonthError());
  }
}

function* getTurnoverByCareer(action) {
  try {
    let data = yield call(
      statsService.getTurnoverByCareer,
      action.year,
      action.month,
    );
    yield put(statsActions.getTurnoverByCareerSuccess(data));
  } catch (e) {
    yield put(statsActions.getTurnoverByCareerError());
  }
}

function* getDirectSales(action) {
  try {
    let data = yield call(statsService.getDirectSales, action.userId);
    yield put(statsActions.getDirectSalesSuccess(action.userId, data));
  } catch (e) {
    yield put(statsActions.getDirectSalesError());
  }
}

function* getNetworkSales(action) {
  try {
    let data = yield call(statsService.getNetworkSales, action.userId);
    yield put(statsActions.getNetworkSalesSuccess(action.userId, data));
  } catch (e) {
    yield put(statsActions.getNetworkSalesError());
  }
}

function* getDirectRecurringSales(action) {
  try {
    let data = yield call(statsService.getDirectRecurringSales, action.userId);
    yield put(statsActions.getDirectRecurringSalesSuccess(action.userId, data));
  } catch (e) {
    yield put(statsActions.getDirectRecurringSalesError());
  }
}

function* getNetworkRecurringSales(action) {
  try {
    let data = yield call(statsService.getNetworkRecurringSales, action.userId);
    yield put(
      statsActions.getNetworkRecurringSalesSuccess(action.userId, data),
    );
  } catch (e) {
    yield put(statsActions.getNetworkRecurringSalesError());
  }
}

function* getDirectRecruitment(action) {
  try {
    let data = yield call(statsService.getDirectRecruitment, action.userId);
    yield put(statsActions.getDirectRecruitmentSuccess(action.userId, data));
  } catch (e) {
    yield put(statsActions.getDirectRecruitmentError());
  }
}

function* getNetworkRecruitment(action) {
  try {
    let data = yield call(statsService.getNetworkRecruitment, action.userId);
    yield put(statsActions.getNetworkRecruitmentSuccess(action.userId, data));
  } catch (e) {
    yield put(statsActions.getNetworkRecruitmentError());
  }
}

function* getDirectBestSellersAmount(action) {
  try {
    let data = yield call(
      statsService.getDirectBestSellersAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getDirectBestSellersAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getDirectBestSellersAmountError());
  }
}

function* getDirectBestRecruitersAmount(action) {
  try {
    let data = yield call(
      statsService.getDirectBestRecruitersAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getDirectBestRecruitersAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getDirectBestRecruitersAmountError());
  }
}

function* getNetworkBestSellersAmount(action) {
  try {
    let data = yield call(
      statsService.getNetworkBestSellersAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getNetworkBestSellersAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getNetworkBestSellersAmountError());
  }
}

function* getNetworkBestRecruitersAmount(action) {
  try {
    let data = yield call(
      statsService.getNetworkBestRecruitersAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getNetworkBestRecruitersAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getNetworkBestRecruitersAmountError());
  }
}

function* getNetworkPacksAmount(action) {
  try {
    let data = yield call(
      statsService.getNetworkPacksAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getNetworkPacksAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getNetworkPacksAmountError());
  }
}

function* getNetworkProductsAmount(action) {
  try {
    let data = yield call(
      statsService.getNetworkProductsAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getNetworkProductsAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getNetworkProductsAmountError());
  }
}

function* getNetworkTurnoverByCareer(action) {
  try {
    let data = yield call(
      statsService.getNetworkTurnoverByCareer,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getNetworkTurnoverByCareerSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getNetworkTurnoverByCareerError());
  }
}

function* getNetworkActiveProsCountByMonth(action) {
  try {
    let data = yield call(
      statsService.getNetworkActiveProsCountByMonth,
      action.userId,
    );
    yield put(
      statsActions.getNetworkActiveProsCountByMonthSuccess(action.userId, data),
    );
  } catch (e) {
    yield put(statsActions.getNetworkActiveProsCountByMonthError());
  }
}

function* getDirectPacksAmount(action) {
  try {
    let data = yield call(
      statsService.getDirectPacksAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getDirectPacksAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getDirectPacksAmountError());
  }
}

function* getDirectProductsAmount(action) {
  try {
    let data = yield call(
      statsService.getDirectProductsAmount,
      action.userId,
      action.year,
      action.month,
    );
    yield put(
      statsActions.getDirectProductsAmountSuccess(
        action.userId,
        action.year,
        action.month,
        data,
      ),
    );
  } catch (e) {
    yield put(statsActions.getDirectProductsAmountError());
  }
}

export default function* watchDashboard() {
  yield takeEvery(
    actionsType.GET_TURNOVER_BY_MONTH_REQUEST,
    getTurnoverByMonth,
  );
  yield takeEvery(
    actionsType.GET_RECURRING_TURNOVER_BY_MONTH_REQUEST,
    getRecurringTurnoverByMonth,
  );
  yield takeEvery(
    actionsType.GET_RECRUITMENT_BY_MONTH_REQUEST,
    getRecruitmentByMonth,
  );
  yield takeEvery(actionsType.GET_PACKS_AMOUNT_REQUEST, getPacksAmount);
  yield takeEvery(actionsType.GET_PRODUCTS_AMOUNT_REQUEST, getProductsAmount);
  yield takeEvery(
    actionsType.GET_BESTSELLERS_AMOUNT_REQUEST,
    getBestSellersAmount,
  );
  yield takeEvery(
    actionsType.GET_BEST_RECRUITERS_AMOUNT_REQUEST,
    getBestRecruitersAmount,
  );
  yield takeEvery(
    actionsType.GET_PACKS_AMOUNT_BY_MONTH_REQUEST,
    getPacksAmountByMonth,
  );
  yield takeEvery(
    actionsType.GET_ORDERS_COUNT_BY_MONTH_REQUEST,
    getOrdersCountByMonth,
  );
  yield takeEvery(
    actionsType.GET_PROS_COUNT_BY_MONTH_REQUEST,
    getProsCountByMonth,
  );
  yield takeEvery(
    actionsType.GET_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST,
    getActiveProsCountByMonth,
  );
  yield takeEvery(
    actionsType.GET_TURNOVER_BY_CAREER_REQUEST,
    getTurnoverByCareer,
  );

  yield takeEvery(actionsType.GET_DIRECT_SALES_REQUEST, getDirectSales);
  yield takeEvery(
    actionsType.GET_DIRECT_RECURRING_SALES_REQUEST,
    getDirectRecurringSales,
  );
  yield takeEvery(actionsType.GET_NETWORK_SALES_REQUEST, getNetworkSales);
  yield takeEvery(
    actionsType.GET_NETWORK_RECURRING_SALES_REQUEST,
    getNetworkRecurringSales,
  );
  yield takeEvery(
    actionsType.GET_DIRECT_RECRUITMENT_REQUEST,
    getDirectRecruitment,
  );
  yield takeEvery(
    actionsType.GET_NETWORK_RECRUITMENT_REQUEST,
    getNetworkRecruitment,
  );
  yield takeEvery(
    actionsType.GET_DIRECT_BESTSELLERS_AMOUNT_REQUEST,
    getDirectBestSellersAmount,
  );
  yield takeEvery(
    actionsType.GET_DIRECT_BEST_RECRUITERS_AMOUNT_REQUEST,
    getDirectBestRecruitersAmount,
  );
  yield takeEvery(
    actionsType.GET_NETWORK_BESTSELLERS_AMOUNT_REQUEST,
    getNetworkBestSellersAmount,
  );
  yield takeEvery(
    actionsType.GET_NETWORK_BEST_RECRUITERS_AMOUNT_REQUEST,
    getNetworkBestRecruitersAmount,
  );
  yield takeEvery(
    actionsType.GET_DIRECT_PACKS_AMOUNT_REQUEST,
    getDirectPacksAmount,
  );
  yield takeEvery(
    actionsType.GET_DIRECT_PRODUCTS_AMOUNT_REQUEST,
    getDirectProductsAmount,
  );
  yield takeEvery(
    actionsType.GET_NETWORK_PACKS_AMOUNT_REQUEST,
    getNetworkPacksAmount,
  );
  yield takeEvery(
    actionsType.GET_NETWORK_PRODUCTS_AMOUNT_REQUEST,
    getNetworkProductsAmount,
  );
  yield takeEvery(
    actionsType.GET_NETWORK_TURNOVER_BY_CAREER_REQUEST,
    getNetworkTurnoverByCareer,
  );
  yield takeEvery(
    actionsType.GET_NETWORK_ACTIVE_PROS_COUNT_BY_MONTH_REQUEST,
    getNetworkActiveProsCountByMonth,
  );
}
