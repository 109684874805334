import SvgIcon from '@mui/material/SvgIcon';
import React from 'react';

const MagIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        className="cls-1"
        d="M30,26.76H19.67A19.69,19.69,0,0,1,0,7.09V0H10.37A19.69,19.69,0,0,1,30,19.67Z"
        fill="#00207f"
      />
      <path
        className="cls-2"
        d="M15.3,23.59a4.28,4.28,0,0,1-.87-.09.44.44,0,0,1-.35-.35A4.65,4.65,0,0,1,15.3,19h0a4.39,4.39,0,0,1,4-1.25.43.43,0,0,1,.34.35,4.65,4.65,0,0,1-1.22,4.12A4.39,4.39,0,0,1,15.3,23.59Zm-.4-.92a3.55,3.55,0,0,0,2.93-1.05,3.76,3.76,0,0,0,1-3,3.57,3.57,0,0,0-2.94,1.05h0A3.75,3.75,0,0,0,14.9,22.67Zm.71-3.32h0Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M13.6,20.69a4.27,4.27,0,0,1-3.06-1.3h0a4.49,4.49,0,0,1-1.18-4A.45.45,0,0,1,9.7,15a4.26,4.26,0,0,1,3.9,1.22,4.47,4.47,0,0,1,1.19,4,.45.45,0,0,1-.35.36A4.78,4.78,0,0,1,13.6,20.69Zm-3.43-4.81a3.6,3.6,0,0,0,1,2.88h0a3.4,3.4,0,0,0,2.82,1,3.6,3.6,0,0,0-1-2.88A3.4,3.4,0,0,0,10.17,15.88Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M14.43,26.76a.44.44,0,0,1-.43-.45V13.57a.44.44,0,1,1,.87,0V26.31A.44.44,0,0,1,14.43,26.76Z"
        fill="#fff"
      />
      <path
        className="cls-2"
        d="M14.43,13.88a6.44,6.44,0,0,1-6.35-6.5A6.44,6.44,0,0,1,14.43.87a6.44,6.44,0,0,1,6.36,6.51A6.44,6.44,0,0,1,14.43,13.88Zm0-12.11A5.55,5.55,0,0,0,9,7.38,5.55,5.55,0,0,0,14.43,13a5.56,5.56,0,0,0,5.48-5.61A5.55,5.55,0,0,0,14.43,1.77Z"
        fill="#fff"
      />
    </SvgIcon>
  );
};
export default MagIcon;
