import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AddShippingAddressDialog from './components/AddShippingAddressDialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch, useSelector } from 'react-redux';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import {
  userShippingAddressesLoaderSelector,
  userShippingAddressesSelector,
} from '../../../Shop/store/selectors';
import { shopActions } from '../../../Shop/store/actions';
import { green } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';
import UpdateShippingAddressDialog from './components/UpdateShippingAddressDialog';
import { SnackbarConfirm } from '../../../../../helpers/snackbar';

const useStyle = makeStyles(() => ({
  root: {},
  addressCard: {
    height: '100%',
    '& p.address-name': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& h2': {
      fontSize: 20,
      minHeight: 80,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiCardHeader-subheader': {
      minHeight: 48,
    },
    '& .MuiCardContent-root': {
      minHeight: 104,
    },
    '& .MuiCardHeader-action': {
      '& .MuiButtonBase-root': {
        height: 40,
      },
    },
  },
  add: {
    height: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F8F8F8',
    },
  },
  labelSelected: {
    background: green[900],
    color: '#fff',
    padding: '0 10px',
    lineHeight: '30px',
    borderRadius: 8,
  },
}));

const ShippingAddresses = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userShippingAddresses = useSelector(userShippingAddressesSelector);
  const loading = useSelector(userShippingAddressesLoaderSelector);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [updateShippingAddressSelected, setUpdateShippingAddressSelected] =
    useState(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState(false);

  useEffect(() => {
    if (!Array.isArray(userShippingAddresses)) {
      dispatch(shopActions.getShippingAddressesRequest());
    }
  }, []);

  const handleRemoveAddress = (address) => {
    return () => {
      SnackbarConfirm(
        <>
          {t('removeShippingAddress')} :<br /> {address.address}
        </>,
        () => {
          dispatch(shopActions.removeShippingAddressRequest(address));
        },
      );
    };
  };

  const handleOpenUpdateDialog = (address) => {
    return () => {
      setUpdateShippingAddressSelected(address);
      setUpdateDialogOpen(true);
    };
  };

  const defaultAddress = (address) => {
    dispatch(shopActions.setAsDefaultShippingAddressRequest(address));
  };

  return (
    <div className={classes.root}>
      {loading && (
        <Grid container justifyContent={'center'} spacing={4}>
          <CircularProgress color={'secondary'} />
        </Grid>
      )}
      {!loading && (
        <Grid container spacing={2}>
          {Array.isArray(userShippingAddresses) &&
            userShippingAddresses.map((address) => (
              <Grid item key={address.id} lg={3} md={6} xs={12}>
                <Card className={classes.addressCard}>
                  <CardHeader
                    action={
                      <>
                        <IconButton
                          aria-label="settings"
                          onClick={handleOpenUpdateDialog(address)}
                        >
                          <EditOutlinedIcon />
                        </IconButton>
                        {!address.byDefault && (
                          <IconButton
                            aria-label="settings"
                            onClick={handleRemoveAddress(address)}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        )}
                      </>
                    }
                    title={address.name ? address.name : t('address')}
                  />
                  <CardContent>
                    <Typography className={'address-name'}>
                      {address.firstName} {address.lastName}
                    </Typography>
                    <Typography className={'address-text'}>
                      {`${address.address} ${
                        address.additionalInfo
                          ? `(${address.additionalInfo})`
                          : ``
                      }`}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {address.byDefault && (
                      <Typography className={classes.labelSelected}>
                        <Trans i18nKey={'defaultShippingAddress'}>
                          Default address
                        </Trans>
                      </Typography>
                    )}
                    {!address.byDefault && (
                      <Button
                        onClick={() => defaultAddress(address)}
                        size="small"
                        variant={'outlined'}
                      >
                        <Trans i18nKey={'selectDefaultShippingAddressButton'}>
                          Set as default address
                        </Trans>
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))}
          <Grid item lg={3} md={6} xs={12}>
            <Card className={classnames(classes.addressCard, classes.add)}>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <AddCircleOutlineOutlinedIcon />
                  </IconButton>
                }
                onClick={() => setAddDialogOpen(true)}
                subheader={
                  <Trans i18nKey="addNewShippingAddress">
                    Add new shipping address
                  </Trans>
                }
                title={<Trans i18nKey="newAddress">New address</Trans>}
              />
            </Card>
          </Grid>
        </Grid>
      )}
      {addDialogOpen && (
        <AddShippingAddressDialog
          dialogOpen={addDialogOpen}
          setDialogOpen={setAddDialogOpen}
        />
      )}
      {updateDialogOpen && updateShippingAddressSelected && (
        <UpdateShippingAddressDialog
          dialogOpen={updateDialogOpen}
          setDialogOpen={setUpdateDialogOpen}
          shippingAddress={updateShippingAddressSelected}
        />
      )}
    </div>
  );
};
export default ShippingAddresses;
