import Cookie from '../../../../helpers/cookie';

const session = Cookie.get('session');
const shippingMethod = Cookie.get('shipping-method');
const shippingAddress = Cookie.get('shipping-address');
const shippingPickupPoint = Cookie.get('shipping-pickup-point');

let cartItems;
try {
  cartItems = JSON.parse(localStorage.getItem('cart'));
} catch (e) {
  cartItems = {};
}

export const initialState = {
  shop: {
    categories: {},
    categoryId: null,
    productsRequested: false,
    products: {},
    catalog: [],
    search: '',
    searchProducts: [],
    isSearchDrawerOpen: false,
    product: null,
    session: session || null,
    sessionError: false,
    checkout: null,
    orders: [],
    giftCards: [],
    giftCard: null,
    activeDiscountCode: null,
    userShipping: {
      addresses: null,
      addressesRequested: false,
    },
    cart: {
      items: cartItems || {},
      error: null,
      isModalOpened: false,
      shipping: {
        methods: [],
        methodsRequested: false,
        method: shippingMethod || null,
        address: shippingAddress || null,
        pickupPoint: shippingPickupPoint || null,
      },
    },
  },
};
