import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';

const LogisticRoutes = () => {
  const TodoList = loadable(
    () => import('../containers/Logistic/Order/TodoList'),
  );
  const Packages = loadable(
    () => import('../containers/Logistic/Packages/Packages'),
  );
  const PackageCreator = loadable(
    () => import('../containers/Logistic/Packages/PackageCreator'),
  );
  const InTransitList = loadable(
    () => import('../containers/Logistic/Order/InTransitList'),
  );
  const ProcessingList = loadable(
    () => import('../containers/Logistic/Order/ProcessingList'),
  );
  const DeliveredList = loadable(
    () => import('../containers/Logistic/Order/DeliveredList'),
  );
  const InPickupPointList = loadable(
    () => import('../containers/Logistic/Order/InPickupPointList'),
  );
  const BackToSenderList = loadable(
    () => import('../containers/Logistic/Order/BackToSenderList'),
  );
  const DoneList = loadable(
    () => import('../containers/Logistic/Order/DoneList'),
  );
  const ErroredList = loadable(
    () => import('../containers/Logistic/Order/ErroredList'),
  );
  const Emergencies = loadable(
    () => import('../containers/Logistic/Order/Emergencies'),
  );
  const Order = loadable(() => import('../containers/Logistic/Order/Order'));
  const StarterKitsTodo = loadable(
    () => import('../containers/Logistic/StarterKit/StarterKitsTodo'),
  );
  const StarterKitsDone = loadable(
    () => import('../containers/Logistic/StarterKit/StarterKitsDone'),
  );
  const CertificatesTodo = loadable(
    () => import('../containers/Logistic/Certificates/CertificatesTodo'),
  );
  const CertificatesDone = loadable(
    () => import('../containers/Logistic/Certificates/CertificatesDone'),
  );
  const Error = loadable(() => import('../containers/Pro/Error'));

  return (
    <Switch>
      <Route component={TodoList} exact path="/logistic/orders/todo" />
      <Route component={Packages} exact path="/logistic/packages" />
      <Route
        component={PackageCreator}
        exact
        path="/logistic/package/creator/:productId"
      />
      <Route component={Order} exact path="/logistic/order/:orderId" />
      <Route
        component={ProcessingList}
        exact
        path="/logistic/orders/processing"
      />
      <Route
        component={InTransitList}
        exact
        path="/logistic/orders/inTransit"
      />
      <Route
        component={InPickupPointList}
        exact
        path="/logistic/orders/inPickupPoint"
      />
      <Route
        component={BackToSenderList}
        exact
        path="/logistic/orders/backToSender"
      />
      <Route
        component={DeliveredList}
        exact
        path="/logistic/orders/delivered"
      />
      <Route component={DoneList} exact path="/logistic/orders/done" />
      <Route component={ErroredList} exact path="/logistic/orders/errored" />
      <Route
        component={Emergencies}
        exact
        path="/logistic/orders/emergencies"
      />
      <Route
        component={StarterKitsTodo}
        exact
        path="/logistic/starterKits/todo"
      />
      <Route
        component={StarterKitsDone}
        exact
        path="/logistic/starterKits/done"
      />
      <Route
        component={CertificatesTodo}
        exact
        path="/logistic/certificates/todo"
      />
      <Route
        component={CertificatesDone}
        exact
        path="/logistic/certificates/done"
      />
      <Route component={Error} path="*" />
    </Switch>
  );
};

export default LogisticRoutes;
