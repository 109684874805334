import Grid from '@mui/material/Grid';
import React from 'react';
import Divider from '@mui/material/Divider';
import makeStyles from '@mui/styles/makeStyles';
import CartListItems from './CartListItems';
import CartDetails from './CartDetails';

const useStylesCart = makeStyles((theme) => ({
  listItems: {
    [theme.breakpoints.up('sm')]: {
      height: '60vh',
      overflow: 'auto',
    },
  },
  details: {
    '& .MuiListItem-root': {
      paddingTop: 20,
      paddingBottom: 20,
    },
  },
}));

const CartContent = ({ cart, session }) => {
  const classes = useStylesCart();

  return (
    <Grid container>
      <Grid item sm={6} xs={12}>
        <CartListItems
          className={classes.listItems}
          items={cart.items}
          session={session}
        />
      </Grid>
      <Divider flexItem orientation="vertical" />
      <Grid item sm={6} xs={10}>
        <CartDetails
          allowFreeShipping={session.allowFreeShipping}
          cartView
          className={classes.details}
          currency={cart.currency}
          discount={cart.discount}
          isFreeShipping={cart.isFreeShipping}
          subTotal={cart.subTotal}
          taxes={cart.taxes}
          total={cart.total}
        />
      </Grid>
    </Grid>
  );
};
export default CartContent;
