import { createSelector } from 'reselect';

export const consultantsSelector = (state) => state.consultants;
export const mapSelector = (state) => state.consultants.map;

export const mapSearchSelector = createSelector([mapSelector], (map) => {
  return map && map.search ? map.search : null;
});

export const mapZoomSelector = createSelector([mapSelector], (map) => {
  return map && map.zoom ? parseInt(map.zoom) : null;
});

export const searchBarModalOpen = createSelector([mapSelector], (map) => {
  return map && map.searchBarModalOpen;
});

export const mapDisplayOptionSelector = createSelector([mapSelector], (map) => {
  switch (map.display) {
    case 'map': {
      return 'map';
    }
    case 'list':
    default: {
      return 'list';
    }
  }
});

export const officesSelector = createSelector([mapSelector], (map) => {
  return map && map.offices ? map.offices : null;
});

export const officeSelector = createSelector(
  [consultantsSelector],
  (consultants) => {
    return consultants.office ? consultants.office : null;
  },
);
