import { createSelector } from 'reselect';
import { cookieConsentReducerKey } from './reducer';

const cookieConsent = (globalState) => globalState[cookieConsentReducerKey];

export const isCookieConsentedSelector = createSelector(
  [cookieConsent],
  (cookieConsent) => {
    return cookieConsent.consent;
  },
);
