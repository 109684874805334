import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import LineChartByYearAndMonth from '../../../../components/Charts/LineChartByYearAndMonth';
import Card from '@mui/material/Card';
import { statsActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  networkRecruitmentDataSelector,
  networkRecruitmentLinesGraphDataSelector,
  networkRecurringSalesLinesGraphDataSelector,
  networkRecurringTurnoverDataSelector,
  networkSalesLinesGraphDataSelector,
  networkTurnoverDataSelector,
} from '../store/selectors';
import { Trans, useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from '@mui/material/LinearProgress';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiSelect-select': {
      fontSize: '1.5rem',
      lineHeight: '1.8rem',
      paddingTop: 10,
      paddingBottom: 10,
      maxWidth: '70vw',
    },
    height: '100%',
  },
  menuItem: {
    fontSize: '1.5rem',
    lineHeight: '1.8rem',
  },
  loader: {
    height: 290,
  },
}));
const NetworkCharts = ({ user }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const [view, setView] = useState('networkSales');
  const networkSalesLinesGraphData = useSelector(
    networkSalesLinesGraphDataSelector(user.id),
  );
  const networkRecurringSalesLinesGraphData = useSelector(
    networkRecurringSalesLinesGraphDataSelector(user.id),
  );
  const networkRecruitmentLinesGraphData = useSelector(
    networkRecruitmentLinesGraphDataSelector(user.id),
  );
  const networkTurnoverData = useSelector(networkTurnoverDataSelector(user.id));
  const networkRecurringTurnoverData = useSelector(
    networkRecurringTurnoverDataSelector(user.id),
  );
  const networkRecruitmentData = useSelector(
    networkRecruitmentDataSelector(user.id),
  );

  useEffect(() => {
    switch (view) {
      case 'networkRecruitments':
        if (!networkRecruitmentLinesGraphData) {
          dispatch(statsActions.getNetworkRecruitmentRequest(user.id));
        }
        break;
      case 'networkRecurringSales':
        if (!networkRecruitmentLinesGraphData) {
          dispatch(statsActions.getNetworkRecurringSalesRequest(user.id));
        }
        break;
      case 'networkSales':
      default:
        if (!networkSalesLinesGraphData) {
          dispatch(statsActions.getNetworkSalesRequest(user.id));
        }
    }
  }, [view]);

  const handleChangeView = (e) => {
    setView(e.target.value);
  };

  const getValues = () => {
    switch (view) {
      case 'networkRecruitments':
        if (!networkRecruitmentLinesGraphData) {
          return null;
        }
        return {
          data: networkRecruitmentLinesGraphData,
          year: `${Math.round(networkRecruitmentData.year * 100) / 100}`,
          month: `${Math.round(networkRecruitmentData.month * 100) / 100}`,
        };
      case 'networkRecurringSales':
        if (!networkRecurringSalesLinesGraphData) {
          return null;
        }
        return {
          data: networkRecurringSalesLinesGraphData,
          year: `SCR ${
            Math.round(networkRecurringTurnoverData.year * 100) / 100
          }`,
          month: `SCR ${
            Math.round(networkRecurringTurnoverData.month * 100) / 100
          }`,
        };
      case 'networkSales':
      default:
        if (!networkSalesLinesGraphData) {
          return null;
        }
        return {
          data: networkSalesLinesGraphData,
          year: `SCR ${Math.round(networkTurnoverData.year * 100) / 100}`,
          month: `SCR ${Math.round(networkTurnoverData.month * 100) / 100}`,
        };
    }
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        sx={{ height: 95 }}
        title={
          <Select
            defaultValue={view}
            onChange={handleChangeView}
            variant={'outlined'}
          >
            <MenuItem className={classes.menuItem} value="networkSales">
              {t('networkSales')}
            </MenuItem>
            <MenuItem
              className={classes.menuItem}
              value="networkRecurringSales"
            >
              {t('networkRecurringSales')}
            </MenuItem>
            <MenuItem className={classes.menuItem} value="networkRecruitments">
              {t('networkRecruitments')}
            </MenuItem>
          </Select>
        }
      />
      <Divider />
      {!getValues() && (
        <Grid
          alignItems={'center'}
          className={classes.loader}
          container
          justifyContent={'center'}
        >
          <Grid item sm={4} xs={6}>
            <Typography align={'center'}>
              <Trans i18nKey={'loading'}>Loading</Trans> ...
            </Typography>
            <LinearProgress color="secondary" />
          </Grid>
        </Grid>
      )}
      {getValues() && (
        <>
          <CardContent>
            <LineChartByYearAndMonth
              data={getValues().data}
              heightLg={350}
              heightMd={300}
              heightSm={300}
              primaryColor={{
                border: 'rgb(89,80,236)',
                background: 'rgba(89,80,236, 0.04)',
              }}
            />
          </CardContent>

          <Grid container>
            <Grid item xs={6}>
              <Typography align={'center'} component={'div'} gutterBottom>
                <Typography>
                  <Trans i18nKey={'thisYear'}>This year</Trans>
                </Typography>
                <Typography variant={'h6'}>{getValues().year}</Typography>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align={'center'} component={'div'} gutterBottom>
                <Typography>
                  <Trans i18nKey={'thisMonth'}>This month</Trans>
                </Typography>
                <Typography variant={'h6'}>{getValues().month}</Typography>
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
    </Card>
  );
};
export default NetworkCharts;
