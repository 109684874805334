import { call, put, takeEvery } from 'redux-saga/effects';
import * as actionsType from './constants';
import { authenticationActions } from './actions';
import { authenticationService } from './services';
import * as userShippingActionsType from '../../Customer/Shop/store/constants';
import * as careerActionsType from '../../Pro/Career/store/constants';
import {
  SnackbarSuccess,
  SnackbarFormattedError,
  SnackbarInfo,
} from '../../../helpers/snackbar';
import i18next from 'i18next';

function* signInJwt(action) {
  try {
    let user = yield call(authenticationService.signInJwt, action.jwt);
    yield put(authenticationActions.signInJwtSuccess(user));
  } catch (e) {
    SnackbarFormattedError(e.error);
    yield put(authenticationActions.signInJwtFailed());
    yield call(authenticationService.signOut);
  }
}

function* signInCredentials(action) {
  try {
    let { refreshToken, token, user } = yield call(
      authenticationService.signInCredentials,
      action.email,
      action.password,
      action.persistent,
    );
    yield put(
      authenticationActions.signInCredentialsSuccess(
        user,
        token,
        refreshToken,
        action.persistent,
      ),
    );
    yield call(SnackbarSuccess, i18next.t('welcome', { name: user.firstName }));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.signInCredentialsFailed());
    //yield call(authenticationService.signOut);
  }
}

function* signUpCredentials(action) {
  try {
    let { refreshToken, token, user } = yield call(
      authenticationService.signUpCredentials,
      action.user,
      action.persistent,
    );
    yield put(
      authenticationActions.signUpCredentialsSuccess(
        user,
        token,
        refreshToken,
        action.persistent,
      ),
    );
    yield call(SnackbarSuccess, i18next.t('welcome', { name: user.firstName }));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.signUpCredentialsFailed());
    //yield call(authenticationService.signOut);
  }
}

function* signUpPsiostoreCredentials(action) {
  try {
    let { refreshToken, token, user } = yield call(
      authenticationService.signUpPsiostoreCredentials,
      action.user,
      true,
    );
    yield put(
      authenticationActions.signUpPsiostoreCredentialsSuccess(
        user,
        token,
        refreshToken,
      ),
    );
    yield call(SnackbarSuccess, i18next.t('welcome', { name: user.firstName }));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.signUpPsiostoreCredentialsFailed());
    //yield call(authenticationService.signOut);
  }
}

function* updateUser(action) {
  try {
    let user = yield call(authenticationService.updateUser, action.user);

    yield put(authenticationActions.updateUserSuccess(user));
    yield call(SnackbarSuccess, i18next.t('dataUpdated'));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.updateUserFailed());
  }
}

function* reloadUser() {
  try {
    let user = yield call(authenticationService.getMe);
    yield put(authenticationActions.reloadUserSuccess(user));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.reloadUserFailed());
    yield call(authenticationService.signOut);
  }
}

function* signOutInvalidAccessToken() {
  yield call(authenticationService.hardSignOut);
  yield call(SnackbarInfo, i18next.t('invalidAccessToken'));
}

function* signOut() {
  yield call(authenticationService.signOut);
  yield call(SnackbarInfo, i18next.t('youAreNoLongerConnected'));
}

function* signOutAllDevices() {
  yield call(authenticationService.signOutAllDevices);
  yield call(authenticationService.hardSignOut);
  yield call(SnackbarInfo, i18next.t('youAreNoLongerConnected'));
}

function* getUserContact(action) {
  try {
    const contact = yield call(authenticationService.getUserContact, action.id);
    yield put(authenticationActions.getUserContactSuccess(contact));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.getUserContactFailed());
  }
}

function* takeControl(action) {
  try {
    const { user } = yield call(
      authenticationService.getUserSessionControl,
      action.userId,
    );
    yield put(authenticationActions.takeControlSuccess(user));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.takeControlFailed(e.error));
  }
}

function* restoreControl() {
  try {
    const { user } = yield call(
      authenticationService.getUserBackFromSessionControl,
    );
    yield put(authenticationActions.restoreControlSuccess(user));
  } catch (e) {
    yield call(SnackbarFormattedError, e.error);
    yield put(authenticationActions.restoreControlFailed(e.error));
  }
}

export default function* watchAuthentication() {
  yield takeEvery(actionsType.SIGNUP_CREDENTIALS_REQUEST, signUpCredentials);
  yield takeEvery(
    actionsType.SIGNUP_PSIOSTORE_CREDENTIALS_REQUEST,
    signUpPsiostoreCredentials,
  );
  yield takeEvery(actionsType.SIGNIN_JWT_REQUEST, signInJwt);
  yield takeEvery(actionsType.SIGNIN_CREDENTIALS_REQUEST, signInCredentials);
  yield takeEvery(actionsType.UPDATE_USER_REQUEST, updateUser);
  yield takeEvery(actionsType.SIGNOUT, signOut);
  yield takeEvery(
    actionsType.SIGNOUT_INVALID_ACCESS_TOKEN,
    signOutInvalidAccessToken,
  );
  yield takeEvery(actionsType.SIGNOUT_ALL_DEVICES, signOutAllDevices);
  yield takeEvery(actionsType.RELOAD_USER_REQUEST, reloadUser);
  yield takeEvery(
    userShippingActionsType.ADD_SHIPPING_ADDRESS_SUCCESS,
    reloadUser,
  );
  yield takeEvery(careerActionsType.VALIDATE_QUALIFICATION_SUCCESS, reloadUser);
  yield takeEvery(
    userShippingActionsType.SET_AS_DEFAULT_SHIPPING_ADDRESS_SUCCESS,
    reloadUser,
  );
  yield takeEvery(actionsType.GET_USER_CONTACT_REQUEST, getUserContact);
  yield takeEvery(actionsType.TAKE_CONTROL_REQUEST, takeControl);
  yield takeEvery(actionsType.RESTORE_CONTROL_REQUEST, restoreControl);
}
