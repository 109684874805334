import axios from '../../../../helpers/axios';

export const mailingService = {
  subscribe,
  subscribePro,
  unsubscribe,
  unsubscribePro,
};

function subscribe() {
  return new Promise((resolve, reject) => {
    axios
      .get(`user/mailing/subscribe`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function subscribePro() {
  return new Promise((resolve, reject) => {
    axios
      .get(`user/mailing/subscribe/pro`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function unsubscribe(email, userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`user/mailing/unsubscribe/${email}/${userId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function unsubscribePro(email, userId) {
  return new Promise((resolve, reject) => {
    axios
      .get(`user/mailing/unsubscribe/pro/${email}/${userId}`)
      .then(() => {
        resolve();
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
