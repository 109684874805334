export const providers = [
  {
    label: 'Sharks',
    value: 'sharks',
  },
  {
    label: 'Nutrilogics',
    value: 'nutrilogics',
  },
  {
    label: 'Kryneo',
    value: 'kryneo',
  },
  {
    label: 'Tina Russillo',
    value: 'tinarussillo',
  },
  {
    label: 'Zenspire',
    value: 'zenspire',
  },
  {
    label: 'Gavots de Provence',
    value: 'gavots',
  },
];
