import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import MapInfoWindow from '../../../../../components/GoogleMap/MapInfoWindow';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 300,
    width: 300,
    '& .name': {
      fontWeight: 500,
    },
    '& .address': {
      fontSize: 13,
      lineHeight: '16px',
    },
    '& .selectButton': {
      fontSize: 13,
      margin: theme.spacing(1, 0),
    },
    '& .openingHours': {
      display: 'flex',
    },
    '& .day': {
      fontSize: 12,
      fontWeight: 'bold',
      width: '33%',
    },
    '& .hour': {
      fontSize: 12,
      width: '33%',
    },
    '& .right': {
      textAlign: 'right',
    },
    '& .closedFrom': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      fontSize: 14,
      fontWeight: '500',
    },
  },
}));

const PickupPointInfoWindow = ({ onSelect, pickupPoint }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSelect = (location) => {
    return () => {
      onSelect(location);
    };
  };

  if (!pickupPoint) {
    return null;
  }

  return (
    <MapInfoWindow
      pixelOffsetY={-35}
      position={{
        lat: pickupPoint.latitude,
        lng: pickupPoint.longitude,
      }}
      zIndex={100}
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography
            className={'name'}
            component="h2"
            gutterBottom
            variant="subtitle2"
          >
            {pickupPoint.name}
          </Typography>
          <Typography className={'address'} gutterBottom>
            {pickupPoint.country === 'BE'
              ? `${pickupPoint.street} ${pickupPoint.number}`
              : `${pickupPoint.number} ${pickupPoint.street}`}
            ,<br />
            {pickupPoint.zip} {pickupPoint.city}
          </Typography>
          <Button
            className={'selectButton'}
            color={'primary'}
            onClick={handleSelect(pickupPoint)}
            size={'small'}
            variant={'contained'}
          >
            <Trans i18nKey={'select'}>Select</Trans>
          </Button>
          <div>
            {pickupPoint.hours.map((day, key) => (
              <div className={'openingHours'} key={`openingHours${key}`}>
                <Typography className={'day'} component={'span'}>
                  {t(day.title)}
                </Typography>
                {day.AMOpen && day.PMClose && !day.AMClose && !day.PMOpen && (
                  <Typography className={'hour'} component={'span'}>
                    {day.AMOpen} - {day.PMClose}
                  </Typography>
                )}
                {day.AMOpen && day.AMClose && (
                  <Typography className={'hour'} component={'span'}>
                    {day.AMOpen} - {day.AMClose}
                  </Typography>
                )}
                {day.PMOpen && day.PMClose && (
                  <Typography className={'hour right'} component={'span'}>
                    {day.PMOpen} - {day.PMClose}
                  </Typography>
                )}
              </div>
            ))}
          </div>
          {(pickupPoint.closedFrom || pickupPoint.closedTo) && (
            <Typography className={'closedFrom'} color={'error'}>
              <Trans
                i18nKey={'closingFromTo'}
                values={{
                  closedFrom: pickupPoint.closedFrom,
                  closedTo: pickupPoint.closedTo,
                }}
              >
                Closed from {pickupPoint.closedFrom} to {pickupPoint.closedTo}
              </Trans>
            </Typography>
          )}
        </CardContent>
      </Card>
    </MapInfoWindow>
  );
};

export default PickupPointInfoWindow;
