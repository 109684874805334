import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import Box from '@mui/material/Box';
if (Array.isArray(registerables)) {
  Chart.register(...registerables);
}

const BarChart = ({
  color,
  data,
  displayLegend = true,
  heightLg,
  heightMd,
  heightSm,
  labels,
  title,
}) => {
  const dataValues = {
    labels: labels,
    datasets: [
      {
        label: title,
        data: data,
        backgroundColor: data && data.map(() => color.background),
        borderColor: data && data.map(() => color.border),
        borderWidth: 1,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      responsive: true,
      legend: {
        display: displayLegend,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          callback: function (value) {
            return this.getLabelForValue(value).substring(0, 15);
          },
          font: {
            size: 9,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
        },
        ticks: {
          min: 0,
        },
      },
    },
  };

  return (
    <Box
      sx={(theme) => ({
        '& canvas': {
          width: '100% !important',
          height: `${heightLg}px !important`,
          [theme.breakpoints.down('lg')]: {
            height: `${heightMd}px !important`,
          },
          [theme.breakpoints.down('md')]: {
            height: `${heightSm}px !important`,
          },
        },
      })}
    >
      <Bar data={dataValues} options={options} />
    </Box>
  );
};
export default BarChart;
