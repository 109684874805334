import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Link } from '../../../../helpers/multilingual';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles(() => ({
  root: {
    '& h2': {
      fontSize: '2.1em',
      fontWeight: '500',
      marginBottom: 32,
    },
    '& .MuiTypography-body1': {
      marginBottom: 16,
    },
    '& .bold': {
      fontWeight: '500',
    },
    '& .card': {
      borderRadius: 32,
      padding: '16px 32px 16px 32px',
      marginBottom: 32,
      '& .MuiCardHeader-root': {
        paddingBottom: 0,
      },
      '& .MuiCardHeader-title': {
        color: '#007676',
        fontWeight: '600',
      },
      '& .MuiTypography-body1': {
        marginBottom: 0,
      },
      '& button': {
        marginBottom: 0,
        textDecoration: 'underline',
      },
      '&:hover': {
        cursor: 'pointer',
        background: '#007676',
        '& .MuiCardHeader-title': {
          color: '#FFFFFF',
        },
        '& .MuiTypography-body1': {
          color: '#FFFFFF',
        },
        '& button': {
          color: '#FFFFFF',
        },
      },
    },
  },
}));

const WaysOfPartnership = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Helmet>
        <link
          as="image"
          href={`${process.env.REACT_APP_API_RESOURCES_URL}/images/speak-partnership.svg`}
          rel="preload"
        />
      </Helmet>
      <Grid container spacing={2}>
        <Grid item md={4} sm={4} xs={12}>
          <img
            alt=""
            src={`${process.env.REACT_APP_API_RESOURCES_URL}/images/speak-partnership.svg`}
            width={'100%'}
          />
        </Grid>
        <Grid item md={8} sm={8} xs={12}>
          <Typography component={'h2'} variant={'h2'}>
            {t('careerOpportunity1')}
          </Typography>

          <Typography>
            <Trans i18nKey={'careerOpportunity2'}>
              Are you passionate about nutrition and well-being and do you have
              a real sensitivity for nature?
              <br />
              Join our community of professionals!
            </Trans>
          </Typography>

          <Typography>{t('careerOpportunity3')}</Typography>

          <Typography className={'bold'}>{t('careerOpportunity4')}</Typography>

          <Link to={'/career-opportunity/micro-franchisee'}>
            <Card className={'card'}>
              <CardHeader title={t('careerOpportunity5')} />
              <CardContent>
                <Typography>{t('careerOpportunity6')}</Typography>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <Button>{t('learnMore')}</Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Link>

          <Link to={'/career-opportunity/partnership'}>
            <Card className={'card'}>
              <CardHeader title={t('careerOpportunity7')} />
              <CardContent>
                <Typography>{t('careerOpportunity8')}</Typography>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <Button>{t('learnMore')}</Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Link>
          {/*
          <Link to={'/career-opportunity/ambassador'}>
            <Card className={'card'}>
              <CardHeader title={t('careerOpportunity9')} />
              <CardContent>
                <Typography>{t('careerOpportunity10')}</Typography>
              </CardContent>
              <CardActions>
                <Grid container justifyContent={'flex-end'}>
                  <Grid item>
                    <Button>{t('learnMore')}</Button>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Link>
          */}
          <Typography component={'i'}>{t('careerOpportunity11')}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default WaysOfPartnership;
