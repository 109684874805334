import axios from '../../../../helpers/axios';

export const academyService = {
  takeFreeFormation,
  getProgramByUrl,
  getPrograms,
  getUserPrograms,
  validateProgramStep,
  resetProgram,
};

function getUserPrograms() {
  return new Promise((resolve, reject) => {
    axios
      .get(`/user/academy/programs`)
      .then(({ programs }) => {
        resolve(programs);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function takeFreeFormation(id) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/academy/program/${id}/add`)
      .then(({ program }) => {
        resolve(program);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getProgramByUrl(country, url) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/academy/program/${country}/${url}`)
      .then(({ program }) => {
        resolve(program);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function getPrograms(lang) {
  return new Promise((resolve, reject) => {
    axios
      .get(`/academy/programs/${lang}`)
      .then(({ programs }) => {
        resolve(programs);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function validateProgramStep(programId, stepId, data) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/academy/program/${programId}/step/${stepId}/validate`, data)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}

function resetProgram(programId) {
  return new Promise((resolve, reject) => {
    axios
      .post(`/academy/program/${programId}/reset`)
      .then((data) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e.response.data);
      });
  });
}
