import React from 'react';
import LogisticRoutes from '../routes/LogisticRoutes';
import Header from '../components/Layout/Logistic/Header';
import MenuDrawer from '../components/Layout/Logistic/MenuDrawer';
import NoSsr from '@mui/material/NoSsr';
import { initLanguage } from '../helpers/multilingual';
import logisticTheme from '../assets/styles/themes/logisticTheme';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Box from '@mui/material/Box';
import useDateFns from '../helpers/useDateFns';

function LogisticLayout() {
  initLanguage('fr');
  const { locale, localeText } = useDateFns();

  return (
    <NoSsr>
      <ThemeProvider theme={logisticTheme}>
        <CssBaseline />
        <Header />
        <Box sx={{ display: 'flex' }}>
          <MenuDrawer />
          <Box
            component={'main'}
            sx={(theme) => ({
              flexGrow: 1,
              padding: theme.spacing(2),
              [theme.breakpoints.down('sm')]: {
                paddingLeft: 0,
                paddingRight: 0,
              },
            })}
          >
            <Box sx={{ minHeight: 'calc(100vh - 64px)' }}>
              <LocalizationProvider
                adapterLocale={locale}
                dateAdapter={AdapterDateFns}
                localeText={localeText}
              >
                <LogisticRoutes />
              </LocalizationProvider>
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </NoSsr>
  );
}

export default LogisticLayout;
