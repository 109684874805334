import React, { useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { shopActions } from './store/actions';
import {
  sessionSelector,
  shopCatalogProductsSelector,
} from './store/selectors';
import { red } from '@mui/material/colors';
import { Helmet } from 'react-helmet-async';
import Breadcrumb from '../../../components/Layout/Customer/Breadcrumb';
import CatalogItem from './components/CatalogItem';
import Button from '@mui/material/Button';
import { Link } from '../../../helpers/multilingual';
import Header from '../../../components/Layout/Customer/Header/Header';

const useStylesShop = makeStyles((theme) => ({
  container: {
    paddingTop: 70,
    paddingBottom: 40,
  },
  pageTitle: {
    paddingTop: 50,
    paddingBottom: 30,
    '& h1': {
      fontWeight: 'bold',
      marginBottom: 5,
    },
    '& .MuiIconButton-root': {
      padding: 11,
    },
    [theme.breakpoints.down('md')]: {
      //paddingTop: 0,
    },
    '& .deliveryCountry': {
      height: 50,
      lineHeight: '50px',
    },
    '& .proShop': {
      color: red[500],
      fontWeight: 'bold',
    },
  },
  filtersContainer: {
    marginBottom: 30,
    '& > .MuiGrid-container': {
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
      },
      '& .MuiButtonGroup-root': {
        marginBottom: 20,
      },
    },
  },
  itemsContainer: {},
  marginTop40: {
    marginTop: 40,
  },
  item: {
    position: 'relative',
    borderRadius: 10,
    '& .MuiCardMedia-img': {
      objectFit: 'contain',
    },
    '& .MuiCardContent-root': {
      padding: 16,
      overflow: 'hidden',
      '& a': {
        textDecoration: 'none',
        '& .MuiTypography-h6': {
          color: '#000',
          marginBottom: 2,
          lineHeight: '24px',
        },
      },
    },
  },
}));

const Catalog = () => {
  const classes = useStylesShop();
  const dispatch = useDispatch();
  const session = useSelector(sessionSelector);
  const { i18n, t } = useTranslation();
  const products = useSelector(shopCatalogProductsSelector);

  useEffect(() => {
    if (
      !Array.isArray(products) ||
      !products.length ||
      products[0].detail.lang !== i18n.language
    ) {
      dispatch(shopActions.getCatalogProductsRequest(i18n.language));
    }
  }, [i18n.language, session]);

  return (
    <div className={classes.container}>
      <Helmet>
        <title itemProp={'name'}>{t('catalog')}</title>
        <meta content={t('catalog')} property="og:title" />
        <meta content={t('catalogMetaDescription')} name="description" />
      </Helmet>
      <Header />
      <Container fixed maxWidth="xl">
        <div className={classes.pageTitle}>
          <Grid
            alignItems={'flex-end'}
            container
            justifyContent={'space-between'}
            spacing={2}
          >
            <Grid item>
              <Typography color={'secondary'} component="h1" variant="h4">
                <Trans i18nKey="catalog">Catalog</Trans>
              </Typography>
              <Breadcrumb
                parents={[{ name: <Trans i18nKey="catalog">Catalog</Trans> }]}
              />
            </Grid>
            <Grid item>
              <Button
                color={'secondary'}
                component={Link}
                size={'large'}
                to={'/shop'}
                variant={'contained'}
              >
                <Trans i18nKey={'shopTitle'}>Shop</Trans>
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.itemsContainer}>
          <Grid container spacing={1}>
            {products.map((product) => (
              <Grid item key={product.id} xs={12}>
                <CatalogItem className={classes.item} product={product} />
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default Catalog;
