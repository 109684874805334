import React, { useEffect, useState } from 'react';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import { statsActions } from '../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import BarChart from '../../../../components/Charts/BarChart';
import {
  networkTurnoverByCareerDataSelector,
  networkTurnoverByCareerGraphDataSelector,
} from '../store/selectors';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';

const NetworkTurnoverByCareerChart = ({ user }) => {
  const dispatch = useDispatch();
  const [isYearPickerOpen, setYearPickerOpen] = useState(false);
  const [isMonthPickerOpen, setMonthPickerOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [yearRange, setYearRange] = useState(false);
  const { data, labels } = useSelector(
    networkTurnoverByCareerGraphDataSelector(
      user.id,
      format(selectedDate, 'yyyy'),
      !yearRange && format(selectedDate, 'MM'),
    ),
  );
  const turnoverByCareer = useSelector(
    networkTurnoverByCareerDataSelector(
      user.id,
      format(selectedDate, 'yyyy'),
      !yearRange && format(selectedDate, 'MM'),
    ),
  );

  useEffect(() => {
    dispatch(
      statsActions.getNetworkTurnoverByCareerRequest(
        user.id,
        format(selectedDate, 'yyyy'),
        !yearRange && format(selectedDate, 'MM'),
      ),
    );
  }, [selectedDate, yearRange]);

  const handleYearChange = (e) => {
    setSelectedDate(e);
    setYearPickerOpen(false);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
    setMonthPickerOpen(false);
  };

  const handleChangeRangeSelector = (e) => {
    setYearRange(e.target.checked);
  };

  return (
    <Card>
      <CardHeader
        sx={{ height: 95 }}
        title={
          <Grid container justifyContent={'space-between'}>
            <Grid item>Chiffre d'affaire par carrière</Grid>
            <Grid item xs={12}>
              <Grid container justifyContent={'flex-end'}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={yearRange}
                        name="range"
                        onChange={handleChangeRangeSelector}
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                      />
                    }
                    label="Par année"
                  />
                </Grid>
                <Grid item>
                  {yearRange && (
                    <MobileDatePicker
                      maxDate={new Date()}
                      onChange={handleYearChange}
                      onClose={() => setYearPickerOpen(false)}
                      onMonthChange={(e) => handleYearChange(e)}
                      onOpen={() => setYearPickerOpen(true)}
                      open={isYearPickerOpen}
                      openTo="month"
                      slotProps={{
                        mobilePaper: {
                          elevation: 2,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: 1,
                          width: 120,
                        },
                      }}
                      value={selectedDate}
                      views={['year']}
                    />
                  )}
                  {!yearRange && (
                    <MobileDatePicker
                      maxDate={new Date()}
                      onChange={handleDateChange}
                      onClose={() => setMonthPickerOpen(false)}
                      onMonthChange={(e) => handleDateChange(e)}
                      onOpen={() => setMonthPickerOpen(true)}
                      open={isMonthPickerOpen}
                      openTo="month"
                      slotProps={{
                        mobilePaper: {
                          elevation: 2,
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-input': {
                          padding: 1,
                          width: 120,
                        },
                      }}
                      value={selectedDate}
                      views={['year', 'month']}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
      <Divider />
      <CardContent>
        <BarChart
          color={{
            border: 'rgb(89,80,236)',
            background: 'rgba(89,80,236, 0.04)',
          }}
          data={data}
          displayLegend={false}
          heightLg={350}
          heightMd={300}
          heightSm={300}
          labels={labels}
          title={"Chiffre d'affaire par carrière"}
        />
      </CardContent>
      <Grid container>
        {turnoverByCareer.map((career) => (
          <Grid item key={career.name} xs={3}>
            <Typography align={'center'} component={'div'} gutterBottom>
              <Typography>{career.name}</Typography>
              <Typography variant={'h6'}>{career.amount || 0} SCR</Typography>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};
export default NetworkTurnoverByCareerChart;
