import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  activeDiscountCodeSelector,
  checkoutSelector,
  giftCardSelector,
  giftCardsSelector,
} from '../../store/selectors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import PublishIcon from '@mui/icons-material/Publish';
import { Trans, useTranslation } from 'react-i18next';
import { shopActions } from '../../store/actions';
import {
  isPro,
  userSelector,
} from '../../../../Authentication/store/selectors';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import { shopService } from '../../store/services';
import {
  SnackbarError,
  SnackbarFormattedError,
} from '../../../../../helpers/snackbar';
import makeStyles from '@mui/styles/makeStyles';
import { red } from '@mui/material/colors';
import { green } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(() => ({
  root: {
    '& .green': {
      '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
        {
          borderColor: green[600],
        },
      '& .MuiInputBase-input.Mui-disabled': {
        color: green[600],
      },
      '& .MuiIconButton-root.Mui-disabled': {
        color: green[600],
      },
      '&.MuiTypography-body1': {
        color: green[600],
      },
    },
    '& .selected': {
      color: green[600],
    },
    '& .red': {
      '& .MuiInputBase-input': {
        color: red[600],
      },
      '& .MuiIconButton-root': {
        color: red[600],
      },
    },
    '& .discounts': {
      '& .displayedMessage': {
        marginTop: 16,
        '& p': {
          marginTop: 0,
          marginBottom: 0,
          color: '#000 !important',
        },
        '& br': {
          display: 'none',
        },
        '& strong': {
          fontWeight: 'normal',
          color: '#000 !important',
        },
      },
      paddingLeft: 0,
    },
  },
}));

const GiftCardSelector = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const userIsPro = useSelector(isPro);
  const selectedGiftCard = useSelector(giftCardSelector);
  const userGiftCards = useSelector(giftCardsSelector);
  const activeDiscountCode = useSelector(activeDiscountCodeSelector);
  const checkout = useSelector(checkoutSelector);
  const [userGiftCardCode, setUserGiftCardCode] = useState('');
  const [userGiftCardCodeError, setUserGiftCardCodeError] = useState(false);

  useEffect(() => {
    dispatch(shopActions.getGiftCardsRequest());
  }, [user]);

  useEffect(() => {
    if (!activeDiscountCode && userGiftCards.length > 0) {
      dispatch(shopActions.selectGiftCard(userGiftCards[0]));
    }
  }, [userGiftCards]);

  const handleChangeGiftCard = (e) => {
    setUserGiftCardCode(e.target.value);
    setUserGiftCardCodeError(false);
    const codeSplit = e.target.value.split('-');
    if (codeSplit[1]?.length === 6) {
      handleValidateAndSubmitGiftCardByCode(e.target.value);
    }
  };

  const handleValidateAndSubmitGiftCardByCode = (code) => {
    shopService
      .getGiftCardByCode(code)
      .then((giftCard) => {
        if (
          giftCard.currency !== 'PERCENT' &&
          (!checkout ||
            checkout.order.totalPrice + checkout.order.taxes < giftCard.amount)
        ) {
          setUserGiftCardCodeError(true);
          SnackbarError(t('errorMessageGiftCardAmount'));
          return;
        }
        setUserGiftCardCode(`${giftCard.code}`);
        dispatch(shopActions.selectGiftCard(giftCard));
      })
      .catch((e) => {
        setUserGiftCardCodeError(true);
        SnackbarFormattedError(e.error);
      });
  };

  const handleUnselectGiftCard = () => {
    setUserGiftCardCode('');
    dispatch(shopActions.selectGiftCard(``));
  };

  const handleSelectGiftCardByCodeInput = (e) => {
    e.preventDefault();
    if (!userGiftCardCode) {
      return;
    }
    handleValidateAndSubmitGiftCardByCode(userGiftCardCode);
  };

  const handleSelectGiftCardByCodeSelector = (code) => {
    return () => {
      handleValidateAndSubmitGiftCardByCode(code);
    };
  };

  if (userIsPro) {
    return null;
  }

  return (
    <Grid item xs={12}>
      <Grid
        className={classes.root}
        container
        justifyContent={'flex-start'}
        spacing={1}
      >
        <Grid item xs={12}>
          <Typography component="h4" variant={'h6'}>
            <Trans i18nKey="AGSGiftCard">Sharks gift card</Trans> :
          </Typography>
        </Grid>
        <Grid
          className={
            selectedGiftCard ? 'green' : userGiftCardCodeError ? 'red' : ''
          }
          item
          xs={12}
        >
          <Grid alignItems={'center'} container spacing={1}>
            <Grid item sm={6} xs={12}>
              <form onSubmit={handleSelectGiftCardByCodeInput}>
                <OutlinedInput
                  disabled={!!selectedGiftCard && !selectedGiftCard.displayed}
                  endAdornment={
                    <InputAdornment position="end">
                      {!selectedGiftCard || selectedGiftCard.displayed ? (
                        <IconButton
                          edge="end"
                          onClick={handleSelectGiftCardByCodeInput}
                          type={'submit'}
                        >
                          <PublishIcon />
                        </IconButton>
                      ) : (
                        <>
                          <IconButton disabled edge="end" type={'submit'}>
                            <CheckIcon />
                          </IconButton>
                          <IconButton
                            edge="end"
                            onClick={handleUnselectGiftCard}
                          >
                            <CloseIcon />
                          </IconButton>
                        </>
                      )}
                    </InputAdornment>
                  }
                  error={userGiftCardCodeError}
                  fullWidth
                  onChange={handleChangeGiftCard}
                  placeholder={t('enterTheGiftCard')}
                  type={'text'}
                  value={
                    selectedGiftCard && !selectedGiftCard?.displayed
                      ? `${selectedGiftCard.code}`
                      : userGiftCardCode
                  }
                />
              </form>
            </Grid>
          </Grid>
          <Grid alignItems={'center'} container spacing={1}>
            <Grid item xs={12}>
              <List>
                {activeDiscountCode && (
                  <ListItem className={'discounts'}>
                    <ListItemText
                      disableTypography
                      primary={
                        <Grid alignItems={'center'} container spacing={2}>
                          <Grid item>
                            <Typography>
                              {`${
                                activeDiscountCode.displayedName[i18n.language]
                                  ? activeDiscountCode.displayedName[
                                      i18n.language
                                    ]
                                  : t('discount')
                              } (- ${activeDiscountCode.amount}%) :`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {activeDiscountCode.id === selectedGiftCard?.id && (
                              <Typography className={'selected'}>
                                {t('selected')} {activeDiscountCode.code}
                              </Typography>
                            )}
                            {activeDiscountCode.id !== selectedGiftCard?.id && (
                              <Button
                                aria-label="comments"
                                color={'primary'}
                                edge="end"
                                onClick={handleSelectGiftCardByCodeSelector(
                                  `${activeDiscountCode.code}`,
                                )}
                                size={'small'}
                                variant={'outlined'}
                              >
                                {t('useTheCode')} {activeDiscountCode.code}
                              </Button>
                            )}
                          </Grid>
                        </Grid>
                      }
                      secondary={
                        <div>
                          {activeDiscountCode.displayedMessage && (
                            <div className={'displayedMessage'}>
                              <Typography
                                dangerouslySetInnerHTML={{
                                  __html:
                                    activeDiscountCode.displayedMessage[
                                      i18n.language
                                    ],
                                }}
                              />
                            </div>
                          )}
                        </div>
                      }
                    />
                  </ListItem>
                )}

                <>
                  {userGiftCards.map((giftCard) => (
                    <ListItem className={'discounts'} key={giftCard.id}>
                      <ListItemText
                        primary={
                          <Grid alignItems={'center'} container spacing={2}>
                            <Grid item>
                              <Typography>
                                {`${t('giftCard')} (${giftCard.amount} ${
                                  giftCard.currency
                                }) :`}
                              </Typography>
                            </Grid>
                            <Grid item>
                              {giftCard.id === selectedGiftCard?.id && (
                                <Typography className={'selected'}>
                                  {t('selected')} {giftCard.code}
                                </Typography>
                              )}
                              {giftCard.id !== selectedGiftCard?.id && (
                                <Button
                                  aria-label="comments"
                                  color={'primary'}
                                  edge="end"
                                  onClick={handleSelectGiftCardByCodeSelector(
                                    `${giftCard.code}`,
                                  )}
                                  size={'small'}
                                  variant={'outlined'}
                                >
                                  {t('useTheCode')} {giftCard.code}
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        }
                      />
                    </ListItem>
                  ))}
                </>
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default GiftCardSelector;
