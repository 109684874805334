import React, { forwardRef, useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import makeStyles from '@mui/styles/makeStyles';
import { FormValidatorContext } from './FormValidator';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';

const useStyle = makeStyles(() => ({
  root: {
    '& .MuiFormHelperText-root': {
      color: '#f44336',
    },
  },
  fullWidth: {
    width: '100%',
  },
}));

const SelectValidator = forwardRef(
  (
    {
      children,
      className,
      fullWidth,
      id,
      label,
      name,
      onChange,
      required,
      value,
      variant,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const classes = useStyle();
    const { setValidationFields, submitted } = useContext(FormValidatorContext);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState(
      t('validatorFieldRequired'),
    );

    useEffect(() => {
      validate(value);
    }, [value]);

    useEffect(() => {
      setValidationFields((fields) => ({ ...fields, [name]: error }));
    }, [error]);

    useEffect(() => {
      //COMPONENT WILL UNMOUNT
      return () => {
        setValidationFields((fields) => {
          delete fields[name];
          return fields;
        });
      };
    }, []);

    const validate = (targetValue) => {
      let hasError = false;
      if (required) {
        hasError = !targetValue;
        setError(hasError);
        setErrorMessage(t('validatorFieldRequired'));
      }
    };

    const handleChange = (e) => {
      validate(e.target.value);
      onChange(e);
    };

    return (
      <FormControl
        className={classnames(
          classes.root,
          fullWidth && classes.fullWidth,
          className,
        )}
        error={submitted && error}
        ref={ref}
        variant={variant ? variant : 'outlined'}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          MenuProps={{
            disableScrollLock: true,
          }}
          id={id}
          label={label}
          name={name}
          onChange={handleChange}
          required={required}
          value={value}
        >
          {children}
        </Select>
        <FormHelperText>{submitted && error && errorMessage}</FormHelperText>
      </FormControl>
    );
  },
);

export default SelectValidator;
